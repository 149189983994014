import React from 'react';
import Paginator from 'components/Paginator';
import SemanticTableGrid from 'semantic-table-grid';

// Types
import { DataGridProps } from './types';

const DataGrid: React.FC<DataGridProps> = ({
  isLoading,
  elements = [],
  columns,
  activePage,
  totalPages,
  onPageChange,
}) => {
  return (
    <>
      <div className="contentGrid">
        <SemanticTableGrid
          isLoading={isLoading}
          // elements={(canFilter ? [filterRow] : []).concat(elements)}
          elements={elements}
          rowClassKey="class"
          // rowUniqueKey={rowUniqueKey}
          columns={columns}
          // rawProps={{
          //   celled: false,
          //   singleLine: true,
          //   padded: 'very',
          // }}
          // canSort={{
          //   active: true,
          //   onSort,
          // }}
          // canPaginate={{
          // canPaginate={{
          //   active: paginate,
          //   pageCount,
          //   pageSize,
          //   totalItems,
          //   page,
          //   onSelect: onPageSelect,
          //   render: (
          //     <Pagination
          //       activePage={page}
          //       floated="right"
          //       onPageChange={(_event, data) =>
          //         onPageSelect(data.activePage || 1)
          //       }
          //       totalPages={pageCount}
          //     />
          //   ),
          // }}
          hiddenHeaderIfEmpty
          emptyResults={
            isLoading
              ? 'Caricando la lista...'
              : 'Nessun elemento disponibile...'
          }
        />
      </div>
      <div className="tableFooter">
        <Paginator
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          shortText
        />
      </div>
    </>
  );
};

export default DataGrid;
