import React, { useContext } from 'react';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import { Grid } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { ResetPasswordFormProps, ResetPasswordFormValues } from './types';
import { AppContext } from 'pages/App';
import TooltipPassword from 'components/TooltipPassword';

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { state } = useContext(AppContext);

  const completeInitialValues: ResetPasswordFormValues = {
    ...initialValues,
    plainPassword: null,
    plainPasswordCheck: null,
  };
  return (
    <Formik
      initialValues={completeInitialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        plainPassword: Yup.string().required(),
        plainPasswordCheck: Yup.string().oneOf(
          [Yup.ref('plainPassword'), null],
          'Le password devono corrispondere',
        ),
      })}
    >
      <Form>
        <Grid className="reset-password-form">
          <Grid.Row>
            <Input type="hidden" name="token" value="" />
            <ErrorMessage name="token" component="span" />
          </Grid.Row>
          <Grid.Row>
            <Input
              readOnly
              name="username"
              placeholder="Username / P.IVA"
              label={
                <FormattedMessage
                  id="username"
                  defaultMessage="Username / P.IVA"
                />
              }
            />
            <ErrorMessage name="email" component="span" />
          </Grid.Row>
          <Grid.Row>
            <Input
              type="password"
              name="plainPassword"
              placeholder="Password"
              label={
                <span>
                  <FormattedMessage
                    id="plainPassword"
                    defaultMessage="Nuova password"
                  />
                  <span>
                    <TooltipPassword />
                  </span>
                </span>
              }
            />
            <ErrorMessage
              name="plainPassword"
              render={() => (
                <div className="error-message">Campo obbligatorio</div>
              )}
            />
          </Grid.Row>
          <Grid.Row>
            <Input
              type="password"
              name="plainPasswordCheck"
              placeholder="Conferma password"
              label={
                <span>
                  <FormattedMessage
                    id="plainPasswordCheck"
                    defaultMessage="Conferma password"
                  />
                  <span>
                    <TooltipPassword />
                  </span>
                </span>
              }
            />
            <ErrorMessage
              name="plainPasswordCheck"
              render={() => (
                <div className="error-message">
                  La conferma della password non corrisponde
                </div>
              )}
            />
          </Grid.Row>
          <Grid.Row>
            <SubmitButton
              content={
                state.isUserLoading ? (
                  ''
                ) : (
                  <FormattedMessage id="resetPassword" defaultMessage="Login" />
                )
              }
              loading={state.isUserLoading}
              disabled={state.isUserLoading}
            />
          </Grid.Row>
        </Grid>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
