import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

// Components
import CustomerMetalResumeList from 'components/CustomerMetalResume/CustomerMetalResumeList';
import PageHeader from 'components/PageHeader';

// Constants - Internals - Utils
import { AppContext } from 'pages/App';
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import { initialMetalResumeState, metalResumeReducer } from './reducer/reducer';
import { MetalResumeAction } from './reducer/action';

// Services
import {
  getMetalResume,
  getMetalResumeAccountEntries,
} from 'services/customer/getMetalResumes';

// Types
import { MetalResumeState } from './types';
import { useIntl } from 'react-intl';
import MetalResumeAccountList from 'components/CustomerMetalAccountEntries/CustomerMetalAccountList';
import { isThatRole } from 'utils/function/acl';
import _ from 'lodash';

export const MetalResumeContext = createContext<{
  state: MetalResumeState;
  dispatch: React.Dispatch<MetalResumeAction>;
}>({
  state: initialMetalResumeState,
  dispatch: () => null,
});

const MetalResumes: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const appState = useContext(AppContext).state;
  const mySelf = appState.mySelf;

  const [state, dispatch] = useReducer(
    metalResumeReducer,
    initialMetalResumeState,
  );

  useEffect(() => {
    // Some customer don't the access to metal account data
    // Eventually redirect it to the main page
    if (
      !mySelf._permissions.customerMetalAccountEnabled &&
      !isThatRole(ROLES.CUSTOMER_AGENT, appState)
    ) {
      navigate(FE_ROUTES.DASHBOARD);
    }

    if (isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      constCallMetalResumeForAgent();
    } else {
      getMetalResume(dispatch);
      getMetalResumeAccountEntries(dispatch);
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    constCallMetalResumeForAgent();
  }, [appState.selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const constCallMetalResumeForAgent = () => {
    if (!_.isEmpty(appState.selectedContact)) {
      getMetalResume(dispatch, appState.selectedContact.contactCode);
      getMetalResumeAccountEntries(
        dispatch,
        appState.selectedContact.contactCode,
      );
    }
  };

  return (
    <MetalResumeContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'customer.metal.resume.title.page',
            defaultMessage: 'MSM - Estratto conto metallo',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'customer.metal.resume.title.header',
          defaultMessage: 'Estratto conto',
        })}
      />
      <div className="metal-resume-tables-container">
        <MetalResumeAccountList />
        <CustomerMetalResumeList />
      </div>
    </MetalResumeContext.Provider>
  );
};

export default MetalResumes;
