import React, { useContext } from 'react';
import SemanticTableGrid from 'semantic-table-grid';
import SvgIcon from 'components/SvgIcon';
import { MetalResumeContext } from 'pages/MetalResume';
import { CompleteMetalResumeAccountTableProps } from './types';
import { CompleteMetalResumeAccountTableColumns } from './types';
import { MetalResumeConstants } from 'pages/MetalResume/reducer/action';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';

const CompleteMetalResumeAccountTable: React.FC<
  CompleteMetalResumeAccountTableProps
> = ({ metalResumes }) => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const { state, dispatch } = useContext(MetalResumeContext);

  const columns: Array<CompleteMetalResumeAccountTableColumns> = [
    {
      key: 'metalResumeEntryType',
      sortable: false,
      name: (
        <FormattedMessage
          id="metal.resume.table.header.metalResumeEntryType"
          defaultMessage="Tipo"
        />
      ),
      formatter: ({ data }) => (
        <>
          {data.entryType === 'Correction' && (
            <Popup
              className="tooltip-password"
              hoverable
              trigger={
                <span>
                  <SvgIcon
                    icon={ICONS.WARNING_CIRCLE}
                    color={COLORS.SECONDARY}
                  />
                </span>
              }
              basic
              content="Correzione"
            />
          )}
          {data.entryType === 'Inbound' && (
            <Popup
              className="tooltip-password"
              content={
                <span className="tooltip-password-content">In entrata</span>
              }
              basic
              hoverable
              trigger={
                <span>
                  <SvgIcon
                    icon={ICONS.ARROW_DOWN}
                    color={COLORS.SUCCESS}
                    className="arrow-rotate-up"
                  />
                </span>
              }
            />
          )}
          {data.entryType === 'Outbound' && (
            <Popup
              className="tooltip-password"
              content={
                <span className="tooltip-password-content">In uscita</span>
              }
              basic
              hoverable
              trigger={
                <span>
                  <SvgIcon icon={ICONS.ARROW_DOWN} color={COLORS.ERROR} />
                </span>
              }
            />
          )}
        </>
      ),
    },
    {
      key: 'postingDate',
      sortable: true,
      name: (
        <div
          className="sortable-header"
          onClick={() =>
            dispatch({
              type: MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL_ACCOUNT,
              payload: {
                activeSortAccount:
                  state.activeSortAccount === 'postingDate-asc'
                    ? 'postingDate-desc'
                    : 'postingDate-asc',
              },
            })
          }
        >
          <FormattedMessage
            id="metal.resume.table.header.metalResumePostingDate"
            defaultMessage="Data"
          />
          {state.activeSortAccount === 'postingDate-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {state.activeSortAccount === 'postingDate-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => moment(data.postingDate).format('DD/MM/YYYY'),
    },
    {
      key: 'metalResumeItemNo',
      sortable: false,
      name: (
        <FormattedMessage
          id="metal.resume.table.header.metalResumeAccountItemNo"
          defaultMessage="Numero documento"
        />
      ),
      formatter: ({ data }) => data.documentNo,
    },
    {
      key: 'metalResumeItemDescription',
      sortable: false,
      name: (
        <FormattedMessage
          id="metal.resume.table.header.item"
          defaultMessage="Articolo"
        />
      ),
      formatter: ({ data }) => data.itemDescription,
    },
    {
      key: 'metalResumeQty',
      sortable: false,
      name: (
        <FormattedMessage
          id="metal.resume.table.header.quantity"
          defaultMessage="Quantità lorda"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.qty)) +
        ' ' +
        data.unit_of_Measure,
    },
    {
      key: 'metalYield',
      sortable: false,
      name: (
        <FormattedMessage
          id="metal.resume.table.header.yield"
          defaultMessage="Resa"
        />
      ),
      formatter: ({ data }) => data.yield,
    },
    {
      key: 'metalResumeQtyAlloy',
      sortable: true,
      name: (
        <FormattedMessage
          id="metal.resume.table.header.metalResumeAccountQtyAlloy"
          defaultMessage="Quantità netta"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.qty_Alloy)) +
        ' ' +
        data.unit_of_Measure,
    },
  ];

  return (
    <div
      className={`semantic-table-grid-standard ${
        state.isMetalActivityCollapsed ? 'metal-resume-collapsed' : ''
      }`}
    >
      <SemanticTableGrid
        className="metal-resume-table"
        columns={columns}
        elements={metalResumes}
        emptyResults={intl.formatMessage({
          id: 'customerMetalAccountTable.table.empty',
          defaultMessage: 'Nessun Elemento disponibile',
        })}
      />
    </div>
  );
};

export default CompleteMetalResumeAccountTable;
