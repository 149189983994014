import { API } from 'utils/global/backendRoutes';
import { AxiosResponse } from 'axios';
import standardClient from 'services/client/standardRequestClient';
import { CustomerDashboardData } from 'components/Dashboards/Customer/types';

export const getCustomerDashboard = (
  setAreDashboardDataLoading: (value: boolean) => void,
  setDashboardData: (dashboardData: CustomerDashboardData) => void,
): void => {
  setAreDashboardDataLoading(true);

  standardClient
    .request({
      url: API.CUSTOMER_DASHBOARD,
      method: 'GET',
    })
    .then((response: AxiosResponse<CustomerDashboardData>) => {
      setDashboardData(response.data);
    })
    .finally(() => setAreDashboardDataLoading(false));
};
