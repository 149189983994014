import { CreditsLimitState } from '../types';
import { CreditsLimitAction, CreditsLimitConstants } from './action';

export const initialCreditsLimitState: CreditsLimitState = {
  isCreditsLimitLoading: false,
  creditsLimitData: {
    creditLimitAmount: '',
    currencyCode: '',
    customerNo: '',
    lines: [],
    totalAmount: '',
    totalExpiredAmount: '',
  },
};

export const CreditsLimitReducer = (
  state: CreditsLimitState,
  action: CreditsLimitAction,
): CreditsLimitState => {
  const { type } = action;

  switch (type) {
    case CreditsLimitConstants.SET_CREDITS_LIMIT_DATA:
      return {
        ...state,
        creditsLimitData: action.payload.creditsLimit,
      };
    case CreditsLimitConstants.SET_CREDITS_LIMIT_LOADING:
      return {
        ...state,
        isCreditsLimitLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
};
