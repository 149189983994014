import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CompleteMetalResumeTableColumns,
  CompleteMetalResumeTableProps,
} from './types';
import SemanticTableGrid from 'semantic-table-grid';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { MetalResumeContext } from 'pages/MetalResume';
import { MetalResumeConstants } from 'pages/MetalResume/reducer/action';
import moment from 'moment';
import { getCustomerDocumentFromEdok } from 'services/document/getCustomerDocuments';
import { AppContext } from 'pages/App';

export const CompleteMetalResumeTable: React.FC<
  CompleteMetalResumeTableProps
> = ({ metalResumes }) => {
  const intl = useIntl();
  const { state, dispatch } = useContext(MetalResumeContext);

  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const columns: Array<CompleteMetalResumeTableColumns> = [
    {
      key: 'metalResumeNumber',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="metal.resume.table.header.period"
            defaultMessage="Mese"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <span style={{ textTransform: 'capitalize' }}>{data.period}</span>
      ),
    },
    {
      key: 'metalResumeStartDate',
      sortable: true,
      name: (
        <div
          className="metal-resume-table-header sortable-header"
          onClick={() =>
            dispatch({
              type: MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL,
              payload: {
                activeSortMetal:
                  state.activeSortMetal === 'start-date-asc'
                    ? 'start-date-desc'
                    : 'start-date-asc',
              },
            })
          }
        >
          <FormattedMessage
            id="metal.resume.table.header.metalResumeStartDate"
            defaultMessage="Data Inizio"
          />
          {state.activeSortMetal === 'start-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {state.activeSortMetal === 'start-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) =>
        moment(data.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      key: 'metalResumeEndDate',
      sortable: true,
      name: (
        <div
          className="metal-resume-table-header sortable-header"
          onClick={() =>
            dispatch({
              type: MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL,
              payload: {
                activeSortMetal:
                  state.activeSortMetal === 'end-date-asc'
                    ? 'end-date-desc'
                    : 'end-date-asc',
              },
            })
          }
        >
          <FormattedMessage
            id="metal.resume.table.header.metalResumeEndDate"
            defaultMessage="Data Fine"
          />
          {state.activeSortMetal === 'end-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {state.activeSortMetal === 'end-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) =>
        moment(data.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <span
            className="actionMenu-button"
            onClick={() =>
              getCustomerDocumentFromEdok(
                data.edokVolumeId,
                data.edokDocumentId,
                intl,
                selectedContact?.name,
              )
            }
          >
            <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="semantic-table-grid-standard">
      <SemanticTableGrid
        columns={columns}
        elements={metalResumes}
        emptyResults={intl.formatMessage({
          id: 'completeMetalResumeTable.table.empty',
          defaultMessage: 'Nessun Elemento disponibile',
        })}
      />
    </div>
  );
};

export default CompleteMetalResumeTable;
