import { LangProviderContext } from 'components/LangProvider';
import SvgIcon from 'components/SvgIcon';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Modal, Popup } from 'semantic-ui-react';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { CalendarPickerProps } from './types';

// Locale date
import { it, enGB } from 'date-fns/locale';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const ReservationCalendarPicker: React.FC<CalendarPickerProps> = ({
  blockedDays = [],
  className = '',
  isOpen,
  onClose,
  onChange,
  value,
  minDate = null,
  maxDate = null,
  availableDays = [],
}) => {
  const { language } = useContext(LangProviderContext);
  const [pickerDate, setPickerDate] = useState(
    !_.isEmpty(value) ? new Date(value) : null,
  );

  useEffect(() => {
    setPickerDate(!_.isEmpty(value) ? new Date(value) : null);
  }, [value]);

  const confirmDateChange = () => {
    onChange(pickerDate);
    onClose();
  };

  return (
    <Modal
      className={`calendar-picker-modal ${className}`}
      dimmer={{ className: 'calendar-picker-dimmer' }}
      onClose={onClose}
      open={isOpen}
      size="tiny"
    >
      <Modal.Content>
        <span className="calendar-picker-close" onClick={() => onClose()}>
          <SvgIcon
            color={COLORS.MID_GREY}
            icon={ICONS.CROSS}
            height={12}
            width={12}
          />
        </span>
        <ReactDatePicker
          onChange={setPickerDate}
          selected={pickerDate}
          calendarStartDay={1} // Calendart start from Monday
          inline
          includeDates={availableDays}
          excludeDates={blockedDays}
          locale={language === 'it-IT' ? it : enGB}
          minDate={!_.isNull(minDate) ? minDate.toDate() : null}
          maxDate={!_.isNull(maxDate) ? maxDate.toDate() : null}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            nextMonthButtonDisabled,
            prevMonthButtonDisabled,
          }) => (
            <div className="calendar-picker-header">
              <div className="calendar-picker-header-text">
                {moment(date).format('MMMM YYYY')}
              </div>
              <div className="calendar-picker-header-buttons">
                <Button
                  className="only-icon-button"
                  content={
                    <SvgIcon icon={ICONS.ARROW_LEFT} width={15} height={22} />
                  }
                  disabled={prevMonthButtonDisabled}
                  onClick={() => decreaseMonth()}
                />
                {nextMonthButtonDisabled ? (
                  <Popup
                    className="tooltip-password"
                    hoverable
                    trigger={
                      <Button
                        className="only-icon-button second-button"
                        icon={
                          <SvgIcon
                            icon={ICONS.ARROW_RIGHT}
                            width={15}
                            height={22}
                          />
                        }
                      />
                    }
                    basic
                    content="Nessun giorno disponibile"
                  />
                ) : (
                  <Button
                    className="only-icon-button second-button"
                    content={
                      <SvgIcon
                        icon={ICONS.ARROW_RIGHT}
                        width={15}
                        height={22}
                      />
                    }
                    onClick={() => increaseMonth()}
                  />
                )}
              </div>
            </div>
          )}
        />
        <div
          className="confirm-date-picker"
          onClick={() => confirmDateChange()}
        >
          <FormattedMessage
            id="calendarPicker.button.confirmDate"
            defaultMessage="Conferma data"
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ReservationCalendarPicker;
