import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

// Internals and constants
import { API } from 'utils/global/backendRoutes';
import { AppAction, appActionConstants } from 'pages/App/reducer/actions';
import authClient from 'services/client/authRequestClient';
// Types
import { StandardResponseError } from 'services/client/types';
import { ResetPasswordFormValues } from 'components/Forms/ResetPasswordForm/types';
import { NavigateFunction } from 'react-router-dom';
import { RpcRequest } from 'utils/types';
import { userLogin } from 'services/auth/userLogin';
import { LoginFormValues } from 'components/Forms/LoginForm/types';

export const userResetPassword = (
  dispatch: React.Dispatch<AppAction>,
  userResetPasswordData: ResetPasswordFormValues,
  navigate: NavigateFunction,
  setLanguage: (language: string) => void,
): void | boolean => {
  // Add to request data also the method param
  const requestData: RpcRequest<ResetPasswordFormValues> = {
    method: 'reset_password',
    params: userResetPasswordData,
  };
  dispatch({
    type: appActionConstants.SET_USER_LOADING,
    payload: { isLoading: true },
  });

  authClient
    .request({
      url: API.PASSWORD_RECOVERY,
      method: 'POST',
      data: requestData,
    })
    .then(() => {
      const loginValues: LoginFormValues = {
        username: userResetPasswordData.username,
        password: userResetPasswordData.plainPassword,
      };
      userLogin(dispatch, loginValues, 'password', navigate, setLanguage);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      toast.error(error.data.detail || 'Impossibile impostare la password');

      dispatch({
        type: appActionConstants.SET_USER_LOADING,
        payload: { isLoading: false },
      });
    });
};
