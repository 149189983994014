import React, { useContext, useEffect, useState } from 'react';
import { CustomerOrder, CustomerOrdersTableColunns } from './types';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerOrders } from 'services/customer/getCustomerDocuments';
import { CUSTOMER_DOCUMENT_METHODS } from 'utils/global/customerCostants';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS, ROLES } from 'utils/global/globalConstants';
import _ from 'lodash';
import { OrdersSupportedSort } from 'pages/UserOrders/types';
import { getCustomerDocument } from 'services/document/getCustomerDocuments';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import CustomerOrderModal from '../CustomerOrderModal';
import { Popup } from 'semantic-ui-react';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';

const CustomerOrders: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const [customerOrdersCollection, setCustomerOrdersCollection] = useState<
    Array<CustomerOrder>
  >([]);
  const [showedCustomerOrdersCollection, setShowedCustomerOrdersCollection] =
    useState<Array<CustomerOrder>>([]);
  const [areCustomerOrdersLoading, setAreCustomerOrdersLoading] =
    useState(false);
  const [activeSort, setActiveSort] =
    useState<OrdersSupportedSort>('delivery-date-desc');

  const [selectedOrderCode, setSelectedOrderCode] = useState('');

  useEffect(() => {
    if (!isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      getCustomerOrders(
        setCompleteOrdersCollection,
        setAreCustomerOrdersLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callOrdersForAgent();
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callOrdersForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerOrders(
        setCompleteOrdersCollection,
        setAreCustomerOrdersLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteOrdersCollection = (
    completeOrdersCollection: Array<CustomerOrder>,
  ) => {
    setCustomerOrdersCollection(completeOrdersCollection);
    const filteredByYearCollection = completeOrdersCollection.filter(order =>
      moment(order.orderDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
    );
    setShowedCustomerOrdersCollection(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let documentsToShow = customerOrdersCollection;

    if (!_.isEmpty(documentNumber)) {
      documentsToShow = documentsToShow.filter(
        document =>
          _.isString(document.no) &&
          document.no.toLowerCase().includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(singleDocument.orderDate),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(singleDocument.orderDate),
      );
    }

    setShowedCustomerOrdersCollection(documentsToShow);
  };

  const onChangeSort = (sortType: OrdersSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedCustomerOrdersCollection(
      onSortOrderCollection(sortType, showedCustomerOrdersCollection),
    );
    setActiveSort(sortType);
  };

  const onSortOrderCollection = (
    sortType: OrdersSupportedSort,
    orderColletion: Array<CustomerOrder>,
  ): Array<CustomerOrder> => {
    let toSortOrdersDocuments = orderColletion;
    switch (sortType) {
      case 'order-number-desc': {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) => {
            if (firstOrder.no > secondOrder.no) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) =>
            moment(firstOrder.orderDate, 'YYYY-MM-DD').diff(
              moment(secondOrder.orderDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) =>
            moment(secondOrder.orderDate, 'YYYY-MM-DD').diff(
              moment(firstOrder.orderDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) => {
            if (firstOrder.no > secondOrder.no) {
              return 1;
            }
            return -1;
          },
        );
      }
    }
    return toSortOrdersDocuments;
  };

  const onCloseModal = () => {
    setSelectedOrderCode(null);
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<CustomerOrder> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return customerOrdersCollection.filter(order =>
        moment(order.orderDate, 'YYYY-MM-DD').isSame(selectedYear, 'year'),
      );
    } else if (selectedYear === 'all') {
      return customerOrdersCollection;
    }
    return customerOrdersCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedCustomerOrdersCollection(
      getFilteredByYearsCollection(selectedYear),
    );
    onChangeSort('delivery-date-desc');
  };

  const columns: Array<CustomerOrdersTableColunns> = [
    {
      key: 'orderNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'order-number-asc'
                ? 'order-number-desc'
                : 'order-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.orders.table.header.orderNumber"
            defaultMessage="N. ordine"
          />
          {activeSort === 'order-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'order-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">{data.no}</div>
      ),
    },
    {
      key: 'externalCode',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.externalCode"
            defaultMessage="N. Ordine cliente"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {data.yourReference}
        </div>
      ),
    },
    {
      key: 'lineNumber',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.orders.table.header.orderDate"
            defaultMessage="Data ordine"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.orderDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'amount',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.bill"
            defaultMessage="Importo (IVA esclusa)"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
          }).format(_.toNumber(data.amount))}
        </div>
      ),
    },
    {
      key: 'payment',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.payment"
            defaultMessage="Metodo e termini pagamento"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-payment">
          <span className="payment-method">{data.paymentMethod}</span>
          <span className="payment-terms">{data.paymentTerms}</span>
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.orders.table.documents.tooltip.details"
                  defaultMessage="Visualizza dettagli"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() => setSelectedOrderCode(data.no)}
              >
                <SvgIcon icon={ICONS.LENS} height={20} width={20} />{' '}
              </span>
            }
          />
          {moment(data.orderDate, 'YYYY-MM-DD').isSameOrAfter(
            moment('2024-01-01').format('DD-MM-YYYY'),
          ) ? (
            <Popup
              className="tooltip-password"
              content={
                <span className="tooltip-password-content">
                  <FormattedMessage
                    id="customerDocuments.orders.table.documents.tooltip.download"
                    defaultMessage="Scarica documento"
                  />
                </span>
              }
              basic
              hoverable
              trigger={
                <span
                  className="actionMenu-button"
                  onClick={() =>
                    getCustomerDocument(
                      data.no,
                      CUSTOMER_DOCUMENT_METHODS.SALES_ORDER,
                      intl,
                      selectedContact?.name,
                    )
                  }
                >
                  <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
                </span>
              }
            />
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomerDocumentsTab
        activeSort={activeSort}
        areDocumentsLoading={areCustomerOrdersLoading}
        completeDocumentsCollection={showedCustomerOrdersCollection}
        filterInputPlaceholder={intl.formatMessage({
          id: 'customerDocuments.orders.filterBar.input.placheolder',
          defaultMessage: 'N. ordine',
        })}
        onChangeSelectedYear={onChangeSelectedYear}
        onFilter={onFilter}
        tableColumns={columns}
        key="customer-orders"
      />

      <CustomerOrderModal
        onClose={onCloseModal}
        orderDocumentCode={selectedOrderCode}
      />
    </>
  );
};

export default CustomerOrders;
