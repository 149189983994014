import _ from 'lodash';

import { ReservationState } from '../types';
import { ReservationAction, ReservationConstants } from './action';

export const initialReservationState: ReservationState = {
  areDataLoading: false,
  isReservationLoading: false,
  isPrevStateLoading: false,
  reservation: null,
  selectedArticleType: null,
  selectedOrdersLine: [],
};

export const reservationReducer = (
  state: ReservationState,
  action: ReservationAction,
): ReservationState => {
  const { type } = action;

  switch (type) {
    case ReservationConstants.ADD_ORDER_LINE: {
      /**
       * @description
       * Different article types check has been disabled as required from the client.
       * For reactivate it remove the commnet to the line code under this comment and in
       * the main component the attribute disable for the checkbox
       */
      /*       if (
        !_.isEmpty(state.selectedOrdersLine) &&
        state.selectedArticleType !== action.payload.articleType
      ) {
        return state;
      } */

      const orderLinesCollection = _.union(state.selectedOrdersLine, [
        action.payload.orderLine,
      ]);

      return {
        ...state,
        selectedArticleType: action.payload.articleType,
        selectedOrdersLine: orderLinesCollection,
      };
    }
    case ReservationConstants.REMOVE_ORDER_LINE: {
      const cleanArray = _.pull(
        state.selectedOrdersLine,
        action.payload.orderLine,
      );

      return {
        ...state,
        selectedOrdersLine: cleanArray,
        selectedArticleType: _.isEmpty(cleanArray)
          ? null
          : state.selectedArticleType,
      };
    }
    case ReservationConstants.SET_ARE_DATA_LOADING:
      return { ...state, areDataLoading: action.payload.areDataLoading };
    case ReservationConstants.SET_RESERVATION:
      return { ...state, reservation: action.payload.reservation };
    case ReservationConstants.SET_RESERVATION_LOADING:
      return {
        ...state,
        isReservationLoading: action.payload.isReservationLoading,
      };
    case ReservationConstants.SET_PREV_RESERVATION_LOADING:
      return {
        ...state,
        isPrevStateLoading: action.payload.isPrevReservationLoading,
      };
    default:
      return state;
  }
};
