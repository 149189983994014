import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AppContext } from 'pages/App';

// Types
import { CheckAuthProps } from './types';
import _ from 'lodash';

const CheckAuth: React.FC<CheckAuthProps> = ({ children, whoCanView }) => {
  const { state } = useContext(AppContext);
  const location = useLocation();

  /**
   * If the myself object and the array roles are defined search if exists
   * at least one element of roles in whoCanView which means that the current
   * user have the permission for the requested route.
   */
  const canView = (): boolean => {
    if (!state.mySelf || _.isEmpty(state.mySelf.roles)) {
      return false;
    }

    if (whoCanView instanceof Array) {
      return whoCanView.some(
        element => state.mySelf.roles.indexOf(element) >= 0,
      );
    }

    return false;
  };

  /**
   * - The required route doesn't have any auth restriction (ANY)
   * - The required route is ANY_NOT_AUTH and the user isn't logged
   * - The required route is ANY_AUTH and the user is logged
   * ==> return the children
   */
  if (
    whoCanView === 'ANY' ||
    (whoCanView === 'ANY_NOT_AUTH' && !state.isAuthenticated) ||
    (whoCanView === 'ANY_AUTH' && state.isAuthenticated)
  ) {
    return children;
  }

  // The required route needs auth and the user is not => redirect to login
  if (!state.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  /**
   * If the page is only for not authenticated user (as login or registration)
   * redirect the already auth user to the homepage
   */
  if (whoCanView === 'ANY_NOT_AUTH' && state.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (canView()) {
    return children;
  }

  // No permission => return to homepage
  return <Navigate to="/" state={{ from: location }} replace />;
};

export default CheckAuth;
