import React from 'react';
import { Image, Popup } from 'semantic-ui-react';

// Types
import { PageHeaderProps } from './types';

/**
 * Customizable component for render a page header with a title, an optional icon
 * on his left. Can also be returned an icon on the left with optionsl popup content on mouse over
 *
 * Ex-> ICON - TITLE                              ICON WITH POPUP ON MOUSE HOVER
 *      ------------------------------(optional)---------------------------------
 */
const PageHeader: React.FC<PageHeaderProps> = ({
  popupContent = null,
  rightIcon,
  title,
  titleIconSrc,
  withBottomBorder,
}) => {
  // If popup content is defiend render the right icon as a popup
  const renderRightIcon = () => {
    // Right icon defined but not the popup content, return only the image
    if (!popupContent) {
      return <Image className="right-icon" src={titleIconSrc} />;
    }

    // Return the Popup element
    return (
      <Popup
        content={popupContent}
        position="bottom center"
        positionFixed
        trigger={<Image className="right-icon" src={titleIconSrc} />}
      />
    );
  };

  return (
    <div className={`page-header ${withBottomBorder ? 'border-bottom' : ''}`}>
      {titleIconSrc && <Image className="title-icon" src={titleIconSrc} />}
      <span className="title">{title}</span>
      {rightIcon && <div className="right-icon">{renderRightIcon()}</div>}
    </div>
  );
};

export default PageHeader;
