import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const check = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 16}
    height={height || 12}
    viewBox="0 0 16 12"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.24255 11.4198C6.51714 12.1452 5.3401 12.1452 4.61504 11.4198L0.544058 7.34878C-0.181353 6.62372 -0.181353 5.44668 0.544058 4.72162C1.26912 3.99621 2.44616 3.99621 3.17157 4.72162L5.59708 7.14677C5.78018 7.32953 6.07742 7.32953 6.26087 7.14677L12.8284 0.579214C13.5535 -0.146196 14.7305 -0.146196 15.4559 0.579214C15.8043 0.927566 16 1.4002 16 1.8928C16 2.38539 15.8043 2.85803 15.4559 3.20638L7.24255 11.4198Z" />
  </svg>
);

export default check;
