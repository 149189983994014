import { AxiosResponse } from 'axios';
import {
  ContactOriginsListAction,
  ContactOriginsListConstants,
} from 'pages/ContactOriginsList/reducer/actions';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { fetchOriginList } from './types';

export const fetchContactOrigin = (
  dispatch: React.Dispatch<ContactOriginsListAction>,
  intl: IntlShape,
): void => {
  dispatch({
    type: ContactOriginsListConstants.SET_IS_LOADING,
    payload: { isLoading: true },
  });

  standardClient({
    url: API.CONTACT_ORIGIN,
    method: 'POST',
    data: {
      method: 'list',
    },
  })
    .then((response: AxiosResponse<fetchOriginList>) => {
      const orderedOrigins = response.data.origins.sort(
        (firstOrigin, secondOrigin) =>
          firstOrigin.name > secondOrigin.name
            ? 1
            : secondOrigin.name > firstOrigin.name
            ? -1
            : 0,
      );
      dispatch({
        type: ContactOriginsListConstants.SET_COMPLETE_ORIGINS_COLLECTION,
        payload: { completeOriginsCollection: orderedOrigins },
      });
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'fetchContactOriginByCompany.error.get',
          defaultMessage: 'Impossibile recupare la lista delle provenienze',
        }),
      ),
    )
    .finally(() =>
      dispatch({
        type: ContactOriginsListConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      }),
    );
};
