import { ReservationListState } from '../types';
import { ReservationListAction, ReservationListConstants } from './action';

export const initialReservationListState: ReservationListState = {
  activaPage: 1,
  areReservationsLoading: false,
  reservationCollection: [],
  totalPages: 1,
  allowedState: [],
};

export const reservationListReducer = (
  state: ReservationListState,
  action: ReservationListAction,
): ReservationListState => {
  const { type } = action;

  switch (type) {
    case ReservationListConstants.SET_ARE_RESERVATIONS_LOADING: {
      return {
        ...state,
        areReservationsLoading: action.payload.areReservationLoading,
      };
    }
    case ReservationListConstants.SET_CURRENT_PAGE: {
      return {
        ...state,
        activaPage: action.payload.currentPage,
      };
    }
    case ReservationListConstants.SET_RESERVATION_COLLECTION:
      return {
        ...state,
        reservationCollection: action.payload.reservationCollection || [],
      };
    case ReservationListConstants.SET_TOTAL_PAGES: {
      return {
        ...state,
        totalPages: action.payload.totalePages,
      };
    }
    case ReservationListConstants.SET_ALLOWED_STATE: {
      return {
        ...state,
        allowedState: action.payload.allowedState,
      };
    }
    default:
      return state;
  }
};
