export enum ReservationStateConstants {
  NEW = 'new',
  BASE_INFORMATION = 'base_information',
  DELIVERY_INFORMATION = 'delivery_information',
  DOCUMENT_UPLOADING = 'document_uploading',

  //Temporary state not saved at DB just for show the finished insert reservation page
  FINISHED = 'finished',

  TO_APPROVE = 'to_approve',
  APPROVED = 'approved',
  CLOSED = 'closed',
  UNAPPROVED = 'unapproved',
}

export enum CustomerReservationStateConstants {
  TYPE_SELECTION = 'type_selection',
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
  ///
  ////
  ///////
  //////
  NEW = 'new',
  BASE_INFORMATION = 'base_information',
  DELIVERY_INFORMATION = 'delivery_information',
  DOCUMENT_UPLOADING = 'document_uploading',

  //Temporary state not saved at DB just for show the finished insert reservation page
  FINISHED = 'finished',

  TO_APPROVE = 'to_approve',
  APPROVED = 'approved',
  CLOSED = 'closed',
  UNAPPROVED = 'unapproved',
}

export enum ReservationTimeSlot {
  MORNING_SLOT = 'morning',
  AFTERNOON_SLOT = 'afternoon',
}

export enum EnvironmentalClass {
  END_OF_WASTE = 'End_of_Waste',
  ENDOFWASTE = 'End of Waste',
  MATERIA_PRIMA = 'Materia_Prima',
  MATERIA_PRIMA_SECONDARIA = 'Materia_Prima_Secondaria',
  RIFIUTO = 'Rifiuto',
  SOTTO_PRODOTTO = 'Sotto_Prodotto',
}

export const environmentalClassOptions = [
  { text: 'End of waste', value: EnvironmentalClass.END_OF_WASTE },
  { text: 'End of waste', value: EnvironmentalClass.ENDOFWASTE },
  { text: 'Materia prima', value: EnvironmentalClass.MATERIA_PRIMA },
  {
    text: 'Materia prima secondaria',
    value: EnvironmentalClass.MATERIA_PRIMA_SECONDARIA,
  },
  { text: 'Rifiuto', value: EnvironmentalClass.RIFIUTO },
  { text: 'Sotto Prodotto', value: EnvironmentalClass.SOTTO_PRODOTTO },
];

export const environmentalClassEngOptions = [
  { text: 'End of waste', value: EnvironmentalClass.END_OF_WASTE },
  { text: 'End of waste', value: EnvironmentalClass.ENDOFWASTE },
  { text: 'Raw Material', value: EnvironmentalClass.MATERIA_PRIMA },
  {
    text: 'Secondary Raw Material',
    value: EnvironmentalClass.MATERIA_PRIMA_SECONDARIA,
  },
  { text: 'Waste', value: EnvironmentalClass.RIFIUTO },
  { text: 'Sotto Prodotto', value: EnvironmentalClass.SOTTO_PRODOTTO },
];

export enum ReservationListTab {
  APPROVED = 'approved',
  CLOSED = 'closed',
  TO_APPROVE = 'toApprove',
  UNAPPROVED = 'unapproved',
  IN_WORKING = 'working',
}

export enum CustomerReservationListTab {
  APPROVED = 'approved',
  CLOSED = 'closed',
  TO_APPROVE = 'toApprove',
  UNAPPROVED = 'unapproved',
  IN_WORKING = 'working',
}

export const RESERVATION_ARTICLES_PER_PAGE = 5;
