import React, { createContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';

// Components
import OrdersList from 'components/UserOrders/OrdersList';
import PageHeader from 'components/PageHeader';

// Constants - Internals - Utils
import { initialUserOrdersState, userOrdersReducer } from './reducer/reducer';
import { UserOrdersAction } from './reducer/action';

// Services
import { fetchOpenOrders } from 'services/orders/fetchOpenOrders';

// Types
import { UserOrdersState } from './types';
import { useIntl } from 'react-intl';

export const UserOrdersContext = createContext<{
  state: UserOrdersState;
  dispatch: React.Dispatch<UserOrdersAction>;
}>({
  state: initialUserOrdersState,
  dispatch: () => null,
});

const UserOrders: React.FC = () => {
  const [state, dispatch] = useReducer(
    userOrdersReducer,
    initialUserOrdersState,
  );
  const intl = useIntl();

  useEffect(() => {
    fetchOpenOrders(dispatch);
  }, []);

  return (
    <UserOrdersContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'user.orders.title.page',
            defaultMessage: 'MSM - Ordini',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'user.orders.title.header',
          defaultMessage: 'Ordini',
        })}
      />
      <OrdersList />
    </UserOrdersContext.Provider>
  );
};

export default UserOrders;
