import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import AdminDashboard from 'components/Dashboards/Admin';
import SupplierDashboard from 'components/Dashboards/Supplier';
import CustomerDashboard from 'components/Dashboards/Customer';
import { ICONS, ROLES } from 'utils/global/globalConstants';
import { isThatRole } from 'utils/function/acl';
import AgentDashboard from 'components/Dashboards/Agent';
import { useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { getCustomerDocument } from 'services/document/getCustomerDocuments';
import { CUSTOMER_DOCUMENT_METHODS } from 'utils/global/customerCostants';
import { AppContext } from 'pages/App';
import SvgIcon from 'components/SvgIcon';
import CustomerAgentDashboard from 'components/Dashboards/CustomerAgent';

const Dashboard: React.FC = () => {
  const intl = useIntl();
  const { state } = useContext(AppContext);

  // Render the dashboard based on the current user
  const getDashboard = () => {
    if (isThatRole(ROLES.ADMIN)) {
      return <AdminDashboard />;
    }

    if (isThatRole(ROLES.CUSTOMER)) {
      return <CustomerDashboard />;
    }

    if (isThatRole(ROLES.AGENT)) {
      return <AgentDashboard />;
    }

    if (isThatRole(ROLES.SUPPLIER) || isThatRole(ROLES.NOVICE_CONTACT)) {
      return <SupplierDashboard />;
    }

    if (isThatRole(ROLES.CUSTOMER_AGENT)) {
      return <CustomerAgentDashboard />;
    }

    return <div className="empty-supplier-dashboard"></div>;
  };

  return (
    <>
      <Helmet>
        <body />
        <title>MSM - Dashboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <div className="page-header border-bottom">
        <span className="title">
          {isThatRole(ROLES.AGENT)
            ? intl.formatMessage({
                defaultMessage: 'Selezione utente',
                id: 'dashboard.pageHeader.title',
              })
            : 'Dashboard'}
        </span>
        {isThatRole(ROLES.CUSTOMER) &&
        state.mySelf._permissions?.customerMetalAccountEnabled ? (
          <div className="right-download-button">
            <Button
              className="main-button dashboard-button"
              content={
                <div className="dashboard-download-button">
                  <SvgIcon icon={ICONS.FILE} height={16} width={16} />
                  {intl.formatMessage({
                    id: 'contactDocuments.button.termsDocument',
                    defaultMessage: 'Condizioni generali',
                  })}
                </div>
              }
              onClick={() =>
                getCustomerDocument(
                  '',
                  CUSTOMER_DOCUMENT_METHODS.CUSTOMER_TERMS_DOCUMENT,
                  intl,
                )
              }
            />
          </div>
        ) : null}
      </div>
      {getDashboard()}
    </>
  );
};

export default Dashboard;
