import React, { useContext, useEffect } from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';

// Components
import DataGrid from 'components/DataGrid';
import FiltersBar from 'components/FiltersBar';

// Constants - Internals - Services - Utils
import { fetchUsersRequests } from 'services/user/fetchUsersRequests';
import { getStyledIcon } from 'utils/function/styles';
import { UserRequestsContext } from 'pages/UserRequests';

// Images
import ellipsisHorizontal from 'assets/images/icons/ellipsisHorizontal.svg';
import eyeIcon from 'assets/images/icons/eye.svg';

// Types
import { Column, SupplierItemParam } from 'components/DataGrid/types';
import { UserRequestTabProps } from 'pages/UserRequests/types';
import { useNavigate } from 'react-router-dom';

const Approved: React.FC<UserRequestTabProps> = ({ onPageChange }) => {
  const { state, dispatch } = useContext(UserRequestsContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsersRequests(dispatch, 2, state.activaPage);
  }, [state.activaPage]);

  const columns: Array<Column> = [
    {
      key: 'companyAndVATNumber',
      name: 'AZIENDA E P.IVA',
      formatter: ({ data }: SupplierItemParam) => (
        <div style={{ textAlign: 'left' }}>
          <div>{data.vatNumber}</div>
          <div>
            <b>{data.name}</b>
          </div>
        </div>
      ),
    },
    {
      key: 'dateRequest',
      name: 'DATA RICHIESTA',
      formatter: ({ data }: SupplierItemParam) => {
        if (_.isEmpty(data.createdAt)) {
          return null;
        }

        const date = moment(data.createdAt, 'YYYY-MM-DD h:mm_ss').utcOffset(4);
        return (
          <div>
            <b>{date.format('D MMM ')}</b>
            {date.format('YYYY')}
            <br />
            {date.format('H:mm:ss')}
          </div>
        );
      },
    },
    {
      key: 'city',
      name: 'CITTÀ',
      formatter: ({ data }: SupplierItemParam) => {
        return (
          <div>
            <b>{data.city}</b>
          </div>
        );
      },
    },
    {
      key: 'settings',
      name: 'AZIONI',
      formatter: ({ data }: SupplierItemParam) => {
        return (
          <Dropdown
            className="standard-dropdown-menu"
            direction="left"
            icon={<Image src={ellipsisHorizontal} />}
            item
          >
            <Dropdown.Menu>
              <Dropdown.Item
                key="visualize"
                text={
                  <FormattedMessage
                    id="visualize"
                    defaultMessage="Visualizza"
                  />
                }
                icon={getStyledIcon(eyeIcon)}
                onClick={() => navigate('/user-details/' + data.id)}
              />
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <FiltersBar
        resetFilters={() => console.log('ok')}
        setFilters={() => console.log('ok')}
      />
      <DataGrid
        isLoading={state.isLoading}
        elements={state.elements}
        columns={columns}
        totalPages={state.totalPages}
        activePage={state.activaPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Approved;
