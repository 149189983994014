import { AxiosResponse } from 'axios';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { Document, DocumentsResponse } from 'services/user/types';
import { API } from 'utils/global/backendRoutes';

export const fetchReservationDocumnents = (
  reservationId: string,
  setDocumentsCollection: (collection: Array<Document>) => void,
): void => {
  standardClient({
    url: API.DOCUMENT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'entityId',
          value: reservationId,
        },
      ],
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then((response: AxiosResponse<DocumentsResponse>) => {
      setDocumentsCollection(response.data._embedded.attachment);
    })
    .catch(() => {
      toast.error('Impossibile caricare i documenti richiesti');
    });
};
