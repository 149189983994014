import { CircularProgress } from '@material-ui/core';
import Paginator from 'components/Paginator';
import _ from 'lodash';
import { ReservationListContext } from 'pages/ReservationList';
import { ReservationListConstants } from 'pages/ReservationList/reducer/action';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { PaginationProps } from 'semantic-ui-react';
import { fetchReservationList } from 'services/reservation/fetchReservation';
import { DEFAULT_RESERVATION_PAGE_SIZE } from 'utils/global/globalConstants';
import ReservationAccordion from '../ReservationAccordion';
import ReservationFiltersBar from '../ReservationFiltersBar';

export const AccordionList: React.FC = () => {
  const { state, dispatch } = useContext(ReservationListContext);

  const onPageChange = (_event, { activePage }: PaginationProps): void => {
    dispatch({
      type: ReservationListConstants.SET_CURRENT_PAGE,
      payload: { currentPage: activePage },
    });
    fetchReservationList(state.allowedState, dispatch, activePage);
  };

  const onReset = () => {
    dispatch({
      type: ReservationListConstants.SET_CURRENT_PAGE,
      payload: { currentPage: 1 },
    });
    fetchReservationList(state.allowedState, dispatch, 1);
  };

  const onSearch = (bookingNumber: string) => {
    dispatch({
      type: ReservationListConstants.SET_CURRENT_PAGE,
      payload: { currentPage: 1 },
    });
    fetchReservationList(
      state.allowedState,
      dispatch,
      1,
      DEFAULT_RESERVATION_PAGE_SIZE,
      bookingNumber,
    );
  };

  const renderOrderBody = () => {
    // If data has not been already loaded show a loader
    if (state.areReservationsLoading) {
      return (
        <div className="reservation-accordion-content-loading">
          <CircularProgress color="inherit" />
          <span>
            <FormattedMessage
              id="accordion.list.reservation.loading"
              defaultMessage="Prenotazioni in caricamento"
            />
          </span>
        </div>
      );
    }

    // If there are no open orders from API show only a message
    if (_.isEmpty(state.reservationCollection)) {
      return (
        <span className="reservation-accordion-content-loading">
          <FormattedMessage
            id="accordion.list.reservation.noOrders"
            defaultMessage="Non sono presenti prenotazioni"
          />
        </span>
      );
    }

    // Map the data from API
    return (
      <div>
        {state.reservationCollection.map(reservation => (
          <ReservationAccordion
            reservation={reservation}
            key={reservation.id}
          />
        ))}
        <div className="reservation-paginator-footer">
          <Paginator
            activePage={state.activaPage}
            totalPages={state.totalPages}
            onPageChange={onPageChange}
            shortText
          />
        </div>
      </div>
    );
  };

  return (
    <div className="reservations-list">
      <ReservationFiltersBar onReset={onReset} onSearch={onSearch} />
      {renderOrderBody()}
    </div>
  );
};

export default AccordionList;
