import React from 'react';
import { useIntl } from 'react-intl';
import { Popup, Icon } from 'semantic-ui-react';

const TooltipPassword: React.FC = () => {
  const intl = useIntl();

  return (
    <Popup
      className="tooltip-password"
      content={
        <span className="tooltip-password-content">
          <li className="tooltip-item">
            {intl.formatMessage({
              id: 'loginForm.tooltip.minChars',
              defaultMessage: 'La password deve contenere almeno 8 caratteri',
            })}
          </li>
          <li className="tooltip-item">
            {intl.formatMessage({
              id: 'loginForm.tooltip.maxChars',
              defaultMessage:
                'La password deve contenere al massimo 32 caratteri',
            })}
          </li>
          <li className="tooltip-item">
            {intl.formatMessage({
              id: 'loginForm.tooltip.genericRules',
              defaultMessage:
                'La password deve seguire le seguenti regole: almeno un numero, ',
            })}
            <br></br>
            {intl.formatMessage({
              id: 'loginForm.tooltip.otherRules',
              defaultMessage:
                'una lettera minuscola, una lettera maiuscola ed un simbolo',
            })}
          </li>
        </span>
      }
      basic
      hoverable
      trigger={
        <Icon className="tooltip-password-icon" name="question circle" />
      }
    />
  );
};

export default TooltipPassword;
