import React, { useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

// Components
import PageHeader from 'components/PageHeader';
import {
  getContactDocuments,
  uploadDocumentFile,
} from 'services/contactDocuments/getContactDocuments';
import {
  contactDocumentsReducer,
  initialContactDocumentsState,
} from './reducer/reducer';
import { DocumentData } from './types';
import { ContactDocumentsConstants } from './reducer/action';
import DocumentsTable from 'components/DocumentsTable';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';
import { ROLES } from 'utils/global/globalConstants';
import { Button } from 'semantic-ui-react';
import NotMandatoryDocumentModal from 'components/NotMandatoryDocumentModal';
import moment from 'moment';

const ContactDocuments: React.FC = () => {
  const [state, dispatch] = useReducer(
    contactDocumentsReducer,
    initialContactDocumentsState,
  );
  const intl = useIntl();
  const { state: AppState } = useContext(AppContext);
  const mySelf = AppState.mySelf;

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = () => {
    let role = null;
    if (isThatRole(ROLES.CUSTOMER, AppState)) {
      role = 'customer';
    } else if (isThatRole(ROLES.SUPPLIER, AppState)) {
      role = 'vendor';
    }

    getContactDocuments(
      setAreDocumentsLoading,
      setDocumentsCollection,
      null,
      null,
      null,
      role,
    );
  };

  const setDocumentsCollection = (documentsCollection: Array<DocumentData>) => {
    dispatch({
      type: ContactDocumentsConstants.SET_DOCUMENTS_COLLECTION,
      payload: { documentsCollection: documentsCollection },
    });
  };

  const setAreDocumentsLoading = (areDocumentsLoading: boolean) => {
    dispatch({
      type: ContactDocumentsConstants.SET_ARE_DOCUMENTS_LOADING,
      payload: { areDocumentsLoading: areDocumentsLoading },
    });
  };

  const closeModal = () => {
    dispatch({
      type: ContactDocumentsConstants.SET_OPEN_MODAL,
      payload: { isModalOpen: false },
    });
  };

  const openModal = () => {
    dispatch({
      type: ContactDocumentsConstants.SET_OPEN_MODAL,
      payload: { isModalOpen: true },
    });
  };

  /** Management of the updated files */
  const handleUploadDocument = (
    documentFile: File,
    releaseDate: string,
    expirationDate: string,
    documentType: string,
  ) => {
    // Check if the file is an instance of File, if it isn't return
    if (!(documentFile instanceof File)) {
      return;
    }

    const formData = new FormData();

    formData.append('document', documentFile);
    formData.append('contactNo', mySelf._registry.contact.id);
    formData.append('documentType', documentType);
    formData.append('fileName', documentFile.name);
    formData.append('attachmentID', '0');
    formData.append('startDate', moment(releaseDate).format('YYYY-MM-DD'));
    formData.append('dueDate', moment(expirationDate).format('YYYY-MM-DD'));

    formData.append('documentID', '0');

    uploadDocumentFile(formData, loadDocuments, intl);
    closeModal();
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'contactDocuments.helmet.title',
            defaultMessage: 'MSM - Documenti',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'contactDocuments.header.title',
          defaultMessage: 'Documenti validazione',
        })}
      />
      <div className="contact-documents">
        {isThatRole(ROLES.NOVICE_CONTACT) && (
          <>
            <div className="contact-documents-title">
              <FormattedMessage
                id="contactDocuments.title"
                defaultMessage="Elenco <bold>documenti richiesti</bold>"
                values={{ bold: (text: string) => <b>{text}</b> }}
              />
            </div>
            <div className="contact-documents-subtitle">
              <FormattedMessage
                id="contactDocuments.subtitle"
                defaultMessage="Per poter operare, è necessario caricare tutta la documentazione obbligatoria."
              />
            </div>
          </>
        )}
        {isThatRole(ROLES.SUPPLIER) && (
          <>
            <div className="contact-documents-title">
              <FormattedMessage
                id="contactDocuments.title"
                defaultMessage="Elenco <bold>documenti richiesti</bold>"
                values={{ bold: (text: string) => <b>{text}</b> }}
              />
            </div>
            <div className="contact-documents-subtitle">
              <FormattedMessage
                id="contactDocuments.registred.subtitle"
                defaultMessage="In questa sezione puoi verificare tutta la documentazione caricata"
              />
            </div>
          </>
        )}
        {isThatRole(ROLES.CUSTOMER) && (
          <div className="contact-documents-title">
            <FormattedMessage
              id="contactDocuments.customer.subtitle"
              defaultMessage="Elenco documenti richiesti per il conferimento in conto trasformazione."
            />
          </div>
        )}
        <DocumentsTable
          areDocumentsLoading={state.areDocumentsLoading}
          contactCode={mySelf._registry.contact.id}
          documentsCollection={state.documentsCollection}
          reloadDocuments={loadDocuments}
        />
        {!isThatRole(ROLES.NOVICE_CONTACT) && (
          <div className="documents-table-footer">
            <Button
              className="main-button"
              content={intl.formatMessage({
                id: 'contactDocuments.button.otherDocuments',
                defaultMessage: 'Carica altri documenti',
              })}
              onClick={() => openModal()}
            />
          </div>
        )}
      </div>
      {!isThatRole(ROLES.NOVICE_CONTACT) && (
        <NotMandatoryDocumentModal
          isOpen={state.isModalOpen}
          uploadDocumentFile={handleUploadDocument}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default ContactDocuments;
