import React from 'react';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { SvgIconProps } from './types/inedx';

// Icons
import arrowDown from './icons/arrowDown';
import arrowLeft from './icons/arrowLeft';
import arrowRight from './icons/arrowRight';
import bell from './icons/bell';
import calendar from './icons/calendar';
import calendarFlat from './icons/calendarFlat';
import check from './icons/check';
import checklist from './icons/checklist';
import clock from './icons/clock';
import clockFilled from './icons/clockFilled';
import cross from './icons/cross';
import dashboard from './icons/dashboard';
import document from './icons/document';
import elipsis from './icons/elipsis';
import eye from './icons/eye';
import file from './icons/file';
import gear from './icons/gear';
import information from './icons/information';
import lens from './icons/lens';
import padlock from './icons/padlock';
import pencil from './icons/pencil';
import plus from './icons/plus';
import questionMark from './icons/questionMark';
import recycle from './icons/recycle';
import shield from './icons/shield';
import towTruck from './icons/towTruck';
import trash from './icons/trash';
import truck from './icons/truck';
import truckFrontal from './icons/truckFrontal';
import user from './icons/user';
import warningCircle from './icons/warningCircle';
import weight from './icons/weight';

/**
 * How to add a new SVG icon?
 * - Add under ICONS constants the name of the new icon
 * - Create under the 'icons' folder a new file that export a
 *   function that takes 4 params (className, color, height, width)
 * - The function must return the <svg> tag with its content
 * - Add the function call in the switch case
 *
 * For add a new color add it under COLORS enum
 */
export const SvgIcon: React.FC<SvgIconProps> = ({
  className,
  color = COLORS.PRIMARY,
  height,
  icon,
  width,
}) => {
  switch (icon) {
    case ICONS.ARROW_DOWN:
      return arrowDown(className, color, height, width);
    case ICONS.ARROW_LEFT:
      return arrowLeft(className, color, height, width);
    case ICONS.ARROW_RIGHT:
      return arrowRight(className, color, height, width);
    case ICONS.BELL:
      return bell(className, color, height, width);
    case ICONS.CALENDAR:
      return calendar(className, color, height, width);
    case ICONS.CALENDAR_FLAT:
      return calendarFlat(className, color, height, width);
    case ICONS.CHECK:
      return check(className, color, height, width);
    case ICONS.CHECKLIST:
      return checklist(className, color, height, width);
    case ICONS.CLOCK:
      return clock(className, color, height, width);
    case ICONS.CLOCK_FILLED:
      return clockFilled(className, color, height, width);
    case ICONS.CROSS:
      return cross(className, color, height, width);
    case ICONS.DASHBOARD:
      return dashboard(className, color, height, width);
    case ICONS.DOCUMENT:
      return document(className, color, height, width);
    case ICONS.ELIPSIS:
      return elipsis(className, color, height, width);
    case ICONS.EYE:
      return eye(className, color, height, width);
    case ICONS.FILE:
      return file(className, color, height, width);
    case ICONS.GEAR:
      return gear(className, color, height, width);
    case ICONS.INFORMATION:
      return information(className, color, height, width);
    case ICONS.LENS:
      return lens(className, color, height, width);
    case ICONS.PADLOCK:
      return padlock(className, color, height, width);
    case ICONS.PENCIL:
      return pencil(className, color, height, width);
    case ICONS.PLUS:
      return plus(className, color, height, width);
    case ICONS.QUESTION_MARK:
      return questionMark(className, color, height, width);
    case ICONS.RECYCLE:
      return recycle(className, color, height, width);
    case ICONS.SHIELD:
      return shield(className, color, height, width);
    case ICONS.TRASH:
      return trash(className, color, height, width);
    case ICONS.TOW_TRUCK:
      return towTruck(className, color, height, width);
    case ICONS.TRUCK:
      return truck(className, color, height, width);
    case ICONS.TRUCK_FRONTAL:
      return truckFrontal(className, color, height, width);
    case ICONS.USER:
      return user(className, color, height, width);
    case ICONS.WARNING_CIRCLE:
      return warningCircle(className, color, height, width);
    case ICONS.WEIGHT:
      return weight(className, color, height, width);
    default:
      return <></>;
  }
};

export default SvgIcon;
