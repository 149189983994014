import _ from 'lodash';

import { CustomerReservationState } from '../types';
import {
  CustomerReservationAction,
  CustomerReservationConstants,
} from './action';

export const initialReservationState: CustomerReservationState = {
  areDataLoading: false,
  isReservationLoading: false,
  isPrevStateLoading: false,
  reservation: null,
  selectedItems: [],
};

export const reservationReducer = (
  state: CustomerReservationState,
  action: CustomerReservationAction,
): CustomerReservationState => {
  const { type } = action;

  switch (type) {
    case CustomerReservationConstants.ADD_ITEM: {
      return {
        ...state,
        selectedItems: _.union(state.selectedItems, [action.payload.itemCode]),
      };
    }
    case CustomerReservationConstants.CLEAR_ITEMS: {
      return { ...state, selectedItems: [] };
    }
    case CustomerReservationConstants.REMOVE_ITEM: {
      return {
        ...state,
        selectedItems: _.pull(state.selectedItems, action.payload.itemCode),
      };
    }
    case CustomerReservationConstants.SET_ARE_DATA_LOADING:
      return { ...state, areDataLoading: action.payload.areDataLoading };
    case CustomerReservationConstants.SET_RESERVATION:
      return { ...state, reservation: action.payload.reservation };
    case CustomerReservationConstants.SET_RESERVATION_LOADING:
      return {
        ...state,
        isReservationLoading: action.payload.isReservationLoading,
      };
    case CustomerReservationConstants.SET_PREV_RESERVATION_LOADING:
      return {
        ...state,
        isPrevStateLoading: action.payload.isPrevReservationLoading,
      };
    default:
      return state;
  }
};
