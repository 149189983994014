import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import _ from 'lodash';

export const uploadReservationDocument = (
  reservationDocumentData: FormData,
  callback: unknown,
): void => {
  const notifyStatus = toast.loading('Upload del file in corso...', {
    closeOnClick: true,
  });

  standardClient
    .request({
      url: API.ATTACHMENT,
      method: 'POST',
      data: reservationDocumentData,
    })
    .then(() =>
      // Show success message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: 'File caricato correttamente',
        type: 'success',
      }),
    )
    .catch(() => {
      // Show error message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: 'Impossibile caricare il file',
        type: 'error',
      });
    })
    .finally(() => _.isFunction(callback) && callback());
};
