import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const document = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 17}
    height={height || 20}
    viewBox="0 0 17 20"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 18C0.5 19.1 1.39 20 2.49 20H14.5C15.6 20 16.5 19.1 16.5 18V6L10.5 0ZM12.5 16H4.5V14H12.5V16ZM12.5 12H4.5V10H12.5V12ZM9.5 7V1.5L15 7H9.5Z" />
  </svg>
);

export default document;
