import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const postCustomerDataCollection = (
  values: unknown,
  setCustomerId: (value: string) => void,
): void => {
  const notifyStatus = toast.loading('Salvataggio del documento in corso...', {
    closeOnClick: true,
  });
  standardClient({
    url: `${API.CUSTOMER_DATA_COLLECTION}`,
    method: 'POST',
    data: values,
  })
    .then(response => {
      setCustomerId(response.data.id);
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: 'Documento salvato correttamente',
        type: 'success',
      });
    })
    .catch(() => {
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: 'Impossibile salvare il documento',
        type: 'error',
      });
    });
};
