import React, { createContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { PaginationProps, Tab } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

// Components
import Approved from 'components/UserRequests/Approved';
import PageHeader from 'components/PageHeader';
import ToActivated from 'components/UserRequests/ToActivated';
import ToApproved from 'components/UserRequests/ToApproved';

// Internals
import { initialUserRequests, userRequestsReducer } from './reducer/reducer';
import { UserRequestsAction, UserRequestsConstants } from './reducer/action';

// Types
import { UserRequestsState } from './types';

export const UserRequestsContext = createContext<{
  state: UserRequestsState;
  dispatch: React.Dispatch<UserRequestsAction>;
}>({
  state: initialUserRequests,
  dispatch: () => null,
});

const UserRequests: React.FC = () => {
  const { activeTab } = useParams();
  const [state, dispatch] = useReducer(
    userRequestsReducer,
    initialUserRequests,
  );

  // If the active tab param is passed in the URL update on component
  // loading the active tab
  useEffect(() => {
    if (activeTab === '1' || activeTab === '2') {
      handleTabChange(activeTab);
    }
  }, []);

  const onChangePage = (
    _event: React.MouseEvent<HTMLAnchorElement>,
    { activePage }: PaginationProps,
  ) => {
    dispatch({
      type: UserRequestsConstants.UPDATE_ACTIVE_PAGE,
      payload: {
        activePage,
      },
    });
  };

  const panes = [
    {
      menuItem: {
        key: 'toActivated',
        content: 'Da attivare',
      },
      render: () => (
        <Tab.Pane>
          <ToActivated onPageChange={onChangePage} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'toApproved',
        content: 'Da approvare',
      },
      render: () => (
        <Tab.Pane>
          <ToApproved onPageChange={onChangePage} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'approved',
        content: 'Approvate',
      },
      render: () => (
        <Tab.Pane>
          <Approved onPageChange={onChangePage} />
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = (activeIndex: string | number) => {
    dispatch({
      type: UserRequestsConstants.UPDATE_ACTIVE_PAGE,
      payload: {
        activePage: 1,
      },
    });

    dispatch({
      type: UserRequestsConstants.UPDATE_ACTIVE_TAB,
      payload: {
        activeTab: activeIndex,
      },
    });
  };

  return (
    <UserRequestsContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>Richieste nuovi utenti</title>
        <meta name="description" content="Richieste nuovi utenti" />
      </Helmet>
      <PageHeader title="Richieste nuovi utenti" />
      <div className="user-requests-content">
        <Tab
          panes={panes}
          activeIndex={state.activeTab}
          onTabChange={(e, { activeIndex }) => handleTabChange(activeIndex)}
        />
      </div>
    </UserRequestsContext.Provider>
  );
};

export default UserRequests;
