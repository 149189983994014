import { SignupDataFormValues } from 'pages/Signup/types';

export const signupDataFormInitialValues: SignupDataFormValues = {
  city: '',
  countryRegionCode: '',
  emailAddress: '',
  fiscalCode: '',
  language: '',
  name: '',
  phoneNumber: '',
  vatNumber: '',
};
