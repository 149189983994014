import { RESERVATION_ARTICLES_PER_PAGE } from 'utils/global/reservationConstants';
import { ArticlePhaseState } from '../types';
import { ArticlePhaseAction, ArticlePhaseConstants } from './action';
import _ from 'lodash';

export const initialArticlePhaseState = {
  areFiltersActive: false,
  areOrdersLineLoading: false,
  completeOrdersLine: [],
  currentPage: 0,
  filteredOrdersLine: [],
  showedOrdersLine: [],
  showedOrdersLineCollection: [],
  totalPageNumber: 0,
};

export const articlePhaseReducer = (
  state: ArticlePhaseState,
  action: ArticlePhaseAction,
): ArticlePhaseState => {
  const { type } = action;

  switch (type) {
    case ArticlePhaseConstants.CHANGE_CURRENT_PAGE: {
      const selectedPage = _.isString(action.payload.currentPage)
        ? parseInt(action.payload.currentPage)
        : action.payload.currentPage;

      // Define last item of the page that is SELECTED_PAGE * ITEMS_PER_PAGE
      // Ex. last item of page 3 with 5 elements x page is 15
      const lastItem = RESERVATION_ARTICLES_PER_PAGE * selectedPage;
      const firstItem = lastItem - RESERVATION_ARTICLES_PER_PAGE;

      return {
        ...state,
        currentPage: selectedPage,
        showedOrdersLine: state.showedOrdersLineCollection.slice(
          firstItem,
          lastItem,
        ),
      };
    }
    case ArticlePhaseConstants.REMOVE_FILTERS: {
      // Get the total pages number
      const totalPageNumber = Math.ceil(
        state.completeOrdersLine.length / RESERVATION_ARTICLES_PER_PAGE,
      );

      const showedOrdersLine = state.completeOrdersLine.slice(
        0,
        RESERVATION_ARTICLES_PER_PAGE,
      );

      return {
        ...state,
        areFiltersActive: false,
        currentPage: 1,
        showedOrdersLine: showedOrdersLine,
        showedOrdersLineCollection: state.completeOrdersLine,
        totalPageNumber: totalPageNumber,
      };
    }
    case ArticlePhaseConstants.SET_ARE_ORDERS_LINE_LOADING:
      return {
        ...state,
        areOrdersLineLoading: action.payload.areOrdersLineLoading,
      };
    case ArticlePhaseConstants.SET_COMPLETE_ORDERS_LINE: {
      // Get the total pages number
      const totalPageNumber = Math.ceil(
        action.payload.completeOrdersLine.length /
          RESERVATION_ARTICLES_PER_PAGE,
      );

      const showedOrdersLine = action.payload.completeOrdersLine.slice(
        0,
        RESERVATION_ARTICLES_PER_PAGE,
      );

      return {
        ...state,
        completeOrdersLine: action.payload.completeOrdersLine,
        currentPage: 1,
        showedOrdersLine: showedOrdersLine,
        showedOrdersLineCollection: action.payload.completeOrdersLine,
        totalPageNumber: totalPageNumber,
      };
    }
    case ArticlePhaseConstants.SET_ORDER_NUMBER_FILTER: {
      const filteredLinesCollection = state.completeOrdersLine.filter(
        currentLine =>
          currentLine.orderNumber
            .toLowerCase()
            .includes(action.payload.orderNumberSearched.toLowerCase()),
      );

      // Get the total pages number
      const totalPageNumber = Math.ceil(
        filteredLinesCollection.length / RESERVATION_ARTICLES_PER_PAGE,
      );

      const showedOrdersLine = filteredLinesCollection.slice(
        0,
        RESERVATION_ARTICLES_PER_PAGE,
      );

      return {
        ...state,
        areFiltersActive: true,
        currentPage: 1,
        showedOrdersLine: showedOrdersLine,
        showedOrdersLineCollection: filteredLinesCollection,
        totalPageNumber: totalPageNumber,
      };
    }
    default:
      return state;
  }
};
