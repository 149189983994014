import { Supplier } from 'pages/UserRequests/types';
import { GetDocoumentDataResponse } from 'services/document/types';
import { DocumentFileStates } from '../types';

export enum DocumentViewerConstants {
  SET_DOCUMENT_DATA = 'DocumentViewer/SET_DOCUMENT_DATA',
  SET_DOCUMENT_DATA_LOADING = 'DocumentViewer/SET_DOCUMENT_DATA_LOADING',
  SET_DOCUMENT_FILE = 'DocumentViewer/SET_DOCUMENT_FILE',
  SET_DOCUMENT_FILE_CURRENT_PAGE = 'DocumentViewer/SET_DOCUMENT_FILE_CURRENT_PAGE',
  SET_DOCUMENT_FILE_PAGES = 'DocumentViewer/SET_DOCUMENT_FILE_PAGES',
  SET_DOCUMENT_FILE_STATE = 'DocumentViewer/SET_DOCUMENT_FILE_LOADING',
  SET_SUPPLIER = 'DocumentViewer/SET_SUPPLIER',
}

export type DocumentViewerAction =
  | {
      type: DocumentViewerConstants.SET_DOCUMENT_DATA;
      payload: { documentData: GetDocoumentDataResponse };
    }
  | {
      type: DocumentViewerConstants.SET_DOCUMENT_DATA_LOADING;
      payload: { areDataLoading: boolean };
    }
  | {
      type: DocumentViewerConstants.SET_DOCUMENT_FILE;
      payload: { documentFile: File };
    }
  | {
      type: DocumentViewerConstants.SET_DOCUMENT_FILE_CURRENT_PAGE;
      payload: { currentPage: 'next' | 'previous' };
    }
  | {
      type: DocumentViewerConstants.SET_DOCUMENT_FILE_PAGES;
      payload: { documentFilePages: number };
    }
  | {
      type: DocumentViewerConstants.SET_DOCUMENT_FILE_STATE;
      payload: { documentFileState: DocumentFileStates };
    }
  | {
      type: DocumentViewerConstants.SET_SUPPLIER;
      payload: { supplier: Supplier };
    };
