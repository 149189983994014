import React, { useContext, useState } from 'react';
import {
  Form,
  Input,
  ResetButton,
  SubmitButton,
} from 'formik-semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import { Grid, Modal } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { PasswordUpdateFormProps } from './types';
import { userChangePassword } from 'services/user/userChangePassword';
import { AppContext } from 'pages/App';
import TooltipPassword from 'components/TooltipPassword';

const PasswordUpdateForm: React.FC<PasswordUpdateFormProps> = ({ onClose }) => {
  const intl = useIntl();
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const submitNewPassword = value => {
    return userChangePassword(value, onClose, setIsLoading, intl);
  };

  return (
    <Formik
      initialValues={{
        user: state.mySelf.username,
        plainPasswordCurrent: '',
        plainPasswordNew: '',
        plainPasswordNewCheck: '',
      }}
      onSubmit={value => {
        submitNewPassword(value);
      }}
      validationSchema={Yup.object().shape({
        plainPasswordCurrent: Yup.string().required().nullable(),
        plainPasswordNew: Yup.string().required(),
        plainPasswordNewCheck: Yup.string().oneOf(
          [Yup.ref('plainPasswordNew'), null],
          'Le password devono corrispondere',
        ),
      })}
    >
      <Form>
        <Modal.Content>
          <Grid className="password-recovery-form" textAlign="center">
            <Grid.Row textAlign="center">
              <Input
                type="password"
                name="plainPasswordCurrent"
                placeholder={intl.formatMessage({
                  id: 'passwordUpdateForm.label.plainPasswordCurrent',
                  defaultMessage: 'Vecchia password',
                })}
                label={
                  <FormattedMessage
                    id="plainPasswordCurrent"
                    defaultMessage="Vecchia password"
                  />
                }
                style={{ width: '25rem' }}
              />
              <ErrorMessage
                name="plainPasswordCurrent"
                render={() => (
                  <div className="error-message">
                    <FormattedMessage
                      id="passwordUpdateForm.error.plainPasswordCurrent"
                      defaultMessage="Campo obbligatorio"
                    />
                  </div>
                )}
              />
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Input
                type="password"
                name="plainPasswordNew"
                placeholder={intl.formatMessage({
                  id: 'passwordUpdateForm.label.plainPasswordNew',
                  defaultMessage: 'Nuova password',
                })}
                label={
                  <span>
                    <FormattedMessage
                      id="plainPasswordNew"
                      defaultMessage="Nuova password"
                    />
                    <span>
                      <TooltipPassword />
                    </span>
                  </span>
                }
                style={{ width: '25rem' }}
              />
              <ErrorMessage
                name="plainPasswordNew"
                render={() => (
                  <div className="error-message">
                    <FormattedMessage
                      id="passwordUpdateForm.error.plainPasswordNew"
                      defaultMessage="Campo obbligatorio"
                    />
                  </div>
                )}
              />
            </Grid.Row>
            <Grid.Row>
              <Input
                type="password"
                name="plainPasswordNewCheck"
                placeholder={intl.formatMessage({
                  id: 'passwordUpdateForm.label.plainPasswordNewCheck',
                  defaultMessage: 'Conferma password',
                })}
                label={
                  <span>
                    <FormattedMessage
                      id="plainPasswordNewCheck"
                      defaultMessage="Conferma password"
                    />
                    <span>
                      <TooltipPassword />
                    </span>
                  </span>
                }
                style={{ width: '25rem' }}
              />
              <ErrorMessage
                name="plainPasswordNewCheck"
                render={() => (
                  <div className="error-message">
                    <FormattedMessage
                      id="passwordUpdateForm.error.plainPasswordNewCheck"
                      defaultMessage="La conferma della password non corrisponde"
                    />
                  </div>
                )}
              />
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <ResetButton
            content={intl.formatMessage({
              id: 'passwordUpdateForm.button.undo',
              defaultMessage: 'Annulla',
            })}
            onClick={() => onClose()}
          />
          <SubmitButton
            content={intl.formatMessage({
              id: 'passwordUpdateForm.button.submit',
              defaultMessage: 'Aggiorna password',
            })}
            loading={isLoading}
            disabled={isLoading}
          />
        </Modal.Actions>
      </Form>
    </Formik>
  );
};

export default PasswordUpdateForm;
