import { Formik } from 'formik';
import { Input, Form } from 'formik-semantic-ui-react';
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Image } from 'semantic-ui-react';
import _ from 'lodash';

/** Images */
import topbarLogo from 'assets/images/topbar-logo.svg';

/** Components */
import GeneralData from 'components/DataCollectionSections/GeneralData';
import Contacts from 'components/DataCollectionSections/Contacts';
import AdministrativeData from 'components/DataCollectionSections/AdministrativeData';
import Logistics from 'components/DataCollectionSections/Logistics';
import TechnicalAdministrativeData from 'components/DataCollectionSections/TechnicalAdministrativeData';
import ProductUpdates from 'components/DataCollectionSections/ProductUpdates';
import PageHeader from 'components/PageHeader';

/** Services - Pages - Reducer */
import { putCustomerDataCollection } from 'services/customer/putCustomerDataCollection';
import { postCustomerDataCollection } from 'services/customer/postCustomerDataCollection';
import { getCustomerDataCollection } from 'services/customer/getCustomerDataCollection';
import { AppContext } from 'pages/App';
import {
  dataCollectionReducer,
  initialDataCollectionState,
} from './reducer/reducer';
import { DataCollectionDetails } from './types';
import { DataCollectionConstants } from './reducer/action';

const DataCollection: React.FC = () => {
  const intl = useIntl();
  const { state } = useContext(AppContext);

  const [dataCollectionState, dataCollectionDispatch] = useReducer(
    dataCollectionReducer,
    initialDataCollectionState,
  );

  const [customerId, setCustomerId] = useState<string>();

  const [seed, setSeed] = useState(1);
  useEffect(() => {
    setSeed(Math.random());
  }, [dataCollectionState.dataCollection]);

  const onSubmit = values => {
    values.user = state.mySelf.id;

    if (!_.isEmpty(customerId)) {
      putCustomerDataCollection(values, customerId, setIsSubmitting);
    } else {
      if (!_.isNull(dataCollectionState.dataCollection)) {
        if (!_.isEmpty(dataCollectionState.dataCollection.id)) {
          putCustomerDataCollection(
            values,
            dataCollectionState.dataCollection?.id,
            setIsSubmitting,
          );
        }
      } else {
        postCustomerDataCollection(values, setCustomerId);
      }
    }
  };

  useEffect(() => {
    getCustomerDataCollection(state.mySelf.id, setDataCollection, setIsLoading);
  }, []);

  const setIsSubmitting = (isSubmitting: boolean) => {
    dataCollectionDispatch({
      type: DataCollectionConstants.SET_IS_SUBMITTING,
      payload: { isSubmitting },
    });
  };

  // Update isLoading state variable
  const setIsLoading = (isLoading: boolean) => {
    dataCollectionDispatch({
      type: DataCollectionConstants.SET_IS_LOADGING,
      payload: { isLoading },
    });
  };

  // Update dataCollection state variable
  const setDataCollection = (dataCollection: DataCollectionDetails) => {
    dataCollectionDispatch({
      type: DataCollectionConstants.SET_DATA_COLLECTION,
      payload: { dataCollection },
    });
  };

  const initialValues = useMemo(() => {
    if (!_.isNull(dataCollectionState.dataCollection)) {
      return {
        legalResidence: dataCollectionState.dataCollection.legalResidence || '',
        operatingOffice:
          dataCollectionState.dataCollection.operatingOffice || '',
        purchasingDepartmentHead:
          dataCollectionState.dataCollection.purchasingDepartmentHead || '',
        purchasingDepartmentPhoneNumber:
          dataCollectionState.dataCollection.purchasingDepartmentPhoneNumber ||
          '',
        purchasingDepartmentEmailAddress:
          dataCollectionState.dataCollection.purchasingDepartmentEmailAddress ||
          '',
        sendOffersEmail:
          dataCollectionState.dataCollection.sendOffersEmail || '',
        sendLeagueContracts:
          dataCollectionState.dataCollection.sendLeagueContracts || '',
        sendOrderConfirmations:
          dataCollectionState.dataCollection.sendOrderConfirmations || '',
        sendTransportDocuments:
          dataCollectionState.dataCollection.sendTransportDocuments || '',
        sendCertifiedGoods:
          dataCollectionState.dataCollection.sendCertifiedGoods || '',
        sendMSMNewsletter:
          dataCollectionState.dataCollection.sendMSMNewsletter || '',
        supplierAdministrationHead:
          dataCollectionState.dataCollection.supplierAdministrationHead || '',
        supplierAdministrationPhoneNumber:
          dataCollectionState.dataCollection
            .supplierAdministrationPhoneNumber || '',
        supplierAdministrationEmailAddress:
          dataCollectionState.dataCollection
            .supplierAdministrationEmailAddress || '',
        SDICode: dataCollectionState.dataCollection.SDICode || '',
        PECMail: dataCollectionState.dataCollection.PECMail || '',
        VATNumber: dataCollectionState.dataCollection.VATNumber || '',
        taxID: dataCollectionState.dataCollection.taxID || '',
        supportBank: dataCollectionState.dataCollection.supportBank || '',
        agency: dataCollectionState.dataCollection.agency || '',
        abi: dataCollectionState.dataCollection.abi || '',
        cab: dataCollectionState.dataCollection.cab || '' || '',
        cc: dataCollectionState.dataCollection.cc || '',
        cin: dataCollectionState.dataCollection.cin || '',
        iban: dataCollectionState.dataCollection.iban || '',
        openingDaysWarehouse:
          dataCollectionState.dataCollection.openingDaysWarehouse,
        openingHoursWarehouse:
          dataCollectionState.dataCollection.openingHoursWarehouse || '',
        companyOperatesInAutomotiveSector:
          dataCollectionState.dataCollection
            .companyOperatesInAutomotiveSector || '',
        companySuppliesCompaniesInAutomotiveSector:
          dataCollectionState.dataCollection
            .companySuppliesCompaniesInAutomotiveSector || '',
        firstEmailAddressMSMNewsletter:
          dataCollectionState.dataCollection.firstEmailAddressMSMNewsletter ||
          '',
        secondEmailAddressMSMNewsletter:
          dataCollectionState.dataCollection.secondEmailAddressMSMNewsletter ||
          '',
        thirdEmailAddressMSMNewsletter:
          dataCollectionState.dataCollection.thirdEmailAddressMSMNewsletter ||
          '',
      };
    }

    return {
      legalResidence: '',
      operatingOffice: '',
      purchasingDepartmentHead: '',
      purchasingDepartmentPhoneNumber: '',
      purchasingDepartmentEmailAddress: '',
      sendOffersEmail: '',
      sendLeagueContracts: '',
      sendOrderConfirmations: '',
      sendTransportDocuments: '',
      sendCertifiedGoods: '',
      sendMSMNewsletter: '',
      supplierAdministrationHead: '',
      supplierAdministrationPhoneNumber: '',
      supplierAdministrationEmailAddress: '',
      SDICode: '',
      PECMail: '',
      VATNumber: '',
      taxID: '',
      supportBank: '',
      agency: '',
      abi: '',
      cab: '',
      cc: '',
      cin: '',
      iban: '',
      openingDaysWarehouse: '',
      openingHoursWarehouse: '',
      companyOperatesInAutomotiveSector: '',
      companySuppliesCompaniesInAutomotiveSector: '',
      firstEmailAddressMSMNewsletter: '',
      secondEmailAddressMSMNewsletter: '',
      thirdEmailAddressMSMNewsletter: '',
    };
  }, [dataCollectionState.dataCollection]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'dataCollection.header.title',
            defaultMessage: 'MSM - Form raccolta dati',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'dataCollection.title',
          defaultMessage: 'Form raccolta dati',
        })}
      />
      <div className="dataCollection-container">
        <Formik initialValues={initialValues} onSubmit={onSubmit} key={seed}>
          {({ values, setFieldValue }) => (
            <Form
              className="dataColletionForm"
              id="dataCollection-form"
              loading={dataCollectionState.isLoading}
            >
              <div className="content">
                <Grid celled>
                  <Grid.Row columns={2}>
                    <Grid.Column width={6} style={{ display: 'flex' }}>
                      <Image alt="Logo" src={topbarLogo} fluid />
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{ fontSize: '1.25rem', textAlign: 'center' }}
                    >
                      Scheda S-004
                      <br />
                      <b>Raccolta informazioni cliente</b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />
                <span>
                  <FormattedMessage
                    id="dataCollection.intro.1"
                    defaultMessage="Gentile cliente,"
                    description="Subtitle message in the phase 2 of the reservation flow, under the title"
                  />
                  <br />
                  <br />
                  <FormattedMessage
                    id="dataCollection.intro.2"
                    defaultMessage="Per una corretta gestione delle comunicazioni con Voi, chiediamo cortesemente di compilare il modulo sottostante con i Vostri dati."
                    description="Subtitle message in the phase 2 of the reservation flow, under the title"
                  />
                  <Grid className="generalDataGrid">
                    <GeneralData key={initialValues.legalResidence} />

                    <Contacts />

                    <AdministrativeData />

                    <Logistics />

                    <TechnicalAdministrativeData
                      setFieldValue={setFieldValue}
                    />

                    <ProductUpdates
                      productUpdatesValues={{
                        firstEmailAddressMSMNewsletter:
                          values.firstEmailAddressMSMNewsletter,
                        secondEmailAddressMSMNewsletter:
                          values.secondEmailAddressMSMNewsletter,
                        thirdEmailAddressMSMNewsletter:
                          values.thirdEmailAddressMSMNewsletter,
                      }}
                    />

                    <br />
                    <FormattedMessage
                      id="dataCollection.intro.3"
                      defaultMessage="I dati forniti saranno utilizzati con strumenti informatici e telematici al solo scopo di fornire il servizio richiesto, pertanto saranno conservati esclusivamente per il periodo in cui tale servizio sarà attivo."
                      description="Subtitle message in the phase 2 of the reservation flow, under the title"
                    />
                    <br />

                    <span className="subtitle">
                      <FormattedMessage
                        id="dataCollection.sub6"
                        defaultMessage="Vi riportiamo qui di seguito i riferimenti Metallurgica San Marco da utilizzare per l’inoltro di ordini e per qualsiasi necessità"
                        description="Subtitle message in the phase 2 of the reservation flow, under the title"
                      />
                    </span>

                    <br />
                    <FormattedMessage
                      id="dataCollection.intro.4"
                      defaultMessage="Per richieste di natura amministrativa:"
                      description="Subtitle message in the phase 2 of the reservation flow, under the title"
                    />

                    <Grid.Row columns={2}>
                      <Grid.Column className="noPaddingLeft">
                        <Input
                          name="supplierAdministrationHead"
                          value="Ufficio Amministrativo - 030/5538201"
                          readOnly
                        />
                      </Grid.Column>

                      <Grid.Column className="noPaddingRight">
                        <Input
                          name="supplierAdministrationHead"
                          value="amministrazione@metallurgicasanmarco.it"
                          readOnly
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <br />
                    <FormattedMessage
                      id="dataCollection.intro.5"
                      defaultMessage="Per richieste di offerta e inoltro ordini, richieste di natura commerciale e logistica, inoltro disegni e specifiche, fattibilità articoli nuovi:"
                      description="Subtitle message in the phase 2 of the reservation flow, under the title"
                    />

                    <Grid.Row columns={2}>
                      <Grid.Column className="noPaddingLeft">
                        <Input
                          name="supplierAdministrationHead"
                          value="Ufficio Commerciale - 030/5538201"
                          readOnly
                        />
                      </Grid.Column>

                      <Grid.Column className="noPaddingRight">
                        <Input
                          name="supplierAdministrationHead"
                          value="sales@metallurgicasanmarco.it"
                          readOnly
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <b>
                        <u>
                          <FormattedMessage
                            id="dataCollection.intro.6"
                            defaultMessage="Vi preghiamo inoltre di inviare tutti gli ordini in forma scritta solo ed sclusivamente ai riferimenti qui sopra."
                            description="Subtitle message in the phase 2 of the reservation flow, under the title"
                          />
                        </u>
                      </b>
                    </Grid.Row>

                    <br />
                    <FormattedMessage
                      id="dataCollection.intro.7"
                      defaultMessage="Per richieste di conformità, certificazioni, dichiarazioni origine:"
                      description="Subtitle message in the phase 2 of the reservation flow, under the title"
                    />

                    <Grid.Row columns={2}>
                      <Grid.Column className="noPaddingLeft">
                        <Input
                          name="supplierAdministrationHead"
                          value="Ufficio Commerciale –  030/5538201"
                          readOnly
                        />
                      </Grid.Column>

                      <Grid.Column className="noPaddingRight">
                        <Input
                          name="supplierAdministrationHead"
                          value="dfrigerio@metallurgicasanmarco.it"
                          readOnly
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <br />
                    <Grid.Row>
                      I certificati di qualità, AIA, Reach e Rohs di MSM sono
                      scaricabili sul sito
                      <Button
                        style={{
                          paddingRight: '0',
                          paddingTop: '0',
                          paddingBottom: '0',
                          paddingLeft: '0.5rem',
                        }}
                        className="underlineButton"
                        onClick={() => {
                          window.location.href =
                            'http://www.metallurgicasanmarco.it';
                        }}
                      >
                        http://www.metallurgicasanmarco.it
                      </Button>
                      alla pagina certificazioni.
                    </Grid.Row>

                    <Grid.Row className="allRowInput">
                      <Input
                        label="PEC MSM"
                        name="supplierAdministrationHead"
                        value="metallurgicasanmarco@legalmail.it"
                        readOnly
                      />
                    </Grid.Row>
                    <Grid.Row className="allRowInput">
                      <Input
                        label="Codice SDI MSM"
                        name="supplierAdministrationHead"
                        value="MJEGRSK"
                        readOnly
                      />
                    </Grid.Row>
                    <br />
                    <FormattedMessage
                      id="dataCollection.intro.8"
                      defaultMessage="Vi ringraziamo anticipatamente per la collaborazione nella compilazione del presente modulo, da rinviare il all’indirizzo e-mail: sales@metallurgicasanmarco.it o al no di fax: 030/9969973."
                      description="Subtitle message in the phase 2 of the reservation flow, under the title"
                    />

                    <Grid.Row
                      style={{ marginTop: '2rem', marginBottom: '5rem' }}
                      centered
                    >
                      <b>
                        Distinti Saluti,
                        <br />
                        <br />
                        Direzione Vendite Metallurgica San Marco S.p.A.
                      </b>
                    </Grid.Row>
                  </Grid>
                </span>
              </div>
            </Form>
          )}
        </Formik>
        <div className="contact-origin-details-footer">
          <div className="footer-content">
            <Button
              className="insertButton"
              content={intl.formatMessage({
                id: 'myself.button.save',
                defaultMessage: 'Salva',
              })}
              form="dataCollection-form"
              type="submit"
              loading={dataCollectionState.isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataCollection;
