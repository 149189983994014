import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from 'semantic-ui-react';
import { Outlet } from 'react-router';
import Footer from 'layout/Footer';

const AccessLayout: React.FC = () => {
  return (
    <div className="access-page">
      <Helmet>
        <body className="access" />
        <title>MSM - Access</title>
        <meta name="description" content="access page" />
      </Helmet>
      <Grid className="access-grid">
        <Grid.Column width={8} className="access-empty-column" />
        <Grid.Column
          width={8}
          verticalAlign="middle"
          className="access-form-column"
        >
          <div className="access-form-container">
            <Outlet />
          </div>
        </Grid.Column>
      </Grid>
      <Footer />
    </div>
  );
};

export default AccessLayout;
