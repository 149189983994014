import React from 'react';
import { Route } from 'react-router-dom';

// Components
import CheckAuth from 'components/CheckAuth';

// Constants
import { FE_ROUTES } from 'utils/global/globalConstants';
import FooterLayout from 'layout/FooterLayout';

// Pages
import Signup from 'pages/Signup';

//Export all the routes with the footer bar
const footerLayoutRoutes = (): JSX.Element => (
  <Route element={<FooterLayout />}>
    <Route
      path={FE_ROUTES.SIGNUP}
      element={
        <CheckAuth whoCanView="ANY_NOT_AUTH">
          <Signup />
        </CheckAuth>
      }
    />
  </Route>
);

export default footerLayoutRoutes;
