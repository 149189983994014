import React, { useContext, useEffect, useState } from 'react';
import {
  CustomerInvoice,
  CustomerInvoicesTableColunns,
  InvoicesSupportedSort,
} from './types';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerInvoices } from 'services/customer/getCustomerDocuments';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS, ROLES } from 'utils/global/globalConstants';
import _ from 'lodash';
import { getCustomerDocumentFromEdok } from 'services/document/getCustomerDocuments';
import CustomerInvoiceModal from '../CustomerInvoiceModal';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import { Popup } from 'semantic-ui-react';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';

const CustomerInvoices: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const [customerInvoicesCollection, setCustomerInvoicesCollection] = useState<
    Array<CustomerInvoice>
  >([]);
  const [showedInvoicesCollection, setShowedInvoicesCollection] = useState<
    Array<CustomerInvoice>
  >([]);
  const [areCustomerInvoicesLoading, setAreCustomerInvoicesLoading] =
    useState(false);
  const [activeSort, setActiveSort] =
    useState<InvoicesSupportedSort>('delivery-date-desc');

  const [selectedInvoiceCode, setSelectedInvoiceCode] = useState('');

  useEffect(() => {
    // Two different calls for customer or customer's agent profile
    if (!isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      getCustomerInvoices(
        setCompleteInvoicesCollection,
        setAreCustomerInvoicesLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callInvoicesForAgent();
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callInvoicesForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerInvoices(
        setCompleteInvoicesCollection,
        setAreCustomerInvoicesLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteInvoicesCollection = (
    completeInvoicesCollection: Array<CustomerInvoice>,
  ) => {
    setCustomerInvoicesCollection(completeInvoicesCollection);
    const filteredByYearCollection = completeInvoicesCollection.filter(
      invoice =>
        moment(invoice.postingDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
    );
    setShowedInvoicesCollection(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let documentsToShow = customerInvoicesCollection;

    if (!_.isEmpty(documentNumber)) {
      documentsToShow = documentsToShow.filter(
        document =>
          _.isString(document.no) &&
          document.no.toLowerCase().includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(
          singleDocument.postingDate,
        ),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(singleDocument.postingDate),
      );
    }

    setShowedInvoicesCollection(documentsToShow);
  };

  const onChangeSort = (sortType: InvoicesSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedInvoicesCollection(
      onSortInvoiceCollection(sortType, showedInvoicesCollection),
    );
    setActiveSort(sortType);
  };

  const onCloseModal = () => {
    setSelectedInvoiceCode(null);
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<CustomerInvoice> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return customerInvoicesCollection.filter(invoice =>
        moment(invoice.postingDate, 'YYYY-MM-DD').isSame(selectedYear, 'year'),
      );
    } else if (selectedYear === 'all') {
      return customerInvoicesCollection;
    }
    return customerInvoicesCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedInvoicesCollection(getFilteredByYearsCollection(selectedYear));
    onChangeSort('delivery-date-desc');
  };

  const onSortInvoiceCollection = (
    sortType: InvoicesSupportedSort,
    invoiceCollection: Array<CustomerInvoice>,
  ): Array<CustomerInvoice> => {
    let toSortInvoiceCollection = invoiceCollection;
    switch (sortType) {
      case 'invoice-number-desc': {
        toSortInvoiceCollection = toSortInvoiceCollection.sort(
          (firstInvoice, secondInvoice) => {
            if (firstInvoice.no > secondInvoice.no) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortInvoiceCollection = toSortInvoiceCollection.sort(
          (firstInvoice, secondInvoice) =>
            moment(firstInvoice.postingDate, 'YYYY-MM-DD').diff(
              moment(secondInvoice.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortInvoiceCollection = toSortInvoiceCollection.sort(
          (firstInvoice, secondInvoice) =>
            moment(secondInvoice.postingDate, 'YYYY-MM-DD').diff(
              moment(firstInvoice.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        toSortInvoiceCollection = toSortInvoiceCollection.sort(
          (firstInvoice, secondInvoice) => {
            if (firstInvoice.no > secondInvoice.no) {
              return 1;
            }
            return -1;
          },
        );
      }
    }
    return toSortInvoiceCollection;
  };

  const columns: Array<CustomerInvoicesTableColunns> = [
    {
      key: 'invoiceNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'invoice-number-asc'
                ? 'invoice-number-desc'
                : 'invoice-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.invoices.table.header.invoiceNumber"
            defaultMessage="N. Fattura"
          />
          {activeSort === 'invoice-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'invoice-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">{data.no}</div>
      ),
    },
    {
      key: 'invoiceDate',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.invoices.table.header.invoiceDate"
            defaultMessage="Data fattura"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.postingDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'invoiceAmount',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.invoices.table.header.invoiceAmount"
            defaultMessage="Importo (IVA Esclusa)"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
          }).format(_.toNumber(data.amount))}
        </div>
      ),
    },
    {
      key: 'payment',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.invoices.table.header.payment"
            defaultMessage="Metodo e termini pagamento"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-payment">
          <span className="payment-method">{data.paymentMethod}</span>
          <span className="payment-terms">{data.paymentTerms}</span>
        </div>
      ),
    },
    {
      key: 'invoiceShipTo',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.invoices.table.header.invoiceShipTo"
            defaultMessage="Spedito a"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {data.shipToName}
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.invoices.table.documents.tooltip.details"
                  defaultMessage="Visualizza dettagli"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() => setSelectedInvoiceCode(data.no)}
              >
                <SvgIcon icon={ICONS.LENS} height={20} width={20} />
              </span>
            }
          />
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.invoices.table.documents.tooltip.download"
                  defaultMessage="Scarica documento"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button"
                onClick={() =>
                  getCustomerDocumentFromEdok(
                    data.edokVolumeId,
                    data.edokDocumentId,
                    intl,
                    selectedContact?.name,
                  )
                }
              >
                <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
              </span>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomerDocumentsTab
        activeSort={activeSort}
        areDocumentsLoading={areCustomerInvoicesLoading}
        completeDocumentsCollection={showedInvoicesCollection}
        filterInputPlaceholder={intl.formatMessage({
          id: 'customerDocuments.invoices.filterBar.input.placheolder',
          defaultMessage: 'N. fattura',
        })}
        onChangeSelectedYear={onChangeSelectedYear}
        onFilter={onFilter}
        tableColumns={columns}
        key="customer-invoices"
      />

      <CustomerInvoiceModal
        onClose={onCloseModal}
        invoiceDocumentCode={selectedInvoiceCode}
      />
    </>
  );
};

export default CustomerInvoices;
