import React from 'react';
import { Route } from 'react-router-dom';

// Components and Layout
import CheckAuth from 'components/CheckAuth';

// Constants
import AccessLayout from 'layout/AccessLayout';
import { FE_ROUTES } from 'utils/global/globalConstants';

// Pages
import Login from 'pages/Login';
import PasswordRecovery from 'pages/PasswordRecovery';
import ResetPassword from 'pages/ResetPassword';

//Export all the routes with the access layout as Login page
const accessLayoutRoutes = (): JSX.Element => (
  <Route element={<AccessLayout />}>
    <Route
      path={FE_ROUTES.LOGIN}
      element={
        <CheckAuth whoCanView="ANY_NOT_AUTH">
          <Login />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.PASSWORD_RECOVERY}
      element={
        <CheckAuth whoCanView="ANY_NOT_AUTH">
          <PasswordRecovery />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.RESET_PASSWORD}
      element={
        <CheckAuth whoCanView="ANY_NOT_AUTH">
          <ResetPassword />
        </CheckAuth>
      }
    />
  </Route>
);

export default accessLayoutRoutes;
