import React from 'react';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS } from 'utils/global/globalConstants';

/**
 * Return the icon gave as param encapsulated in an icon element
 * for apply automatically the Semanitc UI style.
 * It's utils for the dropdown menu style
 *
 * @param iconSrc Icon source
 */
export const getStyledIcon = (iconSrc: string): JSX.Element => (
  <i className="icon">
    <img src={iconSrc} />
  </i>
);

/**
 * Return the icon gave as param encapsulated in an icon element
 * for apply automatically the Semanitc UI style.
 * It's utils for the dropdown menu style
 *
 * @param icon icon name from ICONS enum
 * @param color icon color from COLORS enum, if no assigned is used primary one
 */
export const getSvgStyledIcon = (icon: ICONS, color?: COLORS): JSX.Element => (
  <i className="icon">
    <SvgIcon icon={icon} color={color} />
  </i>
);
