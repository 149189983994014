import QueryString from 'qs';

// Constants and utils
import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';
import React from 'react';
import {
  UserRequestsAction,
  UserRequestsConstants,
} from 'pages/UserRequests/reducer/action';
import { DEFAULT_PAGE_SIZE } from 'utils/global/globalConstants';
import { AxiosResponse } from 'axios';
import { UsersRequestsResponse } from './types';
import { toast } from 'react-toastify';

export const fetchUsersRequests = (
  dispatch: React.Dispatch<UserRequestsAction>,
  state: number,
  page: string | number | undefined = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
): void => {
  dispatch({
    type: UserRequestsConstants.UPDATE_IS_LOADING,
    payload: { isLoading: true },
  });

  standardClient({
    url: API.SUPPLIER,
    method: 'GET',
    params: {
      page,
      pageSize,
      // Require all the supplier in state 0 (to active)
      filter: [
        {
          type: 'eq',
          field: 'state',
          value: state,
        },
      ],
      'order-by': [
        {
          type: 'field',
          field: 'updatedAt',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then((response: AxiosResponse<UsersRequestsResponse>) => {
      dispatch({
        type: UserRequestsConstants.UPDATE_IS_LOADING,
        payload: { isLoading: false },
      });

      dispatch({
        type: UserRequestsConstants.UPDATE_ELEMENTS,
        payload: { elements: response.data._embedded.contact },
      });

      dispatch({
        type: UserRequestsConstants.UPDATE_TOTAL_PAGE,
        payload: { totalPages: response.data.page_count },
      });
    })
    .catch(() => {
      toast.error('Errore nel recuperare i dati');

      dispatch({
        type: UserRequestsConstants.UPDATE_IS_LOADING,
        payload: { isLoading: false },
      });
    });
};
