import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SemanticTableGrid from 'semantic-table-grid';
import _ from 'lodash';

import {
  MetalAvailabilityPendingItemsColumn,
  MetalAvailabilityPendingItemsTableProps,
} from './types';
import moment from 'moment';

const MetalAvailabilityPendingItemsTable: React.FC<
  MetalAvailabilityPendingItemsTableProps
> = ({ pendingItems }) => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');

  const columns: Array<MetalAvailabilityPendingItemsColumn> = [
    {
      key: 'postingDate',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityPendingItems.table.header.postingDate"
          defaultMessage="Data ingresso"
        />
      ),
      formatter: ({ data }) =>
        moment(data.postingDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      key: 'externalDocumentNo',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityPendingItems.table.header.externalDocumentNo"
          defaultMessage="NR. DDT"
        />
      ),
      formatter: ({ data }) => data.externalDocumentNo,
    },
    {
      key: 'originName',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityPendingItems.table.header.originName"
          defaultMessage="Provenienza"
        />
      ),
      formatter: ({ data }) => data.originName,
    },
    {
      key: 'itemDescription',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityPendingItems.table.header.itemDescription"
          defaultMessage="Articolo"
        />
      ),
      formatter: ({ data }) => data.itemDescription,
    },
    {
      key: 'quantity',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityPendingItems.table.header.quantity"
          defaultMessage="Quantità lorda"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.quantity)) + ' KG',
    },
    {
      key: 'yield',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityPendingItems.table.header.yield"
          defaultMessage="Resa prevista"
        />
      ),
      formatter: ({ data }) => data.yield,
    },
  ];

  return (
    <>
      <div className="metalAvailability-section-title">
        <FormattedMessage
          id="metalAvailabilityPendingItems.title"
          defaultMessage="Materiale ricevuto in attesa di controllo qualità"
        />
      </div>
      <div className="semantic-table-grid-standard">
        <SemanticTableGrid
          className="metal-availability-pending-items"
          columns={columns}
          elements={pendingItems}
          emptyResults={intl.formatMessage({
            id: 'metalAvailabilityPendingItems.table.empty',
            defaultMessage: 'Nessun articolo in attesa di controllo',
          })}
        />
      </div>
    </>
  );
};

export default MetalAvailabilityPendingItemsTable;
