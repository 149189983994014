import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { IntlShape } from 'react-intl';

// Constants - Internals - Services - Utils
import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';

// Types
import { StandardResponseError } from 'services/client/types';
import {
  FetchOriginDetails,
  OriginDetails,
} from 'pages/ContactOriginDetail/types';

/**
 * Send put request for create a new contact type origin.
 * If the contact has been created successfully reload the page with the id returned from the backend otherwise notify the error.
 *
 * @param contactId Id of contact to update
 * @param setIsLoading Function for manage loading variable
 * @param intl Function to format message
 */
export const fetchContactAsOriginById = (
  contactCode: string,
  setIsLoading: (isLoading: boolean) => void,
  intl: IntlShape,
  setContactOrigin: (contactOrigin: OriginDetails) => void,
): void => {
  setIsLoading(true);

  standardClient({
    url: API.CONTACT_ORIGIN,
    method: 'POST',
    data: {
      method: 'detail',
      params: {
        contactCode: contactCode,
      },
    },
  })
    .then((response: AxiosResponse<FetchOriginDetails>) => {
      setContactOrigin(response.data.origin);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (error.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error(
          intl.formatMessage({
            id: 'fecthContactAsOrigin.error.get',
            defaultMessage: 'Impossibile recuperare la provenienza',
          }),
        );
      }
    })
    .finally(() => setIsLoading(false));
};
