import { Supplier } from '../types';

export enum UserRequestsConstants {
  UPDATE_IS_LOADING = 'UserRequestsConstants/UPDATE_IS_LOADING',
  UPDATE_ELEMENTS = 'UserRequestsConstants/UPDATE_ELEMENTS',
  UPDATE_ACTIVE_TAB = 'UserRequestsConstants/UPDATE_ACTIVE_TAB',
  UPDATE_ACTIVE_PAGE = 'UserRequestsConstants/UPDATE_ACTIVE_PAGE',
  UPDATE_TOTAL_PAGE = 'UserRequestsConstants/UPDATE_TOTAL_PAGE',
}

export type UserRequestsAction =
  | {
      type: UserRequestsConstants.UPDATE_ACTIVE_PAGE;
      payload: { activePage: number | string | undefined };
    }
  | {
      type: UserRequestsConstants.UPDATE_ACTIVE_TAB;
      payload: { activeTab: number | string | undefined };
    }
  | {
      type: UserRequestsConstants.UPDATE_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: UserRequestsConstants.UPDATE_TOTAL_PAGE;
      payload: { totalPages: number | string };
    }
  | {
      type: UserRequestsConstants.UPDATE_ELEMENTS;
      payload: { elements: Array<Supplier> };
    };
