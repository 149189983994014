import { DataCollectionState } from '../types';
import { DataCollectionAction, DataCollectionConstants } from './action';

export const initialDataCollectionState: DataCollectionState = {
  dataCollection: null,
  isLoading: false,
  isSubmitting: false,
};

export const dataCollectionReducer = (
  state: DataCollectionState,
  action: DataCollectionAction,
): DataCollectionState => {
  const { type } = action;

  switch (type) {
    case DataCollectionConstants.SET_IS_LOADGING:
      return { ...state, isLoading: action.payload.isLoading };
    case DataCollectionConstants.SET_DATA_COLLECTION:
      return { ...state, dataCollection: action.payload.dataCollection };
    case DataCollectionConstants.SET_IS_SUBMITTING:
      return { ...state, isSubmitting: action.payload.isSubmitting };
    default:
      return state;
  }
};
