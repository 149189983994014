import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';
import { AxiosResponse } from 'axios';
import {
  GetCustomerContractsResponse,
  GetCustomerCreditsNotesResponse,
  GetCustomerGeneralShipmentResponse,
  GetCustomerGeneralShipmentTransformationResponse,
  GetCustomerInvoicesResponse,
  GetCustomerOrdersListResponse,
  GetCustomerQuotesListResponse,
} from './type';
import { CustomerOrder } from 'components/CustomerDocuments/CustomerOrders/types';
import { toast } from 'react-toastify';
import { CustomerQuote } from 'components/CustomerDocuments/CustomerQuotes/types';
import { CustomerInvoice } from 'components/CustomerDocuments/CustomerInvoices/types';
import { CustomerContract } from 'components/CustomerDocuments/CustomerContracts/types';
import { CustomerShipment } from 'components/CustomerDocuments/CustomerShipment/types';
import { ShipmentTransformation } from 'components/CustomerDocuments/CustomerShipmentTransformation/types';

export const getCustomerOrders = (
  setCustomerOrders: (documents: Array<CustomerOrder>) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-order',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerOrdersListResponse>) => {
      setCustomerOrders(response.data.salesOrders);
    })
    .catch(() => toast.error('Impossibile scaricare gli ordini'))
    .finally(() => setIsLoading(false));
};

export const getCustomerQuotes = (
  setCustomerQuotes: (documents: Array<CustomerQuote>) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-quote',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerQuotesListResponse>) => {
      setCustomerQuotes(response.data.salesQuotes);
    })
    .catch(() => toast.error('Impossibile scaricare i contratti'))
    .finally(() => setIsLoading(false));
};

export const getCustomerInvoices = (
  setCustomerInvoices: (documents: Array<CustomerInvoice>) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-invoice',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerInvoicesResponse>) => {
      setCustomerInvoices(response.data.salesInvoices);
    })
    .catch(() => toast.error('Impossibile scaricare le fatture'))
    .finally(() => setIsLoading(false));
};

export const getCustomerCreditsNotes = (
  setCustomerCreditsNotes: (documents: Array<CustomerInvoice>) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-credit-memo',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerCreditsNotesResponse>) => {
      setCustomerCreditsNotes(response.data.salesInvoices);
    })
    .catch(() => toast.error('Impossibile scaricare le note di credito'))
    .finally(() => setIsLoading(false));
};

export const getCustomerGeneralShipment = (
  setCustomerGeneralShipment: (documents: Array<CustomerShipment>) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'complete-shipment',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerGeneralShipmentResponse>) => {
      setCustomerGeneralShipment(response.data.shipmentsList);
    })
    .catch(() =>
      toast.error('Impossibile scaricare le informazioni sulle spedizioni'),
    )
    .finally(() => setIsLoading(false));
};

export const getCustomerContracts = (
  setCustomerContracts: (contracts: Array<CustomerContract>) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'customer-contracts',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerContractsResponse>) => {
      setCustomerContracts(response.data.customerContracts);
    })
    .catch(() => toast.error('Impossibile scaricare la lista dei contratti'))
    .finally(() => setIsLoading(false));
};

export const getCustomerGeneralShipmentTransformation = (
  setCustomerGeneralShipment: (
    documents: Array<ShipmentTransformation>,
  ) => void,
  setIsLoading: (isLoading: boolean) => void,
  customerCode?: string,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'general-shipment-transformation',
        params: {
          type: 'list',
          customerCode,
        },
      },
    })
    .then(
      (
        response: AxiosResponse<GetCustomerGeneralShipmentTransformationResponse>,
      ) => {
        setCustomerGeneralShipment(response.data.salesShipmentsTransformation);
      },
    )
    .catch(() =>
      toast.error('Impossibile scaricare le informazioni sulle spedizioni'),
    )
    .finally(() => setIsLoading(false));
};
