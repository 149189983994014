import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const putCustomerDataCollection = (
  values: unknown,
  customerId: string,
  setIsSubmitting: (value: boolean) => void,
): void => {
  const notifyStatus = toast.loading('Salvataggio del documento in corso...', {
    closeOnClick: true,
  });
  setIsSubmitting(true);
  standardClient({
    url: `${API.CUSTOMER_DATA_COLLECTION}/${customerId}`,
    method: 'PUT',
    data: values,
  })
    .then(() => {
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: 'Documento salvato correttamente',
        type: 'success',
      });
    })
    .catch(() =>
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: 'Impossibile salvare il documento',
        type: 'error',
      }),
    )
    .finally(() => {
      setIsSubmitting(false);
    });
};
