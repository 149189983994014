import { ContactOriginState } from '../types';
import { ContactOriginAction, ContactOriginConstants } from './action';

export const initialContactOriginState: ContactOriginState = {
  contactOrigin: null,
  isLoading: false,
  documents: [],
  isLoadingDocuments: false,
};

export const contactOriginReducer = (
  state: ContactOriginState,
  action: ContactOriginAction,
): ContactOriginState => {
  const { type } = action;

  switch (type) {
    case ContactOriginConstants.SET_IS_LOADGING:
      return { ...state, isLoading: action.payload.isLoading };
    case ContactOriginConstants.SET_CONTACT_ORIGIN:
      return { ...state, contactOrigin: action.payload.contactOrigin };
    case ContactOriginConstants.SET_DOCUMENTS:
      return { ...state, documents: action.payload.documents };
    case ContactOriginConstants.SET_IS_LOADGING_DOCUMENTS:
      return {
        ...state,
        isLoadingDocuments: action.payload.isLoadingDocuments,
      };
    default:
      return state;
  }
};
