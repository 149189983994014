import { IntlShape } from 'react-intl';

export const mapUnitOfMeasure = (
  unitOfMeasure: string,
  intl: IntlShape,
): string => {
  switch (unitOfMeasure) {
    case 'NR':
      return intl.formatMessage(
        {
          id: 'unitOfMeasure.number',
          defaultMessage: 'N. PEZZI {space}',
        },
        { space: ' ' },
      );
    case 'HR':
      return intl.formatMessage(
        {
          id: 'unitOfMeasure.hour',
          defaultMessage: 'ORE {space}',
        },
        { space: ' ' },
      );
    case 'LR':
      return intl.formatMessage(
        {
          id: 'unitOfMeasure.liter',
          defaultMessage: 'LITRO {space}',
        },
        { space: ' ' },
      );
    default:
      return 'KG ';
  }
};
