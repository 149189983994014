import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SemanticTableGrid from 'semantic-table-grid';
import _ from 'lodash';

import {
  MetalAvailabilityItemsColumn,
  MetalAvailabilityItemsTableProps,
} from './types';

const MetalAvailabilityItemsTable: React.FC<
  MetalAvailabilityItemsTableProps
> = ({ metalAvailabilityItems, totalAlloyQuantity }) => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');

  const columns: Array<MetalAvailabilityItemsColumn> = [
    {
      key: 'description',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityItems.header.description"
          defaultMessage="Descrizione"
        />
      ),
      formatter: ({ data }) => data.itemDescription,
    },
    {
      key: 'quantity',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityItems.header.quantity"
          defaultMessage="Quantità lorda"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.quantity)) + ' KG',
    },
    {
      key: 'alloyQuantity',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityItems.header.alloyQuantity"
          defaultMessage="Quantità netta"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.alloyQuantity)) + ' KG',
    },
  ];

  return (
    <>
      <div className="metalAvailability-section-title">
        <FormattedMessage
          id="metalAvailabilityItems.title"
          defaultMessage="Articoli"
        />
      </div>
      <div className="semantic-table-grid-standard">
        <SemanticTableGrid
          className="metal-availability-items"
          columns={columns}
          elements={metalAvailabilityItems}
          emptyResults={intl.formatMessage({
            id: 'metalAvailabilityItems.table.empty',
            defaultMessage: 'Nessun Elemento disponibile',
          })}
        />
      </div>
      <div className="metalAvailability-totalAlloyQuantity-footer">
        <FormattedMessage
          id="metalAvailabilityItems.footer.totalAlloyQuantity"
          defaultMessage="Totale netto disponibile:"
        />{' '}
        {intlFormatNumber.format(_.toNumber(totalAlloyQuantity)) + ' KG'}
      </div>
    </>
  );
};

export default MetalAvailabilityItemsTable;
