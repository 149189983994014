import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const shield = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 20}
    height={height || 24}
    viewBox="0 0 20 24"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 0.0839844L0.25 4.41732V10.9173C0.25 16.9298 4.41 22.5523 10 23.9173C15.59 22.5523 19.75 16.9298 19.75 10.9173V4.41732L10 0.0839844ZM7.83333 17.4173L3.5 13.084L5.0275 11.5565L7.83333 14.3515L14.9725 7.21232L16.5 8.75065L7.83333 17.4173Z" />
  </svg>
);

export default shield;
