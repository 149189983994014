import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const questionMark = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 32}
    height={height || 32}
    viewBox="0 0 32 32"
    fill={color || COLORS.SECONDARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" />
    <path
      d="M14.5 23H16.8333V20.6667H14.5V23ZM15.6667 9C13.0883 9 11 11.0883 11 13.6667H13.3333C13.3333 12.3833 14.3833 11.3333 15.6667 11.3333C16.95 11.3333 18 12.3833 18 13.6667C18 16 14.5 15.7083 14.5 19.5H16.8333C16.8333 16.875 20.3333 16.5833 20.3333 13.6667C20.3333 11.0883 18.245 9 15.6667 9Z"
      fill="white"
    />
  </svg>
);

export default questionMark;
