import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Image } from 'semantic-ui-react';
import { FE_ROUTES } from 'utils/global/globalConstants';
import { AppContext } from 'pages/App';

// Images
import mainLogo from 'assets/images/main-logo.svg';

// Form
import ResetPasswordForm from 'components/Forms/ResetPasswordForm';
import { ResetPasswordFormValues } from 'components/Forms/ResetPasswordForm/types';
import { userResetPassword } from 'services/user/userResetPassword';
import { LangProviderContext } from 'components/LangProvider';

const ResetPassword: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const { setLanguage } = useContext(LangProviderContext);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    token: null,
    username: null,
  });

  const onSubmit = (values: ResetPasswordFormValues) => {
    userResetPassword(dispatch, values, navigate, setLanguage);
  };

  useEffect(() => {
    const token = searchParams.get('token');
    const username = searchParams.get('username');
    if (token && username) {
      setInitialValues({ token, username });
      navigate(FE_ROUTES.RESET_PASSWORD, { replace: true });
    } else {
      toast.error('Token non valido');
      navigate(FE_ROUTES.LOGIN);
    }
  }, []);

  return (
    <div className="reset-password-container">
      <Helmet>
        <body className="reset-password" />
        <title>MSM - login</title>
        <meta name="description" content="reset password page" />
      </Helmet>
      <Image src={mainLogo} />
      <div className="reset-password-form-container">
        {initialValues.token && (
          <ResetPasswordForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
