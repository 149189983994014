import { CustomerArticlePhaseState } from 'components/CustomerReservation/CustomerArticlePhase/types';
import {
  CustomerArticlePhaseAction,
  CustomerArticlePhaseConstants,
} from './action';

export const initialCutomerArticlePhaseState: CustomerArticlePhaseState = {
  areOriginsLoading: false,
  isCollectionLoading: false,
  itemsCollection: [],
  originsList: [],
  selectedOrigin: '',
  showedItemsCollection: [],
};

export const customerArticlePhaseReducer = (
  state: CustomerArticlePhaseState,
  action: CustomerArticlePhaseAction,
): CustomerArticlePhaseState => {
  const { type } = action;

  switch (type) {
    case CustomerArticlePhaseConstants.SET_ARE_ORIGINS_LOADING:
      return {
        ...state,
        areOriginsLoading: action.payload.areOriginsLoading,
      };
    case CustomerArticlePhaseConstants.SET_IS_COLLECTION_LOADING:
      return {
        ...state,
        isCollectionLoading: action.payload.isCollectionLoading,
      };
    case CustomerArticlePhaseConstants.SET_ITEMS_COLLECTION:
      return { ...state, itemsCollection: action.payload.itemsCollection };
    case CustomerArticlePhaseConstants.SET_ORIGINS_LIST:
      return { ...state, originsList: action.payload.orginsList };
    case CustomerArticlePhaseConstants.SET_SELECTED_ORIGIN:
      return {
        ...state,
        itemsCollection: [],
        selectedOrigin: action.payload.selectedOriginCode,
      };
    default:
      return state;
  }
};
