import React, { createContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

// Components
import SignupCompleted from 'components/Signup/SignupCompleted';
import SignupDataForm from 'components/Signup/SignupDataForm';
import SignupDocumentForm from 'components/Signup/SignupDocumentForm';
import SignupType from 'components/Signup/SignupType';

// Constants - Internals - Services - Utils
import { CONFIG } from 'utils/global/globalConstants';
import { initialSignupState, signupReducer } from './reducer/reducer';
import { loadScriptByURL } from 'utils/function/loadScriptByURL';
import { SignupAction } from './reducer/action';

// Types
import { SignupState } from './types';

export const SignupContext = createContext<{
  state: SignupState;
  dispatch: React.Dispatch<SignupAction>;
}>({
  state: initialSignupState,
  dispatch: () => null,
});

const Signup: React.FC = () => {
  const [state, dispatch] = useReducer(signupReducer, initialSignupState);
  const intl = useIntl();

  // If is active in the CONFIG load the reCaptcha key for certificate the signup
  useEffect(() => {
    const reCaptchaKey = CONFIG.RECAPTCHA.key;
    if (CONFIG.RECAPTCHA.active) {
      // Load the script by passing the URL
      loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`,
        () => null,
      );
    }
  }, []);

  return (
    <SignupContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'signup.header.title',
            defaultMessage: 'MSM - Registrazione',
            description: 'Title showed in the browser tab for the singup phase',
          })}
        </title>
      </Helmet>
      {state.signupPhase === 'signupType' && <SignupType />}
      {state.signupPhase === 'dataSetting' && <SignupDataForm />}
      {state.signupPhase === 'documentUploading' && <SignupDocumentForm />}
      {state.signupPhase === 'completed' && <SignupCompleted />}
    </SignupContext.Provider>
  );
};

export default Signup;
