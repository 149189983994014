import { LangProviderContext } from 'components/LangProvider';
import { AppContext } from 'pages/App';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { loadMyself } from 'services/auth/loadMyself';
import { HybridauthObject } from 'services/client/types';
import { updateHybridauthObject } from 'utils/function/localStorage';
import { API } from 'utils/global/backendRoutes';
import { HybridauthPopupProps } from './types';

const HybridauthPopup: React.FC<HybridauthPopupProps> = ({
  children,
  height = 500,
  title,
  url,
  width = 500,
}) => {
  const { setLanguage } = useContext(LangProviderContext);

  // Get App component context for eventually set di login
  const { dispatch } = useContext(AppContext);

  //Create a new popup window for the Hybridauth login
  const createPopup = () => {
    // Definition of popup page postion (bottom-right)
    const left = window.screenX + (window.outerWidth - width);
    const top = window.screenX + (window.outerWidth - width);

    // Open popup and focus on it
    const popupPage = window.open(
      API.BASE_URL + url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`,
    );
    popupPage?.focus();

    // Add window event listener for intercept the data returned from hybridauth service
    window.addEventListener(
      'message',
      event => {
        // Get data from event
        const content = event.data;

        // Check if the returned data exsist a Bearer token_type
        if (content.token_type === 'Bearer') {
          // Check if access token exist
          if (content.access_token) {
            const hybridautData: HybridauthObject = {
              accessTokken: content.access_token,
              expiresIn: content.expires_in,
              provider: content.provider,
              refreshToken: content.refresh_token,
              tokenType: content.token_type,
            };

            // Save hybridauth object in the local stoarge and set the session in app state
            updateHybridauthObject(hybridautData);
            loadMyself(dispatch, setLanguage, true);
          }

          try {
            window.removeEventListener('message', () => popupPage?.close());
          } catch (error) {
            console.error(error);
          }
        } else if (content.error) {
          // If the event returned an error display it with notification
          toast.error(
            content.error.detail || 'Errore durante la fase di autenticazione',
          );
        }
      },
      false,
    );
  };

  return (
    <div className="hybridauth-container" onClick={createPopup}>
      {children}
    </div>
  );
};

export default HybridauthPopup;
