import React from 'react';
import { Outlet } from 'react-router-dom';

// Layout
import Footer from 'layout/Footer';

const FooterLayout: React.FC = () => {
  return (
    <div className="footer-layout">
      <div className="footer-main-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default FooterLayout;
