import React, { useState } from 'react';
import { Button, Image, Modal, Select } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';

// Icons
import crossIcon from 'assets/images/icons/cross.svg';

// Types
import { SelectContactModalProps } from './types';
import { SelectedContact } from 'layout/SideBar/types';
import _ from 'lodash';
import { toast } from 'react-toastify';
import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';

const SelectContactModal: React.FC<SelectContactModalProps> = ({
  contactList,
  isOpen,
  onClose,
  onSelectContact,
}) => {
  const intl = useIntl();
  const [selectedContact, setSelectedContact] =
    useState<null | SelectedContact>(null);

  const setSelectedContactAndClose = () => {
    if (_.isEmpty(selectedContact)) {
      toast.warning(
        intl.formatMessage({
          id: 'selectContactModal.select.warning',
          defaultMessage: 'Selezionare un contatto per confermare',
        }),
      );
      return;
    }

    onSelectContact(selectedContact);
    onClose();
  };

  return (
    <Modal className="select-contact-modal" open={isOpen} size="tiny">
      <div className="close-button" onClick={onClose}>
        <Image src={crossIcon} />
      </div>
      <Modal.Header>
        <SvgIcon icon={ICONS.USER} height={24} width={24} />
        <FormattedMessage
          id="selectContactModal.title"
          defaultMessage="Seleziona il contatto"
        />
      </Modal.Header>
      <Modal.Content>
        <Select
          options={contactList}
          value={selectedContact?.contactCode}
          search
          placeholder={intl.formatMessage({
            id: 'selectContactModal.select.placeholder',
            defaultMessage: 'Seleziona contatto',
          })}
          noResultsMessage={intl.formatMessage({
            id: 'selectContactModal.select.empty',
            defaultMessage: 'Nessun contatto trovato',
          })}
          onChange={(e, data) => {
            const onChangedContact = contactList.find(
              contact => contact.value == data.value,
            );
            setSelectedContact({
              contactCode: _.toString(onChangedContact.value),
              name: _.toString(onChangedContact.text),
            });
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="underlineButton"
          content={
            <FormattedMessage
              id="selectContactModal.select.undo"
              defaultMessage="Annulla"
            />
          }
          onClick={() => onClose()}
        />
        <Button
          className="main-button"
          content={
            <FormattedMessage
              id="selectContactModal.select.save"
              defaultMessage="Imposta contatto"
            />
          }
          onClick={() => setSelectedContactAndClose()}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SelectContactModal;
