import React, { useEffect, useState } from 'react';
import { Input } from 'formik-semantic-ui-react';
import CurrencyInput from 'react-currency-input-field';
import { NumberFormatterInputProps } from './types';

const NumberFormatterInput: React.FC<NumberFormatterInputProps> = ({
  field,
  setFieldValue = undefined,
  placeholder = undefined,
  label = undefined,
  readOnly = false,
  prefix = 'KG ',
  onBlur = undefined,
}) => {
  const [value, setValue] = useState(field.value);

  /**
   * Update the local value when the field value is changed from outside the component.
   */
  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <Input name={field.name} label={label}>
      <CurrencyInput
        className="number-formatter-input"
        name={field.name}
        placeholder={placeholder}
        readOnly={readOnly}
        decimalsLimit={2}
        prefix={prefix}
        value={value || undefined}
        onValueChange={(value, name, values) => {
          setValue(values?.value || '');
          setFieldValue && setFieldValue(field.name, values?.float || null);
        }}
        onBlur={event => onBlur && onBlur(event)}
        allowNegativeValue={false}
      />
    </Input>
  );
};

export default NumberFormatterInput;
