import React, { useContext } from 'react';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import SemanticTableGrid from 'semantic-table-grid';
import _ from 'lodash';

// Components
import SvgIcon from 'components/SvgIcon';

// Constants - Internals - Utils
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { ReservationContext } from 'pages/Reservation';
import { ReservationConstants } from 'pages/Reservation/reducer/action';

// Types
import { OrderTableColumn, SelectOrderTableProps } from './types';

const SelectOrderTable: React.FC<SelectOrderTableProps> = ({
  areOrdersLoading,
  ordersCollection,
}) => {
  const { state, dispatch } = useContext(ReservationContext);
  const intlFormatNumber = new Intl.NumberFormat('it-IT');

  // Table definition
  const columns: Array<OrderTableColumn> = [
    {
      key: 'orderNumber',
      name: (
        <div className="select-order-table-header">
          <FormattedMessage
            id="selectOrderTable.tableHeder.orderNumber"
            defaultMessage="N. Ordine - N. Riga"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="select-order-table-orderNumber">
          <span className="order-number">{data.orderNumber}</span>
          <span className="line-no">{data.lineNo}</span>
        </div>
      ),
    },
    {
      key: 'orderItem',
      name: (
        <div className="select-order-table-header">
          <FormattedMessage
            id="selectOrderTable.tableHeder.description"
            defaultMessage="Descrizione articolo"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="select-order-table-item">
          {data.description}
          <br />
          {data.description2}
        </div>
      ),
    },
    {
      key: 'orderAmount',
      name: (
        <div className="select-order-table-header">
          <FormattedMessage
            id="selectOrderTable.tableHeder.residualQuantity"
            defaultMessage="Quantità residua"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="select-order-table-residual-quantity">
          <div className="select-order-table-residual-quantity-icon">
            <SvgIcon
              icon={ICONS.TRUCK}
              color={COLORS.SECONDARY}
              height={32}
              width={32}
            />
            <span>{data.unitOfMeasure}</span>
          </div>
          <div className="select-order-table-residual-quantity-value">
            {intlFormatNumber.format(_.toNumber(data.outstandingQuantity))}
          </div>
        </div>
      ),
    },
    {
      key: 'orderQuantityOnBookings',
      name: (
        <div className="select-order-table-header">
          <FormattedMessage
            id="selectOrderTable.tableHeder.quantityOnBookings"
            defaultMessage="Quantità prenotata"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="select-order-table-residual-quantity">
          <div className="select-order-table-residual-quantity-icon">
            <SvgIcon
              icon={ICONS.TRUCK}
              color={COLORS.SECONDARY}
              height={32}
              width={32}
            />
            <span>{data.unitOfMeasure}</span>
          </div>
          <div className="select-order-table-residual-quantity-value">
            {intlFormatNumber.format(_.toNumber(data.quantityOnBookings))}
          </div>
        </div>
      ),
    },
    {
      key: 'orderQuantityOnBookings',
      name: (
        <div className="select-order-table-header">
          <FormattedMessage
            id="selectOrderTable.tableHeder.bookableQuantity"
            defaultMessage="Quantità prenotabile"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="select-order-table-residual-quantity">
          <div className="select-order-table-residual-quantity-icon">
            <SvgIcon
              icon={ICONS.TRUCK}
              color={COLORS.SECONDARY}
              height={32}
              width={32}
            />
            <span>{data.unitOfMeasure}</span>
          </div>
          <div className="select-order-table-residual-quantity-value">
            {intlFormatNumber.format(
              _.toNumber(data.outstandingQuantity) -
                _.toNumber(data.quantityOnBookings),
            )}
          </div>
        </div>
      ),
    },
    {
      key: 'orderDeliveryDate',
      name: (
        <div className="select-order-table-header">
          <FormattedMessage
            id="selectOrderTable.tableHeder.receiptDate"
            defaultMessage="Consegna entro il"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="select-order-table-delivery-date">
          {moment(data.plannedReceiptDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'orderAction',
      name: '',
      formatter: ({ data }) => (
        /**
         * @description
         * Different article types check has been disabled as required from MSM.
         * To reactivate it remove the comment at the line code under this comment and in
         * the reducer for the ADD_ORDER_LINE action
         * disabled={
         *  !_.isNull(state.selectedArticleType) &&
         *   data.no !== state.selectedArticleType
         * }
         * In the div className => className={`select-order-table-action ${
         *    !_.isNull(state.selectedArticleType) &&
         *    data.no !== state.selectedArticleType
         *      ? 'disabled'
         *      : ''
         *    }`}
         */
        <div className="select-order-table-action">
          <Checkbox
            color="primary"
            onChange={(_e, checked) =>
              handleCheckboxChange(checked, data.orderKey, data.no)
            }
            checked={_.includes(state.selectedOrdersLine, data.orderKey)}
          />
        </div>
      ),
    },
  ];

  /**
   * Add or remove from the array of selected order line the one that has changed
   *
   * @param checked boolean -> true if the checkbox has been selected, false otherwise
   * @param orderKey order key of the checkbox that has change
   * @param articleType order No param represents the type of article of this order line
   */
  const handleCheckboxChange = (
    checked: boolean,
    orderKey: string,
    articleType: string,
  ) => {
    dispatch({
      type: checked
        ? ReservationConstants.ADD_ORDER_LINE
        : ReservationConstants.REMOVE_ORDER_LINE,
      payload: {
        orderLine: orderKey,
        articleType,
      },
    });
  };

  return (
    <div className="select-order-table-container">
      <SemanticTableGrid
        className="select-order-table"
        columns={columns}
        elements={ordersCollection}
        emptyResults={
          <div className="select-order-table-empty">
            {areOrdersLoading ? (
              <>
                <CircularProgress color="inherit" />
                <FormattedMessage
                  id="selectOrderTable.tableLoading"
                  defaultMessage="Linee ordine in caricamento"
                  description="Message showed on order line loading in reservation"
                />
              </>
            ) : (
              <FormattedMessage
                id="selectOrderTable.emptyTable"
                defaultMessage="Nessun risultato"
                description="Message showed when there are no data to show"
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default SelectOrderTable;
