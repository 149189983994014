import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { DropdownItemProps } from 'semantic-ui-react';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { FetchEnvironmentalClassesListResponse } from './types';
import _ from 'lodash';

export const fetchEnvironmentalClassesListOptions = (
  setEnvironmentalClassesListOptions: (
    environmentalClassesListOptions: Array<DropdownItemProps>,
  ) => void,
): void => {
  standardClient({
    url: API.ENVIRONMENTAL_CLASSES_LIST,
    method: 'POST',
  })
    .then((response: AxiosResponse<FetchEnvironmentalClassesListResponse>) => {
      const environmentalClassesListOptions =
        response.data.environmentalClassesCodeList.map(cerElement => ({
          text: cerElement.environmentalClassesLabel,
          value: cerElement.environmentalClassesDescription,
        }));

      setEnvironmentalClassesListOptions(environmentalClassesListOptions);
    })
    .catch(() => {
      toast.error('Impossibile caricare la lista delle Classi Ambientali');
    });
};

export const fetchFilteredEnvironmentalClassesListOptions = (
  setEnvironmentalClassesListOptions: (
    environmentalClassesListOptions: Array<Array<DropdownItemProps>>,
  ) => void,
  setEnvironmentalClassesCodeLoading: (areCerCodeLoading: boolean) => void,
  originCode: string | number | boolean | (string | number | boolean)[] | null,
  reservationCode: string | null,
): void => {
  setEnvironmentalClassesCodeLoading(true);

  standardClient({
    url: API.ENVIRONMENTAL_CLASSES_LIST,
    method: 'POST',
    data: {
      params: {
        originCode: originCode,
        reservationCode: reservationCode,
      },
    },
  })
    .then((response: AxiosResponse<FetchEnvironmentalClassesListResponse>) => {
      const dropdownCerListCollection: Array<Array<DropdownItemProps>> = [];
      Object.entries(response.data).forEach(([articleCode, cerCollection]) => {
        const environmentalClassesListOptions = cerCollection.map(
          cerElement => ({
            text: _.replace(cerElement.environmentalClassesLabel, '_', ' '),
            value: cerElement.environmentalClassesDescription,
          }),
        );

        dropdownCerListCollection[articleCode] =
          environmentalClassesListOptions;
      });

      setEnvironmentalClassesListOptions(dropdownCerListCollection);
    })
    .catch(() => {
      toast.error('Impossibile caricare la lista delle Classi Ambientali');
    })
    .finally(() => setEnvironmentalClassesCodeLoading(false));
};
