import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const bell = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 22}
    height={height || 24}
    viewBox="0 0 22 24"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.0001 10.8822C20.448 10.8822 20.0001 10.4343 20.0001 9.88227C20.0001 7.07727 18.9081 4.44128 16.925 2.45714C16.5341 2.06621 16.5341 1.43431 16.925 1.0432C17.316 0.652267 17.948 0.652267 18.339 1.0432C20.7001 3.40417 22 6.54334 22 9.88227C22 10.4343 21.5521 10.8822 21.0001 10.8822Z" />
    <path d="M0.999942 10.8822C0.44806 10.8822 0 10.4343 0 9.88227C0 6.54334 1.30005 3.40417 3.66102 1.0432C4.05195 0.652267 4.68403 0.652267 5.07497 1.0432C5.46608 1.43431 5.46608 2.06621 5.07497 2.45714C3.09193 4.44018 2.00007 7.07727 2.00007 9.88227C2.00007 10.4343 1.55201 10.8822 0.999942 10.8822Z" />
    <path d="M20.3791 16.9131C18.867 15.6351 18 13.767 18 11.788V9.00002C18 5.48109 15.386 2.56806 12 2.08008V0.999942C12 0.446962 11.552 0 11.0001 0C10.448 0 9.99997 0.446962 9.99997 0.999942V2.08008C6.61308 2.56806 3.99999 5.48109 3.99999 9.00002V11.788C3.99999 13.767 3.13299 15.6351 1.61212 16.921C1.22302 17.2541 1 17.738 1 18.25C1 19.2151 1.78497 20.0001 2.75012 20.0001H19.2501C20.215 20.0001 21 19.2151 21 18.25C21 17.738 20.777 17.2541 20.3791 16.9131Z" />
    <path d="M11.0002 24C12.8111 24 14.3261 22.7089 14.6742 21H7.32617C7.67407 22.7089 9.18909 24 11.0002 24Z" />
  </svg>
);

export default bell;
