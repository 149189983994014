import { SignupState } from '../types';
import { SignupAction, SignupConstants } from './action';

export const initialSignupState: SignupState = {
  contactId: null,
  errorObject: null,
  isLoading: false,
  signupType: undefined,
  requestedDocument: [],
  signupPhase: 'signupType',
};

export const signupReducer = (
  state: SignupState,
  action: SignupAction,
): SignupState => {
  const { type } = action;

  switch (type) {
    case SignupConstants.CHANGE_SIGNUP_PHASE:
      return { ...state, signupPhase: action.payload.signupPhase };
    case SignupConstants.CLEAR_ERROR_OBJECT:
      return { ...state, errorObject: null };
    case SignupConstants.SET_CONTACT_ID:
      return { ...state, contactId: action.payload.contactId };
    case SignupConstants.SET_ERROR_OBJECT:
      return { ...state, errorObject: action.payload.errorObject };
    case SignupConstants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case SignupConstants.SET_REGISTRATION_TYPE:
      return {
        ...state,
        signupType: action.payload.signupType,
        signupPhase: 'dataSetting',
      };
    case SignupConstants.SET_REQUESTED_DOCUMENT:
      return { ...state, requestedDocument: action.payload.requestedDocument };
    default:
      return state;
  }
};
