import { FormikHelpers } from 'formik';
import { Reservation } from 'pages/Reservation/types';
import { nextStateReservation } from 'services/reservation/postReservation';

export const baseInformationOnSubmit = (
  values: Reservation,
  reservationId: string,
  setIsLoading: (isLoading: boolean) => void,
  setReservation: (reservation: Reservation) => void,
  formikHelpers: FormikHelpers<Reservation>,
): void => {
  const submitObject = {
    originCode: values.originCode,
    reservationLines: values.reservationLines,
    state: 'delivery_information',
  };

  nextStateReservation(
    submitObject,
    reservationId,
    setIsLoading,
    setReservation,
    formikHelpers,
  );
};
