import React, { useContext } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

// Components
import HybridauthPopup from 'components/HybridauthPopup';
import TooltipPassword from 'components/TooltipPassword';

// Constants - Utils
import { AppContext } from 'pages/App';
import { CONFIG, FE_ROUTES } from 'utils/global/globalConstants';

// Types
import { LoginFormProps, LoginFormValues } from './types';

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { state } = useContext(AppContext);
  const intl = useIntl();

  const initialFormValues: LoginFormValues = {
    username: '',
    password: '',
  };

  const requiredMessageError = (requiredElement: string) => (
    <span>
      <FormattedMessage
        id="loginForm.error.required"
        defaultMessage="{element} è un campo richiesto"
        values={{ element: requiredElement }}
      />
    </span>
  );

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={value => onSubmit(value)}
      validationSchema={Yup.object().shape({
        username: Yup.string().required().nullable(),
        password: Yup.string().required().nullable(),
      })}
    >
      <Form>
        <Grid className="login-form">
          <Grid.Row>
            <Input
              name="username"
              placeholder={intl.formatMessage({
                id: 'loginForm.placeholder.username',
                defaultMessage: 'Username o P.IVA',
              })}
              label={
                <FormattedMessage
                  id="loginForm.label.username"
                  defaultMessage="Username / P.IVA"
                />
              }
            />
            <ErrorMessage
              name="username"
              component="span"
              render={() => requiredMessageError('Username')}
            />
          </Grid.Row>
          <Grid.Row>
            <Input
              label={
                <span>
                  <FormattedMessage
                    id="loginForm.label.password"
                    defaultMessage="Password"
                  />
                  <span>
                    <TooltipPassword />
                  </span>
                </span>
              }
              name="password"
              placeholder="Password"
              type="password"
            />
            <ErrorMessage
              name="password"
              render={() => requiredMessageError('Password')}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="password-recovery-column">
              <div className="password-recovery">
                <Link to="/password-recovery">
                  <FormattedMessage
                    id="loginForm.passwordRecovery"
                    defaultMessage="Hai dimenticato la password?"
                  />
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <SubmitButton
              content={<FormattedMessage id="login" defaultMessage="Login" />}
              disabled={state.isUserLoading}
              loading={state.isUserLoading}
            />
          </Grid.Row>
          {CONFIG.MICROSOFT_GRAPH_LOGIN_ACTIVE && (
            <Grid.Row>
              <HybridauthPopup
                height={500}
                title="Office365 - Login"
                url={CONFIG.MICROSOFT_GRAPH_POPUP_URL}
                width={500}
              >
                <Button
                  className="microsoft-button"
                  content="Login Office 365"
                  type="button"
                  icon="microsoft"
                  fluid
                  labelPosition="left"
                />
              </HybridauthPopup>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column className="new-user-column">
              <div className="register">
                <Link to={FE_ROUTES.SIGNUP}>
                  <FormattedMessage
                    id="loginForm.registerMessage"
                    defaultMessage={
                      'Sei un nuovo utente? <tag>Registrati!</tag>'
                    }
                    values={{
                      tag: (text: string) => (
                        <b>
                          <u>{text}</u>
                        </b>
                      ),
                    }}
                  />
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Formik>
  );
};

export default LoginForm;
