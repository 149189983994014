import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import {
  ReservationAction,
  ReservationConstants,
} from 'pages/Reservation/reducer/action';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  DEFAULT_RESERVATION_PAGE_SIZE,
  FE_ROUTES,
} from 'utils/global/globalConstants';
import { Reservation } from 'pages/Reservation/types';
import {
  ReservationListAction,
  ReservationListConstants,
} from 'pages/ReservationList/reducer/action';
import { FetchReservationListResponse } from './types';
import QueryString from 'qs';
import { ReservationStateConstants } from 'utils/global/reservationConstants';
import moment from 'moment';
import { Nullable } from 'utils/types';
import _ from 'lodash';

export const fetchReservation = (
  dispatch: React.Dispatch<ReservationAction>,
  reservationId: string,
  navigate: NavigateFunction,
): void => {
  dispatch({
    type: ReservationConstants.SET_ARE_DATA_LOADING,
    payload: { areDataLoading: true },
  });

  standardClient({
    url: API.RESERVATION + '/' + reservationId,
    method: 'GET',
  })
    .then((response: AxiosResponse<Reservation>) => {
      dispatch({
        type: ReservationConstants.SET_RESERVATION,
        payload: { reservation: response.data },
      });
    })
    .catch(() => {
      /** @TODO Add more detailed error */
      toast.error('Impossibile caricare la prenotazione richiesta');

      // If the reservation required doesn't exists redirect to the list
      navigate(FE_ROUTES.RESERVATION_LIST + '/working');
    })
    .finally(() => {
      dispatch({
        type: ReservationConstants.SET_ARE_DATA_LOADING,
        payload: { areDataLoading: false },
      });
    });
};

export const fetchReservationList = (
  stateToSearch: Array<string>,
  dispatch: React.Dispatch<ReservationListAction>,
  page: string | number | undefined = 1,
  pageSize: number = DEFAULT_RESERVATION_PAGE_SIZE,
  reservationCode: Nullable<string> = null,
): void => {
  dispatch({
    type: ReservationListConstants.SET_ARE_RESERVATIONS_LOADING,
    payload: { areReservationLoading: true },
  });

  dispatch({
    type: ReservationListConstants.SET_ALLOWED_STATE,
    payload: { allowedState: stateToSearch },
  });

  standardClient({
    url: API.RESERVATION,
    method: 'GET',
    params: {
      page,
      pageSize,
      filter: [
        {
          type: 'in',
          field: 'state',
          values: stateToSearch,
        },
        !_.isEmpty(reservationCode)
          ? {
              type: 'like',
              field: 'bookingNumber',
              value: `%${reservationCode}%`,
            }
          : {},
      ],
      'order-by': [
        {
          type: 'field',
          field: 'bookingNumber',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then((response: AxiosResponse<FetchReservationListResponse>) => {
      dispatch({
        type: ReservationListConstants.SET_RESERVATION_COLLECTION,
        payload: { reservationCollection: response.data._embedded.reservation },
      });
      dispatch({
        type: ReservationListConstants.SET_TOTAL_PAGES,
        payload: { totalePages: response.data.page_count },
      });
    })
    .catch(() => {
      /** @TODO Add more detailed error */
      toast.error('Impossibile caricare elenco prenotazioni');
    })
    .finally(() => {
      dispatch({
        type: ReservationListConstants.SET_ARE_RESERVATIONS_LOADING,
        payload: { areReservationLoading: false },
      });
    });
};

export const fetchReservationByDate = (
  searchedDate: Date,
  setReservationCollection: (reservationCollection: Array<Reservation>) => void,
  setLoading: (isLoading: boolean) => void,
): void => {
  setLoading(true);

  standardClient({
    url: API.RESERVATION,
    method: 'GET',
    params: {
      pageSize: -1,
      filter: [
        {
          type: 'in',
          field: 'state',
          values: [
            ReservationStateConstants.TO_APPROVE,
            ReservationStateConstants.APPROVED,
            ReservationStateConstants.CLOSED,
          ],
        },
        {
          type: 'eq',
          field: 'deliveryDate',
          value: moment(searchedDate).format('YYYY-MM-DD').toString(),
        },
      ],
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then((response: AxiosResponse<FetchReservationListResponse>) => {
      setReservationCollection(response.data._embedded.reservation);
    })
    .catch(() => {
      /** @TODO Add more detailed error */
      toast.error('Impossibile caricare elenco prenotazioni');
    })
    .finally(() => setLoading(false));
};
