import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const changePreferredProfile = (intl: IntlShape): void => {
  const notifyStatus = toast.loading(
    intl.formatMessage({
      id: 'service.changePreferredProfofile.loading',
      defaultMessage: 'Cambio profilo in corso...',
    }),
    {
      closeOnClick: true,
    },
  );

  standardClient
    .request({
      baseURL: API.BASE_URL,
      url: API.CHANGE_PREFERRED_PROFILE,
      method: 'POST',
    })
    .then(() => {
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.changePreferredProfofile.succesfully',
          defaultMessage: 'Profilo cambiato correttamente',
        }),
        type: 'success',
      });
      window.location.reload();
    })
    .catch(() => {
      window.location.reload();
    });
};
