import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { DropdownItemProps } from 'semantic-ui-react';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { GetDocumentsTypeListResponse } from './types';

export const getDocumentsTypeList = (
  setDocumentsTypeList: (documentsList: Array<DropdownItemProps>) => void,
): void => {
  standardClient({
    url: API.DOCUMENTS_LIST_TYPE,
    method: 'GET',
  })
    .then((response: AxiosResponse<GetDocumentsTypeListResponse>) => {
      setDocumentsTypeList(response.data.documentsTypeList);
    })
    .catch(() => {
      toast.error('Impossibile caricare la lista dei documenti');
    });
};
