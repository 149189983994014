import React, { useState, useEffect, useContext } from 'react';
import { Grid } from 'semantic-ui-react';

// Components
import CertificatesCard from '../SharedComponents/CertificatesCard';

import approvedReservationsIcon from 'assets/images/icons/approvedReservations.svg';
import arrow from 'assets/images/icons/arrow.svg';

import { FE_ROUTES } from 'utils/global/globalConstants';
import Card from 'components/Card';
import { useIntl } from 'react-intl';
import { CustomerDashboardData } from './types';
import { getCustomerDashboard } from 'services/dashboard/getCustomerDashboard';
import { AppContext } from 'pages/App';

const initialDashboardData: CustomerDashboardData = {
  salesQuotes: 0,
  salesOrders: 0,
  salesShipments: 0,
  salesInvoices: 0,
  salesCreditMemos: 0,
  generalShipments: 0,
  customerContracts: 0,
};

const CustomerDashboard: React.FC = () => {
  const intl = useIntl();
  const myself = useContext(AppContext).state.mySelf;

  const [areDashboardDataLoading, setAreDashboardDataLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(initialDashboardData);

  useEffect(() => {
    getCustomerDashboard(setAreDashboardDataLoading, setDashboardData);
  }, []);

  return (
    <div className="supplier-dashboard">
      <Grid columns={2}>
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <Grid columns={3}>
              <Grid.Row stretched>
                <Grid.Column>
                  <Card
                    iconSrc={approvedReservationsIcon}
                    rightIcon={arrow}
                    title={dashboardData.salesOrders}
                    subTitle={intl.formatMessage({
                      id: 'customerDashboard.reservationApproved',
                      defaultMessage: 'Ordini',
                    })}
                    link={intl.formatMessage({
                      id: 'customerDashboard.linkToOrders',
                      defaultMessage: 'Vedi ordini',
                    })}
                    linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/orders`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
                <Grid.Column>
                  {myself._permissions.customerContractsEnabled ? (
                    <Card
                      iconSrc={approvedReservationsIcon}
                      rightIcon={arrow}
                      title={dashboardData.customerContracts}
                      subTitle={intl.formatMessage({
                        id: 'customerDashboard.customerContracts',
                        defaultMessage: 'Contratti',
                      })}
                      link={intl.formatMessage({
                        id: 'customerDashboard.linkToContracts',
                        defaultMessage: 'Vedi contratti',
                      })}
                      linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/contract`}
                      areDataLoading={areDashboardDataLoading}
                    />
                  ) : (
                    <Card
                      iconSrc={approvedReservationsIcon}
                      rightIcon={arrow}
                      title={
                        dashboardData.generalShipments +
                        dashboardData.salesShipments
                      }
                      subTitle={intl.formatMessage({
                        id: 'customerDashboard.ddt',
                        defaultMessage: 'DDT',
                      })}
                      link={intl.formatMessage({
                        id: 'customerDashboard.linkToDDT',
                        defaultMessage: 'Vedi DDT',
                      })}
                      linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/ddt`}
                      areDataLoading={areDashboardDataLoading}
                    />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Card
                    iconSrc={approvedReservationsIcon}
                    rightIcon={arrow}
                    title={dashboardData.salesQuotes}
                    subTitle={intl.formatMessage({
                      id: 'customerDashboard.salesQuotes',
                      defaultMessage: 'Offerte',
                    })}
                    link={intl.formatMessage({
                      id: 'customerDashboard.linkToQuotes',
                      defaultMessage: 'Vedi offerte',
                    })}
                    linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/offer`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column>
                  <Card
                    iconSrc={approvedReservationsIcon}
                    rightIcon={arrow}
                    title={dashboardData.salesInvoices}
                    subTitle={intl.formatMessage({
                      id: 'customerDashboard.salesInvoices',
                      defaultMessage: 'Fatture',
                    })}
                    link={intl.formatMessage({
                      id: 'customerDashboard.linkToInvoices',
                      defaultMessage: 'Vedi fatture',
                    })}
                    linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/bill`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Card
                    iconSrc={approvedReservationsIcon}
                    rightIcon={arrow}
                    title={dashboardData.salesCreditMemos}
                    subTitle={intl.formatMessage({
                      id: 'customerDashboard.salesCreditMemos',
                      defaultMessage: 'Note di credito',
                    })}
                    link={intl.formatMessage({
                      id: 'customerDashboard.linkToSalesCreditMemos',
                      defaultMessage: 'Vedi note di credito',
                    })}
                    linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/creditsNotes`}
                    areDataLoading={areDashboardDataLoading}
                  />
                </Grid.Column>
                <Grid.Column>
                  {myself._permissions.customerContractsEnabled ? (
                    <Card
                      iconSrc={approvedReservationsIcon}
                      rightIcon={arrow}
                      title={
                        dashboardData.generalShipments +
                        dashboardData.salesShipments
                      }
                      subTitle={intl.formatMessage({
                        id: 'customerDashboard.ddt',
                        defaultMessage: 'DDT',
                      })}
                      link={intl.formatMessage({
                        id: 'customerDashboard.linkToDDT',
                        defaultMessage: 'Vedi DDT',
                      })}
                      linkTo={`${FE_ROUTES.CUSTOMER_DOCUMENTS}/ddt`}
                      areDataLoading={areDashboardDataLoading}
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CertificatesCard />
    </div>
  );
};

export default CustomerDashboard;
