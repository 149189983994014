import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const document = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6663 1.81152H12.1984V13.3053C12.1984 14.0688 11.5769 14.6904 10.8133 14.6904H6.07313H2.30371V15.1164C2.30371 15.6043 2.6997 16.0003 3.18759 16.0003H7.9261H12.6646C13.1525 16.0003 13.5485 15.6043 13.5485 15.1164V2.6954C13.5485 2.20751 13.1542 1.81152 12.6663 1.81152Z" />
    <path d="M6.07287 14.1888H10.8114C11.2993 14.1888 11.6953 13.7928 11.6953 13.3049V1.81119V0.883876C11.6953 0.39599 11.2993 0 10.8114 0H6.07287H4.49726V0.292398C4.50059 0.322473 4.50227 0.354219 4.50227 0.385965V2.31245V2.80702C4.50227 3.49373 3.94421 4.0518 3.2575 4.0518H2.76293H0.836443C0.81138 4.0518 0.784646 4.05013 0.759584 4.04845H0.452148V13.3049C0.452148 13.7928 0.848138 14.1888 1.33602 14.1888H2.30511H6.07287ZM6.25165 11.1846H3.16226C2.93168 11.1846 2.74455 10.9975 2.74455 10.7669C2.74455 10.5363 2.93168 10.3492 3.16226 10.3492H6.25332C6.4839 10.3492 6.67103 10.5363 6.67103 10.7669C6.67103 10.9975 6.48222 11.1846 6.25165 11.1846ZM9.34271 9.05265H3.16226C2.93168 9.05265 2.74455 8.8655 2.74455 8.63494C2.74455 8.40434 2.93168 8.21723 3.16226 8.21723H9.34271C9.57329 8.21723 9.76042 8.40434 9.76042 8.63494C9.76042 8.8655 9.57329 9.05265 9.34271 9.05265ZM3.16226 5.95322H9.34271C9.57329 5.95322 9.76042 6.14035 9.76042 6.37093C9.76042 6.6015 9.57329 6.78864 9.34271 6.78864H3.16226C2.93168 6.78864 2.74455 6.6015 2.74455 6.37093C2.74455 6.14035 2.93168 5.95322 3.16226 5.95322Z" />
    <path d="M0.83639 3.55085H3.25743C3.2591 3.55085 3.26244 3.55085 3.26412 3.55085C3.66846 3.54751 3.99594 3.22002 3.99928 2.81568C3.99928 2.81401 3.99928 2.81066 3.99928 2.80899V0.38627C3.99928 0.162377 3.81549 0.0136719 3.62503 0.0136719C3.53314 0.0136719 3.44124 0.0470887 3.36438 0.123948L0.572398 2.91593C0.33848 3.14985 0.503893 3.55085 0.83639 3.55085Z" />
  </svg>
);

export default document;
