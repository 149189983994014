import React from 'react';
import { ReservationCardProps } from './types';
import SvgIcon from 'components/SvgIcon';
import { FE_ROUTES, ICONS } from 'utils/global/globalConstants';
import { useNavigate } from 'react-router-dom';

const ReservationCard: React.FC<ReservationCardProps> = ({ reservation }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`reservation-card ${reservation.state}`}
      onClick={() =>
        navigate(
          FE_ROUTES.RESERVATION_DETAILS +
            '/' +
            reservation.id +
            '/view' +
            '/calendar',
        )
      }
    >
      <div className="reservation-card-content">
        <div className="reservation-card-content-description">
          <div className="card-description-orderNumber">
            <SvgIcon icon={ICONS.INFORMATION} height={24} width={21} />
            {reservation._embedded?.contact?.name}
          </div>
          <div className="card-description-descriptionText">
            {reservation.bookingNumber}
          </div>
        </div>
        <div className="reservation-card-content-weight">
          <div className="card-weight-container">
            <span className="card-weight-unit">Linee ordine</span>
            <span className="card-weight-value">
              {reservation.reservationLines.length}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationCard;
