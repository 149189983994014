import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Dropdown, Image } from 'semantic-ui-react';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';

// Components
import ReservationTable from '../ReservationTable';
import ConfirmModal from 'components/ConfirmModal';
import { deleteReservation } from 'services/reservation/deleteReservation';

// Icons
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import eye from 'assets/images/icons/eye.svg';

// Constants, types and utils
import { Nullable } from 'utils/types';
import { isThatRole } from 'utils/function/acl';
import { FE_ROUTES, ICONS, ROLES } from 'utils/global/globalConstants';
import { AppContext } from 'pages/App';
import { getStyledIcon, getSvgStyledIcon } from 'utils/function/styles';
import { ReservationStateConstants } from 'utils/global/reservationConstants';
import { ReservationAccordionProps } from './types';
import { Reservation } from 'pages/Reservation/types';
import { fetchReservationList } from 'services/reservation/fetchReservation';
import { ReservationListContext } from 'pages/ReservationList';
import moment from 'moment';
import SvgIcon from 'components/SvgIcon';

export const ReservationAccordion: React.FC<ReservationAccordionProps> = ({
  reservation,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { state, dispatch } = useContext(ReservationListContext);
  const { state: AppState } = useContext(AppContext);

  // Use for manage the accordion open state
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const [loadingDeleteReservation, setLoadingDeleteReservation] =
    useState<Nullable<string>>(null);

  const [reservationToDelete, setReservationToDelete] =
    useState<Nullable<Reservation>>(null);

  /** Redirect to the reservation's page */
  const redirectToReservation = (reservationId: string, mode: string) => {
    navigate(FE_ROUTES.RESERVATION_DETAILS + '/' + reservationId + '/' + mode);
  };

  const reloadReservation = () => {
    fetchReservationList(state.allowedState, dispatch);
  };

  /** Utils function for manage the open and close of an accordion */
  const handleAccordionClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  /** Show dropdown with options for single reservation */
  const renderSettings = () => {
    // If this reservation is deleting show a loader
    if (reservation.id === loadingDeleteReservation) {
      return (
        <div className="reservations-accordion-actionMenu">
          <CircularProgress color="inherit" />
        </div>
      );
    }

    if (reservation.state === ReservationStateConstants.UNAPPROVED) {
      return null;
    }

    return (
      <div className="reservations-accordion-actionMenu">
        <Dropdown
          className="standard-dropdown-menu"
          direction="left"
          icon={<SvgIcon icon={ICONS.EYE} height={24} width={24} />}
          onClick={() => redirectToReservation(reservation.id, 'view')}
        />
        <Dropdown
          className="standard-dropdown-menu"
          direction="left"
          icon={<SvgIcon icon={ICONS.GEAR} />}
          item
        >
          <Dropdown.Menu>
            <Dropdown.Item
              key="visualize"
              text={
                <FormattedMessage
                  id="reservationAccordion.show"
                  defaultMessage="Visualizza"
                />
              }
              icon={getStyledIcon(eye)}
              onClick={() => redirectToReservation(reservation.id, 'view')}
            />
            {isThatRole(ROLES.SUPPLIER, AppState) &&
              ![ReservationStateConstants.CLOSED].includes(
                reservation.state,
              ) && (
                <Dropdown.Item
                  key="approve"
                  text={
                    <FormattedMessage
                      id="reservationAccordion.modify"
                      defaultMessage="Modifica"
                    />
                  }
                  icon={getSvgStyledIcon(ICONS.PENCIL)}
                  onClick={() => redirectToReservation(reservation.id, 'edit')}
                />
              )}
            {isThatRole(ROLES.SUPPLIER, AppState) &&
              ![
                ReservationStateConstants.CLOSED,
                ReservationStateConstants.APPROVED,
              ].includes(reservation.state) && (
                <Dropdown.Item
                  key="delete"
                  text={
                    <FormattedMessage
                      id="reservationAccordion.delete"
                      defaultMessage="Elimina"
                    />
                  }
                  icon={getSvgStyledIcon(ICONS.TRASH)}
                  onClick={() => setReservationToDelete(reservation)}
                />
              )}
          </Dropdown.Menu>
        </Dropdown>
        {reservation.documentsCount > 0 && (
          <Dropdown
            className="standard-dropdown-menu"
            direction="left"
            icon={<SvgIcon icon={ICONS.DOCUMENT} height={24} width={24} />}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Accordion className="reservation-accordion">
        <Accordion.Title
          onClick={handleAccordionClick}
          className="reservation-accordion-title"
        >
          <div className="reservation-vehiclePlate-section">
            <span className="reservation-vehiclePlate-title">
              <FormattedMessage
                id="reservationAccordion.header.reservationNumber"
                defaultMessage="Numero prenotazione"
              />
            </span>
            <span className="reservation-vehiclePlate-value">
              {reservation.bookingNumber}
            </span>
          </div>
          {isThatRole(ROLES.ADMIN, AppState) && (
            <div className="reservation-vehiclePlate-section">
              <span className="reservation-vehiclePlate-title">Fornitore</span>
              <span className="reservation-vehiclePlate-value">
                {reservation._embedded?.contact.name}
              </span>
            </div>
          )}
          <div className="reservation-date-section">
            {reservation.deliveryDate && (
              <span className="reservation-date-value">
                <FormattedMessage
                  id="reservationAccordion.header.date.of"
                  defaultMessage="del"
                />
                <span className="reservation-date-bold">
                  {moment(reservation.deliveryDate).format(' DD MMM YYYY')}
                </span>
              </span>
            )}
            <Image
              src={arrowDownIcon}
              className={`reservation-accordion-icon ${
                isAccordionOpen ? 'accordion-open' : 'accordion-close'
              }`}
            />
            {renderSettings()}
          </div>
        </Accordion.Title>
        <Accordion.Content active={isAccordionOpen}>
          <div className="reservation-accordion-content">
            <ReservationTable reservationLines={reservation.reservationLines} />
          </div>
        </Accordion.Content>
      </Accordion>

      <ConfirmModal
        type="delete"
        confirmButtonText={intl.formatMessage({
          id: 'reservationAccordion.modal.deleteButton',
          defaultMessage: 'Elimina prenotazione',
        })}
        isOpen={!_.isEmpty(reservationToDelete)}
        onClose={() => setReservationToDelete(null)}
        onConfirm={() => {
          deleteReservation(
            reservationToDelete.id,
            setLoadingDeleteReservation,
            reloadReservation,
            intl,
          );
          setReservationToDelete(null);
        }}
        titleText={intl.formatMessage({
          id: 'reservationAccordion.modal.deleteReservation',
          defaultMessage: 'Elimina prenotazione',
        })}
        subtitleText={intl.formatMessage({
          id: 'reservationAccordion.modal.descriptionDeleteReservation',
          defaultMessage:
            'Si conferma di volere eliminare la seguente prenotazione?',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'reservationAccordion.modal.undoDelete',
          defaultMessage: 'Annulla',
        })}
      />
    </div>
  );
};

export default ReservationAccordion;
