import React, { useState } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import { FiltersBarProps } from './types';
import CalendarPicker from 'components/CalendarPicker';
import moment from 'moment';

const FiltersBar: React.FC<FiltersBarProps> = ({
  hideDataFilter = false,
  resetFilters,
}) => {
  /** STATE DEFINTION */
  const [filterDate, setFilterDate] = useState<string>('');
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState(false);

  /** Create a FiltersObject with their current values and call the callback gave as prop */
  const onSetFilters = () => {
    /*     const currentFilters: FiltersObject = {
      filterDate: filterDate,
    };

    setFilters(currentFilters); */
  };

  /** Reset all the filters value in the component state and call the callback gave as prop */
  const onResetFilters = () => {
    setFilterDate('');
    resetFilters();
  };

  return (
    <Grid className="filterBar">
      <Grid.Row>
        {!hideDataFilter && (
          <Input
            name="releaseDate"
            placeholder="Selezionare una data"
            value={filterDate}
            readOnly
            onClick={() => setIsCalendarPickerOpen(true)}
          />
        )}
        <Button.Group>
          <Button
            className="mainButton"
            onClick={onSetFilters}
            content="Cerca"
          />
          <Button
            className="underlineButton"
            onClick={onResetFilters}
            content="Rimuovi i filtri attivi"
          />
        </Button.Group>
      </Grid.Row>

      <CalendarPicker
        isOpen={isCalendarPickerOpen}
        onClose={() => setIsCalendarPickerOpen(false)}
        onChange={(date: Date) => {
          const formattedDate = moment(date).format('DD-MM-YYYY').toString();
          setFilterDate(formattedDate);
        }}
        value={filterDate}
      />
    </Grid>
  );
};

export default FiltersBar;
