import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const eye = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 20}
    height={height || 13}
    viewBox="0 0 20 13"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.99992 2.00065C13.1583 2.00065 15.9749 3.77565 17.3499 6.58398C15.9749 9.39232 13.1583 11.1673 9.99992 11.1673C6.84158 11.1673 4.02492 9.39232 2.64992 6.58398C4.02492 3.77565 6.84158 2.00065 9.99992 2.00065ZM9.99992 0.333984C5.83325 0.333984 2.27492 2.92565 0.833252 6.58398C2.27492 10.2423 5.83325 12.834 9.99992 12.834C14.1666 12.834 17.7249 10.2423 19.1666 6.58398C17.7249 2.92565 14.1666 0.333984 9.99992 0.333984ZM9.99992 4.50065C11.1499 4.50065 12.0833 5.43398 12.0833 6.58398C12.0833 7.73398 11.1499 8.66732 9.99992 8.66732C8.84992 8.66732 7.91658 7.73398 7.91658 6.58398C7.91658 5.43398 8.84992 4.50065 9.99992 4.50065ZM9.99992 2.83398C7.93325 2.83398 6.24992 4.51732 6.24992 6.58398C6.24992 8.65065 7.93325 10.334 9.99992 10.334C12.0666 10.334 13.7499 8.65065 13.7499 6.58398C13.7499 4.51732 12.0666 2.83398 9.99992 2.83398Z" />
  </svg>
);

export default eye;
