import { Formik } from 'formik';
import { Form, Input } from 'formik-semantic-ui-react';
import { DocumentViewerContext } from 'pages/DocumentViewer';
import React, { useContext } from 'react';
import { Document, Page } from 'react-pdf';
import { Button } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocumentFile } from 'services/contactDocuments/getContactDocuments';
import moment from 'moment';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { DocumentViewerConstants } from 'pages/DocumentViewer/reducer/action';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

const DocViewer: React.FC = () => {
  const intl = useIntl();
  const { state, dispatch } = useContext(DocumentViewerContext);
  const { documentId, attachmentId = null } = useParams();
  const navigate = useNavigate();

  /**
   * Based on the current document state return a text to show in the table and
   * the color to use for the icon
   *  0 - Not loaded + gray
   *  1 - Validation + yellow
   *  2 - Rejected + red
   *  3 - Approved + green
   *
   * @param state Dcouments current state (number between 0 and 3)
   * @returns object witch color (string with colors enum) and text (string)
   */
  const renderDocumentStatus = (state: number | string) => {
    switch (state) {
      case 1:
        return intl.formatMessage({
          id: 'documentsTable.data.acceptance.underValidation',
          defaultMessage: 'In validazione',
        });
      case 2:
        return intl.formatMessage({
          id: 'documentsTable.data.acceptance.rejected',
          defaultMessage: 'Rifiutato',
        });
      case 3:
        return intl.formatMessage({
          id: 'documentsTable.data.acceptance.approved',
          defaultMessage: 'Approvato',
        });
      default:
        return intl.formatMessage({
          id: 'documentsTable.data.acceptance.notLoaded',
          defaultMessage: 'Non caricato',
        });
    }
  };

  const documentLoaded = (pdfNumPages: number) => {
    dispatch({
      type: DocumentViewerConstants.SET_DOCUMENT_FILE_PAGES,
      payload: { documentFilePages: pdfNumPages },
    });
  };

  return (
    <div className="doc-viewer">
      <div className="doc-viewer-data">
        <Formik
          initialValues={state.supplier || {}}
          enableReinitialize
          onSubmit={() => {
            null;
          }}
        >
          <Form>
            <Input
              readOnly
              name="name"
              placeholder="- - -"
              label={intl.formatMessage({
                id: 'docViewer.label.name',
                defaultMessage: 'Fornitore',
              })}
              value={state.documentData?.name}
            />
            <Input
              readOnly
              name="vatNumber"
              placeholder="- - -"
              label={intl.formatMessage({
                id: 'docViewer.label.vatNumber',
                defaultMessage: 'Partita IVA',
              })}
              value={state.documentData?.vatNumber}
            />
            <Input
              readOnly
              name="documentType"
              placeholder="- - -"
              label={intl.formatMessage({
                id: 'docViewer.label.documentType',
                defaultMessage: 'Tipo documento',
              })}
              value={state.documentData?.document?.documentDesc}
            />
            <Input
              readOnly
              name="documentType"
              placeholder="- - -"
              label={intl.formatMessage({
                id: 'docViewer.label.state',
                defaultMessage: 'Stato',
              })}
              value={
                state.documentData?.document?.status
                  ? renderDocumentStatus(state.documentData.document.status)
                  : null
              }
            />
            <Input
              readOnly
              name="documentType"
              placeholder="- - -"
              label={intl.formatMessage({
                id: 'docViewer.label.expirationDate',
                defaultMessage: 'Data scadenza',
              })}
              value={
                state.documentData?.document?.expirationDate
                  ? moment(state.documentData.document.expirationDate).format(
                      'DD/MM/YYYY',
                    )
                  : null
              }
            />
          </Form>
        </Formik>
        <div className="doc-viewer-data-action">
          <Button
            className="back-button"
            content={intl.formatMessage({
              id: 'docViewer.button.back',
              defaultMessage: 'Torna alla lista documenti',
            })}
            onClick={() => navigate(-1)}
          />
          <Button
            className="main-button"
            content="Download"
            onClick={() =>
              getDocumentFile(intl, () => null, documentId, attachmentId)
            }
          />
        </div>
      </div>
      <div className="doc-viewer-file">
        <Document
          file={state.documentFile}
          loading={
            <>
              <CircularProgress color="inherit" size={20} />
              <span className="optional-document-loading">
                Documento in caricamento
              </span>
            </>
          }
          onLoadSuccess={pdf => documentLoaded(pdf.numPages)}
          error={
            <span className="optional-document-loading">
              <FormattedMessage
                id="docviewer.viewer.error"
                defaultMessage="Il formato del file non è supportato. Scaricare il documento per visualizzarlo"
              />
            </span>
          }
        >
          <Page
            pageNumber={state.documentFileCurrentPage}
            key={`document_file_${state.documentFileCurrentPage}`}
          />
          {/*           {state.documentFileState === 'error' && <Page pageNumber={1} />}
          {state.documentFileState === 'loading' && (

          )} */}
        </Document>

        <div className="doc-viewer-file-pages">
          <span
            className={`left-button ${
              state.documentFileCurrentPage > 1 ? '' : 'left-button-disable'
            }`}
            onClick={() =>
              dispatch({
                type: DocumentViewerConstants.SET_DOCUMENT_FILE_CURRENT_PAGE,
                payload: { currentPage: 'previous' },
              })
            }
          >
            <SvgIcon
              icon={ICONS.ARROW_LEFT}
              color={
                state.documentFileCurrentPage > 1
                  ? COLORS.BLACK
                  : COLORS.LIGHT_GREY
              }
            />
          </span>
          <span className="text-container">
            <FormattedMessage
              id="docviewer.pageManager.totalePages"
              defaultMessage="Pagina {currentPage} di {totalPages}"
              values={{
                currentPage: state.documentFileCurrentPage,
                totalPages: state.documentFilePages,
              }}
            />
          </span>
          <span
            className={`right-button ${
              state.documentFileCurrentPage < state.documentFilePages
                ? ''
                : 'right-button-disable'
            }`}
            onClick={() =>
              dispatch({
                type: DocumentViewerConstants.SET_DOCUMENT_FILE_CURRENT_PAGE,
                payload: { currentPage: 'next' },
              })
            }
          >
            <SvgIcon
              icon={ICONS.ARROW_RIGHT}
              color={
                state.documentFileCurrentPage < state.documentFilePages
                  ? COLORS.BLACK
                  : COLORS.LIGHT_GREY
              }
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DocViewer;
