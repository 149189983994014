import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const arrowLeft = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 9}
    height={height || 15}
    viewBox="0 0 9 15"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.83571 1.25008C7.22624 0.859551 7.8594 0.859551 8.24992 1.25008C8.64045 1.6406 8.64045 2.27377 8.24992 2.66429L3.16414 7.75008L8.24992 12.8359C8.64045 13.2264 8.64045 13.8596 8.24992 14.2501C7.8594 14.6406 7.22624 14.6406 6.83571 14.2501L1.04282 8.45718C0.652293 8.06666 0.652293 7.43349 1.04282 7.04297L6.83571 1.25008Z"
    />
  </svg>
);

export default arrowLeft;
