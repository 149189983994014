import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Dropdown } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import SemanticTableGrid from 'semantic-table-grid';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// Components
import SvgIcon from 'components/SvgIcon';
import UploadDocumentModal from 'components/UploadDocumentModal';

// Constants - Internals - Utils
import { COLORS, FE_ROUTES, ICONS } from 'utils/global/globalConstants';
import { getSvgStyledIcon } from 'utils/function/styles';

// Services
import {
  getDocumentFile,
  getDocumentForm,
  uploadDocumentFile,
} from 'services/contactDocuments/getContactDocuments';

// Types
import { DocumentData } from 'pages/ContactDocuments/types';
import { DocumentsTableColumn, DocumentsTableProps } from './types';
import { Nullable } from 'utils/types';

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  areDocumentsLoading,
  contactCode,
  disabledUpload = false,
  documentsCollection,
  reloadDocuments,
  showUnique = true,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedDocument, setSelectedDocument] =
    useState<Nullable<DocumentData>>(null);

  const columns: Array<DocumentsTableColumn> = [
    {
      key: 'documentName',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.documentTitle"
            defaultMessage="Tipo documento"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="document-name">{data.documentDesc}</div>
      ),
    },
    {
      key: 'state',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.state"
            defaultMessage="Stato"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="documents-table-attachmentsNumber">
          {data.isMissing
            ? intl.formatMessage({
                id: 'documentsTable.data.state.toUpload',
                defaultMessage: 'Da caricare',
              })
            : data.isExpired
            ? intl.formatMessage({
                id: 'documentsTable.data.state.expired',
                defaultMessage: 'Scaduto',
              })
            : data.isExpiring
            ? intl.formatMessage({
                id: 'documentsTable.data.state.expiring',
                defaultMessage: 'In scadenza',
              })
            : intl.formatMessage({
                id: 'documentsTable.data.state.uploaded',
                defaultMessage: 'Caricato',
              })}
        </div>
      ),
      width: '11%',
    },
    {
      key: 'isMandatory',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.isMandatory"
            defaultMessage="Obbligatorio"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="documents-table-attachmentsNumber">
          {data.isMandatory === 1 ? (
            <SvgIcon icon={ICONS.CHECK} color={COLORS.SUCCESS} />
          ) : null}
        </div>
      ),
    },
    {
      key: 'expirationDate',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.expirationDate"
            defaultMessage="Data scadenza"
          />
        </div>
      ),
      formatter: ({ data }) => {
        return (
          <div className="documents-table-expirationDate">
            <div className="documents-table-expirationDate-container">
              <SvgIcon
                icon={ICONS.CLOCK_FILLED}
                color={renderExpirationColor(data)}
              />
              {!_.isEmpty(data.expirationDate) && !data.isMissing
                ? moment(data.expirationDate).format('DD-MM-YYYY')
                : '- - - - - - - - - -'}
            </div>
          </div>
        );
      },
    },
    {
      key: 'documentAcceptance',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.documentAcceptance"
            defaultMessage="Approvazione"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="documents-table-state">
          <div className="documents-table-state-container">
            <SvgIcon
              icon={ICONS.FILE}
              color={renderDocumentStatus(data.status).color}
              width={20}
              height={20}
            />
            {renderDocumentStatus(data.status).popupText}
          </div>
        </div>
      ),
      width: '10%',
    },
    {
      key: 'attachmentsNumber',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.attachmentsNumber"
            defaultMessage="Allegati"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="documents-table-attachmentsNumber">
          {data.noOfAttachments}
        </div>
      ),
    },
    {
      key: 'documentActions',
      name: (
        <div className="documents-table-header">
          <FormattedMessage
            id="documentsTable.table.header.actions"
            defaultMessage="Azioni"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="documents-table-actions">
          <Dropdown
            className="standard-dropdown-menu"
            direction="left"
            icon={<SvgIcon icon={ICONS.ELIPSIS} />}
            item
          >
            <Dropdown.Menu>
              {data.noOfAttachments > 1 && (
                <Dropdown.Item className="attahcments-item-dropdown">
                  <Dropdown
                    className="attachments-dropdown"
                    text={intl.formatMessage({
                      id: 'documentsTable.actions.dropdown.attachmentsList',
                      defaultMessage: 'Visualizza',
                    })}
                    icon={getSvgStyledIcon(ICONS.ARROW_LEFT)}
                    simple
                    closeOnBlur={false}
                  >
                    <Dropdown.Menu>
                      {data.attachments.map(attachment => (
                        <Dropdown.Item
                          key={attachment.fileName + attachment.attachmentId}
                          text={attachment.fileName}
                          icon={getSvgStyledIcon(ICONS.EYE)}
                          onClick={() =>
                            navigate(
                              FE_ROUTES.DOCUMENT +
                                '/' +
                                data.documentId +
                                '/' +
                                attachment.attachmentId +
                                '/' +
                                contactCode,
                            )
                          }
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
              )}
              {data.noOfAttachments === 1 && (
                <Dropdown.Item
                  key="visualize"
                  text={intl.formatMessage({
                    id: 'documentsTable.actions.dropdown.viusalize',
                    defaultMessage: 'Visualizza',
                  })}
                  icon={getSvgStyledIcon(ICONS.EYE)}
                  onClick={() =>
                    navigate(
                      FE_ROUTES.DOCUMENT +
                        '/' +
                        data.documentId +
                        '/' +
                        data.attachments[0].attachmentId +
                        '/' +
                        contactCode,
                    )
                  }
                />
              )}
              {!disabledUpload && data.noOfAttachments > 0 && (
                <Dropdown.Item
                  key="download"
                  text={
                    data.noOfAttachments === 1
                      ? intl.formatMessage({
                          id: 'documentsTable.actions.dropdown.downloadFile',
                          defaultMessage: 'Scarica',
                        })
                      : intl.formatMessage({
                          id: 'documentsTable.actions.dropdown.downloadZip',
                          defaultMessage: 'Scarica archivio',
                        })
                  }
                  icon={getSvgStyledIcon(ICONS.ARROW_DOWN)}
                  onClick={() =>
                    getDocumentFile(intl, () => null, data.documentId)
                  }
                />
              )}
              <Dropdown.Item
                key="addAttachments"
                text={intl.formatMessage({
                  id: 'documentsTable.actions.dropdown.uploadAttachments',
                  defaultMessage: 'Carica allegato',
                })}
                icon={getSvgStyledIcon(ICONS.CHECKLIST)}
                onClick={() => setSelectedDocument(data)}
              />
              {data.isForm && (
                <Dropdown.Item
                  key="downloadForm"
                  text={intl.formatMessage({
                    id: 'documentsTable.actions.dropdown.downloadForm',
                    defaultMessage: 'Scarica form',
                  })}
                  icon={getSvgStyledIcon(ICONS.PENCIL)}
                  onClick={() => getDocumentForm(data.documentType, intl)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      width: '10%',
    },
  ];

  /**
   * Based on the current document state return a text to show in the table and
   * the color to use for the icon
   *  0 - Not loaded + gray
   *  1 - Validation + yellow
   *  2 - Rejected + red
   *  3 - Approved + green
   *
   * @param state Dcouments current state (number between 0 and 3)
   * @returns object witch color (string with colors enum) and text (string)
   */
  const renderDocumentStatus = (state: number) => {
    switch (state) {
      case 1:
        return {
          color: COLORS.SECONDARY,
          popupText: intl.formatMessage({
            id: 'documentsTable.data.acceptance.underValidation',
            defaultMessage: 'In validazione',
          }),
        };
      case 2:
        return {
          color: COLORS.ERROR,
          popupText: intl.formatMessage({
            id: 'documentsTable.data.acceptance.rejected',
            defaultMessage: 'Rifiutato',
          }),
        };
      case 3:
        return {
          color: COLORS.SUCCESS,
          popupText: intl.formatMessage({
            id: 'documentsTable.data.acceptance.approved',
            defaultMessage: 'Approvato',
          }),
        };
      default:
        return {
          color: COLORS.PRIMARY,
          popupText: intl.formatMessage({
            id: 'documentsTable.data.acceptance.notLoaded',
            defaultMessage: 'Non caricato',
          }),
        };
    }
  };

  const renderExpirationColor = (document: DocumentData) => {
    if (document.isExpired) {
      return COLORS.ERROR;
    }

    if (document.isExpiring) {
      return COLORS.SECONDARY;
    }

    if (document.isMissing) {
      return COLORS.PRIMARY;
    }

    return COLORS.SUCCESS;
  };

  /** Management of the updated files */
  const handleUploadDocument = (
    documentFile: File,
    releaseDate: string,
    expirationDate: string,
  ) => {
    // Check if the file is an instance of File, if it isn't return
    if (!(documentFile instanceof File)) {
      return;
    }

    const formData = new FormData();

    formData.append('document', documentFile);
    formData.append('contactNo', contactCode);
    formData.append('documentType', selectedDocument.documentType);
    formData.append('fileName', documentFile.name);
    formData.append('attachmentID', '0');
    formData.append('startDate', moment(releaseDate).format('YYYY-MM-DD'));
    formData.append('dueDate', moment(expirationDate).format('YYYY-MM-DD'));

    /**
     * If the selected document's state is APPROVED or REJECTED the document id should be 0
     * If we want append another attachments when the DOCUMENT is pending the documentID
     * should be the same
     */
    if (
      selectedDocument.noOfAttachments === 0 ||
      selectedDocument.status === 2 ||
      selectedDocument.status === 3
    ) {
      formData.append('documentID', '0');
    } else {
      formData.append('documentID', selectedDocument.documentId);
    }

    uploadDocumentFile(formData, reloadDocuments, intl);
    setSelectedDocument(null);
  };

  return (
    <div className="documents-table">
      <SemanticTableGrid
        elements={
          showUnique
            ? documentsCollection
            : documentsCollection.filter(document => !document.isUnique)
        }
        columns={columns}
        totalPages={0}
        emptyResults={
          <div className="documents-table-empty">
            {areDocumentsLoading ? (
              <>
                <CircularProgress color="inherit" />
                <FormattedMessage
                  id="documentsTable.tableLoading"
                  defaultMessage="Documenti in caricamento"
                />
              </>
            ) : (
              <FormattedMessage
                id="documentsTable.emptyTable"
                defaultMessage="Nessun risultato"
                description="Message showed when there are no data to show"
              />
            )}
          </div>
        }
      />

      <UploadDocumentModal
        uploadDocumentFile={handleUploadDocument}
        isOpen={!!selectedDocument}
        onClose={() => setSelectedDocument(null)}
      />
    </div>
  );
};

export default DocumentsTable;
