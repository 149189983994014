import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const calendar = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_916_7947)">
      <path d="M0.468054 12.2191H12.7186C12.8285 12.2191 12.9347 12.1806 13.0189 12.1106C13.1366 12.012 15.8206 9.70303 15.9843 4.71875H2.82747C2.66443 9.24313 0.19251 11.3697 0.166821 11.3909C0.0162208 11.5182 -0.039188 11.7261 0.0285651 11.911C0.0958494 12.0955 0.271201 12.2191 0.468054 12.2191Z" />
      <path d="M15.5313 1.90629H13.1875V1.43752C13.1875 1.17501 12.9812 0.96875 12.7187 0.96875C12.4562 0.96875 12.2499 1.17501 12.2499 1.43752V1.90629H9.87483V1.43752C9.87483 1.17501 9.66857 0.96875 9.40606 0.96875C9.14355 0.96875 8.93729 1.17501 8.93729 1.43752V1.90629H6.59343V1.43752C6.59343 1.17501 6.38717 0.96875 6.12466 0.96875C5.86215 0.96875 5.65589 1.17501 5.65589 1.43752V1.90629H3.31203C3.04952 1.90629 2.84326 2.11255 2.84326 2.37506V3.78138H16.0001V2.37506C16.0001 2.11255 15.7938 1.90629 15.5313 1.90629Z" />
      <path d="M13.621 12.8298C13.3665 13.0417 13.0469 13.1571 12.7187 13.1571H2.84326V14.5634C2.84326 14.8225 3.05293 15.0322 3.31203 15.0322H15.5313C15.7904 15.0322 16.0001 14.8225 16.0001 14.5634V9.30859C15.0963 11.5259 13.8351 12.6505 13.621 12.8298Z" />
    </g>
    <defs>
      <clipPath id="clip0_916_7947">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default calendar;
