import React from 'react';
import { FormattedMessage } from 'react-intl';

export const completeTimeSlot = [
  {
    text: (
      <FormattedMessage
        id="timeslot.options.morning"
        defaultMessage="Mattina 8:00 - 12:00"
      />
    ),
    value: 'morning',
  },
  {
    text: (
      <FormattedMessage
        id="timeslot.options.afternoon"
        defaultMessage="Pomeriggio 14:00 - 18:00"
      />
    ),
    value: 'afternoon',
  },
];

export const onlyMorningTimeSlot = [
  {
    text: (
      <FormattedMessage
        id="timeslot.options.morning"
        defaultMessage="Mattina 8:00 - 12:00"
      />
    ),
    value: 'morning',
  },
];

export const onlyAfternoonTimeSlot = [
  {
    text: (
      <FormattedMessage
        id="timeslot.options.afternoon"
        defaultMessage="Pomeriggio 14:00 - 18:00"
      />
    ),
    value: 'afternoon',
  },
];
