import { OrderLine } from 'pages/UserOrders/types';

export enum ArticlePhaseConstants {
  CHANGE_CURRENT_PAGE = 'ArticlePhase/CHANGE_CURRENT_PAGE',
  REMOVE_FILTERS = 'ArticlePhase/REMOVE_FILTERS',
  SET_ARE_ORDERS_LINE_LOADING = 'ArticlePhase/SET_ARE_ORDERS_LINE_LOADING',
  SET_COMPLETE_ORDERS_LINE = 'ArticlePhase/SET_COMPLETE_ORDERS_LINE',
  SET_ORDER_NUMBER_FILTER = 'ArticlePhase/SET_ORDER_NUMBER_FILTER',
}

export type ArticlePhaseAction =
  | {
      type: ArticlePhaseConstants.CHANGE_CURRENT_PAGE;
      payload: { currentPage: number | string };
    }
  | {
      type: ArticlePhaseConstants.REMOVE_FILTERS;
    }
  | {
      type: ArticlePhaseConstants.SET_ARE_ORDERS_LINE_LOADING;
      payload: { areOrdersLineLoading: boolean };
    }
  | {
      type: ArticlePhaseConstants.SET_COMPLETE_ORDERS_LINE;
      payload: { completeOrdersLine: Array<OrderLine> };
    }
  | {
      type: ArticlePhaseConstants.SET_ORDER_NUMBER_FILTER;
      payload: { orderNumberSearched: string };
    };
