import React, { useContext, useState } from 'react';
import { Button, Image, Modal, Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

// Icons
import crossIcon from 'assets/images/icons/cross.svg';
import warningIcon from 'assets/images/icons/warning-circle.svg';

// Internals
import { LangProviderContext } from 'components/LangProvider';

// Types
import { LanguageUpdateProps } from './types';
import { AppContext } from 'pages/App';
import { updateLanguage } from 'services/user/updateLanguage';

const languageOptions = [
  { text: 'English', value: 'en' },
  { text: 'Italiano', value: 'it-IT' },
];

const languageToFormat = ['it-IT'];

const LanguageUpdate: React.FC<LanguageUpdateProps> = ({ isOpen, onClose }) => {
  const { language, setLanguage } = useContext(LangProviderContext);
  const [selectedLanguage, updateSelectedLanguage] = useState(language);

  const { state } = useContext(AppContext);

  /**
   * On save update the LanProviderContext with the new selected language,
   * make the API call for update the user profile with the new language (@TODO)
   * and close the modal.
   */
  const onSaveDefaultLanguage = () => {
    let language = selectedLanguage;

    if (languageToFormat.includes(language)) {
      language = language.substring(0, selectedLanguage.indexOf('-'));
    }

    // Update the langugae in the LangProvider
    updateLanguage(state.mySelf.id, language, setLanguage, onClose);
  };

  return (
    <Modal className="language-update-modal" open={isOpen} size="tiny">
      <div className="close-button" onClick={onClose}>
        <Image src={crossIcon} />
      </div>
      <Modal.Header>
        <Image src={warningIcon} />
        <FormattedMessage
          id="languageUpdate.title"
          defaultMessage="Aggiornamento lingua di default"
        />
      </Modal.Header>
      <Modal.Content>
        <Select
          options={languageOptions}
          value={selectedLanguage}
          onChange={(e, data) => updateSelectedLanguage(data.value.toString())}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="underlineButton"
          content={
            <FormattedMessage
              id="languageUpdate.cancel"
              defaultMessage="Annulla"
            />
          }
          onClick={() => onClose()}
        />
        <Button
          className="main-button"
          content={
            <FormattedMessage
              id="languageUpdate.save"
              defaultMessage="Aggiorna lingua"
            />
          }
          onClick={() => onSaveDefaultLanguage()}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default LanguageUpdate;
