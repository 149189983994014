import React, { useContext } from 'react';
import SemanticTableGrid from 'semantic-table-grid';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

// Utils, types and constants
import {
  ReservationsColumn,
  ReservationsListProps,
  SingleReservationParam,
} from './types';
import {
  environmentalClassEngOptions,
  environmentalClassOptions,
} from 'utils/global/reservationConstants';
import { Nullable } from 'utils/types';
import { LangProviderContext } from 'components/LangProvider';

const ReservationTable: React.FC<ReservationsListProps> = ({
  reservationLines,
}) => {
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const currentLang = useContext(LangProviderContext).language;

  const mapEnvironmentalClass = (environmentalClass: Nullable<string>) => {
    if (_.isEmpty(environmentalClass)) {
      return '- - -';
    }

    let environmentalText = '- - -';
    if (currentLang === 'it-IT' || currentLang === 'it') {
      environmentalClassOptions.map(environmental => {
        if (environmentalClass === environmental.value) {
          environmentalText = environmental.text;
        }
      });
    } else {
      environmentalClassEngOptions.map(environmental => {
        if (environmentalClass === environmental.value) {
          environmentalText = environmental.text;
        }
      });
    }

    return environmentalText;
  };

  const columns: Array<ReservationsColumn> = [
    {
      key: 'reservationOrderNumber',
      name: (
        <div className="reservation-table-header">
          <FormattedMessage
            id="reservationTable.table.orderNumber"
            defaultMessage="N. ORDINE"
          />
        </div>
      ),
      formatter: ({ data }: SingleReservationParam) => (
        <div className="reservations-table-orderNumber">
          <span className="order-number">{data.documentNumber}</span>
        </div>
      ),
      width: '12%',
    },
    {
      key: 'reservationOrderLine',
      name: (
        <div className="reservation-table-header">
          <FormattedMessage
            id="reservationTable.table.orderLine"
            defaultMessage="N. RIGA"
          />
        </div>
      ),
      formatter: ({ data }: SingleReservationParam) => (
        <div className="reservations-table-environmentalClass">
          {data.orderNavLineNumber ? data.orderNavLineNumber : '- - -'}
        </div>
      ),
      width: '10%',
    },
    {
      key: 'reservationDescription',
      name: (
        <div className="reservation-table-header">
          <FormattedMessage
            id="reservationTable.table.description"
            defaultMessage="Descrizione articolo"
          />
        </div>
      ),
      formatter: ({ data }: SingleReservationParam) => (
        <div className="reservations-table-description">
          {data.orderDescription}
          <br />
          {data.secondaryDescription}
        </div>
      ),
      width: '48<%',
    },
    {
      key: 'reservationEnvironmentalClass',
      name: (
        <div className="reservation-table-header">
          <FormattedMessage
            id="reservationTable.table.environmentalClass"
            defaultMessage="Classe ambientale"
          />
        </div>
      ),
      formatter: ({ data }: SingleReservationParam) => (
        <div className="reservations-table-environmentalClass">
          {mapEnvironmentalClass(data.environmentalClass)}
        </div>
      ),
      width: '10%',
    },
    {
      key: 'reservationCerCode',
      name: (
        <div className="reservation-table-header">
          <FormattedMessage
            id="reservationTable.table.cerCode"
            defaultMessage="Codice CER"
          />
        </div>
      ),
      formatter: ({ data }: SingleReservationParam) => (
        <div className="reservations-table-cerCode">
          {data.cerCode || '- - -'}
        </div>
      ),
      width: '10%',
    },
    {
      key: 'reservationWeight',
      name: (
        <div className="reservation-table-header">
          <FormattedMessage
            id="reservationTable.table.weight"
            defaultMessage="Quantità"
          />
        </div>
      ),
      formatter: ({ data }: SingleReservationParam) => (
        <div className="reservations-table-weight">
          {data.transportedQuantity
            ? intlFormatNumber.format(data.transportedQuantity) +
              ' ' +
              data.unitOfMeasure
            : '- - -'}
        </div>
      ),
      width: '10%',
    },
  ].filter(Boolean);

  return (
    <div className="reservationsLine-list">
      <SemanticTableGrid
        className="reservations-table"
        columns={columns}
        elements={reservationLines}
        emptyResults={'Nessuna riga di prenotazione presente'}
      />
    </div>
  );
};

export default ReservationTable;
