import SvgIcon from 'components/SvgIcon';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Dropdown, Image } from 'semantic-ui-react';
import { getStyledIcon, getSvgStyledIcon } from 'utils/function/styles';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { ReservationOptionalDocumentProps } from './types';
import download from 'assets/images/icons/file-download.svg';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import { CircularProgress } from '@material-ui/core';
import {
  getDocuments,
  getPreviewDocument,
} from 'services/document/getDocuments';
import { Nullable } from 'utils/types';
import _ from 'lodash';
import { Document, Page } from 'react-pdf';

const ReservationOptionalDocument: React.FC<
  ReservationOptionalDocumentProps
> = ({ document, setDeleteDocument, readOnly = false }) => {
  // Use for manage the accordion open state
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [documentFile, setDocumentFile] = useState<Nullable<File>>(null);
  const intl = useIntl();

  // Utils function for manage the open and close of an accordion
  const handleAccordionClick = () => {
    setIsAccordionOpen(!isAccordionOpen);

    // If the the accordion is opened and this has not already done load the file
    if (_.isNull(documentFile) && !isDocumentLoading) {
      getPreviewDocument(document.id, setIsDocumentLoading, setDocumentFile);
    }
  };

  const renderAccordinContent = () => {
    if (isDocumentLoading) {
      return (
        <>
          <CircularProgress color="inherit" size={20} />
          <span className="optional-document-loading">
            Documento in caricamento
          </span>
        </>
      );
    }

    return (
      <div className="doc-viewer-file">
        <Document file={documentFile}>
          <Page pageNumber={1} />
        </Document>
      </div>
    );
  };

  return (
    <div className="single-uploaded-document">
      <Accordion className="optional-document-accordion">
        <Accordion.Title onClick={handleAccordionClick}>
          <div className="single-uploaded-document-name">
            {document.name}
            <Image
              src={arrowDownIcon}
              className={`${
                isAccordionOpen ? 'accordion-open' : 'accordion-close'
              }`}
            />
          </div>
        </Accordion.Title>
        <Accordion.Content active={isAccordionOpen}>
          <div className="optional-document-accordion-content">
            {renderAccordinContent()}
          </div>
        </Accordion.Content>
      </Accordion>
      <Dropdown
        className="standard-dropdown-menu"
        direction="left"
        icon={
          <SvgIcon
            icon={ICONS.ELIPSIS}
            color={COLORS.MID_GREY}
            height={18}
            width={24}
          />
        }
        item
      >
        <Dropdown.Menu>
          <Dropdown.Item
            key="visualize"
            text={
              <FormattedMessage
                id="optionalDocument.download"
                defaultMessage="Scarica"
              />
            }
            icon={getStyledIcon(download)}
            onClick={() => getDocuments(document.id, intl)}
          />
          {!readOnly && (
            <Dropdown.Item
              key="delete"
              text={
                <FormattedMessage
                  id="reservationTable.delete"
                  defaultMessage="Elimina"
                />
              }
              icon={getSvgStyledIcon(ICONS.TRASH)}
              onClick={() => setDeleteDocument(document.id)}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ReservationOptionalDocument;
