import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

// Internals and constants
import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';

// Types
import { StandardResponseError } from 'services/client/types';
import { ChangePasswordFormValues } from 'components/Forms/ResetPasswordForm/types';
import { RpcRequest } from 'utils/types';
import { IntlShape } from 'react-intl';

export const userChangePassword = (
  changePasswordData: ChangePasswordFormValues,
  onClose: () => void,
  setIsLoading: (isLoading: boolean) => void,
  intl: IntlShape,
): void | boolean => {
  setIsLoading(true);
  // Add to request data also the method param
  const requestData: RpcRequest<ChangePasswordFormValues> = {
    method: 'change_password',
    params: changePasswordData,
  };

  standardClient
    .request({
      url: API.USER_MANAGER,
      method: 'POST',
      data: requestData,
    })
    .then(() => {
      toast.success(
        intl.formatMessage({
          id: 'service.userChangePassword.success',
          defaultMessage: 'Password aggiornata correttamente',
        }),
      );
      onClose();
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      toast.error(
        error.data.detail ||
          toast.success(
            intl.formatMessage({
              id: 'service.userChangePassword.error',
              defaultMessage: 'Impossibile aggiornare la password',
            }),
          ),
      );
      return false;
    })
    .finally(() => setIsLoading(false));
};
