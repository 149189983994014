import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import { Input } from 'formik-semantic-ui-react';

const Logistics: React.FC = () => {
  return (
    <>
      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub4"
          defaultMessage="Logistica"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
        <span>
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                Indicare qui di seguito i Vostri giorni e orari di magazzino per
                carico/scarico merce
              </span>
            }
            basic
            hoverable
            trigger={
              <Icon className="tooltip-password-icon" name="question circle" />
            }
          />
        </span>
      </span>

      <Grid.Row className="allRowInput" style={{ marginTop: '2rem' }}>
        <Input
          name="openingDaysWarehouse"
          label={
            <FormattedMessage
              id="openingDaysWarehouse"
              defaultMessage="Giorni di apertura magazzino"
            />
          }
          placeholder="Giorni di apertura magazzino"
        />
      </Grid.Row>

      <Grid.Row className="allRowInput" style={{ marginTop: '2rem' }}>
        <Input
          name="openingHoursWarehouse"
          label={
            <FormattedMessage
              id="openingHoursWarehouse"
              defaultMessage="Orari di apertura magazzino"
            />
          }
          placeholder="Orari di apertura magazzino"
        />
      </Grid.Row>
    </>
  );
};

export default Logistics;
