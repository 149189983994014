import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { FetchOriginsListResponse } from './types';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';

export const fetchOriginsListOptions = (
  contactCode: string,
  setCerListOptions: (originsListOptions: Array<DropdownItemProps>) => void,
  setAreOriginsLoading: (areOriginsLoading: boolean) => void,
  intl: IntlShape,
): void => {
  setAreOriginsLoading(true);
  standardClient({
    url: `${API.CONTACT_ORIGINS_LIST}/${contactCode}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<FetchOriginsListResponse>) => {
      const originListOptions = response.data.contactOriginsList.map(
        origin => ({
          text: origin.name,
          value: origin.originCode,
        }),
      );

      const orderedOriginListOptions = _.orderBy(originListOptions, ['text']);

      setCerListOptions(orderedOriginListOptions);
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.fetchOriginsList.error',
          defaultMessage: 'Impossibile caricare la lista delle provenienze',
        }),
      ),
    )
    .finally(() => setAreOriginsLoading(false));
};
