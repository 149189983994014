import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';

// Constants - Internals - Services - Utils
import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';

// Types
import { ContactType, OriginDetails } from 'pages/ContactOriginDetail/types';
import { FE_ROUTES } from 'utils/global/globalConstants';
import { NavigateFunction } from 'react-router-dom';
import moment from 'moment';
import QueryString from 'qs';
import { IntlShape } from 'react-intl';

export const postNewContact = (
  values: OriginDetails,
  setIsLoading: (isLoading: boolean) => void,
  navigate: NavigateFunction,
  formikHelpers: FormikHelpers<OriginDetails>,
  intl: IntlShape,
): void => {
  setIsLoading(true);
  formikHelpers.setSubmitting(true);

  standardClient({
    url: API.CONTACT_ORIGIN,
    method: 'POST',
    data: {
      method: 'upload',
      params: {
        values,
      },
    },
  })
    .then((response: AxiosResponse<{ requestId: string }>) => {
      if (values.type === ContactType.OTHER_ORIGIN) {
        const formData = new FormData();

        formData.append('document', values.documentFile);
        formData.append('contactNo', response.data.requestId);
        formData.append('requestId', response.data.requestId);
        formData.append('documentType', '03');
        formData.append('fileName', values.documentFile.name);
        formData.append('attachmentID', '0');
        formData.append('startDate', moment().format('YYYY-MM-DD'));
        formData.append('dueDate', moment().add(1, 'y').format('YYYY-MM-DD'));

        standardClient
          .request({
            url: `${API.CONTACT_DOCUMENT}`,
            method: 'POST',
            data: formData,
            paramsSerializer: params => QueryString.stringify(params),
          })
          .then(() => {
            navigate(FE_ROUTES.ORIGINS_CONTACT);
          })
          .catch(() =>
            toast.error(
              intl.formatMessage({
                id: 'service.postContactOrigin.error',
                defaultMessage:
                  'Impossibile creare una nuova proveninenza. Controllare i campi obbligatori inseriti',
              }),
            ),
          );
      } else {
        navigate(FE_ROUTES.ORIGINS_CONTACT);
      }
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.postContactOrigin.generalError',
          defaultMessage:
            'Impossibile creare una nuova proveninenza. Controllare i campi obbligatori inseriti',
        }),
      ),
    )
    .finally(() => {
      setIsLoading(false);
      formikHelpers.setSubmitting(false);
    });
};
