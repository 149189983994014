import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const weight = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 25}
    height={height || 24}
    viewBox="0 0 25 24"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.9744 21.137L21.8793 8.62074C21.6823 7.4406 20.6602 6.57811 19.4639 6.57811H16.316C16.6851 5.93501 16.8962 5.19002 16.8962 4.39667C16.8962 1.97245 14.9243 0 12.4995 0C10.0753 0 8.10284 1.97245 8.10284 4.39667C8.10284 5.19002 8.31391 5.93501 8.68303 6.57811H5.53512C4.33835 6.57811 3.31677 7.44164 3.11974 8.62178L1.02512 21.1396C0.906063 21.8492 1.10622 22.5797 1.57152 23.1287C2.03733 23.6782 2.72046 24 3.44102 24H21.5601C22.2806 24 22.9638 23.6782 23.4296 23.1287C23.8944 22.5786 24.094 21.8472 23.9744 21.137ZM12.5 2.56148C13.5117 2.56148 14.3352 3.38498 14.3352 4.39719C14.3352 5.40941 13.5117 6.23239 12.5 6.23239C11.4878 6.23239 10.6643 5.40889 10.6643 4.39667C10.6643 3.38446 11.4878 2.56148 12.5 2.56148Z" />
  </svg>
);

export default weight;
