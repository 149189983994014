import { DropdownItemProps } from 'semantic-ui-react';

export const nationalityOptions: Array<DropdownItemProps> = [
  { text: 'Altro', value: 'other' },
  { text: 'Italiana', value: 'IT' },
];

export const languageOptions: Array<DropdownItemProps> = [
  { text: 'English', value: 'en' },
  { text: 'Italiano', value: 'it' },
];
