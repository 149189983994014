import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const padlock = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 11}
    height={height || 14}
    viewBox="0 0 11 14"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.33333 4.66667H8.66667V3.33333C8.66667 1.49333 7.17333 0 5.33333 0C3.49333 0 2 1.49333 2 3.33333V4.66667H1.33333C0.6 4.66667 0 5.26667 0 6V12.6667C0 13.4 0.6 14 1.33333 14H9.33333C10.0667 14 10.6667 13.4 10.6667 12.6667V6C10.6667 5.26667 10.0667 4.66667 9.33333 4.66667ZM5.33333 10.6667C4.6 10.6667 4 10.0667 4 9.33333C4 8.6 4.6 8 5.33333 8C6.06667 8 6.66667 8.6 6.66667 9.33333C6.66667 10.0667 6.06667 10.6667 5.33333 10.6667ZM7.4 4.66667H3.26667V3.33333C3.26667 2.19333 4.19333 1.26667 5.33333 1.26667C6.47333 1.26667 7.4 2.19333 7.4 3.33333V4.66667Z" />
  </svg>
);

export default padlock;
