import React, { useContext } from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

// Icons
import gbFlag from 'assets/images/flags/gb.svg';
import itFlag from 'assets/images/flags/it.svg';

// Utils
import { getStyledIcon } from 'utils/function/styles';
import { LangProviderContext } from 'components/LangProvider';

const Footer: React.FC = () => {
  const { language, setLanguage } = useContext(LangProviderContext);

  return (
    <div className="footer">
      <Dropdown
        className="footer-select-language standard-dropdown-menu"
        icon={
          <Image
            src={language === 'it-IT' || language === 'it' ? itFlag : gbFlag}
          />
        }
        item
        //simple
        upward
      >
        <Dropdown.Menu>
          <Dropdown.Item
            key="en"
            text="English"
            icon={getStyledIcon(gbFlag)}
            onClick={() => setLanguage('en')}
          />
          <Dropdown.Item
            key="it"
            text="Italiano"
            icon={getStyledIcon(itFlag)}
            onClick={() => setLanguage('it-IT')}
          />
        </Dropdown.Menu>
      </Dropdown>
      <div className="footer-text">
        <FormattedMessage
          id="copyright"
          defaultMessage="© Copyright 2023 Metallurgica San Marco"
        />
      </div>
    </div>
  );
};

export default Footer;
