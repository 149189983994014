import { ContactOriginsListState } from '../types';
import {
  ContactOriginsListAction,
  ContactOriginsListConstants,
} from './actions';

export const initialContactOriginsState: ContactOriginsListState = {
  completeOriginsCollection: [],
  filteredOriginsCollection: [],
  isLoading: false,
};

export const contactOriginsReducer = (
  state: ContactOriginsListState,
  action: ContactOriginsListAction,
): ContactOriginsListState => {
  const { type } = action;

  switch (type) {
    case ContactOriginsListConstants.RESET_ALL_FILTERS:
      return {
        ...state,
        filteredOriginsCollection: state.completeOriginsCollection,
      };
    case ContactOriginsListConstants.SET_COMPLETE_ORIGINS_COLLECTION:
      return {
        ...state,
        completeOriginsCollection: action.payload.completeOriginsCollection,
        filteredOriginsCollection: action.payload.completeOriginsCollection,
      };
    case ContactOriginsListConstants.SET_FILTERED_ORIGINS_COLLECTION:
      return {
        ...state,
        filteredOriginsCollection: action.payload.filteredOriginsCollection,
      };
    case ContactOriginsListConstants.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    default:
      return state;
  }
};
