import React from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import standardClient from 'services/client/standardRequestClient';
import {
  UserOrdersAction,
  UserOrdersConstants,
} from 'pages/UserOrders/reducer/action';
import { API } from 'utils/global/backendRoutes';

import { FetchOpenOrdersResponse } from './types';
import { OrderLine } from 'pages/UserOrders/types';
import {
  ArticlePhaseAction,
  ArticlePhaseConstants,
} from 'components/Reservation/ArticlePhase/reducer/action';
import moment from 'moment';

export const fetchOpenOrders = (
  dispatch: React.Dispatch<UserOrdersAction>,
): void => {
  dispatch({
    type: UserOrdersConstants.SET_ORDERS_LOADING,
    payload: { areOrdersLoading: true },
  });

  standardClient({
    url: API.SUPPLIER_ORDERS,
    method: 'GET',
  })
    .then((response: AxiosResponse<FetchOpenOrdersResponse>) => {
      dispatch({
        type: UserOrdersConstants.SET_COMPLETE_ORDERS_COLLECTION,
        payload: { ordersCollection: response.data.orders },
      });
    })
    .catch(() => {
      toast.error('Impossibile recuperare gli ordini aperti');
    })
    .finally(() => {
      dispatch({
        type: UserOrdersConstants.SET_ORDERS_LOADING,
        payload: { areOrdersLoading: false },
      });
    });
};

export const fetchOpenOrdersLine = (
  dispatch: React.Dispatch<ArticlePhaseAction>,
): void => {
  dispatch({
    type: ArticlePhaseConstants.SET_ARE_ORDERS_LINE_LOADING,
    payload: { areOrdersLineLoading: true },
  });

  standardClient({
    url: API.SUPPLIER_ORDERS,
    method: 'GET',
  })
    .then((response: AxiosResponse<FetchOpenOrdersResponse>) => {
      let orderLines: Array<OrderLine> = [];
      response.data.orders.map(order => {
        orderLines = [...orderLines, ...order.lines];
      });

      const orderedOrdersLine = orderLines.sort((firstOrder, secondOrder) =>
        moment(firstOrder.plannedReceiptDate, 'YYYY-MM-DD').diff(
          moment(secondOrder.plannedReceiptDate, 'YYYY-MM-DD'),
        ),
      );

      dispatch({
        type: ArticlePhaseConstants.SET_COMPLETE_ORDERS_LINE,
        payload: { completeOrdersLine: orderedOrdersLine },
      });
    })
    .catch(() => {
      toast.error('Impossibile recuperare gli ordini aperti');
    })
    .finally(() => {
      dispatch({
        type: ArticlePhaseConstants.SET_ARE_ORDERS_LINE_LOADING,
        payload: { areOrdersLineLoading: false },
      });
    });
};
