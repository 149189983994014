import { DocumentData } from 'pages/ContactDocuments/types';
import { Nullable } from 'utils/types';

export type ContactOriginState = {
  contactOrigin: Nullable<OriginDetails>;
  isLoading: boolean;
  documents: Array<DocumentData>;
  isLoadingDocuments: boolean;
};

export type ContactOrigin = {
  key: Nullable<string>;
  id: Nullable<string>;
  businessName: Nullable<string>;
  vatNumber: Nullable<string>;
  fiscalCode: Nullable<string>;
  emailAddress: Nullable<string>;
  phoneNumber: Nullable<string>;
  country: Nullable<string>;
  approved?: boolean;
  type?: Nullable<string>;
};

export enum ContactType {
  OTHER_ORIGIN = 'origin',
  BRANCH = 'branch',
}

export type FetchOriginDetails = {
  origin: OriginDetails;
};

export type OriginDetails = {
  contactCode: string;
  address: Nullable<string>;
  city: Nullable<string>;
  countryRegionCode: string;
  documentFile: Nullable<File>;
  fiscalCode: Nullable<string>;
  isSedeSecondaria: string;
  name: string;
  type: Nullable<string>;
  vatNumber: Nullable<string>;
  registeredOffice: boolean;
};
