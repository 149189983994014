import { Order, OrdersSupportedSort } from '../types';

export enum UserOrdersConstants {
  CHANGE_ACTIVE_SORT = 'UserOrders/CHANGE_ACTIVE_SORT',
  RESET_ALL_FILTERS = 'UserOrders/RESET_ALL_FILTERS',
  SET_COMPLETE_ORDERS_COLLECTION = 'UserOrders/SET_COMPLETE_ORDERS_COLLECTION',
  SET_FILTERED_ORDERS_COLLECTION = 'UserOrders/SET_FILTERED_ORDERS_COLLECTION',
  SET_ORDERS_LOADING = 'UserOrders/SET_ORDERS_LOADING',
}

export type UserOrdersAction =
  | {
      type: UserOrdersConstants.CHANGE_ACTIVE_SORT;
      payload: { activeSort: OrdersSupportedSort };
    }
  | {
      type: UserOrdersConstants.RESET_ALL_FILTERS;
    }
  | {
      type: UserOrdersConstants.SET_COMPLETE_ORDERS_COLLECTION;
      payload: { ordersCollection: Array<Order> };
    }
  | {
      type: UserOrdersConstants.SET_FILTERED_ORDERS_COLLECTION;
      payload: { filteredOrdersCollection: Array<Order> };
    }
  | {
      type: UserOrdersConstants.SET_ORDERS_LOADING;
      payload: { areOrdersLoading: boolean };
    };
