import React, { createContext, useReducer, useState, useEffect } from 'react';
import { Button, Menu, Segment } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Constants - Internals - Utils
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import {
  initialReservationListState,
  reservationListReducer,
} from './reducer/reducer';
import { isThatRole } from 'utils/function/acl';
import { ReservationListAction } from './reducer/action';
import { ReservationListTab } from 'utils/global/reservationConstants';

// Components
import ApprovedList from 'components/ReservationList/ApprovedList';
import InWorkingList from 'components/ReservationList/InWorkingList';
import ToApproveList from 'components/ReservationList/ToApproveList';
import UnapprovedList from 'components/ReservationList/UnapprovedList';

// Types
import { ReservationListState } from './types';
import ClosedList from 'components/ReservationList/ClosedList';

export const ReservationListContext = createContext<{
  state: ReservationListState;
  dispatch: React.Dispatch<ReservationListAction>;
}>({
  state: initialReservationListState,
  dispatch: () => null,
});

const ReservationList: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { tab } = useParams();

  const [state, dispatch] = useReducer(
    reservationListReducer,
    initialReservationListState,
  );

  const [activeItem, setActiveItem] = useState<string>(tab);

  useEffect(() => {
    if (
      !Object.values(ReservationListTab).includes(tab as ReservationListTab)
    ) {
      navigate(`${FE_ROUTES.RESERVATION_LIST}/${ReservationListTab.APPROVED}`);
      setActiveItem(ReservationListTab.APPROVED);
    }
  }, [tab]);

  const renderTab = () => {
    if (activeItem === ReservationListTab.APPROVED) {
      return <ApprovedList />;
    }

    if (activeItem === ReservationListTab.TO_APPROVE) {
      return <ToApproveList />;
    }

    if (activeItem === ReservationListTab.UNAPPROVED) {
      return <UnapprovedList />;
    }

    if (activeItem === ReservationListTab.IN_WORKING) {
      return <InWorkingList />;
    }

    if (activeItem === ReservationListTab.CLOSED) {
      return <ClosedList />;
    }
  };

  return (
    <ReservationListContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'reservation.list.page.title',
            defaultMessage: 'MSM - Prenotazioni',
          })}
        </title>
      </Helmet>
      <div className="reservations-page-header">
        <span className="title">
          <FormattedMessage
            id="reservation.list.title.header"
            defaultMessage="Prenotazioni"
          />
        </span>
        {isThatRole(ROLES.SUPPLIER) && (
          <Button
            className="insertButton"
            content={intl.formatMessage({
              id: 'reservationsList.button.book',
              defaultMessage: 'Prenota',
            })}
            icon="plus"
            onClick={() => navigate(FE_ROUTES.RESERVATION_DETAILS + '/new')}
          />
        )}
      </div>
      <div className="reservations-container">
        <Menu attached="top" tabular>
          <Menu.Item
            name="working"
            active={activeItem === 'working'}
            onClick={(_event, { name }) => setActiveItem(name)}
            as={Link}
            to={`${FE_ROUTES.RESERVATION_LIST}/${ReservationListTab.IN_WORKING}`}
            content={intl.formatMessage({
              id: 'reservationsList.menu.working',
              defaultMessage: 'Bozze',
            })}
          />
          <Menu.Item
            name="toApprove"
            active={activeItem === 'toApprove'}
            onClick={(_event, { name }) => setActiveItem(name)}
            as={Link}
            to={`${FE_ROUTES.RESERVATION_LIST}/${ReservationListTab.TO_APPROVE}`}
            content={intl.formatMessage({
              id: 'reservationsList.menu.toApprove',
              defaultMessage: 'In Approvazione',
            })}
          />
          <Menu.Item
            name="approved"
            active={activeItem === 'approved'}
            onClick={(_event, { name }) => setActiveItem(name)}
            as={Link}
            to={`${FE_ROUTES.RESERVATION_LIST}/${ReservationListTab.APPROVED}`}
            content={intl.formatMessage({
              id: 'reservationsList.menu.approved',
              defaultMessage: 'Approvate',
            })}
          />
          <Menu.Item
            name="closed"
            active={activeItem === 'closed'}
            onClick={(_event, { name }) => setActiveItem(name)}
            as={Link}
            to={`${FE_ROUTES.RESERVATION_LIST}/${ReservationListTab.CLOSED}`}
            content={intl.formatMessage({
              id: 'reservationsList.menu.closed',
              defaultMessage: 'Chiuse',
            })}
          />
          <Menu.Item
            name="unapproved"
            active={activeItem === 'unapproved'}
            onClick={(_event, { name }) => setActiveItem(name)}
            as={Link}
            to={`${FE_ROUTES.RESERVATION_LIST}/${ReservationListTab.UNAPPROVED}`}
            content={intl.formatMessage({
              id: 'reservationsList.menu.unapproved',
              defaultMessage: 'Non approvate',
            })}
          />
        </Menu>
        <Segment className="reservations-container-segment" attached="bottom">
          {renderTab()}
        </Segment>
      </div>
    </ReservationListContext.Provider>
  );
};

export default ReservationList;
