import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import fileIcon from 'assets/images/icons/file-upload.svg';
import { FileInputFieldProps } from './types';
import { FormattedMessage } from 'react-intl';

const FileInputField: React.FC<FileInputFieldProps> = ({
  field,
  isButton,
  label,
  setFieldValue,
  readOnly = false,
}) => {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const selectFile = () => {
    if (_.isEmpty(uploadedFileName)) {
      hiddenFileInput.current?.click();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>): void => {
    event.currentTarget.value = '';
  };

  /** @TODO change it with elipsis in css style */
  const handleChange = (event: { target: { files: FileList | null } }) => {
    if (_.isObject(event.target.files)) {
      let fileNameString = event.target.files[0].name;
      if (fileNameString.length > 35) {
        fileNameString = fileNameString.slice(0, 30) + '...';
      }

      setFieldValue(field, event.target.files[0]);
      setUploadedFileName(fileNameString);
    }
  };

  const onReset = () => {
    setFieldValue(field, null);
    setUploadedFileName(null);
  };

  if (isButton) {
    return (
      <div className="input-field-button" onClick={selectFile}>
        {label && <span className="input-field-button-text">{label}</span>}
        <input
          type="file"
          name="attachedFile"
          id="attachedFile"
          style={{ display: 'none' }}
          ref={hiddenFileInput}
          onChange={handleChange}
          onClick={handleClick}
        />
      </div>
    );
  }

  return (
    <span className="file-input-field">
      {label && <label className="input-field-label">{label}</label>}
      <Form.Field
        className={`fileUploadField ${uploadedFileName ? '' : 'fileNotSet'}`}
        onClick={selectFile}
        disabled={readOnly}
      >
        <Grid verticalAlign="middle">
          <Grid.Column width={12}>
            <span className="labelGrid">
              {!uploadedFileName && (
                <span className="placeholder">
                  <FormattedMessage
                    id="fileInputFiled.placeholder.upload"
                    defaultMessage="Carica allegato"
                  />
                </span>
              )}
              {uploadedFileName && (
                <span className="fileUploaded">{uploadedFileName}</span>
              )}
            </span>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {!uploadedFileName && (
              <Button
                type="button"
                icon={<img src={fileIcon} />}
                disabled={readOnly}
              />
            )}
            {uploadedFileName && (
              <Button
                type="reset"
                className="crossButton"
                onClick={onReset}
                disabled={readOnly}
              >
                <Icon name="close" size="large" className="crossIcon" />
              </Button>
            )}
          </Grid.Column>
        </Grid>
        <input
          type="file"
          name="attachedFile"
          id="attachedFile"
          style={{ display: 'none' }}
          ref={hiddenFileInput}
          onChange={handleChange}
          onClick={handleClick}
          disabled={readOnly}
        />
      </Form.Field>
      {label && (
        <label className="input-field-label">
          <FormattedMessage
            id="fileInputFiled.label.supportedFiles"
            defaultMessage="Carica allegato"
          />
        </label>
      )}
    </span>
  );
};

export default FileInputField;
