import React, { useContext, useEffect, useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

// Components
import PageHeader from 'components/PageHeader';
import { AppContext } from 'pages/App';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Constants
import { FE_ROUTES } from 'utils/global/globalConstants';
import { CustomerDocumentListTab } from 'utils/global/documentCustomerCostants';
import CustomerOrders from 'components/CustomerDocuments/CustomerOrders';
import CustomerQuotes from 'components/CustomerDocuments/CustomerQuotes';
import CustomerInvoices from 'components/CustomerDocuments/CustomerInvoices';
import CustomerCreditsNotes from 'components/CustomerDocuments/CustomerCreditsNotes';
import CustomerContracts from 'components/CustomerDocuments/CustomerContracts';
import CustomerShipments from 'components/CustomerDocuments/CustomerShipment';
import CustomerShipmentTransformation from 'components/CustomerDocuments/CustomerShipmentTransformation';
import NoContactSelected from 'components/NoContactSelected';
import _ from 'lodash';

const CustomerAgentDocuments: React.FC = () => {
  const appState = useContext(AppContext).state;
  const navigate = useNavigate();
  const intl = useIntl();

  const { tab } = useParams();

  const [activeItem, setActiveItem] = useState<string>(tab);

  useEffect(() => {
    if (
      !Object.values(CustomerDocumentListTab).includes(
        tab as CustomerDocumentListTab,
      )
    ) {
      navigate(
        `${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.OFFER}`,
      );
      setActiveItem(CustomerDocumentListTab.OFFER);
    }
  }, [tab]);

  const renderTab = () => {
    if (activeItem === CustomerDocumentListTab.ORDERS) {
      return <CustomerOrders />;
    }
    if (activeItem === CustomerDocumentListTab.CONTRACT) {
      return <CustomerContracts />;
    }

    if (activeItem === CustomerDocumentListTab.OFFER) {
      return <CustomerQuotes />;
    }

    if (activeItem === CustomerDocumentListTab.BILL) {
      return <CustomerInvoices />;
    }

    if (activeItem === CustomerDocumentListTab.CREDITS_NOTES) {
      return <CustomerCreditsNotes />;
    }
    if (activeItem === CustomerDocumentListTab.DDT) {
      return <CustomerShipments />;
    }
    if (activeItem === CustomerDocumentListTab.DDT_CT) {
      return <CustomerShipmentTransformation />;
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'agentCustomerDocuments.helmet.title',
            defaultMessage: 'MSM - Documenti',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={
          intl.formatMessage({
            id: 'agentCustomerDocuments.header.title',
            defaultMessage: 'Documenti',
          }) + _.isEmpty(appState.selectedContact)
            ? ''
            : ' - ' + appState.selectedContact.name
        }
      />
      {_.isEmpty(appState.selectedContact) ? (
        <NoContactSelected />
      ) : (
        <div className="reservations-container">
          <Menu attached="top" tabular>
            <Menu.Item
              name="orders"
              active={activeItem === 'orders'}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.ORDERS}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.orders',
                defaultMessage: 'Ordini',
              })}
            />
            <Menu.Item
              name="offer"
              active={activeItem === 'offer'}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.OFFER}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.offer',
                defaultMessage: 'Offerte',
              })}
            />
            <Menu.Item
              name="contract"
              active={activeItem === 'contract'}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.CONTRACT}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.contract',
                defaultMessage: 'Contratti',
              })}
            />
            <Menu.Item
              name="bill"
              active={activeItem === 'bill'}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.BILL}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.bill',
                defaultMessage: 'Fatture',
              })}
            />
            <Menu.Item
              name={CustomerDocumentListTab.CREDITS_NOTES}
              active={activeItem === CustomerDocumentListTab.CREDITS_NOTES}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.CREDITS_NOTES}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.creditsNotes',
                defaultMessage: 'Note di credito',
              })}
            />
            <Menu.Item
              name={CustomerDocumentListTab.DDT}
              active={activeItem === CustomerDocumentListTab.DDT}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.DDT}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.ddt',
                defaultMessage: 'DDT',
              })}
            />
            <Menu.Item
              name={CustomerDocumentListTab.DDT_CT}
              active={activeItem === CustomerDocumentListTab.DDT_CT}
              onClick={(_event, { name }) => setActiveItem(name)}
              as={Link}
              to={`${FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS}/${CustomerDocumentListTab.DDT_CT}`}
              content={intl.formatMessage({
                id: 'agentCustomerDocuments.menu.header.ddtCt',
                defaultMessage: 'Ingressi CT',
              })}
            />
          </Menu>
          <Segment className="reservations-container-segment" attached="bottom">
            {renderTab()}
          </Segment>
        </div>
      )}
    </>
  );
};

export default CustomerAgentDocuments;
