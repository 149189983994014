import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';
import { AxiosResponse } from 'axios';
import {
  GetCreditsLimit,
  GetCustomerGeneralShipmentDetailsResponse,
  GetCustomerInvoiceDetailsResponse,
  GetCustomerOrderDetailsResponse,
  GetCustomerQuoteDetailsResponse,
  GetCustomerSalesMemoResponse,
} from './type';
import { toast } from 'react-toastify';
import { CustomerOrderDetails } from 'components/CustomerDocuments/CustomerOrderModal/types';
import { CustomerInvoiceDetails } from 'components/CustomerDocuments/CustomerInvoiceModal/types';
import { CustomerQuoteDetails } from 'components/CustomerDocuments/CustomerQuoteModal/types';
import { GeneralShipmentDetails } from 'components/CustomerDocuments/CustomerShipmentModal/types';
import { CustomerCreditNoteDetail } from 'components/CustomerDocuments/CustomerCreditsNoteModal/types';
import { CreditsLimit } from 'pages/CustomerCreditsLimit/types';
import { IntlShape } from 'react-intl';

export const getCustomerOrderDetails = (
  customerOrderCode: string,
  setCustomerOrderDetails: (customerOrderDetails: CustomerOrderDetails) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-order',
        params: {
          type: 'detail',
          dataCode: customerOrderCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerOrderDetailsResponse>) => {
      setCustomerOrderDetails(response.data);
    })
    .catch(() => toast.error("Impossibile scaricare l'ordine richiesto"))
    .finally(() => setIsLoading(false));
};

export const getCustomerQuoteDetails = (
  customerQuoteCode: string,
  setCustomerQuoteDetails: (customerQuoteDetails: CustomerQuoteDetails) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-quote',
        params: {
          type: 'detail',
          dataCode: customerQuoteCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerQuoteDetailsResponse>) => {
      setCustomerQuoteDetails(response.data);
    })
    .catch(() => toast.error("Impossibile scaricare l'ordine richiesto"))
    .finally(() => setIsLoading(false));
};

export const getCustomerInvoiceDetails = (
  customerInvoiceCode: string,
  setCustomerInvoiceDetails: (
    customerInvoiceDetails: CustomerInvoiceDetails,
  ) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-invoice',
        params: {
          type: 'detail',
          dataCode: customerInvoiceCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerInvoiceDetailsResponse>) => {
      setCustomerInvoiceDetails(response.data);
    })
    .catch(() => toast.error('Impossibile scaricare la fattura richiesta'))
    .finally(() => setIsLoading(false));
};

export const getCustomerCreditMemo = (
  customerInvoiceCode: string,
  setCustomerInvoiceDetails: (
    customerInvoiceDetails: CustomerCreditNoteDetail,
  ) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-credit-memo',
        params: {
          type: 'detail',
          dataCode: customerInvoiceCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCustomerSalesMemoResponse>) => {
      setCustomerInvoiceDetails(response.data);
    })
    .catch(() =>
      toast.error('Impossibile scaricare la nota di credito richiesta'),
    )
    .finally(() => setIsLoading(false));
};

export const getGeneralShipmentDetails = (
  customerGeneralShipmentCode: string,
  setCustomerGeneralShipmentDetails: (
    customerGeneralShipmentDetails: GeneralShipmentDetails,
  ) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'general-shipment',
        params: {
          type: 'detail',
          dataCode: customerGeneralShipmentCode,
        },
      },
    })
    .then(
      (response: AxiosResponse<GetCustomerGeneralShipmentDetailsResponse>) => {
        setCustomerGeneralShipmentDetails(response.data);
      },
    )
    .catch(() => toast.error('Impossibile scaricare le informazioni sul DDT'))
    .finally(() => setIsLoading(false));
};

export const getSalesShipmentDetails = (
  customerGeneralShipmentCode: string,
  setCustomerGeneralShipmentDetails: (
    customerGeneralShipmentDetails: GeneralShipmentDetails,
  ) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'sales-shipment',
        params: {
          type: 'detail',
          dataCode: customerGeneralShipmentCode,
        },
      },
    })
    .then(
      (response: AxiosResponse<GetCustomerGeneralShipmentDetailsResponse>) => {
        setCustomerGeneralShipmentDetails(response.data);
      },
    )
    .catch(() => toast.error('Impossibile scaricare le informazioni sul DDT'))
    .finally(() => setIsLoading(false));
};

export const getCustomerCreditsLimit = (
  setIsLoading: (isLoading: boolean) => void,
  setData: (data: CreditsLimit) => void,
  contactCode: string,
  intl: IntlShape,
): void => {
  setIsLoading(true);
  standardClient
    .request({
      url: API.CUSTOMER_DATA,
      method: 'POST',
      data: {
        method: 'get-customer-summary',
        params: {
          type: 'aging',
          customerCode: contactCode,
        },
      },
    })
    .then((response: AxiosResponse<GetCreditsLimit>) => {
      setData(response.data);
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.getCustomerCreditsLimit.error',
          defaultMessage: 'Impossibile scaricare la situazione contabile',
        }),
      ),
    )
    .finally(() => setIsLoading(false));
};
