import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { StandardResponseError } from 'services/client/types';
import { API } from 'utils/global/backendRoutes';

export const deleteUserRequest = (
  idUser: string,
  reloadPage: () => void,
): void => {
  standardClient({
    url: API.SUPPLIER + '/' + idUser,
    method: 'DELETE',
  })
    .then(() => {
      toast.success('Richiesta eliminata correttamente');
      reloadPage();
    })
    .catch(() => toast.error('Impossibile eliminare la richiesta'));
};

export const updateUserRequest = (
  changeState: 1 | 2, // 1: Approve, 2: Active
  idUser: string,
  reloadPage: () => void,
): void => {
  standardClient({
    url: API.SUPPLIER + '/' + idUser,
    method: 'PATCH',
    data: {
      state: changeState,
    },
  })
    .then(() => {
      toast.success('Profilo utente attivato correttamente');
      reloadPage();
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (error.status === 422 && error.data.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error('Impossibile attivare il profilo utente');
      }
    });
};
