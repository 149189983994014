import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Button, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import ContactOriginsTable from 'components/ContactOriginsList/ContactOriginsTable';
import { fetchContactOrigin } from 'services/contactOrigin/fetchContactOrigin';
import { ContactOriginsListContext } from 'pages/ContactOriginsList';

import OriginsFiltersBar from '../OriginsFiltersBar';
import { isThatRole } from 'utils/function/acl';

const ContactOriginsForContact: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { dispatch } = useContext(ContactOriginsListContext);

  useEffect(() => {
    fetchContactOrigin(dispatch, intl);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'contact.origins.page.header',
            defaultMessage: 'MSM - Provenienze',
          })}
        </title>
      </Helmet>
      <div className="contact-origins-list-page-header">
        <span className="title">
          {intl.formatMessage({
            id: 'contact.origins.page.title',
            defaultMessage: 'Provenienze',
          })}
        </span>
        <Button
          className="insertButton"
          content={intl.formatMessage({
            id: 'contactOrigins.button.book',
            defaultMessage: 'Aggiungi',
          })}
          icon="plus"
          onClick={() => navigate(FE_ROUTES.ORIGINS_CONTACT + '/new')}
        />
      </div>
      <div className="contact-origins-list-container">
        <Segment className="contact-origins-list-segment" attached="bottom">
          {isThatRole(ROLES.CUSTOMER) && (
            <div className="contact-origins-subtitle">
              <FormattedMessage
                id="contactOriginsList.customer.subtitle"
                defaultMessage="Elenco provenienze abilitate al conferimento in conto trasformazione."
              />
            </div>
          )}
          <OriginsFiltersBar />
          <ContactOriginsTable />
        </Segment>
      </div>
    </>
  );
};

export default ContactOriginsForContact;
