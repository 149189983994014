import React from 'react';
import { Pagination } from 'semantic-ui-react';
import { useIntl } from 'react-intl';

import { PaginatorProps } from './types';

const Paginator: React.FC<PaginatorProps> = ({
  activePage,
  disabled = false,
  onPageChange,
  shortText = false,
  totalPages,
}) => {
  // Intl message definition
  const intl = useIntl();
  const firstPageMessage = intl.formatMessage({
    defaultMessage: '<< Prima',
    id: 'paginator.firstPage',
  });
  const lastPageMessage = intl.formatMessage({
    defaultMessage: 'Ultima >>',
    id: 'paginator.lastPage',
  });
  const previousPageMessage = intl.formatMessage({
    defaultMessage: '< Precedente',
    id: 'paginator.previousPage',
  });
  const nextPageMessage = intl.formatMessage({
    defaultMessage: 'Successiva >',
    id: 'paginator.nextPage',
  });

  const isFirstItemDisabled = (): boolean => {
    if (activePage && activePage >= 2) {
      return false;
    }
    return true;
  };

  const isLastItemDisabled = (): boolean => {
    if (activePage < totalPages) {
      return false;
    }
    return true;
  };

  return (
    <Pagination
      className="paginator"
      activePage={activePage}
      boundaryRange={0}
      disabled={disabled}
      ellipsisItem={null}
      firstItem={
        totalPages > 1
          ? {
              content: shortText ? '<<' : firstPageMessage,
              disabled: isFirstItemDisabled(),
            }
          : null
      }
      lastItem={
        totalPages > 1
          ? {
              content: shortText ? '>>' : lastPageMessage,
              disabled: isLastItemDisabled(),
            }
          : null
      }
      nextItem={
        totalPages > 1
          ? {
              content: shortText ? '>' : nextPageMessage,
              disabled: isLastItemDisabled(),
            }
          : null
      }
      prevItem={
        totalPages > 1
          ? {
              content: shortText ? '<' : previousPageMessage,
              disabled: isFirstItemDisabled(),
            }
          : null
      }
      totalPages={totalPages}
      onPageChange={onPageChange}
    />
  );
};

export default Paginator;
