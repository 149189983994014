import React, { useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, PaginationProps } from 'semantic-ui-react';
import { fetchOpenOrdersLine } from 'services/orders/fetchOpenOrders';
import SelectOrderTable from './SelectOrderTable';
import {
  articlePhaseReducer,
  initialArticlePhaseState,
} from './reducer/reducer';
import Paginator from 'components/Paginator';
import _ from 'lodash';
import { ArticlePhaseConstants } from './reducer/action';
import { Formik } from 'formik';
import { Form, Input, ResetButton } from 'formik-semantic-ui-react';
import { ReservationContext } from 'pages/Reservation';

const ArticlePhase: React.FC = () => {
  const intl = useIntl();
  const reservationState = useContext(ReservationContext);
  const [state, dispatch] = useReducer(
    articlePhaseReducer,
    initialArticlePhaseState,
  );

  // On component mount load the open order line of the user
  useEffect(() => {
    fetchOpenOrdersLine(dispatch);
  }, []);

  /** Update the current activa page, callback for paginator component */
  const changePage = (_event, { activePage }: PaginationProps): void => {
    dispatch({
      type: ArticlePhaseConstants.CHANGE_CURRENT_PAGE,
      payload: { currentPage: activePage },
    });
  };

  return (
    <div className="article-phase">
      <div className="reservation-header">
        <span className="reservation-header-title">
          <FormattedMessage
            id="articlePhase.title"
            defaultMessage="Articolo"
            description="Title message in the phase 1 of the reservation flow"
          />
        </span>
        <span className="reservation-header-subtitle">
          <FormattedMessage
            id="articlePhase.subtitle"
            defaultMessage="Seleziona una o più righe d'acquisto dello stesso materiale"
            description="Subtitle message in the phase 1 of the reservation flow, under the title"
          />
        </span>
      </div>

      <div className="article-phase-content">
        <Formik
          initialValues={{ orderLinesNumber: '' }}
          onSubmit={values => {
            if (_.isEmpty(values.orderLinesNumber)) {
              dispatch({ type: ArticlePhaseConstants.REMOVE_FILTERS });
            } else {
              dispatch({
                type: ArticlePhaseConstants.SET_ORDER_NUMBER_FILTER,
                payload: { orderNumberSearched: values.orderLinesNumber },
              });
            }
          }}
        >
          <Form className="article-phase-filter-bar">
            <Input
              className="article-phase-filter-bar-input"
              name="orderLinesNumber"
              placeholder={intl.formatMessage({
                id: 'reservation.articlePhase.filterBar.orderNumber',
                defaultMessage: 'N. ordine',
              })}
            />
            <Button
              className="article-phase-filter-bar-search"
              content={intl.formatMessage({
                id: 'reservation.articlePhase.button.search',
                defaultMessage: 'Cerca',
              })}
              disabled={_.isEmpty(state.completeOrdersLine)}
              type="submit"
            />
            {state.areFiltersActive && (
              <ResetButton
                className="underlineButton article-phase-filter-bar-reset"
                content={
                  <FormattedMessage
                    id="articlePhase.filterBar.resetButton"
                    defaultMessage="Rimuovi i filtri attivi"
                  />
                }
                onClick={() =>
                  dispatch({ type: ArticlePhaseConstants.REMOVE_FILTERS })
                }
                type="reset"
              />
            )}
          </Form>
        </Formik>
        <SelectOrderTable
          areOrdersLoading={state.areOrdersLineLoading}
          ordersCollection={state.showedOrdersLine}
        />
        {!_.isEmpty(state.completeOrdersLine) && (
          <div className="article-phase-paginator">
            <div className="article-phase-paginator-orderLines">
              {reservationState.state.selectedOrdersLine.length === 1 && (
                <FormattedMessage
                  id="articlephase.footer.selectedLine"
                  defaultMessage="1 riga d'ordine selezionata"
                />
              )}
              {reservationState.state.selectedOrdersLine.length > 1 && (
                <FormattedMessage
                  id="articlephase.footer.selectedLines"
                  defaultMessage="{orderLines} righe d'ordine selezionate"
                  values={{
                    orderLines:
                      reservationState.state.selectedOrdersLine.length,
                  }}
                />
              )}
            </div>
            <Paginator
              totalPages={state.totalPageNumber}
              activePage={state.currentPage}
              onPageChange={changePage}
              shortText
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticlePhase;
