import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { TechnicalAdministrativeDataProps } from './types';
import { Checkbox } from '@material-ui/core';

const TechnicalAdministrativeData: React.FC<
  TechnicalAdministrativeDataProps
> = ({ setFieldValue }) => {
  return (
    <>
      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub21"
          defaultMessage="Dati tecnici / qualitativi"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
      </span>

      <Grid.Row columns={2}>
        <Grid.Column
          width={12}
          style={{ paddingTop: '0.7rem', paddingLeft: '0' }}
        >
          La Vostra azienda opera direttamente nel settore automotive?
        </Grid.Column>
        <Grid.Column width={4}>
          <Checkbox
            style={{ color: '#FDC300' }}
            key="companyOperatesInAutomotiveSector"
            className="formCheckbox"
            name="companyOperatesInAutomotiveSector"
            onChange={(_event, checked) => {
              setFieldValue('companyOperatesInAutomotiveSector', checked);
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column
          width={12}
          style={{ paddingTop: '0.7rem', paddingLeft: '0' }}
        >
          La Vostra azienda fornisce abitualmente aziende operanti nel settore
          automotive?
        </Grid.Column>
        <Grid.Column width={4}>
          <Checkbox
            style={{ color: '#FDC300' }}
            key="companySuppliesCompaniesInAutomotiveSector"
            className="formCheckbox"
            name="companySuppliesCompaniesInAutomotiveSector"
            onChange={(_event, checked) => {
              setFieldValue(
                'companySuppliesCompaniesInAutomotiveSector',
                checked,
              );
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <br />
      <br />

      <FormattedMessage
        id="dataCollection.sub22"
        defaultMessage="In caso di almeno una risposta positiva indicare/allegare eventuali requisiti specifici del settore automotive."
        description="Subtitle message in the phase 2 of the reservation flow, under the title"
      />

      <br />

      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub23"
          defaultMessage="NOTA PER I CLIENTI AUTOMOTIVE"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
      </span>
      <FormattedMessage
        id="dataCollection.sub24"
        defaultMessage="Essendo Metallurgica San Marco Spa certificata IATF 16949:2016, informiamo i clienti automotive che alcuni fornitori di materia prima potrebbero non essere certificati ISO 9001 di terza parte. Metallurgica San Marco Spa controlla al 100% la materia prima in ingresso (effettuando prove di resa fusoria e analisi chimica) e il 100% della conformità (analisi chimica) per tutte le leghe e per tutte le colate prodotte, garantendo così il rispetto dei requisiti e di conformità di tutti i prodotti forniti."
        description="Subtitle message in the phase 2 of the reservation flow, under the title"
      />
    </>
  );
};

export default TechnicalAdministrativeData;
