import PasswordUpdateForm from 'components/Forms/PasswordUpdateForm';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Image, Modal } from 'semantic-ui-react';
import { PasswordUpdateProps } from './types';

// Icons
import crossIcon from 'assets/images/icons/cross.svg';
import warningIcon from 'assets/images/icons/warning-circle.svg';

const PasswordUpdate: React.FC<PasswordUpdateProps> = ({ isOpen, onClose }) => {
  return (
    <Modal className="password-update-modal" open={isOpen} size="tiny">
      <div className="close-button" onClick={onClose}>
        <Image src={crossIcon} />
      </div>
      <Modal.Header>
        <Image src={warningIcon} />
        <FormattedMessage
          id="passwordUpdateTitle"
          defaultMessage="Aggiornamento password"
        />
      </Modal.Header>
      <PasswordUpdateForm onClose={onClose} />
    </Modal>
  );
};

export default PasswordUpdate;
