import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import _ from 'lodash';

// Components
import SvgIcon from 'components/SvgIcon';

// Constants - Internals
import { CONFIG, ICONS } from 'utils/global/globalConstants';
import { SignupContext } from 'pages/Signup';
import { signupDataFormInitialValues } from './initialValues';

// Images
import mainLogo from 'assets/images/main-logo.svg';

// Services
import { getNationalitiesOptions } from 'services/signup/getNationalitiesOptions';

// Types
import { DropdownItemProps } from 'semantic-ui-react';
import { SignupDataFormValues } from 'pages/Signup/types';
import { signupContactRequest } from 'services/signup/signup';
import { languageOptions } from './selectOptions';
import { LangProviderContext } from 'components/LangProvider';

const SignupDataForm: React.FC = () => {
  const { state, dispatch } = useContext(SignupContext);
  const applicationLanguage = useContext(LangProviderContext).language;
  const [nationalityOptions, setNationalityOption] = useState<
    Array<DropdownItemProps>
  >([{ text: 'ITALIA', value: 'IT' }]);

  // On component mount load the nationality options for populate the select
  useEffect(() => {
    getNationalitiesOptions(setNationalityOption, true);
  }, []);

  // INTL Message defining
  const intl = useIntl();
  const supplierIntlText = intl.formatMessage({
    id: 'signup.dataform.supplier',
    defaultMessage: 'fornitore',
  });
  const customerIntlText = intl.formatMessage({
    id: 'signup.dataform.customer',
    defaultMessage: 'cliente',
  });
  const requiredFiled = intl.formatMessage({
    id: 'signup-dataform.validation.required',
    defaultMessage: 'Campo obbligatorio',
  });

  const getValidationErrorMessage = (dataField: string) => {
    if (!state.errorObject || !state.errorObject[dataField]) {
      return;
    }

    const errors = state.errorObject[dataField];

    const diplayedErrors = [];
    _.forOwn(errors, (value, key) => {
      diplayedErrors.push({
        key: key,
        text: value,
      });
    });

    return diplayedErrors.map(error => (
      <div className="error-message" key={error.key + '-' + dataField}>
        {error.text}
      </div>
    ));
  };

  /** Handle the submit of the data (first api request) */
  const handleDataSubmit = (values: SignupDataFormValues) => {
    if (CONFIG.RECAPTCHA.active) {
      window['grecaptcha'].ready(() => {
        window['grecaptcha']
          .execute(CONFIG.RECAPTCHA.key, { action: 'subscription/request' })
          .then(token => {
            signupContactRequest(
              dispatch,
              values,
              state.signupType,
              applicationLanguage,
              token,
              intl,
            );
          });
      });
    } else {
      signupContactRequest(
        dispatch,
        values,
        state.signupType,
        applicationLanguage,
        null, // Null token
        intl,
      );
    }
  };

  return (
    <div className="signup-dataform">
      <Image src={mainLogo} className="signup-dataform-logo" />
      <div className="signup-dataform-title">
        <FormattedMessage
          id="signup.dataform.title"
          defaultMessage={'Richiesta inserimento <tag>anagrafica </tag> MSM'}
          values={{
            tag: (text: string) => (
              <span className="bold-text">
                {`${text} ${
                  state.signupType === 'customer'
                    ? customerIntlText
                    : supplierIntlText
                }`}
              </span>
            ),
          }}
        />
      </div>
      <Formik
        initialValues={signupDataFormInitialValues}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string().required(requiredFiled),
          fiscalCode: Yup.string().required(requiredFiled),
          name: Yup.string().required(requiredFiled),
          phoneNumber: Yup.string().required(requiredFiled),
          vatNumber: Yup.string().required(requiredFiled),
        })}
        onSubmit={(values: SignupDataFormValues) => handleDataSubmit(values)}
      >
        <Form className="signup-dataform-form">
          <div className="signup-input-row">
            <div className="signup-input-container">
              <Input
                label={intl.formatMessage({
                  id: 'signup.dataform.name.label',
                  defaultMessage: 'Nome azienda / Ragione sociale *',
                })}
                name="name"
                placeholder={intl.formatMessage({
                  id: 'signup.dataform.name.placeholder',
                  defaultMessage: 'Inserire nome azienda o ragione sociale',
                })}
              />
              <ErrorMessage
                name="name"
                render={message => (
                  <span className="error-message">{message}</span>
                )}
              />
              {getValidationErrorMessage('name')}
            </div>
            <div className="signup-input-container">
              <Input
                label={intl.formatMessage({
                  id: 'signup.dataform.vatNumber.label',
                  defaultMessage: 'Partita IVA *',
                })}
                name="vatNumber"
                placeholder={intl.formatMessage({
                  id: 'signup.dataform.vatNumber.placeholder',
                  defaultMessage: 'Inserire Parita IVA',
                })}
              />
              <ErrorMessage
                name="vatNumber"
                render={message => (
                  <span className="signup-error-message">{message}</span>
                )}
              />
              {getValidationErrorMessage('vatNumber')}
            </div>
          </div>
          <div className="signup-input-row">
            <div className="signup-input-container">
              <Input
                label={intl.formatMessage({
                  id: 'signup.dataform.fiscalCode.label',
                  defaultMessage: 'Codice fiscale *',
                })}
                name="fiscalCode"
                placeholder={intl.formatMessage({
                  id: 'signup.dataform.fiscalCode.placeholder',
                  defaultMessage: 'Inserire il codice fiscale',
                })}
              />
              <ErrorMessage
                name="fiscalCode"
                render={message => (
                  <span className="error-message">{message}</span>
                )}
              />
              {getValidationErrorMessage('fiscalCode')}
            </div>
            <div className="signup-input-container">
              <Input
                label={intl.formatMessage({
                  id: 'signup.dataform.emailAddress.label',
                  defaultMessage: 'E-mail *',
                })}
                name="emailAddress"
                placeholder={intl.formatMessage({
                  id: 'signup.dataform.emailAddress.placeholder',
                  defaultMessage: 'Inserire email',
                })}
              />
              <ErrorMessage
                name="emailAddress"
                render={message => (
                  <span className="signup-error-message">{message}</span>
                )}
              />
              {getValidationErrorMessage('emailAddress')}
            </div>
          </div>
          <div className="signup-input-row">
            <div className="signup-input-container">
              <Input
                label={intl.formatMessage({
                  id: 'signup.dataform.phoneNumber.label',
                  defaultMessage: 'Numero di telefono *',
                })}
                name="phoneNumber"
                placeholder={intl.formatMessage({
                  id: 'signup.dataform.phoneNumber.placeholder',
                  defaultMessage: 'Inserire il numero di telefono',
                })}
              />
              <ErrorMessage
                name="phoneNumber"
                render={message => (
                  <span className="error-message">{message}</span>
                )}
              />
              {getValidationErrorMessage('phoneNumber')}
            </div>
            <div className="signup-input-container">
              <Input
                label={intl.formatMessage({
                  id: 'signup.dataform.city.label',
                  defaultMessage: 'Città',
                })}
                name="city"
                placeholder={intl.formatMessage({
                  id: 'signup.dataform.city.placeholder',
                  defaultMessage: 'Inserire città',
                })}
              />
              {getValidationErrorMessage('city')}
            </div>
          </div>
          <div className="signup-input-row">
            <div className="signup-input-container">
              <Select
                icon={<SvgIcon icon={ICONS.ARROW_DOWN} />}
                label={intl.formatMessage({
                  id: 'signup.dataform.language.label',
                  defaultMessage: 'Lingua *',
                })}
                name="language"
                options={languageOptions}
              />
              {getValidationErrorMessage('language')}
            </div>
            <div className="signup-input-container">
              <Select
                icon={<SvgIcon icon={ICONS.ARROW_DOWN} />}
                label={intl.formatMessage({
                  id: 'signup.dataform.nationality.label',
                  defaultMessage: 'Nazionalità *',
                })}
                loading={_.isEmpty(nationalityOptions)}
                name="countryRegionCode"
                options={nationalityOptions}
                search
              />
              {getValidationErrorMessage('countryRegionCode')}
            </div>
          </div>
          <div className="signup-button-container">
            <SubmitButton
              className="register-button"
              content={
                state.isLoading
                  ? ''
                  : intl.formatMessage({
                      id: 'signup.dataform.button.next',
                      defaultMessage: 'Prosegui',
                    })
              }
              disabled={state.isLoading}
              loading={state.isLoading}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SignupDataForm;
