import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const arrowDown = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 14}
    height={height || 10}
    viewBox="0 0 14 10"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.00517 8.80033C5.93435 8.72927 5.87459 8.65218 5.82261 8.57107L0.396879 3.14509C-0.132209 2.61575 -0.13246 1.75696 0.39713 1.22736C0.92647 0.697774 1.78501 0.697774 2.31486 1.22736L6.98776 5.90027L11.685 1.20326C12.2144 0.673666 13.0727 0.673666 13.6027 1.20326C13.8679 1.46818 14 1.81546 14 2.162C14 2.50853 13.8679 2.85606 13.6027 3.12073L8.1519 8.57107C8.09992 8.65218 8.04016 8.72977 7.96935 8.80033C7.69865 9.07103 7.34207 9.20161 6.98726 9.19508C6.63244 9.20161 6.27611 9.07103 6.00517 8.80033Z" />
  </svg>
);

export default arrowDown;
