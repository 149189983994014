import React, { useContext, useState } from 'react';
import {
  Form,
  Input,
  ResetButton,
  SubmitButton,
} from 'formik-semantic-ui-react';
import { Formik } from 'formik';
import { UserOrdersContext } from 'pages/UserOrders';
import { UserOrdersConstants } from 'pages/UserOrders/reducer/action';
import _ from 'lodash';
import CalendarPicker from 'components/CalendarPicker';
import moment from 'moment';
import * as Yup from 'yup';
import { DATE_REGEX, ICONS } from 'utils/global/globalConstants';
import SvgIcon from 'components/SvgIcon';
import { useIntl } from 'react-intl';

export const OrdersFiltersBar: React.FC = () => {
  const { state, dispatch } = useContext(UserOrdersContext);
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState(false);
  const [isSecondCalendarPickerOpen, setIsSecondCalendarPickerOpen] =
    useState(false);
  const intl = useIntl();
  const dateError = intl.formatMessage({
    id: 'orders.filters.bar.error.date',
    defaultMessage: 'Inserire una data valida nel formato GG-MM-YYYY',
  });

  const onFilter = (orderNumber: string, dateFrom: string, dateTo: string) => {
    let orderListToShow = state.completeOrdersCollection;

    if (!_.isEmpty(orderNumber)) {
      orderListToShow = orderListToShow.filter(
        order =>
          _.isString(order.orderNumber) &&
          order.orderNumber.toLowerCase().includes(orderNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      orderListToShow = orderListToShow.map(singleOrder => {
        return {
          orderDate: singleOrder.orderDate,
          orderNumber: singleOrder.orderNumber,
          lines: singleOrder.lines.filter(order => {
            return moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(
              order.plannedReceiptDate,
            );
          }),
        };
      });
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      orderListToShow = orderListToShow.map(singleOrder => {
        return {
          orderDate: singleOrder.orderDate,
          orderNumber: singleOrder.orderNumber,
          lines: singleOrder.lines.filter(order => {
            return moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(
              order.plannedReceiptDate,
            );
          }),
        };
      });
    }

    dispatch({
      type: UserOrdersConstants.SET_FILTERED_ORDERS_COLLECTION,
      payload: { filteredOrdersCollection: orderListToShow },
    });
  };

  return (
    <div className="orders-filters-bar">
      <Formik
        initialValues={{
          orderNumber: '',
          filteredDateFrom: '',
          filteredDateTo: '',
        }}
        onSubmit={values =>
          onFilter(
            values.orderNumber,
            values.filteredDateFrom,
            values.filteredDateTo,
          )
        }
        onReset={() =>
          dispatch({
            type: UserOrdersConstants.RESET_ALL_FILTERS,
          })
        }
        validationSchema={Yup.object().shape({
          filteredDateFrom: Yup.string()
            .matches(DATE_REGEX, dateError)
            .nullable(),
          filteredDateTo: Yup.string()
            .matches(DATE_REGEX, dateError)
            .nullable(),
        })}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <Input
              className="orders-number-input"
              placeholder={intl.formatMessage({
                id: 'orderes.filters.number.placeholder.orderNumber',
                defaultMessage: 'Numero ordine',
              })}
              name="orderNumber"
            />
            <Input
              name="filteredDateFrom"
              className="orders-date-input"
              placeholder={intl.formatMessage({
                id: 'ordersFilterBar.filter.dateFrom',
                defaultMessage: 'Consegna da',
              })}
              icon={
                <span
                  className="calendar-modal-button"
                  onClick={() => setIsCalendarPickerOpen(true)}
                >
                  <SvgIcon icon={ICONS.CALENDAR_FLAT} width={22} height={22} />
                </span>
              }
            />
            <Input
              name="filteredDateTo"
              className="orders-date-input"
              placeholder={intl.formatMessage({
                id: 'ordersFilterBar.filter.dateTo',
                defaultMessage: 'Consegna da',
              })}
              icon={
                <span
                  className="calendar-modal-button"
                  onClick={() => setIsSecondCalendarPickerOpen(true)}
                >
                  <SvgIcon icon={ICONS.CALENDAR_FLAT} width={22} height={22} />
                </span>
              }
            />
            <SubmitButton
              className="orders-filters-search"
              content={intl.formatMessage({
                id: 'ordersFilterBar.button.search',
                defaultMessage: 'Cerca',
              })}
              loading={false}
            />
            <ResetButton
              className="underlineButton orders-filters-reset"
              content={intl.formatMessage({
                id: 'ordersFilterBar.button.reset',
                defaultMessage: 'Rimuovi i filtri attivi',
              })}
            />
            {(!_.isEmpty(errors.filteredDateFrom) ||
              !_.isEmpty(errors.filteredDateTo)) && (
              <span className="default-error-message">
                Inserire una data valida nel formato GG-MM-YYYY
              </span>
            )}

            <CalendarPicker
              isOpen={isCalendarPickerOpen}
              onClose={() => setIsCalendarPickerOpen(false)}
              onChange={(date: Date) => {
                const formattedDate = moment(date)
                  .format('DD-MM-YYYY')
                  .toString();
                setFieldValue('filteredDateFrom', formattedDate);
              }}
              value={
                moment(values.filteredDateFrom, 'DD-MM-YYYY').isValid()
                  ? moment(values.filteredDateFrom, 'DD-MM-YYYY')
                      .format('MM-DD-YYYY')
                      .toString()
                  : null
              }
            />
            <CalendarPicker
              isOpen={isSecondCalendarPickerOpen}
              onClose={() => setIsSecondCalendarPickerOpen(false)}
              onChange={(date: Date) => {
                const formattedDate = moment(date)
                  .format('DD-MM-YYYY')
                  .toString();
                setFieldValue('filteredDateTo', formattedDate);
              }}
              value={
                moment(values.filteredDateTo, 'DD-MM-YYYY').isValid()
                  ? moment(values.filteredDateTo, 'DD-MM-YYYY')
                      .format('MM-DD-YYYY')
                      .toString()
                  : null
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrdersFiltersBar;
