import { API } from 'utils/global/backendRoutes';
import { AxiosResponse } from 'axios';
import standardClient from 'services/client/standardRequestClient';
import { AdminContactLoginListResponse, AdminLoginResponse } from './types';
import { toast } from 'react-toastify';
import {
  AdminLoginContactData,
  AdminLoginContactList,
} from 'pages/AdminContactLogin/types';
import { NavigateFunction } from 'react-router-dom';
import { LOCAL_STORAGE } from 'utils/global/globalConstants';

export const adminContactLoginList = (
  setAdminContactLoginList: (list: AdminLoginContactList) => void,
  setIsListLoading: (isLoading: boolean) => void,
  setCompleteContactCollection: (
    completeCollection: Array<AdminLoginContactData>,
  ) => void,
): void => {
  setIsListLoading(true);

  standardClient
    .request({
      url: API.ADMIN_CONTACT_LOGIN,
      method: 'POST',
      data: {
        method: 'list',
      },
    })
    .then((response: AxiosResponse<AdminContactLoginListResponse>) => {
      setAdminContactLoginList(response.data);
      setCompleteContactCollection(
        response.data.customerList.concat(response.data.supplierList),
      );
    })
    .catch(() => toast.error('Impossibile caricare la lista degli utenti'))
    .finally(() => setIsListLoading(false));
};

export const adminContactLoginCall = (
  contactCode: string,
  setIsAdminLogginIn: (isLogginIn: boolean) => void,
  navigate: NavigateFunction,
): void => {
  setIsAdminLogginIn(true);

  standardClient({
    url: API.ADMIN_CONTACT_LOGIN,
    method: 'POST',
    data: {
      method: 'login',
      params: {
        contactCode,
      },
    },
  })
    .then((response: AxiosResponse<AdminLoginResponse>) => {
      localStorage.setItem(
        LOCAL_STORAGE.ACCESS_TOKEN,
        response.data.accessToken,
      );
      navigate(0);
    })
    .catch(() => {
      toast.error('Impossibile eseguire la login');
    })
    .finally(() => setIsAdminLogginIn(false));
};
