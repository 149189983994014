// General application configuration
export const CONFIG = {
  MICROSOFT_GRAPH_LOGIN_ACTIVE: true, // Login with Offie365
  MICROSOFT_GRAPH_POPUP_URL: '/hybridauth/login/MicrosoftGraph', // Login Office365 address
  RECAPTCHA: {
    active: true,
    key: '6Lc_NX0eAAAAAPiyxj-8j0wYzVjM4jd4_M5Lq4qW',
  },
};

// Routes of application
export const FE_ROUTES = {
  ADMIN: '/admin',
  ADMIN_CONTACT_LOGIN: '/admin-contact-login',
  AGENT_CUSTOMER_CREDITS_LIMIT: '/agent-customer-credits-limit',
  AGENT_CUSTOMER_DOCUMENTS: '/agent-customer-documents',
  AGENT_CUSTOMER_METAL_AVAILABILITY: '/agent-customer-metal-availability',
  AGENT_CUSTOMER_METAL_RESUME: '/agent-customer-metal-resume',
  CALENDAR: '/calendar',
  CUSTOMER_DOCUMENTS: '/customer-documents',
  CUSTOMER_METAL_AVAILABILITY: '/customer-metal-availability',
  CUSTOMER_METAL_RESUME: '/customer-metal-resume',
  CUSTOMER_RESERVATION: '/customer-reservation',
  CUSTOMER_RESERVATION_LIST: '/customer-reservations',
  DASHBOARD: '/dashboard',
  DATA_COLLECTION: '/data-collection',
  DOCUMENT: '/document',
  DOCUMENTS: '/documents',
  HOMEPAGE: '/homepage',
  LOGIN: '/login',
  MAIN_ROUTE: '',
  MYSELF: '/myself',
  NEW_USER_REQUESTS: '/new-user-requests',
  ORDERS: '/orders',
  ORIGINS_CONTACT: '/origin',
  PASSWORD_RECOVERY: '/password-recovery',
  PROVIDERS: '/providers',
  RESERVATION_DETAILS: '/reservation',
  RESERVATION_LIST: '/reservations',
  RESET_PASSWORD: '/reset-password',
  SIGNUP: '/signup',
  USER_DETAILS: '/user-details/:userId',
};

export enum ROLES {
  ADMIN = 'ROLE_ADMIN',
  AGENT = 'ROLE_AGENT',
  CONTACT = 'ROLE_CONTACT',
  CUSTOMER = 'ROLE_CUSTOMER',
  CUSTOMER_AGENT = 'ROLE_CUSTOMER_AGENT',
  NOVICE_CONTACT = 'ROLE_NOVICE_CONTACT',
  SUPPLIER = 'ROLE_SUPPLIER',
}

// Define language settings
export const DEFAULT_LOCALE = 'it-IT';

// Variable constant in local storage definition
export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'accessToken',
  EXPIRES_IN: 'expiresIn',
  PROVIDER: 'provider',
  REFRESH_TOKEN: 'refreshToken',
  SCOPE: 'scope',
  TOKEN_TYPE: 'tokenType',
};

// Custom event definition
export const CUSTOM_EVENT = {
  LOGOUT: 'custom.event.logout',
};

// Style
export const HEIGHT_TOPBAR = '4rem';
export const WIDTH_SIDEBAR = '15.5rem';
export const MAIN_CONTENT_BACKGROUND =
  'linear-gradient(287.15deg, #3C3C3B 0%, rgba(60, 60, 59, 0.88) 100%);';

export enum ICONS {
  ARROW_DOWN = 'arrow_down',
  ARROW_LEFT = 'arrow_left',
  ARROW_RIGHT = 'arrow_right',
  BELL = 'bell',
  CALENDAR = 'calendar',
  CALENDAR_FLAT = 'calendar_flat',
  CHECK = 'check',
  CHECKLIST = 'checklist',
  CLOCK = 'clock',
  CLOCK_FILLED = 'clock_filled',
  CROSS = 'cross',
  DASHBOARD = 'dashboard',
  DOCUMENT = 'document',
  ELIPSIS = 'elipsis',
  EYE = 'eye',
  FILE = 'file',
  GEAR = 'gear',
  INFORMATION = 'information',
  LENS = 'lens',
  PADLOCK = 'padlock',
  PENCIL = 'pencil',
  PLUS = 'plus',
  QUESTION_MARK = 'question_mark',
  RECYCLE = 'recycle',
  SHIELD = 'shield',
  TOW_TRUCK = 'tow_truck',
  TRASH = 'trash',
  TRUCK = 'truck',
  TRUCK_FRONTAL = 'truck_frontal',
  USER = 'user',
  WARNING_CIRCLE = 'warning_circle',
  WEIGHT = 'weight',
}

export enum COLORS {
  BLACK = '#000000',
  ERROR = '#EB5757',
  MID_GREY = '#7A7A78',
  LIGHT_GREY = '#EBEFF2',
  PRIMARY = '#3C3C3B',
  SECONDARY = '#FDC300',
  SUCCESS = '#27AE60',
  WHITE = '#FFFFFF',
}

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_RESERVATION_PAGE_SIZE = 8;

export const DATE_REGEX =
  /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
