import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerGeneralShipment } from 'services/customer/getCustomerDocuments';
import {
  CUSTOMER_DOCUMENT_METHODS,
  CUSTOMER_SHIPMENT_TYPE,
} from 'utils/global/customerCostants';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS, ROLES } from 'utils/global/globalConstants';
import _ from 'lodash';
import {
  getCustomerDocument,
  getCustomerDocumentFromEdok,
} from 'services/document/getCustomerDocuments';
import { Nullable } from 'utils/types';
import {
  CustomerShipment,
  CustomerShipmentTableColumns,
  SelectedShipmentElement,
  ShipmentSupportedSort,
} from './types';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import CustomerShipmentModal from '../CustomerShipmentModal';
import { Popup } from 'semantic-ui-react';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';

const CustomerShipments: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const [customerShipmentCollection, setCustomerShipmentCollection] = useState<
    Array<CustomerShipment>
  >([]);
  const [showedCustomerShipment, setShowedCustomerShipment] = useState<
    Array<CustomerShipment>
  >([]);
  const [areShipmentLoading, setAreShipmentLoading] = useState(false);
  const [activeSort, setActiveSort] =
    useState<ShipmentSupportedSort>('delivery-date-desc');

  const [selectedShipment, setSelectedShipment] =
    useState<Nullable<SelectedShipmentElement>>(null);

  useEffect(() => {
    if (!isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      getCustomerGeneralShipment(
        setCompleteShipmentCollection,
        setAreShipmentLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callShipmentsForAgent();
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callShipmentsForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerGeneralShipment(
        setCompleteShipmentCollection,
        setAreShipmentLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteShipmentCollection = (
    completeShipmentCollection: Array<CustomerShipment>,
  ) => {
    setCustomerShipmentCollection(completeShipmentCollection);
    const filteredByYearCollection = completeShipmentCollection.filter(
      shipment =>
        moment(shipment.postingDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
    );
    setShowedCustomerShipment(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let documentsToShow = customerShipmentCollection;

    if (!_.isEmpty(documentNumber)) {
      documentsToShow = documentsToShow.filter(
        document =>
          _.isString(document.postingNo) &&
          document.postingNo
            .toLowerCase()
            .includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(
          singleDocument.postingDate,
        ),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(singleDocument.postingDate),
      );
    }

    setShowedCustomerShipment(documentsToShow);
  };

  const onChangeSort = (sortType: ShipmentSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedCustomerShipment(
      onShortShipmentCollection(sortType, showedCustomerShipment),
    );
    setActiveSort(sortType);
  };

  const onShortShipmentCollection = (
    sortType: ShipmentSupportedSort,
    shipmentCollection: Array<CustomerShipment>,
  ): Array<CustomerShipment> => {
    let toSortShipmenstDocuments = shipmentCollection;

    switch (sortType) {
      case 'shipment-number-desc': {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) => {
            if (firsthShipment.postingNo > secondShipment.postingNo) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) =>
            moment(firsthShipment.postingDate, 'YYYY-MM-DD').diff(
              moment(secondShipment.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) =>
            moment(secondShipment.postingDate, 'YYYY-MM-DD').diff(
              moment(firsthShipment.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) => {
            if (firsthShipment.postingNo > secondShipment.postingNo) {
              return 1;
            }
            return -1;
          },
        );
      }
    }

    return toSortShipmenstDocuments;
  };

  const onCloseModal = () => {
    setSelectedShipment(null);
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<CustomerShipment> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return customerShipmentCollection.filter(shipment =>
        moment(shipment.postingDate, 'YYYY-MM-DD').isSame(selectedYear, 'year'),
      );
    }
    return customerShipmentCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedCustomerShipment(getFilteredByYearsCollection(selectedYear));
    onChangeSort('delivery-date-desc');
  };

  const columns: Array<CustomerShipmentTableColumns> = [
    {
      key: 'generalShipmentNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'shipment-number-asc'
                ? 'shipment-number-desc'
                : 'shipment-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.shipments.table.header.generalShipmentNumber"
            defaultMessage="N. DDT"
          />
          {activeSort === 'shipment-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'shipment-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">
          {data.postingNo}
        </div>
      ),
    },
    {
      key: 'generalShipmentDate',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.shipments.table.header.generalShipmentDate"
            defaultMessage="Data"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.postingDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'type',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipments.table.header.type"
            defaultMessage="Tipologia"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {data.shipmentType}
        </div>
      ),
    },
    {
      key: 'type',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipments.table.header.rowType"
            defaultMessage="Tipologia vendita"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {_.isEmpty(data.rowType) ? '- - -' : data.rowType}
        </div>
      ),
    },
    {
      key: 'generalShipmentShipToName',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipments.table.header.generalShipmentShipToName"
            defaultMessage="Spedito a"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {data.shipToName}
        </div>
      ),
    },
    {
      key: 'generalShipmentShipToAddress',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipments.table.header.generalShipmentShipToAddress"
            defaultMessage="Indirizzo spedizione"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-payment">
          <span className="payment-method">{data.shipToAddress}</span>
          <span className="payment-terms">({data.shipToCity})</span>
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.shipments.table.documents.tooltip.details"
                  defaultMessage="Visualizza dettagli"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() =>
                  setSelectedShipment({
                    shipmentCode: data.no,
                    shipmentType: data.type,
                  })
                }
              >
                <SvgIcon icon={ICONS.LENS} height={20} width={20} />
              </span>
            }
          />
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.shipments.table.documents.tooltip.download"
                  defaultMessage="Scarica documento"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() =>
                  getCustomerDocumentFromEdok(
                    data.edokVolumeId,
                    data.edokDocumentId,
                    intl,
                    selectedContact?.name,
                  )
                }
              >
                <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
              </span>
            }
          />
          {data.qualityCertificatesExists && (
            <Popup
              className="tooltip-password"
              content={
                <span className="tooltip-password-content">
                  <FormattedMessage
                    id="customerDocuments.shipments.table.documents.tooltip.qualityCertificates"
                    defaultMessage="Scarica certificato qualità"
                  />
                </span>
              }
              basic
              hoverable
              trigger={
                <span
                  className="actionMenu-button margin-right"
                  onClick={() =>
                    getCustomerDocument(
                      data.no,
                      data.type === CUSTOMER_SHIPMENT_TYPE.SALES_SHIPMENT_TYPE
                        ? CUSTOMER_DOCUMENT_METHODS.SALES_SHIPMENT_CERTIFICATE
                        : CUSTOMER_DOCUMENT_METHODS.GENERAL_SHIPMENT_CERTIFICATE,
                      intl,
                    )
                  }
                >
                  <SvgIcon icon={ICONS.SHIELD} height={20} width={20} />
                </span>
              }
            />
          )}
          {data.packingListExists && (
            <Popup
              className="tooltip-password"
              content={
                <span className="tooltip-password-content">
                  <FormattedMessage
                    id="customerDocuments.shipments.table.documents.tooltip.packingList"
                    defaultMessage="Scarica packing list"
                  />
                </span>
              }
              basic
              hoverable
              trigger={
                <span
                  className="actionMenu-button"
                  onClick={() =>
                    getCustomerDocument(
                      data.no,
                      data.type === CUSTOMER_SHIPMENT_TYPE.SALES_SHIPMENT_TYPE
                        ? CUSTOMER_DOCUMENT_METHODS.SALES_SHIPMENT_PACKING_LIST
                        : CUSTOMER_DOCUMENT_METHODS.GENERAL_SHIPMENT_PACKING_LIST,
                      intl,
                    )
                  }
                >
                  <SvgIcon icon={ICONS.CHECKLIST} height={20} width={20} />
                </span>
              }
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomerDocumentsTab
        activeSort={activeSort}
        areDocumentsLoading={areShipmentLoading}
        completeDocumentsCollection={showedCustomerShipment}
        filterInputPlaceholder={intl.formatMessage({
          id: 'customerDocuments.shipments.filterBar.input.placheolder',
          defaultMessage: 'N. DDT',
        })}
        onChangeSelectedYear={onChangeSelectedYear}
        onFilter={onFilter}
        tableColumns={columns}
        key="customer-credits-notes"
      />

      <CustomerShipmentModal
        onClose={onCloseModal}
        selectedShipment={selectedShipment}
      />
    </>
  );
};

export default CustomerShipments;
