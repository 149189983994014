import { ContactDocumentsState } from '../types';
import { ContactDocumentsAction, ContactDocumentsConstants } from './action';

export const initialContactDocumentsState: ContactDocumentsState = {
  areDocumentsLoading: false,
  documentsCollection: [],
  isModalOpen: false,
};

export const contactDocumentsReducer = (
  state: ContactDocumentsState,
  action: ContactDocumentsAction,
): ContactDocumentsState => {
  const { type } = action;

  switch (type) {
    case ContactDocumentsConstants.SET_ARE_DOCUMENTS_LOADING:
      return {
        ...state,
        areDocumentsLoading: action.payload.areDocumentsLoading,
      };
    case ContactDocumentsConstants.SET_DOCUMENTS_COLLECTION:
      return {
        ...state,
        documentsCollection: action.payload.documentsCollection,
      };
    case ContactDocumentsConstants.SET_OPEN_MODAL:
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
      };
    default:
      return state;
  }
};
