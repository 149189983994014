import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { GetCustomerItemsCollectionResponse } from './types';
import { CustomerItem } from 'components/CustomerReservation/CustomerArticlePhase/types';
import { IntlShape } from 'react-intl';

export const getCustomerItemsCollection = (
  setIsCollectionLoading: (isLoading: boolean) => void,
  setCollection: (itemsCollection: Array<CustomerItem>) => void,
  selectedOriginCode: string,
  intl: IntlShape,
): void => {
  setIsCollectionLoading(true);
  standardClient({
    url: API.GET_CUSTOMER_ITEMS,
    method: 'POST',
    data: {
      params: {
        originCode: selectedOriginCode,
      },
    },
  })
    .then((response: AxiosResponse<GetCustomerItemsCollectionResponse>) => {
      setCollection(response.data.customerItems);
    })
    .catch(() => {
      toast.error(
        intl.formatMessage({
          id: 'service.getCustomerItemsCollection.error',
          defaultMessage: 'Impossibile scaricare la lista degli articoli',
        }),
      );
    })
    .finally(() => setIsCollectionLoading(false));
};
