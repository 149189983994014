import _ from 'lodash';
import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import { EmptyReservationsProps } from './types';

const EmptyReservations: React.FC<EmptyReservationsProps> = ({
  iconSrc,
  subtitleText = null,
  title,
}) => {
  return (
    <div className="emptyReservations">
      <Image src={iconSrc} />
      <Header as="h1">
        {title}
        {!_.isEmpty(subtitleText) && (
          <Header.Subheader>{subtitleText}</Header.Subheader>
        )}
      </Header>
    </div>
  );
};

export default EmptyReservations;
