import React, { useContext } from 'react';
import SemanticTableGrid from 'semantic-table-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactOriginsTableColumn, SingleContactOriginParam } from './types';
import { ContactOriginsListContext } from 'pages/ContactOriginsList';
import { COLORS, FE_ROUTES, ICONS } from 'utils/global/globalConstants';
import { useNavigate } from 'react-router-dom';

import SvgIcon from 'components/SvgIcon';

const ContactOriginsTable: React.FC = () => {
  const intl = useIntl();
  const { state } = useContext(ContactOriginsListContext);
  const navigate = useNavigate();

  /** Redirect to the contact details page */
  const redirectToContactOrigin = (contactId: string, mode: string) => {
    navigate(FE_ROUTES.ORIGINS_CONTACT + '/' + contactId + '/' + mode);
  };

  const columns: Array<ContactOriginsTableColumn> = [
    {
      key: 'businessName',
      name: (
        <div className="origins-table-header">
          <FormattedMessage
            id="originsTable.factoryAndVatNumber"
            defaultMessage="Azienda"
          />
        </div>
      ),
      formatter: ({ data }: SingleContactOriginParam) => (
        <div className="origins-table-businessName">
          <span className="origins-table-businessName-name">{data.name}</span>
        </div>
      ),
    },
    {
      key: 'vatNumber',
      name: (
        <div className="origins-table-header-center">
          <FormattedMessage
            id="originsTable.vatNumber"
            defaultMessage="Partita IVA"
          />
        </div>
      ),
      formatter: ({ data }: SingleContactOriginParam) => (
        <div className="origins-table-email">{data.vatNumber}</div>
      ),
    },
    {
      key: 'missingDocuments',
      name: (
        <div className="origins-table-header-center">
          <FormattedMessage
            id="originsTable.missingDocuments"
            defaultMessage="Doc. da caricare"
          />
        </div>
      ),
      formatter: ({ data }: SingleContactOriginParam) => (
        <div className="origins-table-email">{data.missingDocuments}</div>
      ),
    },
    {
      key: 'expiringDocuments',
      name: (
        <div className="origins-table-header-center">
          <FormattedMessage
            id="originsTable.expiringDocuments"
            defaultMessage="Doc. in scadenza"
          />
        </div>
      ),
      formatter: ({ data }: SingleContactOriginParam) => (
        <div className="origins-table-email">{data.expiringDocuments}</div>
      ),
    },
    {
      key: 'expiredDocuments',
      name: (
        <div className="origins-table-header-center">
          <FormattedMessage
            id="originsTable.expiredDocuments"
            defaultMessage="Doc. scaduti"
          />
        </div>
      ),
      formatter: ({ data }: SingleContactOriginParam) => (
        <div className="origins-table-email">{data.expiredDocuments}</div>
      ),
    },
    {
      key: 'approved',
      name: (
        <div className="origins-table-header-center">
          <FormattedMessage
            id="originsTable.approved"
            defaultMessage="Stato provenienza"
          />
        </div>
      ),
      formatter: ({ data }: SingleContactOriginParam) => (
        <div className="origins-table-approved">
          {data.originState === 0 && (
            <span className={`origins-table-approved-cross`}>
              <SvgIcon
                icon={ICONS.CROSS}
                color={COLORS.WHITE}
                height={16}
                width={16}
              />
            </span>
          )}
          {data.originState === 1 && (
            <span className={`origins-table-approved-pending`}>
              <SvgIcon
                icon={ICONS.CLOCK_FILLED}
                color={COLORS.WHITE}
                height={16}
                width={16}
              />
            </span>
          )}
          {data.originState === 2 && (
            <span className={`origins-table-approved-check`}>
              <SvgIcon
                icon={ICONS.CHECK}
                color={COLORS.WHITE}
                height={16}
                width={16}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      key: 'settings',
      name: '',
      formatter: ({ data }: SingleContactOriginParam) => {
        return (
          <div className="origins-table-actionMenu">
            {!data.isPending ? (
              <span
                className="origins-table-actionMenu-view-button"
                onClick={() => redirectToContactOrigin(data.originCode, 'view')}
              >
                <SvgIcon icon={ICONS.EYE} height={20} width={20} />
              </span>
            ) : null}
          </div>
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className="contact-origins-list">
      <SemanticTableGrid
        className="contact-origins-list"
        columns={columns}
        elements={state.filteredOriginsCollection}
        emptyResults={intl.formatMessage({
          id: 'contactOriginsTable.empty',
          defaultMessage: 'Nessuna provenienza presente',
        })}
        isLoading={state.isLoading}
      />
    </div>
  );
};

export default ContactOriginsTable;
