import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import {
  CustomerGeneralShipmentModalProps,
  GeneralShipmentDetails,
} from './types';
import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import { Nullable } from 'utils/types';
import _ from 'lodash';
import {
  getGeneralShipmentDetails,
  getSalesShipmentDetails,
} from 'services/customer/getCustomerDocumentDetails';
import { CircularProgress } from '@material-ui/core';
import { CUSTOMER_SHIPMENT_TYPE } from 'utils/global/customerCostants';

const CustomerShipmentModal: React.FC<CustomerGeneralShipmentModalProps> = ({
  onClose,
  selectedShipment,
}) => {
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const [isCustomerOrderLoading, setIsCustomerOrderLoading] = useState(false);
  const [customerOrderDetails, setCustomerOrderDetails] =
    useState<Nullable<GeneralShipmentDetails>>(null);

  useEffect(() => {
    if (_.isEmpty(selectedShipment)) {
      setCustomerOrderDetails(null);
      return;
    }

    if (
      selectedShipment.shipmentType ===
      CUSTOMER_SHIPMENT_TYPE.GENRAL_SHIPMENT_TYPE
    ) {
      getGeneralShipmentDetails(
        selectedShipment.shipmentCode,
        setCustomerOrderDetails,
        setIsCustomerOrderLoading,
      );
    } else if (
      selectedShipment.shipmentType ===
      CUSTOMER_SHIPMENT_TYPE.SALES_SHIPMENT_TYPE
    ) {
      getSalesShipmentDetails(
        selectedShipment.shipmentCode,
        setCustomerOrderDetails,
        setIsCustomerOrderLoading,
      );
    }
  }, [selectedShipment]);

  return (
    <Modal
      open={!_.isEmpty(selectedShipment)}
      onClose={onClose}
      className="customer-documents-modal"
    >
      <Button className="crossIcon" onClick={onClose}>
        <SvgIcon icon={ICONS.CROSS} />
      </Button>
      {isCustomerOrderLoading && (
        <div className="customer-documents-modal-loading">
          <CircularProgress color="inherit" />
          <FormattedMessage
            id="customerDocuments.ddt.details.dataLoading"
            defaultMessage="Dettaglio DDT in caricamento"
          />
        </div>
      )}
      {!isCustomerOrderLoading && !_.isEmpty(customerOrderDetails) && (
        <>
          <Modal.Header className="upload-document-modal-header">
            <SvgIcon icon={ICONS.FILE} height={30} width={30} />
            <FormattedMessage
              id="customerDocuments.ddt.details.header.title"
              defaultMessage="Documento DDT {orderNumber}"
              values={{
                orderNumber: customerOrderDetails.postingNo,
              }}
            />
          </Modal.Header>
          <Modal.Content className="noPaddingTop upload-document-modal-content">
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.shipment.details.date"
                  defaultMessage="Data: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {moment(customerOrderDetails.postingDate, 'YYYY-MM-DD').format(
                  'DD MMM YYYY',
                )}
              </span>
            </li>

            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.ddt.details.totalQuantity"
                  defaultMessage="Quantità totale: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {intlFormatNumber.format(
                  _.toNumber(customerOrderDetails.totalQuantityKg),
                )}{' '}
                KG
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.shipment.details.shipToCustomer"
                  defaultMessage="Spedito a: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.shipToName}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.shipment.details.shipAddress"
                  defaultMessage="Indirizzo di spedizione: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.shipToAddress}
                {' ('}
                {customerOrderDetails.shipToCity})
              </span>
            </li>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};

export default CustomerShipmentModal;
