import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerCreditsNotes } from 'services/customer/getCustomerDocuments';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS, ROLES } from 'utils/global/globalConstants';
import _ from 'lodash';
import { getCustomerDocumentFromEdok } from 'services/document/getCustomerDocuments';
import {
  CreditsNotes,
  CreditsNotesSupportedSort,
  CustomerCreditesNotesTableColunns,
} from './types';
import CustomerCreditsNoteModal from '../CustomerCreditsNoteModal';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import { Popup } from 'semantic-ui-react';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';

const CustomerCreditsNotes: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const [customerCreditsNotesCollection, setCustomerCreditsNotesCollection] =
    useState<Array<CreditsNotes>>([]);
  const [showedCreditsNotes, setShowedCreditsNotes] = useState<
    Array<CreditsNotes>
  >([]);
  const [areCreditsNotesLoading, setAreCreditsNotesLoading] = useState(false);
  const [activeSort, setActiveSort] =
    useState<CreditsNotesSupportedSort>('delivery-date-desc');

  const [selectedCreditsNotesCode, setSelectedCreditsNotesCode] = useState('');

  useEffect(() => {
    // Two different calls for customer or customer's agent profile
    if (!isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      getCustomerCreditsNotes(
        setCompleteCreditsNotesCollection,
        setAreCreditsNotesLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callCreditNotesForAgent();
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callCreditNotesForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerCreditsNotes(
        setCompleteCreditsNotesCollection,
        setAreCreditsNotesLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteCreditsNotesCollection = (
    completeCreditsNotesCollection: Array<CreditsNotes>,
  ) => {
    setCustomerCreditsNotesCollection(completeCreditsNotesCollection);
    const filteredByYearCollection = completeCreditsNotesCollection.filter(
      note => moment(note.postingDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
    );
    setShowedCreditsNotes(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let documentsToShow = customerCreditsNotesCollection;

    if (!_.isEmpty(documentNumber)) {
      documentsToShow = documentsToShow.filter(
        document =>
          _.isString(document.no) &&
          document.no.toLowerCase().includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(
          singleDocument.postingDate,
        ),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(singleDocument.postingDate),
      );
    }

    setShowedCreditsNotes(documentsToShow);
  };

  const onChangeSort = (sortType: CreditsNotesSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedCreditsNotes(
      onSortCreditsNotesCollection(sortType, showedCreditsNotes),
    );
    setActiveSort(sortType);
  };

  const onSortCreditsNotesCollection = (
    sortType: CreditsNotesSupportedSort,
    creditsNotesCollection: Array<CreditsNotes>,
  ): Array<CreditsNotes> => {
    let toSortCreditsNotes = creditsNotesCollection;

    switch (sortType) {
      case 'credit-note-number-desc': {
        toSortCreditsNotes = toSortCreditsNotes.sort(
          (firstNote, secondNote) => {
            if (firstNote.no > secondNote.no) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortCreditsNotes = toSortCreditsNotes.sort((firstNote, secondNote) =>
          moment(firstNote.postingDate, 'YYYY-MM-DD').diff(
            moment(secondNote.postingDate, 'YYYY-MM-DD'),
          ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortCreditsNotes = toSortCreditsNotes.sort((firstNote, secondNote) =>
          moment(secondNote.postingDate, 'YYYY-MM-DD').diff(
            moment(firstNote.postingDate, 'YYYY-MM-DD'),
          ),
        );
        break;
      }
      default: {
        toSortCreditsNotes = toSortCreditsNotes.sort(
          (firstNote, secondNote) => {
            if (firstNote.no > secondNote.no) {
              return 1;
            }
            return -1;
          },
        );
      }
    }
    return toSortCreditsNotes;
  };

  const onCloseModal = () => {
    setSelectedCreditsNotesCode(null);
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<CreditsNotes> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return customerCreditsNotesCollection.filter(note =>
        moment(note.postingDate, 'YYYY-MM-DD').isSame(selectedYear, 'year'),
      );
    }
    return customerCreditsNotesCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedCreditsNotes(getFilteredByYearsCollection(selectedYear));
    onChangeSort('delivery-date-desc');
  };

  const columns: Array<CustomerCreditesNotesTableColunns> = [
    {
      key: 'creditNotesNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'credit-note-number-asc'
                ? 'credit-note-number-desc'
                : 'credit-note-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.orders.table.header.creditNotesNumber"
            defaultMessage="N. Nota di credito"
          />
          {activeSort === 'credit-note-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'credit-note-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">{data.no}</div>
      ),
    },
    {
      key: 'creditNotesDate',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.orders.table.header.creditNotesDate"
            defaultMessage="Data Nota di credito"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.postingDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'invoiceAmount',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.invoiceAmount"
            defaultMessage="Importo (IVA Esclusa)"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
          }).format(_.toNumber(data.amount))}
        </div>
      ),
    },
    {
      key: 'payment',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.payment"
            defaultMessage="Metodo e termini pagamento"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-payment">
          <span className="payment-method">{data.paymentMethod}</span>
          <span className="payment-terms">{data.paymentTerms}</span>
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.creditNotes.table.documents.tooltip.details"
                  defaultMessage="Visualizza dettagli"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() => setSelectedCreditsNotesCode(data.no)}
              >
                <SvgIcon icon={ICONS.LENS} height={20} width={20} />{' '}
              </span>
            }
          />
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.creditNotes.table.documents.tooltip.download"
                  defaultMessage="Scarica documento"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button"
                onClick={() =>
                  getCustomerDocumentFromEdok(
                    data.edokVolumeId,
                    data.edokDocumentId,
                    intl,
                    selectedContact?.name,
                  )
                }
              >
                <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
              </span>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomerDocumentsTab
        activeSort={activeSort}
        areDocumentsLoading={areCreditsNotesLoading}
        completeDocumentsCollection={showedCreditsNotes}
        filterInputPlaceholder={intl.formatMessage({
          id: 'customerDocuments.creditNotes.filterBar.input.placheolder',
          defaultMessage: 'N. nota di credito',
        })}
        onChangeSelectedYear={onChangeSelectedYear}
        onFilter={onFilter}
        tableColumns={columns}
        key="customer-credits-notes"
      />

      <CustomerCreditsNoteModal
        onClose={onCloseModal}
        invoiceDocumentCode={selectedCreditsNotesCode}
      />
    </>
  );
};

export default CustomerCreditsNotes;
