import { AxiosResponse } from 'axios';
import { ReactText } from 'react';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { CUSTOMER_DOCUMENT_METHODS } from 'utils/global/customerCostants';

export const getCustomerDocument = (
  customerDocumentCode: string,
  customerDocumentType: CUSTOMER_DOCUMENT_METHODS,
  intl: IntlShape,
  customerName?: string,
): void => {
  // Show a notification with loading when a file is downloading
  const notifyStatus = toast.loading(
    intl.formatMessage({
      id: 'service.getCustomerDocuments.download.running',
      defaultMessage: 'Recupero del file in corso...',
    }),
    {
      closeOnClick: true,
    },
  );

  standardClient({
    url: API.CUSTOMER_DOCUMENT,
    method: 'POST',
    headers: { Accept: 'application/vnd.msm\\media' },
    responseType: 'arraybuffer',
    data: {
      method: customerDocumentType,
      params: {
        documentNo: customerDocumentCode,
        customerName,
      },
    },
  })
    .then((response: AxiosResponse) =>
      createFileAfterDownload(response, notifyStatus, intl),
    )
    .catch(() => downoloadErrorNotify(notifyStatus, intl));
};

/**
 * Get the document by its id and volume id from Edok
 * @param edokVolumeId
 * @param edokDocumentId
 * @param intl
 */
export const getCustomerDocumentFromEdok = (
  edokVolumeId: string,
  edokDocumentId: string,
  intl: IntlShape,
  customerName?: string,
): void => {
  // Show a notification with loading when a file is downloading
  const notifyStatus = toast.loading(
    intl.formatMessage({
      id: 'service.getCustomerDocumentFromEdok.download.running',
      defaultMessage: 'Recupero del file in corso...',
    }),
    {
      closeOnClick: true,
    },
  );

  standardClient({
    url: API.CUSTOMER_DOCUMENT,
    method: 'POST',
    headers: { Accept: 'application/vnd.msm\\media' },
    responseType: 'arraybuffer',
    data: {
      method: 'customer-edok-document',
      params: {
        edokVolumeId: edokVolumeId,
        edokDocumentId: edokDocumentId,
        customerName,
      },
    },
  })
    .then((response: AxiosResponse) =>
      createFileAfterDownload(response, notifyStatus, intl),
    )
    .catch(() => downoloadErrorNotify(notifyStatus, intl));
};

const createFileAfterDownload = (
  response: AxiosResponse,
  notifyStatus: ReactText,
  intl: IntlShape,
) => {
  // Get the name from content-dispositon
  // Ex. string => content-dispositon: attachment;filename=sample.pdf
  const contentDisposition = response.headers['content-disposition'];
  const fileNameString = contentDisposition.split('filename=')[1];

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileNameString || 'file.pdf');
  document.body.appendChild(link);
  link.click();

  // Show success message with auto dismiss after 3 second
  toast.update(notifyStatus, {
    autoClose: 3000,
    isLoading: false,
    render: intl.formatMessage({
      id: 'service.getCustomerDocumentFromEdok.download.done',
      defaultMessage: 'File scaricato correttamente',
    }),
    type: 'success',
  });
};

/**
 * Notify the download error
 * @param notifyStatus
 * @param intl
 */
const downoloadErrorNotify = (notifyStatus: ReactText, intl: IntlShape) => {
  // Show error message with auto dismiss after 3 second
  toast.update(notifyStatus, {
    autoClose: 3000,
    isLoading: false,
    render: intl.formatMessage({
      id: 'service.getCustomerDocumentFromEdok.download.error',
      defaultMessage: 'Impossibile scaricare il file richiesto',
    }),
    type: 'error',
  });
};
