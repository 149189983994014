import { CustomerReservation } from '../types';

export enum CustomerReservationConstants {
  ADD_ITEM = 'CustomerReservation/ADD_ITEM',
  CLEAR_ITEMS = 'CustomerReservation/CLEAR_ITEMS',
  REMOVE_ITEM = 'CustomerReservation/REMOVE_ITEM',
  SET_ARE_DATA_LOADING = 'CustomerReservation/SET_ARE_DATA_LOADING',
  SET_RESERVATION = 'CustomerReservation/SET_RESERVATION',
  SET_RESERVATION_LOADING = 'CustomerReservation/SET_RESERVATION_LOADING',
  SET_PREV_RESERVATION_LOADING = 'CustomerReservation/SET_PREV_RESERVATION_LOADING',
}

export type CustomerReservationAction =
  | {
      type: CustomerReservationConstants.ADD_ITEM;
      payload: { itemCode: string };
    }
  | {
      type: CustomerReservationConstants.CLEAR_ITEMS;
    }
  | {
      type: CustomerReservationConstants.REMOVE_ITEM;
      payload: { itemCode: string };
    }
  | {
      type: CustomerReservationConstants.SET_ARE_DATA_LOADING;
      payload: { areDataLoading: boolean };
    }
  | {
      type: CustomerReservationConstants.SET_RESERVATION;
      payload: { reservation: CustomerReservation };
    }
  | {
      type: CustomerReservationConstants.SET_RESERVATION_LOADING;
      payload: { isReservationLoading: boolean };
    }
  | {
      type: CustomerReservationConstants.SET_PREV_RESERVATION_LOADING;
      payload: { isPrevReservationLoading: boolean };
    };
