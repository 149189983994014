import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const warningCircle = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 28}
    height={height || 28}
    viewBox="0 0 28 28"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6665 17.9993H15.3332V20.666H12.6665V17.9993ZM12.6665 7.33268H15.3332V15.3327H12.6665V7.33268ZM13.9865 0.666016C6.6265 0.666016 0.666504 6.63935 0.666504 13.9993C0.666504 21.3594 6.6265 27.3327 13.9865 27.3327C21.3598 27.3327 27.3332 21.3594 27.3332 13.9993C27.3332 6.63935 21.3598 0.666016 13.9865 0.666016ZM13.9998 24.666C8.1065 24.666 3.33317 19.8927 3.33317 13.9993C3.33317 8.10602 8.1065 3.33268 13.9998 3.33268C19.8932 3.33268 24.6665 8.10602 24.6665 13.9993C24.6665 19.8927 19.8932 24.666 13.9998 24.666Z" />
  </svg>
);

export default warningCircle;
