import React, { useContext } from 'react';
import SemanticTableGrid from 'semantic-table-grid';
import { FormattedMessage, useIntl } from 'react-intl';

import { AppContext } from 'pages/App';
import { CustomerAgentColumn } from './types';
import { Popup } from 'semantic-ui-react';
import { appActionConstants } from 'pages/App/reducer/actions';
import { SelectedContact } from 'layout/SideBar/types';
import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';

const CustomerAgentDashboard: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const dispatch = useContext(AppContext).dispatch;
  const customerList = appState.mySelf._agentCustomerList;

  const setSelectedContact = (selectedContact: SelectedContact) => {
    dispatch({
      type: appActionConstants.SET_SELECTED_CONTACT,
      payload: { contact: selectedContact },
    });
  };

  const columns: Array<CustomerAgentColumn> = [
    {
      key: 'customerName',
      sortable: true,
      name: (
        <FormattedMessage
          id="customerAgent.table.header.customerName"
          defaultMessage="Nome cliente"
        />
      ),
      formatter: ({ data }) => <div className="align-left">{data.text}</div>,
    },
    {
      key: 'vatNumber',
      sortable: true,
      name: (
        <FormattedMessage
          id="customerAgent.table.header.vatNumber"
          defaultMessage="Partita IVA"
        />
      ),
      formatter: ({ data }) => data.contact.vatNumber,
    },
    {
      key: 'customerAddress',
      sortable: true,
      name: (
        <FormattedMessage
          id="customerAgent.table.header.address"
          defaultMessage="Indirizzo"
        />
      ),
      formatter: ({ data }) => (
        <>
          {data.contact.address}
          <br />
          {data.contact.city}
        </>
      ),
    },
    /*     {
      key: 'creditLimit',
      sortable: true,
      name: (
        <FormattedMessage
          id="customerAgent.table.header.creditLimit"
          defaultMessage="Limiti di credito (€)"
        />
      ),
      formatter: ({ data }) =>
        !_.isEmpty(data.contact.customerCreditLimit)
          ? new Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: 'EUR',
            }).format(_.toNumber(data.contact.customerCreditLimit))
          : '-',
    }, */
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: (
        <FormattedMessage
          id="customerAgent.table.header.choseCustomer"
          defaultMessage="Seleziona cliente"
        />
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerAgent.table.tooltip.selectUser"
                  defaultMessage="Seleziona contatto"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() =>
                  setSelectedContact({
                    contactCode: data.value,
                    name: data.text,
                  })
                }
              >
                <SvgIcon icon={ICONS.USER} height={20} width={20} />
              </span>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div className="customer-agent-dashboard-container">
      <div className="semantic-table-grid-standard">
        <SemanticTableGrid
          className="metal-availability-contracts"
          columns={columns}
          elements={customerList}
          emptyResults={intl.formatMessage({
            id: 'customerAgent.table.empty',
            defaultMessage: 'Nessun cliente trovato',
          })}
        />
      </div>
    </div>
  );
};

export default CustomerAgentDashboard;
