import { SignupDocument, SignuPhaseConstants } from '../types';

export enum SignupConstants {
  CHANGE_SIGNUP_PHASE = 'SignupConstants/CHANGE_SIGNUP_PHASE',
  CLEAR_ERROR_OBJECT = 'SignupConstants/CLEAR_ERROR_OBJECT',
  SET_CONTACT_ID = 'SignupConstants/SET_CONTACT_ID',
  SET_ERROR_OBJECT = 'SignupConstants/SET_ERROR_OBJECT',
  SET_IS_LOADING = 'SignupConstants/SET_IS_LOADING',
  SET_REGISTRATION_TYPE = 'SignupConstants/SET_REGISTRATION_TYPE',
  SET_REQUESTED_DOCUMENT = 'SignupConstants/SET_REQUESTED_DOCUMENT',
}

export type SignupAction =
  | {
      type: SignupConstants.CHANGE_SIGNUP_PHASE;
      payload: { signupPhase: SignuPhaseConstants };
    }
  | {
      type: SignupConstants.CLEAR_ERROR_OBJECT;
    }
  | {
      type: SignupConstants.SET_CONTACT_ID;
      payload: { contactId: string };
    }
  | {
      type: SignupConstants.SET_REQUESTED_DOCUMENT;
      payload: { requestedDocument: Array<SignupDocument> };
    }
  | {
      type: SignupConstants.SET_ERROR_OBJECT;
      payload: { errorObject: unknown };
    }
  | {
      type: SignupConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: SignupConstants.SET_REGISTRATION_TYPE;
      payload: { signupType: 'customer' | 'supplier' };
    };
