import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import QueryString from 'qs';
import _ from 'lodash';

export const getCustomerDataCollection = (
  customerId: string,
  setCustomerDataCollection: (dataCollection) => void,
  setAreCustomerDataCollectionLoading: (value: boolean) => void,
): void => {
  setAreCustomerDataCollectionLoading(true);
  standardClient({
    url: API.CUSTOMER_DATA_COLLECTION,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'user',
          value: customerId,
        },
      ],
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then(response => {
      if (!_.isEmpty(response.data._embedded.customer_data_collection)) {
        setCustomerDataCollection(
          response.data._embedded.customer_data_collection[0],
        );
      }
    })
    .catch(() => toast.error('Impossibile recuperare il customer'))
    .finally(() => setAreCustomerDataCollectionLoading(false));
};
