import { Formik } from 'formik';
import { Input } from 'formik-semantic-ui-react';
import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Grid } from 'semantic-ui-react';
import { fetchUserData } from 'services/user/fetchUserData';
import {
  deleteUserRequest,
  updateUserRequest,
} from 'services/user/manageUserRequest';
import { FE_ROUTES } from 'utils/global/globalConstants';
import { UserDetailsConstants } from './reducer/action';

import { initialUserDetailsState, userDetailsReducer } from './reducer/reducer';
import DocumentsTable from 'components/DocumentsTable';
import { DocumentData } from 'pages/ContactDocuments/types';
import { getContactDocuments } from 'services/contactDocuments/getContactDocuments';

const UserDetails: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(
    userDetailsReducer,
    initialUserDetailsState,
  );

  useEffect(() => {
    fetchUserData(dispatch, userId, navigate);
    loadContactDocuments();
  }, []);

  const loadContactDocuments = () => {
    getContactDocuments(setDocumentsLoading, setDocumentCollection, userId);
  };

  const setDocumentsLoading = (areLoading: boolean) => {
    dispatch({
      type: UserDetailsConstants.SET_DOCUMENTS_LOADING,
      payload: { areDocumentsLoading: areLoading },
    });
  };

  const setDocumentCollection = (documentCollection: Array<DocumentData>) => {
    dispatch({
      type: UserDetailsConstants.SET_USER_DOCUMENTS,
      payload: { documentsCollection: documentCollection },
    });
  };

  const onActiveUserRequest = () => {
    if (!state.userDetails || state.userDetails.state >= 2) {
      return;
    }

    updateUserRequest(
      state.userDetails.state === 0 ? 1 : 2,
      userId,
      userRequestsRedirect,
    );
  };

  const onDeleteUserRequest = () => {
    deleteUserRequest(userId, userRequestsRedirect);
  };

  const userRequestsRedirect = () => {
    /**
     * The user's state suppose from which tab i'm arrived, for example if the state
     * is 1 probably i want come back to ToApprove requests so append the staate in the tab
     */
    let selectedTabRedirect = '';
    if (
      state.userDetails &&
      (state.userDetails.state === 1 || state.userDetails.state === 2)
    ) {
      selectedTabRedirect = state.userDetails.state.toString();
    }
    navigate(FE_ROUTES.NEW_USER_REQUESTS + '/' + selectedTabRedirect);
  };

  /**
   * Check if nationality is defined (country parama in supplier profile) and return the
   * description string for show it in the label
   * @returns String with nationality description
   */
  const getNationality = () => {
    if (!state.userDetails || !state.userDetails._embedded) {
      return '- - -';
    }

    return state.userDetails._embedded.country?.name || '- - -';
  };

  const getLanguage = () => {
    if (!state.userDetails) {
      return '';
    }

    if (state.userDetails.language === 'it') {
      return 'Italiano';
    }

    return 'English';
  };

  return (
    <>
      <Helmet>
        <body />
        <title>Dettaglio utente</title>
      </Helmet>
      <div className="user-details-header">
        <span className="user-complete-name">
          {state.userDetails ? state.userDetails.name : ''}
        </span>
        <span className="vertical-divider" />
        <span className="vat-number-text">
          {state.userDetails && (
            <>
              P.IVA
              <span className="user-vat-number">
                {state.userDetails.vatNumber}
              </span>
            </>
          )}
        </span>
      </div>
      <div className="user-details-body">
        <div className="user-details-main-content">
          <span className="form-title">Dati utente</span>
          <Formik
            initialValues={state.userDetails || {}}
            enableReinitialize
            onSubmit={() => {
              null;
            }}
          >
            <Form className="user-details-form">
              <Grid textAlign="center">
                <Grid.Row columns={2}>
                  <Grid.Column className="left-column">
                    <Input label="Ragione sociale *" readOnly name="name" />
                  </Grid.Column>
                  <Grid.Column className="right-column">
                    <Input readOnly label="Partita IVA *" name="vatNumber" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column className="left-column">
                    <Input
                      name="fiscalCode"
                      label="Codice fiscale *"
                      readOnly
                    />
                  </Grid.Column>
                  <Grid.Column className="right-column">
                    <Input name="emailAddress" label="E-mail *" readOnly />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column className="left-column">
                    <Input
                      name="phoneNumber"
                      label="Numero di telefono *"
                      readOnly
                    />
                  </Grid.Column>
                  <Grid.Column className="right-column">
                    <Input name="city" readOnly label="Città" />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column className="left-column">
                    <Input
                      label="Nazionalità *"
                      readOnly
                      value={getNationality()}
                    />
                  </Grid.Column>
                  <Grid.Column className="right-column">
                    <Input readOnly label="Lingua *" value={getLanguage()} />
                  </Grid.Column>
                </Grid.Row>
                <DocumentsTable
                  areDocumentsLoading={state.areDocumentsLoading}
                  contactCode={state.userDetails?.id}
                  disabledUpload
                  documentsCollection={state.documentsCollection}
                  reloadDocuments={loadContactDocuments}
                />
              </Grid>
            </Form>
          </Formik>
          {state.userDetails && state.userDetails.state !== 2 && (
            <Button
              className="deleteButton"
              content="Nega Richiesta"
              onClick={() => onDeleteUserRequest()}
            />
          )}
        </div>
        <div className="user-details-footer">
          <div className="footer-content">
            <Button
              className="underlineButton"
              content="Torna alle richieste"
              onClick={() => userRequestsRedirect()}
            />
            {state.userDetails && state.userDetails.state !== 2 && (
              <Button
                className="insertButton"
                content="Approva richiesta"
                onClick={() => onActiveUserRequest()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
