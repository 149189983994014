import { FormikHelpers } from 'formik';
import { Reservation } from 'pages/Reservation/types';
import { updateReservation } from 'services/reservation/postReservation';

export const toApprovePhaseSubmit = (
  values: Reservation,
  reservationId: string,
  setIsLoading: (isLoading: boolean) => void,
  setReservation: (reservation: Reservation) => void,
  formikHelpers: FormikHelpers<Reservation>,
): void => {
  const submitObject = {
    state: values.state,
    vehiclePlate: values.vehiclePlate,
    shippingAgent: values.shippingAgent,
  };

  updateReservation(
    submitObject,
    reservationId,
    setIsLoading,
    setReservation,
    formikHelpers,
  );
};
