import React from 'react';
import { Image } from 'semantic-ui-react';

// Constants - Utils
import { certificateList } from './certificatesList';

// Icons
import arrowIcon from 'assets/images/icons/arrow.svg';
import msmLogo from 'assets/images/topbar-logo.svg';
import { FormattedMessage } from 'react-intl';

const CertificatesCard: React.FC = () => {
  const handleLinkClick = (link: string) => {
    window.open(link);
  };

  return (
    <div className="certificates-card">
      <div className="resume-left-car">
        <div className="title-row">
          <Image src={msmLogo} />
        </div>
        <div className="subtitle-row">
          <div className="subtitle-text">
            <FormattedMessage
              id="certificates.title"
              defaultMessage="Certificazioni MSM"
            />
          </div>
          <div className="subtitle-link">
            <span
              className="underline-text"
              onClick={() =>
                handleLinkClick(
                  'https://www.metallurgicasanmarco.it/certificazioni/',
                )
              }
            >
              <FormattedMessage
                id="certificates.link"
                defaultMessage="Altre certificazioni"
              />
            </span>
            <span
              className="link-button"
              onClick={() =>
                handleLinkClick(
                  'https://www.metallurgicasanmarco.it/certificazioni/',
                )
              }
            >
              <Image src={arrowIcon} />
            </span>
          </div>
        </div>
      </div>
      <div className="list-certificates-card">
        {certificateList.map(certificate => (
          <div className="certificate-element" key={certificate.id}>
            <Image src={certificate.iconSrc} />
            <div
              className="certificate-link"
              onClick={() => handleLinkClick(certificate.downloadLink)}
            >
              Download
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CertificatesCard;
