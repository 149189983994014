import { DocumentData } from 'pages/ContactDocuments/types';
import { OriginDetails } from '../types';

export enum ContactOriginConstants {
  SET_IS_LOADGING = 'ContactOrigin/SET_IS_LOADGING',
  SET_CONTACT_ORIGIN = 'ContactOrigin/SET_CONTACT_ORIGIN',
  SET_DOCUMENTS = 'ContactOrigin/SET_DOCUMENTS',
  SET_IS_LOADGING_DOCUMENTS = 'ContactOrigin/SET_IS_LOADGING_DOCUMENTS',
}

export type ContactOriginAction =
  | {
      type: ContactOriginConstants.SET_IS_LOADGING;
      payload: { isLoading: boolean };
    }
  | {
      type: ContactOriginConstants.SET_CONTACT_ORIGIN;
      payload: { contactOrigin: OriginDetails };
    }
  | {
      type: ContactOriginConstants.SET_DOCUMENTS;
      payload: { documents: Array<DocumentData> };
    }
  | {
      type: ContactOriginConstants.SET_IS_LOADGING_DOCUMENTS;
      payload: { isLoadingDocuments: boolean };
    };
