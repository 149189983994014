import _ from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CompleteOrderTableColumns, CompleteOrderTableProps } from './types';
import SemanticTableGrid from 'semantic-table-grid';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { UserOrdersContext } from 'pages/UserOrders';
import { UserOrdersConstants } from 'pages/UserOrders/reducer/action';

export const CompleteOrderTable: React.FC<CompleteOrderTableProps> = ({
  orders,
}) => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const { state, dispatch } = useContext(UserOrdersContext);

  const columns: Array<CompleteOrderTableColumns> = [
    {
      key: 'orderNumber',
      sortable: true,
      name: (
        <div
          className="order-table-header sortable-header"
          onClick={() =>
            dispatch({
              type: UserOrdersConstants.CHANGE_ACTIVE_SORT,
              payload: {
                activeSort:
                  state.activeSort === 'order-number-asc'
                    ? 'order-number-desc'
                    : 'order-number-asc',
              },
            })
          }
        >
          <FormattedMessage
            id="order.table.header.orderNumber"
            defaultMessage="N. ordine"
          />
          {state.activeSort === 'order-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {state.activeSort === 'order-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-line-number">{data.orderNumber}</div>
      ),
    },
    {
      key: 'lineNumber',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.lineNumber"
            defaultMessage="N. Riga"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-line-number">{data.lineNo}</div>
      ),
    },
    {
      key: 'orderItem',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.articleDescription"
            defaultMessage="Descrizione articolo"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-item">
          {data.description}
          <br />
          {data.description2}
        </div>
      ),
    },
    {
      key: 'orderAmount',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.totalQuantity"
            defaultMessage="Quanità ordinata"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-amount">
          <div className="order-table-amount-value">
            {intlFormatNumber.format(_.toNumber(data.quantity))}{' '}
            {data.unitOfMeasure}
          </div>
        </div>
      ),
    },
    {
      key: 'price',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.price"
            defaultMessage="Prezzo"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-residual-quantity">
          <div className="order-table-residual-quantity-value">
            {data.directUnitCost !== '0'
              ? data.unitOfMeasure === 't'
                ? `${data.directUnitCost} €/t`
                : `${intlFormatNumber.format(
                    _.toNumber(data.directUnitCost) * 1000,
                  )},00 €/t`
              : data.priceType}
          </div>
        </div>
      ),
    },
    {
      key: 'orderDeliveredAmount',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.residualQuantity"
            defaultMessage="Quantità residua"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-residual-quantity">
          <div className="order-table-residual-quantity-value">
            {intlFormatNumber.format(_.toNumber(data.outstandingQuantity))}{' '}
            {data.unitOfMeasure}
          </div>
        </div>
      ),
    },
    {
      key: 'orderQuantityBooked',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.quantityBooked"
            defaultMessage="Quantità prenotata"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-residual-quantity">
          <div className="order-table-residual-quantity-value">
            {intlFormatNumber.format(_.toNumber(data.quantityOnBookings))}{' '}
            {data.unitOfMeasure}
          </div>
        </div>
      ),
    },
    {
      key: 'orderQuantityOnBookings',
      sortable: false,
      name: (
        <div className="order-table-header">
          <FormattedMessage
            id="order.table.header.quantityOnBookings"
            defaultMessage="Quantità prenotabile"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="order-table-residual-quantity">
          <div className="order-table-residual-quantity-value">
            {intlFormatNumber.format(
              _.toNumber(data.outstandingQuantity) -
                _.toNumber(data.quantityOnBookings),
            )}{' '}
            {data.unitOfMeasure}
          </div>
        </div>
      ),
    },
    {
      key: 'orderDeliveryDate',
      sortable: false,
      name: (
        <div
          className="order-table-header sortable-header"
          onClick={() =>
            dispatch({
              type: UserOrdersConstants.CHANGE_ACTIVE_SORT,
              payload: {
                activeSort:
                  state.activeSort === 'delivery-date-asc'
                    ? 'delivery-date-desc'
                    : 'delivery-date-asc',
              },
            })
          }
        >
          <FormattedMessage
            id="order.table.header.deliveryDate"
            defaultMessage="Consegna entro il"
          />
          {state.activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {state.activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div
          className={`order-table-delivery-date ${
            moment(data.plannedReceiptDate, 'YYYY-MM-DD').isBefore()
              ? 'expired-delivery-date'
              : null
          }`}
        >
          {moment(data.plannedReceiptDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
  ];

  return (
    <div className="completeOrderTable-contianer">
      <SemanticTableGrid
        className="order-table"
        columns={columns}
        elements={orders}
        emptyResults={intl.formatMessage({
          id: 'completeOrderTable.table.empty',
          defaultMessage: 'Nessun Elemento disponibile',
        })}
      />
    </div>
  );
};

export default CompleteOrderTable;
