import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Image, Segment } from 'semantic-ui-react';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({
  customActionOnClick,
  iconSrc,
  rightIcon,
  title,
  subTitle,
  link,
  linkTo = '',
  areDataLoading = false,
}) => {
  return (
    <Segment className="card">
      <Header style={{ backgroundColor: 'transparent' }} as="h2" image>
        <Image src={iconSrc} size="mini" />
        <Header.Content>
          {areDataLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            title
          )}
        </Header.Content>
        <Image
          as={Link}
          to={linkTo}
          inline={true}
          floated="right"
          src={rightIcon}
          size="big"
        />
      </Header>
      <h3>{subTitle}</h3>
      <Button
        as={Link}
        to={linkTo}
        style={{ textAlign: 'left', paddingLeft: '0rem' }}
        className="underlineButton"
        onClick={() => customActionOnClick || null}
      >
        {link}
      </Button>
    </Segment>
  );
};

export default Card;
