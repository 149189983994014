import { UserRequestsState } from '../types';
import { UserRequestsAction, UserRequestsConstants } from './action';

export const initialUserRequests: UserRequestsState = {
  isLoading: false,
  activeTab: 0,
  activaPage: 1,
  totalPages: 1,
  elements: [],
};

export const userRequestsReducer = (
  state: UserRequestsState,
  action: UserRequestsAction,
): UserRequestsState => {
  const { type } = action;

  switch (type) {
    case UserRequestsConstants.UPDATE_ACTIVE_TAB:
      return { ...state, activeTab: action.payload.activeTab, activaPage: 1 };
    case UserRequestsConstants.UPDATE_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case UserRequestsConstants.UPDATE_ELEMENTS:
      return { ...state, elements: action.payload.elements };
    case UserRequestsConstants.UPDATE_ACTIVE_PAGE:
      return { ...state, activaPage: action.payload.activePage };
    case UserRequestsConstants.UPDATE_TOTAL_PAGE:
      return { ...state, totalPages: action.payload.totalPages };
    default:
      return state;
  }
};
