import React from 'react';
import { Route } from 'react-router-dom';

// Components and Layout
import CheckAuth from 'components/CheckAuth';
import StandardLayout from 'layout/StandardLayout';

// Constants
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';

// Pages
import Calendar from 'pages/Calendar';
import ContactDocuments from 'pages/ContactDocuments';
import ContactOriginDetail from 'pages/ContactOriginDetail';
import ContactOriginsList from 'pages/ContactOriginsList';
import Dashboard from 'pages/Dashboard';
import DocumentViewer from 'pages/DocumentViewer';
import Reservation from 'pages/Reservation';
import Reservations from 'pages/ReservationList';
import UserDetails from 'pages/UserDetails';
import UserOrders from 'pages/UserOrders';
import UserRequests from 'pages/UserRequests';
import Myself from 'pages/Myself';
import DataCollection from 'pages/DataCollection';
import CustomerDocuments from 'pages/CustomerDocuments';
import AdminContactLogin from 'pages/AdminContactLogin';
import CustomerReservation from 'pages/CustomerReservation';
import MetalResume from 'pages/MetalResume';
import MetalAvailability from 'pages/CustomerMetalAvailability';
import CustomerReservationList from 'pages/CustomerReservationList';
import CustomerAgentDocuments from 'pages/CustomerAgentDocuments';
import CustomerCreditsLimit from 'pages/CustomerCreditsLimit';

// Export all the routes with the standard layout (Topbar and Sidebar)
const standardLayoutRoutes = (): JSX.Element => (
  <Route element={<StandardLayout />}>
    <Route
      index
      element={
        <CheckAuth whoCanView="ANY_AUTH">
          <Dashboard />
        </CheckAuth>
      }
    />
    <Route path={FE_ROUTES.NEW_USER_REQUESTS}>
      <Route
        path=""
        element={
          <CheckAuth whoCanView={[ROLES.ADMIN]}>
            <UserRequests />
          </CheckAuth>
        }
      />
      <Route
        path=":activeTab"
        element={
          <CheckAuth whoCanView={[ROLES.ADMIN]}>
            <UserRequests />
          </CheckAuth>
        }
      />
    </Route>
    <Route
      path={FE_ROUTES.CALENDAR}
      element={
        <CheckAuth whoCanView={[ROLES.ADMIN]}>
          <Calendar />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CALENDAR + '/:selectedDate'}
      element={
        <CheckAuth whoCanView={[ROLES.ADMIN]}>
          <Calendar />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.ADMIN_CONTACT_LOGIN}
      element={
        <CheckAuth whoCanView={[ROLES.ADMIN]}>
          <AdminContactLogin />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.DASHBOARD}
      element={
        <CheckAuth whoCanView="ANY_AUTH">
          <Dashboard />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.DOCUMENTS}
      element={
        <CheckAuth
          whoCanView={[ROLES.NOVICE_CONTACT, ROLES.SUPPLIER, ROLES.CUSTOMER]}
        >
          <ContactDocuments />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS + '/:tab'}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER_AGENT]}>
          <CustomerAgentDocuments />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.AGENT_CUSTOMER_METAL_RESUME}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER_AGENT]}>
          <MetalResume />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.AGENT_CUSTOMER_METAL_AVAILABILITY}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER_AGENT]}>
          <MetalAvailability />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.AGENT_CUSTOMER_CREDITS_LIMIT}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER_AGENT]}>
          <CustomerCreditsLimit />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CUSTOMER_DOCUMENTS + '/:tab'}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER]}>
          <CustomerDocuments />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CUSTOMER_METAL_RESUME}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER]}>
          <MetalResume />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CUSTOMER_METAL_AVAILABILITY}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER]}>
          <MetalAvailability />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CUSTOMER_RESERVATION_LIST + '/:tab'}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER, ROLES.ADMIN]}>
          <CustomerReservationList />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CUSTOMER_RESERVATION + '/:reservationId'}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER]}>
          <CustomerReservation />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.CUSTOMER_RESERVATION + '/:reservationId' + '/:mode'}
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER, ROLES.ADMIN]}>
          <CustomerReservation />
        </CheckAuth>
      }
    />
    <Route
      path={
        FE_ROUTES.CUSTOMER_RESERVATION + '/:reservationId' + '/:mode' + '/:from'
      }
      element={
        <CheckAuth whoCanView={[ROLES.CUSTOMER, ROLES.ADMIN]}>
          <CustomerReservation />
        </CheckAuth>
      }
    />
    <Route
      path={
        FE_ROUTES.DOCUMENT + '/:documentId' + '/:attachmentId' + '/:contactId'
      }
      element={
        <CheckAuth
          whoCanView={[
            ROLES.CUSTOMER,
            ROLES.CONTACT,
            ROLES.ADMIN,
            ROLES.SUPPLIER,
          ]}
        >
          <DocumentViewer />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.USER_DETAILS}
      element={
        <CheckAuth whoCanView={[ROLES.ADMIN]}>
          <UserDetails />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.ORDERS}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER]}>
          <UserOrders />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.RESERVATION_LIST + '/:tab'}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER, ROLES.ADMIN]}>
          <Reservations />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.RESERVATION_DETAILS + '/:reservationId'}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER]}>
          <Reservation />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.RESERVATION_DETAILS + '/:reservationId' + '/:mode'}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER, ROLES.ADMIN]}>
          <Reservation />
        </CheckAuth>
      }
    />
    <Route
      path={
        FE_ROUTES.RESERVATION_DETAILS + '/:reservationId' + '/:mode' + '/:from'
      }
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER, ROLES.ADMIN]}>
          <Reservation />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.ORIGINS_CONTACT}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER, ROLES.CUSTOMER]}>
          <ContactOriginsList />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.ORIGINS_CONTACT + '/:originId'}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER, ROLES.CUSTOMER]}>
          <ContactOriginDetail />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.ORIGINS_CONTACT + '/:originId' + '/:mode'}
      element={
        <CheckAuth whoCanView={[ROLES.SUPPLIER, ROLES.CUSTOMER]}>
          <ContactOriginDetail />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.MYSELF}
      element={
        <CheckAuth
          whoCanView={[
            ROLES.CONTACT,
            ROLES.CUSTOMER,
            ROLES.AGENT,
            ROLES.SUPPLIER,
          ]}
        >
          <Myself />
        </CheckAuth>
      }
    />
    <Route
      path={FE_ROUTES.DATA_COLLECTION}
      element={
        <CheckAuth whoCanView={[]}>
          {/*<CheckAuth whoCanView={[ROLES.CUSTOMER]}></CheckAuth>*/}
          <DataCollection />
        </CheckAuth>
      }
    />
  </Route>
);

export default standardLayoutRoutes;
