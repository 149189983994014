import { DocumentViewerState } from '../types';
import { DocumentViewerAction, DocumentViewerConstants } from './action';

export const initialDocumentViewerState: DocumentViewerState = {
  areDocumentDataLoading: false,
  documentData: null,
  documentFile: null,
  documentFileCurrentPage: 1,
  documentFilePages: 1,
  documentFileState: 'loading',
  supplier: null,
};

export const documentViewerReducer = (
  state: DocumentViewerState,
  action: DocumentViewerAction,
): DocumentViewerState => {
  const { type } = action;

  switch (type) {
    case DocumentViewerConstants.SET_DOCUMENT_DATA:
      return { ...state, documentData: action.payload.documentData };
    case DocumentViewerConstants.SET_DOCUMENT_DATA_LOADING:
      return {
        ...state,
        areDocumentDataLoading: action.payload.areDataLoading,
      };
    case DocumentViewerConstants.SET_DOCUMENT_FILE:
      return { ...state, documentFile: action.payload.documentFile };
    case DocumentViewerConstants.SET_DOCUMENT_FILE_CURRENT_PAGE: {
      let currentPage = state.documentFileCurrentPage;

      if (
        action.payload.currentPage === 'next' &&
        currentPage < state.documentFilePages
      ) {
        currentPage = currentPage + 1;
      } else if (action.payload.currentPage === 'previous' && currentPage > 1) {
        currentPage = currentPage - 1;
      }

      return { ...state, documentFileCurrentPage: currentPage };
    }
    case DocumentViewerConstants.SET_DOCUMENT_FILE_PAGES:
      return {
        ...state,
        documentFilePages: action.payload.documentFilePages,
        documentFileState: 'loaded',
      };
    case DocumentViewerConstants.SET_DOCUMENT_FILE_STATE:
      return { ...state, documentFileState: action.payload.documentFileState };
    case DocumentViewerConstants.SET_SUPPLIER:
      return { ...state, supplier: action.payload.supplier };
    default:
      return state;
  }
};
