import { AppContext } from 'pages/App';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Popup, Icon } from 'semantic-ui-react';
import { isThatRole } from 'utils/function/acl';
import { ROLES } from 'utils/global/globalConstants';

const TooltipEmail: React.FC = () => {
  const intl = useIntl();
  const { state: AppState } = useContext(AppContext);

  return (
    <Popup
      className="tooltip-password"
      content={
        isThatRole(ROLES.SUPPLIER, AppState) ? (
          <span className="tooltip-password-content">
            {intl.formatMessage({
              id: 'myself.tooltip.generalDescription',
              defaultMessage:
                'Alla mail riportata arriveranno tutte le comunicazioni del portale, ad esempio',
            })}
            <br />
            <li className="tooltip-item">
              {intl.formatMessage({
                id: 'myself.tooltip.expiringDocuments',
                defaultMessage: 'Notifica quando un documento è in scadenza',
              })}
            </li>
            <li className="tooltip-item">
              {intl.formatMessage({
                id: 'myself.tooltip.approvedReservation',
                defaultMessage: 'Notifica di accettazione di una prenotazione',
              })}
            </li>
            <li className="tooltip-item">
              {intl.formatMessage({
                id: 'myself.tooltip.origin',
                defaultMessage:
                  'Notifica di accettazione di una provenienza inserita',
              })}
            </li>
            <li className="tooltip-item">
              {intl.formatMessage({
                id: 'myself.tooltip.passwordRecovery',
                defaultMessage: 'Recupero o aggiornamento della password',
              })}
            </li>
          </span>
        ) : (
          <span className="tooltip-password-content">
            <br />
            {intl.formatMessage({
              id: 'myself.tooltip.customer.description.secondLine',
              defaultMessage:
                'Per modificare eventualmente la prima mail di notifica contattare direttamente Metallurcia San Marco',
            })}
          </span>
        )
      }
      basic
      hoverable
      trigger={
        <Icon className="tooltip-password-icon" name="question circle" />
      }
    />
  );
};

export default TooltipEmail;
