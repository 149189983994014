import QueryString from 'qs';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { DocumentData } from 'pages/ContactDocuments/types';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { GetContactDocumentsResponse } from './types';
import { IntlShape } from 'react-intl';
import { FetchDocumentActionTypes } from 'utils/global/documentConstants';

export const getContactDocuments = (
  setIsLoading: (isLoading: boolean) => void,
  setDocuments: (documents: Array<DocumentData>) => void,
  contactId?: string | null,
  contactCode?: string | null,
  originCode?: string | null,
  profileType?: string | null,
): void => {
  setIsLoading(true);

  standardClient
    .request({
      url: API.CONTACT_DOCUMENT,
      method: 'GET',
      params: {
        contactCode,
        contactId,
        originCode,
        profileType,
      },
      paramsSerializer: params => QueryString.stringify(params),
    })
    .then((response: AxiosResponse<GetContactDocumentsResponse>) => {
      setDocuments(response.data._embedded.contact_document);
    })
    .finally(() => setIsLoading(false));
};

export const getDocumentFile = (
  intl: IntlShape,
  setIsLoading: (isLoading: boolean) => void,
  documentId: string,
  attachmentId?: string | null,
): void => {
  setIsLoading(true);

  standardClient
    .request({
      url: `${API.CONTACT_DOCUMENT}/${documentId}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.msm\\media' },
      params: {
        actionType: FetchDocumentActionTypes.GET_MEDIA,
        attachmentId: attachmentId,
      },
      paramsSerializer: params => QueryString.stringify(params),
    })
    .then((response: AxiosResponse) => {
      // Get the name from content-dispositon
      // Ex. string => content-dispositon: attachment;filename=sample.pdf
      const type = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];
      const fileNameString = contentDisposition.split('filename=')[1];

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameString || 'file.pdf');
      document.body.appendChild(link);
      link.click();
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.getContactDocuments.getDocument.error',
          defaultMessage: 'Impossibile scaricare il documento',
        }),
      ),
    )
    .finally(() => setIsLoading(false));
};

export const uploadDocumentFile = (
  formData: FormData,
  reloadFile: () => void,
  intl: IntlShape,
): void => {
  const notifyStatus = toast.loading(
    intl.formatMessage({
      id: 'service.getContactDocuments.upload.loading',
      defaultMessage: 'Upload del file in corso...',
    }),
    {
      closeOnClick: true,
    },
  );

  standardClient
    .request({
      url: `${API.CONTACT_DOCUMENT}`,
      method: 'POST',
      data: formData,
      paramsSerializer: params => QueryString.stringify(params),
    })
    .then(() => {
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.getContactDocuments.upload.succesfully',
          defaultMessage: 'File caricato correttamente',
        }),
        type: 'success',
      });

      reloadFile();
    })
    .catch(() =>
      // Show error message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.getContactDocuments.upload.error',
          defaultMessage: 'Impossibile caricare il file',
        }),
        type: 'error',
      }),
    );
};

export const getDocumentForm = (
  documentType: string,
  intl: IntlShape,
): void => {
  standardClient
    .request({
      url: `${API.CONTACT_DOCUMENT}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.msm\\media' },
      params: {
        actionType: FetchDocumentActionTypes.GET_FORM,
        documentForm: documentType,
      },
      paramsSerializer: params => QueryString.stringify(params),
    })
    .then((response: AxiosResponse) => {
      // Get the name from content-dispositon
      // Ex. string => content-dispositon: attachment;filename=sample.pdf
      const type = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];
      const fileNameString = contentDisposition.split('filename=')[1];

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameString || 'file.pdf');
      document.body.appendChild(link);
      link.click();
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.getContactDocuments.form.error',
          defaultMessage: 'Impossibile scaricare il form',
        }),
      ),
    );
};
