import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerGeneralShipmentTransformation } from 'services/customer/getCustomerDocuments';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS, ROLES } from 'utils/global/globalConstants';
import _ from 'lodash';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import {
  ShipmentTransformation,
  ShipmentTransformationSupportedSort,
  ShipmentTransformationTableColumns,
} from './types';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';
import { Popup } from 'semantic-ui-react';
import { getCustomerDocumentFromEdok } from 'services/document/getCustomerDocuments';

const CustomerShipmentTransformation: React.FC = () => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const [
    shipmentTransformationCollection,
    setShipmentTransformationCollection,
  ] = useState<Array<ShipmentTransformation>>([]);
  const [
    showedShipmentTransformationCollection,
    setShowedShipmentTransformationCollection,
  ] = useState<Array<ShipmentTransformation>>([]);
  const [
    areShipmentTransformationLoading,
    setAreShipmentTransformationLoading,
  ] = useState(false);

  const [activeSort, setActiveSort] =
    useState<ShipmentTransformationSupportedSort>('delivery-date-desc');

  useEffect(() => {
    if (!isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      getCustomerGeneralShipmentTransformation(
        setCompleteShipmentTransformationCollection,
        setAreShipmentTransformationLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callShipmentsTransformationForAgent();
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callShipmentsTransformationForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerGeneralShipmentTransformation(
        setCompleteShipmentTransformationCollection,
        setAreShipmentTransformationLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteShipmentTransformationCollection = (
    completeShipmentTransformationCollection: Array<ShipmentTransformation>,
  ) => {
    setShipmentTransformationCollection(
      completeShipmentTransformationCollection,
    );
    const filteredByYearCollection =
      completeShipmentTransformationCollection.filter(shipment =>
        moment(shipment.postingDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
      );
    setShowedShipmentTransformationCollection(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let documentsToShow = shipmentTransformationCollection;

    if (!_.isEmpty(documentNumber)) {
      documentsToShow = documentsToShow.filter(
        document =>
          _.isString(document.yourShipmentNo) &&
          document.yourShipmentNo
            .toLowerCase()
            .includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(
          singleDocument.postingDate,
        ),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(singleDocument.postingDate),
      );
    }

    setShowedShipmentTransformationCollection(documentsToShow);
  };

  const onChangeSort = (sortType: ShipmentTransformationSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedShipmentTransformationCollection(
      onShortShipmentCollection(
        sortType,
        showedShipmentTransformationCollection,
      ),
    );
    setActiveSort(sortType);
  };

  const onShortShipmentCollection = (
    sortType: ShipmentTransformationSupportedSort,
    shipmentCollection: Array<ShipmentTransformation>,
  ): Array<ShipmentTransformation> => {
    let toSortShipmenstDocuments = shipmentCollection;

    switch (sortType) {
      case 'shipment-number-desc': {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) => {
            if (firsthShipment.yourShipmentNo > secondShipment.yourShipmentNo) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) =>
            moment(firsthShipment.postingDate, 'YYYY-MM-DD').diff(
              moment(secondShipment.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) =>
            moment(secondShipment.postingDate, 'YYYY-MM-DD').diff(
              moment(firsthShipment.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        toSortShipmenstDocuments = toSortShipmenstDocuments.sort(
          (firsthShipment, secondShipment) => {
            if (firsthShipment.yourShipmentNo > secondShipment.yourShipmentNo) {
              return 1;
            }
            return -1;
          },
        );
      }
    }

    return toSortShipmenstDocuments;
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<ShipmentTransformation> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return shipmentTransformationCollection.filter(shipment =>
        moment(shipment.postingDate, 'YYYY-MM-DD').isSame(selectedYear, 'year'),
      );
    }
    return shipmentTransformationCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedShipmentTransformationCollection(
      getFilteredByYearsCollection(selectedYear),
    );
    onChangeSort('delivery-date-desc');
  };

  const columns: Array<ShipmentTransformationTableColumns> = [
    {
      key: 'generalShipmentNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'shipment-number-asc'
                ? 'shipment-number-desc'
                : 'shipment-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.shipmentsTransformation.table.header.generalShipmentNumber"
            defaultMessage="N. DDT"
          />
          {activeSort === 'shipment-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'shipment-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">
          {data.yourShipmentNo}
        </div>
      ),
    },
    {
      key: 'generalShipmentDate',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.shipmentsTransformation.table.header.generalShipmentDate"
            defaultMessage="Data"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.postingDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'generalShipmentCtDescription',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipmentsTransformation.table.header.generalShipmentCtDescription"
            defaultMessage="Descrizione"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {data.description.split('\n').map((string, index) => (
            <>
              {index != 0 ? <br /> : ''}
              {string}
            </>
          ))}
        </div>
      ),
    },
    {
      key: 'generalShipmentCtQuantity',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipmentsTransformation.table.header.generalShipmentCtQuantity"
            defaultMessage="Quantità totale (KG)"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {intlFormatNumber.format(_.toNumber(data.totalQuantity)) + ' KG'}
        </div>
      ),
    },
    {
      key: 'generalShipmentCtQuantity',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipmentsTransformation.table.header.originName"
            defaultMessage="Provenienza"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {data.originName}
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 0,
      name: '',
      formatter: ({ data }) =>
        moment(data.postingDate, 'YYYY-MM-DD').isSameOrAfter(
          moment('2024-01-01').format('DD-MM-YYYY'),
        ) ? (
          <div className="customer-documents-table-actions">
            <Popup
              className="tooltip-password"
              content={
                <span className="tooltip-password-content">
                  <FormattedMessage
                    id="customerShipmentsDocuments.shipments.table.documents.tooltip.download"
                    defaultMessage="Scarica documento"
                  />
                </span>
              }
              basic
              hoverable
              trigger={
                <span
                  className="actionMenu-button margin-right"
                  onClick={() =>
                    getCustomerDocumentFromEdok(
                      data.edokVolumeId,
                      data.edokDocumentId,
                      intl,
                      selectedContact?.name,
                    )
                  }
                >
                  <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
                </span>
              }
            />
          </div>
        ) : null,
    },
    /* DISABLE AS REQUIRED BY THE CUSTOMER 
        {
      key: 'generalShipmentShipToAddress',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.shipmentsTransformation.table.header.generalShipmentShipToAddress"
            defaultMessage="Tipo spedizione"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-payment">
          <span className="payment-method">{data.shipmentType}</span>
        </div>
      ),
    }, */
  ];

  return (
    <>
      <CustomerDocumentsTab
        activeSort={activeSort}
        areDocumentsLoading={areShipmentTransformationLoading}
        completeDocumentsCollection={showedShipmentTransformationCollection}
        filterInputPlaceholder={intl.formatMessage({
          id: 'customerDocuments.shipmentsTransformation.filterBar.input.placheolder',
          defaultMessage: 'N. DDT',
        })}
        onChangeSelectedYear={onChangeSelectedYear}
        onFilter={onFilter}
        tableColumns={columns}
        key="customer-credits-notes"
      />
    </>
  );
};

export default CustomerShipmentTransformation;
