import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { Form, Select } from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, FieldArray, Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';

// Components
import SvgIcon from 'components/SvgIcon';

// Constants - Internals - Utils
import { baseInformationOnSubmit } from './onSubmit';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import {
  EnvironmentalClass,
  ReservationStateConstants,
} from 'utils/global/reservationConstants';
import { ReservationContext } from 'pages/Reservation';
import { ReservationConstants } from 'pages/Reservation/reducer/action';

// Services
import { fetchFilteredCerListOptions } from 'services/reservation/fetchCerList';

// Types
import { Reservation } from 'pages/Reservation/types';
import NumberFormatterInput from 'components/NumberFormatterInput';
import { mapUnitOfMeasure } from './mapUnitOfMeasure';
import { fetchOriginsListOptions } from 'services/reservation/fetchOriginsList';
import { fetchFilteredEnvironmentalClassesListOptions } from 'services/reservation/fetchEnvironmentalClassesList';

const BaseInformationPhase: React.FC<{ readOnly?: boolean }> = ({
  readOnly = false,
}) => {
  const intl = useIntl();
  const { state, dispatch } = useContext(ReservationContext);

  const [areCerCodeLoading, setAreCerCodeLoading] = useState(false);
  const [originsList, setOriginsList] = useState<Array<DropdownItemProps>>([]);
  const [cerCodeCollection, setCerCodeCollection] = useState<
    Array<Array<DropdownItemProps>>
  >([]);
  const [
    areEnvironmentalClassesCodeLoading,
    setAreEnvironmentalClassesCodeLoading,
  ] = useState(false);
  const [
    environmentalClassesCodeCollection,
    setEnvironmentalClassesCodeCollection,
  ] = useState<Array<Array<DropdownItemProps>>>([]);

  const { reservationId } = useParams();

  // On component mount load the options for the select of CER code, this will be showed
  // only if the environmental class is "rifiuto"
  useEffect(() => {
    fetchOriginsListOptions(
      state.reservation._embedded.contact.contactCode,
      setOriginsList,
      () => null,
      intl,
    );
  }, []);

  const setReservation = (reservation: Reservation) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION,
      payload: { reservation: reservation },
    });
  };

  const setIsLoading = (isLoading: boolean) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION_LOADING,
      payload: { isReservationLoading: isLoading },
    });
  };

  const onOriginChange = (
    originCode: string | number | boolean | (string | number | boolean)[],
    values: Reservation,
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    setCerCodeCollection([]);
    setEnvironmentalClassesCodeCollection([]);

    if (values.reservationLines.length > 0) {
      values.reservationLines.map((_reservation, index) =>
        setFieldValue(`reservationLines.${index}.cerCode`, ''),
      );
    }

    if (!_.isEmpty(originCode)) {
      fetchFilteredEnvironmentalClassesListOptions(
        setEnvironmentalClassesCodeCollection,
        setAreEnvironmentalClassesCodeLoading,
        originCode,
        state.reservation.bookingNumber,
      );
      fetchFilteredCerListOptions(
        setCerCodeCollection,
        setAreCerCodeLoading,
        originCode,
        state.reservation.bookingNumber,
      );
    }
  };

  const initialValues = useMemo<Reservation>(() => {
    if (state.reservation) {
      if (!_.isEmpty(state.reservation.originCode)) {
        onOriginChange(
          state.reservation.originCode,
          state.reservation,
          () => null,
        );
      }
      return state.reservation;
    }

    return {
      id: '',
      state: ReservationStateConstants.NEW,
      originCode: null,
      deliveryDate: null,
      timeSlot: null,
      vehiclePlate: '',
      reservationLines: [],
      bookingNumber: '',
      shippingAgent: '',
      documentsCount: 0,
    };
  }, [state.reservation]);

  return (
    <div className="base-information">
      <div className="reservation-header">
        <span className="reservation-header-title">
          <FormattedMessage
            id="baseInformation.title"
            defaultMessage="Informazioni sulla spedizione"
            description="Title message in the phase 2 of the reservation flow"
          />
        </span>
        <span className="reservation-header-subtitle">
          <FormattedMessage
            id="baseInformation.subtitle"
            defaultMessage="Compilare tutti i campi richiesti"
            description="Subtitle message in the phase 2 of the reservation flow, under the title"
          />
        </span>
      </div>

      <div className="base-information-content">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(
            values: Reservation,
            formikHelpers: FormikHelpers<Reservation>,
          ) => {
            baseInformationOnSubmit(
              values,
              reservationId,
              setIsLoading,
              setReservation,
              formikHelpers,
            );
          }}
          validationSchema={Yup.object().shape({
            originCode: Yup.string()
              .nullable()
              .required(
                intl.formatMessage({
                  id: 'baseInformation.error.originCode.required',
                  defaultMessage: 'Devi selezionare una provenienza',
                }),
              ),
            reservationLines: Yup.array().of(
              Yup.object().shape({
                transportedQuantity: Yup.number()
                  .nullable()
                  .required(
                    intl.formatMessage({
                      id: 'baseInformation.error.transportedQuantity.required',
                      defaultMessage: 'Devi inserire una quantita',
                    }),
                  )
                  .min(0),
                environmentalClass: Yup.string()
                  .nullable()
                  .required(
                    intl.formatMessage({
                      id: 'baseInformation.error.environmentalClass.required',
                      defaultMessage: 'Devi selezionare una classe ambientale',
                    }),
                  ),
                cerCode: Yup.string()
                  .nullable()
                  .when('environmentalClass', {
                    is: (value: string) =>
                      value && value === EnvironmentalClass.RIFIUTO,
                    then: Yup.string()
                      .nullable()
                      .required(
                        intl.formatMessage({
                          id: 'baseInformation.error.cerCode.required',
                          defaultMessage:
                            'Quando la classe ambientale è rifiuto bisogna selezionare un codice CER',
                        }),
                      ),
                  }),
              }),
            ),
          })}
        >
          {({ values, setFieldValue, setFieldTouched }) => (
            <Form id={state.reservation?.state}>
              <div className="reservation-fields-row">
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.USER}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Select
                      name="originCode"
                      placeholder={intl.formatMessage({
                        id: 'baseInformation.placeholder.originBusinessName',
                        defaultMessage: 'Selezionare provenienza',
                      })}
                      label={intl.formatMessage({
                        id: 'baseInformation.label.originBusinessName',
                        defaultMessage: 'Ragione sociale provenienza *',
                      })}
                      options={originsList}
                      disabled={readOnly || _.isEmpty(originsList)}
                      search
                      noResultsMessage={intl.formatMessage({
                        id: 'baseInformation.form.origin.noResultSelect',
                        defaultMessage: 'Nessuna origine trovata',
                      })}
                      onChange={(_e, data) => {
                        onOriginChange(data.value, values, setFieldValue);
                        setFieldTouched('reservationLines', false);
                      }}
                    />
                    <ErrorMessage
                      name="originCode"
                      render={message => (
                        <span className="default-error-message">{message}</span>
                      )}
                    />
                  </div>
                </div>
              </div>
              {values.reservationLines && values.reservationLines.length > 0 && (
                <FieldArray
                  name="reservationLines"
                  render={() =>
                    values.reservationLines.map((line, index) => (
                      <>
                        <div className="reservation-form-divider" />
                        <div className="reservation-fields-row">
                          <div className="fields-with-icon">
                            <SvgIcon
                              icon={ICONS.WEIGHT}
                              color={COLORS.SECONDARY}
                              height={32}
                              width={32}
                            />
                            <div className="fields-content">
                              <NumberFormatterInput
                                field={{
                                  name: `reservationLines.${index}.transportedQuantity`,
                                  value: _.toString(line.transportedQuantity),
                                }}
                                placeholder={intl.formatMessage({
                                  id: 'baseInformation.placeholder.transportedQuantity',
                                  defaultMessage: 'Inserire il valore',
                                })}
                                label={intl.formatMessage({
                                  id: 'baseInformation.label.transportedQuantity',
                                  defaultMessage:
                                    'Peso del materiale trasportato *',
                                })}
                                prefix={mapUnitOfMeasure(
                                  line.unitOfMeasure,
                                  intl,
                                )}
                                setFieldValue={setFieldValue}
                                readOnly={readOnly}
                              />
                              <span className="max-quantity">
                                <FormattedMessage
                                  id="baseInformation.maxQuantity"
                                  defaultMessage="La quantità massima prenotabile per questa riga d'ordine è di {orderNumber} {unitOfMeasure}"
                                  values={{
                                    orderNumber: line.outstandingQuantity,
                                    unitOfMeasure: line.unitOfMeasure,
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="reservation-description-field">
                            {line.orderDescription}
                            <br />
                            {line.secondaryDescription}
                          </div>
                        </div>
                        <div className="reservation-fields-row">
                          <div className="fields-with-icon">
                            <SvgIcon
                              icon={ICONS.RECYCLE}
                              color={COLORS.SECONDARY}
                              height={32}
                              width={32}
                            />
                            <div className="fields-content">
                              <Select
                                name={`reservationLines.${index}.environmentalClass`}
                                label={intl.formatMessage({
                                  id: 'baseInformation.label.environmentalClass',
                                  defaultMessage: 'Classe ambientale *',
                                })}
                                placeholder={intl.formatMessage({
                                  defaultMessage:
                                    'Selezionare la classe ambientale',
                                  description:
                                    'Placeholder message showed when none options is selected',
                                  id: 'baseInformation.placeholder.environmentalClass',
                                })}
                                options={
                                  environmentalClassesCodeCollection[
                                    line.orderNavNumber
                                  ] !== undefined
                                    ? environmentalClassesCodeCollection[
                                        line.orderNavNumber
                                      ]
                                    : []
                                }
                                search
                                noResultsMessage={intl.formatMessage({
                                  id: 'baseInformation.environmentalClass.noResult',
                                  defaultMessage:
                                    'Nessuna classe ambientale disponibile, verificare i documenti di fornitore e provenienza',
                                })}
                                disabled={readOnly}
                                onChange={(_e, { value }) => {
                                  setFieldValue(
                                    `reservationLines.${index}.cerCode`,
                                    '',
                                  );
                                  setFieldValue(
                                    `reservationLines.${index}.environmentalClass`,
                                    value,
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`reservationLines.${index}.environmentalClass`}
                                render={message => (
                                  <span className="default-error-message">
                                    {message}
                                  </span>
                                )}
                              />
                            </div>
                          </div>
                          {line.environmentalClass ===
                            EnvironmentalClass.RIFIUTO && (
                            <div className="fields-with-icon">
                              <SvgIcon
                                icon={ICONS.SHIELD}
                                color={COLORS.SECONDARY}
                                height={32}
                                width={32}
                              />
                              <div className="fields-content">
                                <Select
                                  name={`reservationLines.${index}.cerCode`}
                                  label={intl.formatMessage({
                                    id: 'baseInformation.label.cerCode',
                                    defaultMessage: 'Codice CER *',
                                  })}
                                  placeholder={intl.formatMessage({
                                    defaultMessage:
                                      'Selezionare il codice di certificazione',
                                    description:
                                      'Placeholder message showed when none options is selected',
                                    id: 'baseInformation.placeholder.cerCode',
                                  })}
                                  options={
                                    cerCodeCollection[line.orderNavNumber] !==
                                    undefined
                                      ? cerCodeCollection[line.orderNavNumber]
                                      : []
                                  }
                                  search
                                  noResultsMessage={intl.formatMessage({
                                    id: 'baseInformation.cerCode.noResult',
                                    defaultMessage:
                                      'La provenienza selezionata non ha codici CER abilitati a questo articolo',
                                  })}
                                  loading={
                                    areCerCodeLoading ||
                                    areEnvironmentalClassesCodeLoading
                                  }
                                  disabled={readOnly}
                                />
                                <ErrorMessage
                                  name={`reservationLines.${index}.cerCode`}
                                  render={message => (
                                    <span className="default-error-message">
                                      {message}
                                    </span>
                                  )}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))
                  }
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BaseInformationPhase;
