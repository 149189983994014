import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const deleteReservation = (
  reservationId: string,
  setLoading: (reservationDeleting: string | null) => void,
  reloadReservations: () => void,
  intl: IntlShape,
): void => {
  setLoading(reservationId);

  standardClient({
    url: API.RESERVATION + '/' + reservationId,
    method: 'DELETE',
  })
    .then(() => {
      toast.success(
        intl.formatMessage({
          id: 'service.deleteReservation.deleted',
          defaultMessage: 'Prenotazione eliminata correttamente',
        }),
      );
      reloadReservations();
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.deleteReservation.error',
          defaultMessage: 'Impossibile eliminare la prenotazione',
        }),
      ),
    )
    .finally(() => setLoading(null));
};
