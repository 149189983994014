import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const checklist = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5 1H9.414C9.208 0.418 8.652 0 8 0C7.348 0 6.792 0.418 6.585 1H5.5C5.224 1 5 1.224 5 1.5V3.5C5 3.776 5.224 4 5.5 4H10.5C10.776 4 11 3.776 11 3.5V1.5C11 1.224 10.776 1 10.5 1Z" />
    <path d="M13 2H12V3.5C12 4.327 11.327 5 10.5 5H5.5C4.673 5 4 4.327 4 3.5V2H3C2.449 2 2 2.449 2 3V15C2 15.561 2.439 16 3 16H13C13.561 16 14 15.561 14 15V3C14 2.439 13.561 2 13 2ZM7.854 10.854L5.854 12.854C5.756 12.951 5.628 13 5.5 13C5.372 13 5.244 12.951 5.146 12.854L4.146 11.854C3.951 11.659 3.951 11.342 4.146 11.147C4.341 10.952 4.658 10.952 4.853 11.147L5.5 11.793L7.146 10.147C7.341 9.952 7.658 9.952 7.853 10.147C8.048 10.342 8.049 10.658 7.854 10.854ZM7.854 6.854L5.854 8.854C5.756 8.951 5.628 9 5.5 9C5.372 9 5.244 8.951 5.146 8.854L4.146 7.854C3.951 7.659 3.951 7.342 4.146 7.147C4.341 6.952 4.658 6.952 4.853 7.147L5.5 7.793L7.146 6.147C7.341 5.952 7.658 5.952 7.853 6.147C8.048 6.342 8.049 6.658 7.854 6.854ZM11.5 12H9.5C9.224 12 9 11.776 9 11.5C9 11.224 9.224 11 9.5 11H11.5C11.776 11 12 11.224 12 11.5C12 11.776 11.776 12 11.5 12ZM11.5 8H9.5C9.224 8 9 7.776 9 7.5C9 7.224 9.224 7 9.5 7H11.5C11.776 7 12 7.224 12 7.5C12 7.776 11.776 8 11.5 8Z" />
  </svg>
);

export default checklist;
