import { UserOrdersState } from '../types';
import { UserOrdersAction, UserOrdersConstants } from './action';

export const initialUserOrdersState: UserOrdersState = {
  activeSort: 'delivery-date-asc',
  areFiltersActive: false,
  areOrdersLoading: false,
  completeOrdersCollection: [],
  filteredOrdersCollection: [],
};

export const userOrdersReducer = (
  state: UserOrdersState,
  action: UserOrdersAction,
): UserOrdersState => {
  const { type } = action;

  switch (type) {
    case UserOrdersConstants.CHANGE_ACTIVE_SORT: {
      return { ...state, activeSort: action.payload.activeSort };
    }
    case UserOrdersConstants.RESET_ALL_FILTERS:
      return {
        ...state,
        filteredOrdersCollection: state.completeOrdersCollection,
      };
    case UserOrdersConstants.SET_COMPLETE_ORDERS_COLLECTION:
      return {
        ...state,
        completeOrdersCollection: action.payload.ordersCollection,
        filteredOrdersCollection: action.payload.ordersCollection,
      };
    case UserOrdersConstants.SET_FILTERED_ORDERS_COLLECTION:
      return {
        ...state,
        filteredOrdersCollection: action.payload.filteredOrdersCollection,
      };
    case UserOrdersConstants.SET_ORDERS_LOADING:
      return {
        ...state,
        areOrdersLoading: action.payload.areOrdersLoading,
      };
    default:
      return state;
  }
};
