import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';

// Components
import ConfirmModal from 'components/ConfirmModal';
import DataGrid from 'components/DataGrid';
import FiltersBar from 'components/FiltersBar';

// Constants - Internals - Services - Utils
import {
  deleteUserRequest,
  updateUserRequest,
} from 'services/user/manageUserRequest';
import { fetchUsersRequests } from 'services/user/fetchUsersRequests';
import { getStyledIcon } from 'utils/function/styles';
import { UserRequestsConstants } from 'pages/UserRequests/reducer/action';
import { UserRequestsContext } from 'pages/UserRequests';

// Images
import check from 'assets/images/icons/check.svg';
import deleteImage from 'assets/images/icons/cross.svg';
import ellipsisHorizontal from 'assets/images/icons/ellipsisHorizontal.svg';
import eye from 'assets/images/icons/eye.svg';

// Types
import { Column, SupplierItemParam } from 'components/DataGrid/types';
import { Supplier, UserRequestTabProps } from 'pages/UserRequests/types';
import { useNavigate } from 'react-router-dom';

const ToApproved: React.FC<UserRequestTabProps> = ({ onPageChange }) => {
  const { state, dispatch } = useContext(UserRequestsContext);
  const navigate = useNavigate();

  // Selected user
  const [deleteUser, setDeleteUser] = useState<Supplier | null>(null);
  const [approveUser, setApproveUser] = useState<Supplier | null>(null);

  useEffect(() => {
    fetchUsersRequests(dispatch, 1, state.activaPage);
  }, [state.activaPage]);

  const reloadPage = () => {
    dispatch({
      type: UserRequestsConstants.UPDATE_ACTIVE_PAGE,
      payload: {
        activePage: 1,
      },
    });
    fetchUsersRequests(dispatch, 1);
  };

  const onApproveUserRequest = (idUser: string) => {
    updateUserRequest(2, idUser, reloadPage);
    setApproveUser(null);
  };

  const onDeleteUserRequest = (idUser: string) => {
    /** @TODO Keep the modal open while doing the request and show a loder on the button */
    setDeleteUser(null);
    deleteUserRequest(idUser, reloadPage);
  };

  const columns: Array<Column> = [
    {
      key: 'companyAndVATNumber',
      name: 'AZIENDA E P.IVA',
      formatter: ({ data }: SupplierItemParam) => (
        <div style={{ textAlign: 'left' }}>
          <div>{data.vatNumber}</div>
          <div>
            <b>{data.name}</b>
          </div>
        </div>
      ),
    },
    {
      key: 'dateRequest',
      name: 'DATA RICHIESTA',
      formatter: ({ data }: SupplierItemParam) => {
        if (_.isEmpty(data.createdAt)) {
          return null;
        }

        const date = moment(data.createdAt, 'YYYY-MM-DD h:mm_ss').utcOffset(4);
        return (
          <div>
            <b>{date.format('D MMM ')}</b>
            {date.format('YYYY')}
            <br />
            {date.format('H:mm:ss')}
          </div>
        );
      },
    },
    {
      key: 'city',
      name: 'CITTÀ',
      formatter: ({ data }: SupplierItemParam) => {
        return (
          <div>
            <b>{data.city}</b>
          </div>
        );
      },
    },
    {
      key: 'settings',
      name: 'AZIONI',
      formatter: ({ data }: SupplierItemParam) => {
        return (
          <Dropdown
            className="standard-dropdown-menu"
            direction="left"
            icon={<Image src={ellipsisHorizontal} />}
            item
          >
            <Dropdown.Menu>
              <Dropdown.Item
                key="visualize"
                text={
                  <FormattedMessage
                    id="visualize"
                    defaultMessage="Visualizza"
                  />
                }
                icon={getStyledIcon(eye)}
                onClick={() => navigate('/user-details/' + data.id)}
              />
              <Dropdown.Item
                key="approve"
                text={
                  <FormattedMessage
                    id="userRequest.toApproved.dropdown.approve"
                    defaultMessage="Approva"
                  />
                }
                icon={getStyledIcon(check)}
                onClick={() => setApproveUser(data)}
              />
              <Dropdown.Item
                key="delete"
                text={
                  <FormattedMessage id="removeRequest" defaultMessage="Nega" />
                }
                icon={getStyledIcon(deleteImage)}
                onClick={() => setDeleteUser(data)}
              />
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <FiltersBar
        resetFilters={() => console.log('ok')}
        setFilters={() => fetchUsersRequests(dispatch, 1)}
      />
      <DataGrid
        isLoading={state.isLoading}
        elements={state.elements}
        columns={columns}
        totalPages={state.totalPages}
        activePage={state.activaPage}
        onPageChange={onPageChange}
      />

      {/** Confirm modal for user request DELETE */}
      <ConfirmModal
        confirmButtonText="Nega"
        isOpen={!_.isEmpty(deleteUser)}
        onClose={() => setDeleteUser(null)}
        onConfirm={() =>
          deleteUser ? onDeleteUserRequest(deleteUser.id) : null
        }
        subtitleText="Si conferma di volere negare la richiesta di registrazione?"
        titleText="Negazione richiesta"
        type="delete"
      />
      {/** Confirm modal for active the user request */}
      <ConfirmModal
        confirmButtonText="Approva"
        isOpen={!_.isEmpty(approveUser)}
        onClose={() => setApproveUser(null)}
        onConfirm={() =>
          approveUser ? onApproveUserRequest(approveUser.id) : null
        }
        subtitleText="Si conferma di volere approvare il profilo utente?"
        titleText="Approvazione utente"
        type="insert"
      />
    </>
  );
};

export default ToApproved;
