import React, { useContext, useEffect, useState } from 'react';
import {
  CustomerQuote,
  CustomerQuotesTableColunns,
  QuotesSupportedSort,
} from './types';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerQuotes } from 'services/customer/getCustomerDocuments';
import { CUSTOMER_DOCUMENT_METHODS } from 'utils/global/customerCostants';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS, ROLES } from 'utils/global/globalConstants';
import _ from 'lodash';
import { getCustomerDocument } from 'services/document/getCustomerDocuments';
import CustomerQuoteModal from '../CustomerQuoteModal';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import { Popup } from 'semantic-ui-react';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';

const CustomerQuotes: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;

  const [customerQuotesCollection, setCustomerQuotesCollection] = useState<
    Array<CustomerQuote>
  >([]);
  const [showedCustomerQuotesCollection, setShowedCustomerQuotesCollection] =
    useState<Array<CustomerQuote>>([]);
  const [areCustomerQuotesLoading, setAreCustomerQuotesLoading] =
    useState(false);
  const [activeSort, setActiveSort] =
    useState<QuotesSupportedSort>('delivery-date-desc');

  const [selectedQuoteCode, setSelectedQuoteCode] = useState('');

  useEffect(() => {
    if (!isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      getCustomerQuotes(
        setCompleteQuotesCollection,
        setAreCustomerQuotesLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callQuotesForAgent();
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callQuotesForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerQuotes(
        setCompleteQuotesCollection,
        setAreCustomerQuotesLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteQuotesCollection = (
    completeQuotesCollection: Array<CustomerQuote>,
  ) => {
    setCustomerQuotesCollection(completeQuotesCollection);
    const filteredByYearCollection = completeQuotesCollection.filter(quote =>
      moment(quote.orderDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
    );
    setShowedCustomerQuotesCollection(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let documentsToShow = customerQuotesCollection;

    if (!_.isEmpty(documentNumber)) {
      documentsToShow = documentsToShow.filter(
        document =>
          _.isString(document.no) &&
          document.no.toLowerCase().includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(singleDocument.orderDate),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      documentsToShow = documentsToShow.filter(singleDocument =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(singleDocument.orderDate),
      );
    }

    setShowedCustomerQuotesCollection(documentsToShow);
  };

  const onChangeSort = (sortType: QuotesSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedCustomerQuotesCollection(
      onSortQuotesCollection(sortType, showedCustomerQuotesCollection),
    );
    setActiveSort(sortType);
  };

  const onSortQuotesCollection = (
    sortType: QuotesSupportedSort,
    quotesCollection: Array<CustomerQuote>,
  ): Array<CustomerQuote> => {
    let toSortOrdersDocuments = quotesCollection;

    switch (sortType) {
      case 'quote-number-desc': {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) => {
            if (firstOrder.no > secondOrder.no) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) =>
            moment(firstOrder.orderDate, 'YYYY-MM-DD').diff(
              moment(secondOrder.orderDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) =>
            moment(secondOrder.orderDate, 'YYYY-MM-DD').diff(
              moment(firstOrder.orderDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        toSortOrdersDocuments = toSortOrdersDocuments.sort(
          (firstOrder, secondOrder) => {
            if (firstOrder.no > secondOrder.no) {
              return 1;
            }
            return -1;
          },
        );
      }
    }

    return toSortOrdersDocuments;
  };

  const onCloseModal = () => {
    setSelectedQuoteCode(null);
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<CustomerQuote> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return customerQuotesCollection.filter(quote =>
        moment(quote.orderDate, 'YYYY-MM-DD').isSame(selectedYear, 'year'),
      );
    }
    return customerQuotesCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedCustomerQuotesCollection(
      getFilteredByYearsCollection(selectedYear),
    );
    onChangeSort('delivery-date-desc');
  };

  const columns: Array<CustomerQuotesTableColunns> = [
    {
      key: 'quoteNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'quote-number-asc'
                ? 'quote-number-desc'
                : 'quote-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.orders.table.header.quoteNumber"
            defaultMessage="N. offerta"
          />
          {activeSort === 'quote-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'quote-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">{data.no}</div>
      ),
    },
    {
      key: 'quoteDate',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.orders.table.header.quoteDate"
            defaultMessage="Data offerta"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.orderDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'quoteDueDate',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.quoteDueDate"
            defaultMessage="Data scadenza"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {moment(data.dueDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'amount',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.quotes.table.header.bill"
            defaultMessage="Importo (IVA Esclusa)"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
          }).format(_.toNumber(data.amount))}
        </div>
      ),
    },
    {
      key: 'payment',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.orders.table.header.payment"
            defaultMessage="Metodo e termini pagamento"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-payment">
          <span className="payment-method">{data.paymentMethod}</span>
          <span className="payment-terms">{data.paymentTerms}</span>
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.quotes.table.documents.tooltip.details"
                  defaultMessage="Visualizza dettagli"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() => setSelectedQuoteCode(data.no)}
              >
                <SvgIcon icon={ICONS.LENS} height={20} width={20} />
              </span>
            }
          />
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.quotes.table.documents.tooltip.download"
                  defaultMessage="Scarica documento"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button"
                onClick={() =>
                  getCustomerDocument(
                    data.no,
                    CUSTOMER_DOCUMENT_METHODS.SALES_QUOTE,
                    intl,
                    selectedContact?.name,
                  )
                }
              >
                <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
              </span>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomerDocumentsTab
        activeSort={activeSort}
        areDocumentsLoading={areCustomerQuotesLoading}
        completeDocumentsCollection={showedCustomerQuotesCollection}
        filterInputPlaceholder={intl.formatMessage({
          id: 'customerDocuments.quotes.filterBar.input.placheolder',
          defaultMessage: 'N. offerta',
        })}
        onChangeSelectedYear={onChangeSelectedYear}
        onFilter={onFilter}
        tableColumns={columns}
        key="customer-orders"
      />

      <CustomerQuoteModal
        onClose={onCloseModal}
        quoteDocumentCode={selectedQuoteCode}
      />
    </>
  );
};

export default CustomerQuotes;
