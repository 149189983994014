import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { DropdownItemProps } from 'semantic-ui-react';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { FetchCerListResponse } from './types';

export const fetchCerListOptions = (
  setCerListOptions: (cerListOptions: Array<DropdownItemProps>) => void,
): void => {
  standardClient({
    url: API.CER_LIST,
    method: 'POST',
  })
    .then((response: AxiosResponse<FetchCerListResponse>) => {
      const cerListOptions = response.data.cerCodeList.map(cerElement => ({
        text: cerElement.cerCode + ' - ' + cerElement.cerDescription,
        value: cerElement.cerCode,
      }));

      setCerListOptions(cerListOptions);
    })
    .catch(() => {
      toast.error('Impossibile caricare la lista dei Codici CER');
    });
};

export const fetchFilteredCerListOptions = (
  setCerListOptions: (cerListOptions: Array<Array<DropdownItemProps>>) => void,
  setAreCerCodeLoading: (areCerCodeLoading: boolean) => void,
  originCode: string | number | boolean | (string | number | boolean)[] | null,
  reservationCode: string | null,
): void => {
  setAreCerCodeLoading(true);

  standardClient({
    url: API.CER_LIST,
    method: 'POST',
    data: {
      params: {
        originCode: originCode,
        reservationCode: reservationCode,
      },
    },
  })
    .then((response: AxiosResponse<FetchCerListResponse>) => {
      const dropdownCerListCollection: Array<Array<DropdownItemProps>> = [];
      Object.entries(response.data).forEach(([articleCode, cerCollection]) => {
        const cerListOptions = cerCollection.map(cerElement => ({
          text: cerElement.cerCode + ' - ' + cerElement.cerDescription,
          value: cerElement.cerCode,
        }));

        dropdownCerListCollection[articleCode] = cerListOptions;
      });

      setCerListOptions(dropdownCerListCollection);
    })
    .catch(() => {
      toast.error('Impossibile caricare la lista dei Codici CER');
    })
    .finally(() => setAreCerCodeLoading(false));
};
