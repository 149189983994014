import { SelectedContact } from 'layout/SideBar/types';
import { MySelf } from 'services/auth/types';

export enum appActionConstants {
  REVOKE_SESSION = 'App/REVOKE_SESSION',
  SET_LANGUAGE = 'App/SET_LANGUAGE',
  SET_MYSELF = 'App/SET_MYSELF',
  SET_SESSION = 'App/SET_SESSION',
  SET_USER_LOADING = 'App/SET_USER_LOADING',
  SET_SELECTED_CONTACT = 'App/SET_AGENT_CUSTOMER',
}

export type AppAction =
  | { type: appActionConstants.REVOKE_SESSION }
  | {
      type: appActionConstants.SET_LANGUAGE;
      payload: { language: 'it' | 'en' };
    }
  | { type: appActionConstants.SET_MYSELF; payload: { mySelf: MySelf } }
  | { type: appActionConstants.SET_SESSION }
  | {
      type: appActionConstants.SET_USER_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: appActionConstants.SET_SELECTED_CONTACT;
      payload: { contact: SelectedContact };
    };
