import { FormikHelpers } from 'formik';
import _ from 'lodash';

export const getFormikErrors = (
  error: unknown,
  formikHelpers: FormikHelpers<any>, // eslint-disable-line
): void => {
  const errors = _.get(error, 'data.validation_messages', null);

  if (formikHelpers && errors) {
    // Set the errors in the Formik bag.
    _.forOwn(errors, (value, key) => {
      for (const i in value) {
        if (_.isObject(value[i])) {
          _.forOwn(value[i], (subvalue, subkey) => {
            for (const j in subvalue) {
              formikHelpers.setFieldError(`${key}.${i}.${subkey}`, subvalue[j]);
            }
          });
        } else {
          formikHelpers.setFieldError(key, value[i]);
        }
      }
    });
  }
};
