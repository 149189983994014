import { FormikHelpers } from 'formik';
import { Reservation } from 'pages/Reservation/types';
import { nextStateReservation } from 'services/reservation/postReservation';
import { ReservationStateConstants } from 'utils/global/reservationConstants';

export const deliveryInformationSubmit = (
  values: Reservation,
  reservationId: string,
  setIsLoading: (isLoading: boolean) => void,
  setReservation: (reservation: Reservation) => void,
  formikHelpers: FormikHelpers<Reservation>,
): void => {
  const submitObject = {
    timeSlot: values.timeSlot,
    state: ReservationStateConstants.DOCUMENT_UPLOADING,
    deliveryDate: values.deliveryDate,
    vehiclePlate: values.vehiclePlate,
    shippingAgent: values.shippingAgent,
  };

  nextStateReservation(
    submitObject,
    reservationId,
    setIsLoading,
    setReservation,
    formikHelpers,
  );
};
