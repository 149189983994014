import { UserDetailsState } from '../types';
import { UserDetailsAction, UserDetailsConstants } from './action';

export const initialUserDetailsState: UserDetailsState = {
  areDetailsLoading: false,
  areDocumentsLoading: false,
  documentsCollection: [],
  userDetails: null,
};

export const userDetailsReducer = (
  state: UserDetailsState,
  action: UserDetailsAction,
): UserDetailsState => {
  const { type } = action;

  switch (type) {
    case UserDetailsConstants.SET_DETAILS_LOADING:
      return {
        ...state,
        areDetailsLoading: action.payload.areDetailsLoading,
      };
    case UserDetailsConstants.SET_DOCUMENTS_LOADING:
      return {
        ...state,
        areDocumentsLoading: action.payload.areDocumentsLoading,
      };
    case UserDetailsConstants.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    case UserDetailsConstants.SET_USER_DOCUMENTS:
      return {
        ...state,
        documentsCollection: action.payload.documentsCollection,
      };
    default:
      return state;
  }
};
