import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import SvgIcon from 'components/SvgIcon';
import Dropzone from 'react-dropzone';

import uploadIcon from 'assets/images/icons/upload.svg';
import { Button, Image } from 'semantic-ui-react';
import { fetchReservationDocumnents } from 'services/reservation/fetchReservatioDocument';
import { useParams } from 'react-router-dom';
import { Document } from 'services/user/types';
import _ from 'lodash';
import { Nullable } from 'utils/types';
import ConfirmModal from 'components/ConfirmModal';
import { uploadReservationDocument } from 'services/reservation/uploadDocument';
import { deleteReservationDocument } from 'services/reservation/deleteReservationDocument';
import ReservationOptionalDocument from '../ReservationOptionalDocument';

const DocumentPhase: React.FC<{ readOnly?: boolean }> = ({
  readOnly = false,
}) => {
  const intl = useIntl();
  const { reservationId } = useParams();

  const [deleteDocument, setDeleteDocument] = useState<Nullable<string>>(null);
  const [optionalDocuments, setOptionalDocuments] = useState<Array<Document>>(
    [],
  );

  useEffect(() => {
    fetchReservationDocumnents(reservationId, setOptionalDocuments);
  }, []);

  const onDocumentAdd = (files: Array<File>) => {
    // Upload files
    const requests = files.map(file => {
      const documentFormData = new FormData();
      documentFormData.append('file', file);
      documentFormData.append('entityName', 'Reservation\\Entity\\Reservation');
      documentFormData.append('entityId', reservationId);
      documentFormData.append('documentType', 'reservation');

      return new Promise(resolve =>
        uploadReservationDocument(documentFormData, resolve),
      );
    });

    // Reload list of documents
    Promise.all(requests).then(() =>
      fetchReservationDocumnents(reservationId, setOptionalDocuments),
    );
  };

  const removeDocument = (documentId: string) => {
    const documentCollectionClone = _.clone(optionalDocuments);
    _.remove(documentCollectionClone, { id: documentId });
    setOptionalDocuments(documentCollectionClone);
  };

  const onDeleteDocument = () => {
    deleteReservationDocument(deleteDocument, removeDocument);
    setDeleteDocument(null);
  };

  const renderOptionalDocumentList = () => (
    <div className="document-phase-uploaded-document-list">
      {optionalDocuments.map(document => (
        <ReservationOptionalDocument
          document={document}
          setDeleteDocument={setDeleteDocument}
          key={document.id}
          readOnly={readOnly}
        />
      ))}
    </div>
  );

  return (
    <div className="document-phase">
      <div className="reservation-header">
        <span className="reservation-header-title">
          <FormattedMessage
            id="documentPhase.title"
            defaultMessage="Documentazione aggiuntiva"
          />
        </span>
        <span className="reservation-header-subtitle">
          <FormattedMessage
            id="documentPhase.subtitle"
            defaultMessage="E’ possibile presentare i documenti contestualmente all’arrivo in MSM"
          />
        </span>
      </div>

      {/** @TODO Fix NAV document style and link the service from BE to get the list of documents */}
      <div className="document-phase-content">
        <div className="document-phase-horizontal-divider">
          <SvgIcon
            icon={ICONS.TOW_TRUCK}
            color={COLORS.WHITE}
            height={23}
            width={53}
          />
          <SvgIcon icon={ICONS.CLOCK} color={COLORS.WHITE} />
          <FormattedMessage
            id="documentPhase.document.divider"
            defaultMessage="Allegare la documentazione in anticipo riduce i tempi di attesa in MSM”"
          />
        </div>
        <div className="document-phase-dropzone">
          {!readOnly && (
            <Dropzone onDrop={files => onDocumentAdd(files)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div className="dropzone-text-section">
                    <Image src={uploadIcon} />
                    <span>
                      <FormattedMessage
                        id="documentPhase.document.dropzone"
                        defaultMessage="Trascina i {firstRow} richiesti in {secondRow}"
                        values={{
                          firstRow: (
                            <b>
                              {intl.formatMessage({
                                id: 'documentPhase.document.dropzone.document',
                                defaultMessage: 'documenti',
                              })}
                              <br />
                            </b>
                          ),
                          secondRow: (
                            <b>
                              {intl.formatMessage({
                                id: 'documentPhase.document.dropzone.acceptance',
                                defaultMessage: 'accettazione',
                              })}
                            </b>
                          ),
                        }}
                      />
                    </span>
                  </div>
                  <div className="dropzone-button-section">
                    <Button
                      content={intl.formatMessage({
                        id: 'documentPhase.button.upload',
                        defaultMessage: 'Carica file',
                      })}
                      className="dropzone-upload-button"
                    />
                  </div>
                </div>
              )}
            </Dropzone>
          )}
          {!_.isEmpty(optionalDocuments) && (
            <div className="document-phase-uploaded-document">
              <div className="document-phase-uploaded-document-title">
                <FormattedMessage
                  id="documentPhase.allDocuments"
                  defaultMessage="Tutti i documenti caricati"
                />
              </div>
              {renderOptionalDocumentList()}
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        type="delete"
        isOpen={!_.isEmpty(deleteDocument)}
        onClose={() => setDeleteDocument(null)}
        onConfirm={() => onDeleteDocument()}
        titleText="Conferma eliminazione documento"
        subtitleText="Si conferma di voler eliminare il documento selezionato"
      />
    </div>
  );
};

export default DocumentPhase;
