import { Reservation } from 'pages/Reservation/types';

export enum CalendarConstants {
  NEXT_DATE = 'CalendarConstants/NEXT_DATE',
  PREVIOUS_DATE = 'CalendarConstants/PREVIOUS_DATE',
  SET_DATE = 'CalendarConstants/SET_DATE',
  SET_IS_LOADING = 'CalendarConstants/SET_IS_LOADING',
  SET_OPEN_CALENDAR = 'CalendarConstants/SET_OPEN_CALENDAR',
  SET_RESERVATION_COLLECTION = 'CalendarConstants/SET_RESERVATION_COLLECTION',
}

export type CalendarAction =
  | {
      type: CalendarConstants.NEXT_DATE;
    }
  | {
      type: CalendarConstants.PREVIOUS_DATE;
    }
  | {
      type: CalendarConstants.SET_DATE;
      payload: { selectedDate: Date };
    }
  | {
      type: CalendarConstants.SET_IS_LOADING;
      payload: { isCollectionLoading: boolean };
    }
  | {
      type: CalendarConstants.SET_OPEN_CALENDAR;
      payload: { isCalendarOpen: boolean };
    }
  | {
      type: CalendarConstants.SET_RESERVATION_COLLECTION;
      payload: { reservationCollection: Array<Reservation> };
    };
