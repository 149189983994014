import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import SvgIcon from 'components/SvgIcon';

import { SelectedContact, SideBarProps } from './types';
import { COLORS, FE_ROUTES, ICONS, ROLES } from 'utils/global/globalConstants';
import { isThatRole } from 'utils/function/acl';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import SelectContactModal from 'components/SelectContactModal';
import { appActionConstants } from 'pages/App/reducer/actions';
import { changePreferredProfile } from 'services/user/changePreferredProfile';

const SideBar: React.FC<SideBarProps> = ({
  appState,
  dispatchAppState,
  menuItems,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };

  const setSelectedContact = (selectedContact: SelectedContact) => {
    dispatchAppState({
      type: appActionConstants.SET_SELECTED_CONTACT,
      payload: { contact: selectedContact },
    });
  };

  /**
   * Return true if the link passed as param is the same of
   * the current URL. Used for make evident the current menu section.
   * Special rule for the dashboard which URL can be also /
   */
  const isCurrentUrl = (linkAddress: string) => {
    const { pathname } = useLocation();

    // Special rule for dashboard page (main page)
    if (pathname === '/' && linkAddress === FE_ROUTES.DASHBOARD) {
      return true;
    }

    // Special rule for reservations
    if (
      linkAddress.includes(FE_ROUTES.RESERVATION_DETAILS) &&
      pathname.includes(FE_ROUTES.RESERVATION_DETAILS)
    ) {
      return true;
    }

    // Special rule for customerDocuments
    if (
      linkAddress.includes(FE_ROUTES.CUSTOMER_DOCUMENTS) &&
      pathname.includes(FE_ROUTES.CUSTOMER_DOCUMENTS)
    ) {
      return true;
    }

    // Special rule for customer agent documents
    if (
      linkAddress.includes(FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS) &&
      pathname.includes(FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS)
    ) {
      return true;
    }

    return pathname.includes(linkAddress);
  };

  return (
    <Menu className="sidebar" size="massive">
      {menuItems.map(menuElement => (
        <div key={menuElement.id}>
          <div className="menu-section" key={menuElement.id}>
            {menuElement.menuTitle}
          </div>
          {menuElement.menuElements.map(element => (
            <div
              className={`menu-element ${
                isCurrentUrl(element.linkAddress) ? 'selected' : ''
              }`}
              key={element.id}
              onClick={() => navigate(element.linkAddress)}
            >
              <SvgIcon
                color={
                  isCurrentUrl(element.linkAddress)
                    ? COLORS.SECONDARY
                    : COLORS.MID_GREY
                }
                icon={element.icon}
                height={24}
                width={24}
              />
              <div className="element-text">{element.elementTitle}</div>
            </div>
          ))}
        </div>
      ))}
      {isThatRole(ROLES.CUSTOMER_AGENT, appState) ? (
        <div
          className="agent-select-customer"
          onClick={() => setIsContactModalOpen(true)}
        >
          <SvgIcon
            icon={ICONS.USER}
            color={COLORS.WHITE}
            height={24}
            width={24}
          />
          <span className="agent-select-customer-text">
            {_.isEmpty(appState.selectedContact) ? (
              <FormattedMessage
                id="agent.empty.select.customer"
                defaultMessage="Seleziona contatto"
              />
            ) : (
              appState.selectedContact.name
            )}
          </span>
        </div>
      ) : null}
      {appState.mySelf?._permissions.isMultiRole ? (
        <div
          className="contact-select-profile"
          onClick={() => changePreferredProfile(intl)}
        >
          <SvgIcon
            icon={ICONS.USER}
            color={COLORS.WHITE}
            height={24}
            width={24}
          />
          <span className="contact-select-profile-text">
            {isThatRole(ROLES.SUPPLIER, appState) ? (
              <>
                <FormattedMessage
                  id="sidebar.changeProfileType.supplierActive"
                  defaultMessage="PROFILO FORNITORE ATTIVO"
                />
                <br />
                <FormattedMessage
                  id="sidebar.changeProfileType.toCustomer"
                  defaultMessage="Passa a cliente"
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  id="sidebar.changeProfileType.customerActive"
                  defaultMessage="PROFILO CLIENTE ATTIVO"
                />
                <br />
                <FormattedMessage
                  id="sidebar.changeProfileType.toSupplier"
                  defaultMessage="Passa a fornitore"
                />
              </>
            )}
          </span>
        </div>
      ) : null}
      <SelectContactModal
        contactList={appState.mySelf?._agentCustomerList}
        isOpen={isContactModalOpen}
        onClose={closeContactModal}
        onSelectContact={setSelectedContact}
      />
    </Menu>
  );
};

export default SideBar;
