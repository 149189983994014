import { useContext } from 'react';
import _, { isArray } from 'lodash';
import { AppContext } from 'pages/App';
import { ROLES } from 'utils/global/globalConstants';
import { AppState } from 'pages/App/reducer/reducer';

// Verify if the current user role in my self object have the searched role.
export const isThatRole = (
  searchedRoles: ROLES | Array<ROLES>,
  state: AppState | null = null,
): boolean => {
  if (!state) {
    ({ state } = useContext(AppContext));
  }
  if (!state.mySelf || _.isEmpty(state.mySelf.roles)) {
    return false;
  }

  if (isArray(searchedRoles)) {
    const canAccess = searchedRoles.some(r => state.mySelf.roles.includes(r));
    return canAccess;
  } else if (_.includes(state.mySelf.roles, searchedRoles)) {
    return true;
  }
  return false;
};
