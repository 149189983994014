import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const truck = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 25}
    height={height || 24}
    viewBox="0 0 25 24"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_353_11005)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27185 21.1298C2.27185 19.8187 3.33469 18.7559 4.64578 18.7559C5.95688 18.7559 7.01972 19.8187 7.01972 21.1298C7.01972 22.4409 5.95688 23.5037 4.64578 23.5037C3.33469 23.5037 2.27185 22.4409 2.27185 21.1298ZM5.66314 21.1298C5.66314 20.5679 5.20763 20.1124 4.64574 20.1124C4.08385 20.1124 3.62833 20.5679 3.62833 21.1298C3.62833 21.6917 4.08385 22.1472 4.64574 22.1472C5.20763 22.1472 5.66314 21.6917 5.66314 21.1298ZM15.1589 21.1298C15.1589 19.8187 16.2217 18.7559 17.5328 18.7559C18.8439 18.7559 19.9067 19.8187 19.9067 21.1298C19.9067 22.4409 18.8439 23.5037 17.5328 23.5037C16.2217 23.5037 15.1589 22.4409 15.1589 21.1298ZM18.5502 21.1298C18.5502 20.5679 18.0947 20.1124 17.5328 20.1124C16.9709 20.1124 16.5154 20.5679 16.5154 21.1298C16.5154 21.6917 16.9709 22.1472 17.5328 22.1472C18.0947 22.1472 18.5502 21.6917 18.5502 21.1298Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59347 21.1293H0.915184C0.540606 21.1293 0.236897 20.8256 0.236897 20.451V16.3814C0.236897 16.0068 0.540558 15.7031 0.915184 15.7031H21.2631C21.6377 15.7031 21.9414 16.0068 21.9414 16.3814V20.451C21.9414 20.8256 21.6377 21.1293 21.2631 21.1293H20.5848C20.5848 19.4436 19.2183 18.0771 17.5327 18.0771C15.847 18.0771 14.4805 19.4436 14.4805 21.1293H7.69786C7.69786 19.4436 6.33137 18.0771 4.64569 18.0771C2.96001 18.0771 1.59347 19.4436 1.59347 21.1293Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0783 13.8959L21.8985 16.4939C21.6577 16.7808 21.2299 16.8183 20.9429 16.5775L11.0708 8.29385C10.7839 8.05307 10.7464 7.62525 10.9872 7.33829L13.1671 4.74036C13.4079 4.45339 13.8357 4.41596 14.1227 4.65674L23.9947 12.9404C24.2817 13.1812 24.3191 13.609 24.0783 13.8959Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9345 12.2148L12.6407 12.1079C12.2887 11.9797 11.8995 12.1612 11.7714 12.5132L10.6115 15.7C10.4834 16.0521 10.6648 16.4413 11.0169 16.5694C11.3689 16.6975 11.7581 16.516 11.8862 16.164L12.81 13.6127L13.4517 13.8458L12.7468 15.7825C12.6187 16.1345 12.8002 16.5237 13.1522 16.6519C13.5042 16.78 13.8934 16.5985 14.0216 16.2465L14.9582 13.6732C15.0863 13.3212 14.9048 12.932 14.5528 12.8039L14.2092 12.6788L14.9582 10.621C15.0863 10.269 14.9048 9.8798 14.5528 9.75165C14.2008 9.6235 13.8116 9.80503 13.6834 10.157L12.9345 12.2148ZM7.69789 10.9561H3.35583L1.5935 14.1779V16.3822C1.5935 16.7568 1.28984 17.0605 0.915215 17.0605C0.540588 17.0605 0.236974 16.7569 0.236974 16.3822V14.0083C0.236974 13.8948 0.265473 13.7831 0.319791 13.6835L2.3546 9.95307C2.47344 9.73519 2.70186 9.59961 2.95003 9.59961H8.37613C8.75071 9.59961 9.05442 9.90327 9.05442 10.2779V15.704C9.05442 16.0786 8.75076 16.3823 8.37613 16.3823C8.0015 16.3823 7.69784 16.0786 7.69784 15.704V10.9561H7.69789Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_353_11005">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.237061)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default truck;
