import { Input } from 'formik-semantic-ui-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from 'semantic-ui-react';

const AdministrativeData: React.FC = () => {
  return (
    <>
      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub3"
          defaultMessage="Dati amministrativi"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
      </span>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="VATNumber"
            label={
              <FormattedMessage
                id="data.collection.vatNumber"
                defaultMessage="Partita IVA"
              />
            }
            placeholder="Partita IVA"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="taxID"
            label={
              <FormattedMessage id="taxID" defaultMessage="Codice Fiscale" />
            }
            placeholder="Codice Fiscale"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="supportBank"
            label={
              <FormattedMessage
                id="supportBank"
                defaultMessage="Vs. Banca d'appoggio"
              />
            }
            placeholder="Vs. Banca d'appoggio"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="agency"
            label={<FormattedMessage id="agency" defaultMessage="Agenzia" />}
            placeholder="Agenzia"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={3}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="abi"
            label={
              <FormattedMessage id="data.collection.abi" defaultMessage="ABI" />
            }
            placeholder="Vs. Banca d'appoggio"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="cab"
            label={<FormattedMessage id="cab" defaultMessage="CAB" />}
            placeholder="CAB"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="cc"
            label={<FormattedMessage id="cc" defaultMessage="C/C" />}
            placeholder="C/C"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="cin"
            label={<FormattedMessage id="cin" defaultMessage="CIN" />}
            placeholder="CIN"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="iban"
            label={<FormattedMessage id="iban" defaultMessage="IBAN" />}
            placeholder="IBAN"
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default AdministrativeData;
