import { CircularProgress } from '@material-ui/core';
import { MetalResumeContext } from 'pages/MetalResume';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MetalResumeLine } from 'pages/MetalResume/types';
import moment from 'moment';
import CompleteMetalResumeAccountTable from '../CustomerMetalAccountTable';
import { MetalResumeConstants } from 'pages/MetalResume/reducer/action';

const MetalResumeAccountList: React.FC = () => {
  const intl = useIntl();
  const { state, dispatch } = useContext(MetalResumeContext);

  const getMetalResumeLines = (metalResumes: Array<MetalResumeLine>) => {
    const metalResumeLineArray: Array<MetalResumeLine> = metalResumes;
    let metalResumeLines: Array<MetalResumeLine> = [];

    switch (state.activeSortAccount) {
      case 'postingDate-asc': {
        metalResumeLines = metalResumeLineArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(firstMetalResume.postingDate, 'YYYY-MM-DD').diff(
              moment(secondMetalResume.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'postingDate-desc': {
        metalResumeLines = metalResumeLineArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(secondMetalResume.postingDate, 'YYYY-MM-DD').diff(
              moment(firstMetalResume.postingDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        metalResumeLines = metalResumeLineArray.sort(
          (firstMetalResume, secondMetalResume) => {
            if (firstMetalResume.itemNo === secondMetalResume.itemNo) {
              if (firstMetalResume.itemNo > secondMetalResume.itemNo) {
                return 1;
              }
              return -1;
            }

            if (firstMetalResume.itemNo > secondMetalResume.itemNo) {
              return 1;
            } else {
              return -1;
            }
          },
        );
      }
    }
    return metalResumeLines;
  };

  return (
    <>
      {state.areMetalResumesAccountLoading ? (
        <>
          <CircularProgress color="inherit" />
          <span className="metal-resume-loading">
            <FormattedMessage
              id="accordion.list.metal.account.resume.loading"
              defaultMessage="Ultimo estratto conto in caricamento"
            />
          </span>
        </>
      ) : (
        <>
          <div className="metal-resume-title">
            {intl.formatMessage({
              id: 'customer.metal.resume.account.title.section',
              defaultMessage: 'Estratto conto mese corrente',
            })}
          </div>
          <CompleteMetalResumeAccountTable
            metalResumes={getMetalResumeLines(state.metalAccountResumeList)}
          />
          <div
            className="metal-resume-table-footer"
            onClick={() =>
              dispatch({ type: MetalResumeConstants.COLLAPSE_METAL_ACTIVITY })
            }
          >
            {state.isMetalActivityCollapsed ? (
              <FormattedMessage
                id="customerMetalAccount.table.footer.showElement"
                defaultMessage="Mostra tutti i movimenti"
              />
            ) : (
              <FormattedMessage
                id="customerMetalAccount.table.footer.collapseElement"
                defaultMessage="Mostra meno movimenti"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MetalResumeAccountList;
