import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const deleteReservationDocument = (
  documentId: string,
  removeDocument: (documentId: string) => void,
): void => {
  standardClient({
    url: API.ATTACHMENT + '/' + documentId,
    method: 'DELETE',
  })
    .then(() => {
      toast.success('Documento eliminato correttamente');
      removeDocument(documentId);
    })
    .catch(() => toast.error('Impossibile eliminare il documento'));
};
