import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Image, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Form
import PasswordRecoveryForm from '../../components/Forms/PasswordRecoveryForm';

// Types and constants
import { PasswordRecoveryState } from './types';
import { FE_ROUTES } from 'utils/global/globalConstants';

// Images
import mainLogo from 'assets/images/main-logo.svg';
import registeredIcon from 'assets/images/signup-completed.svg';

const PasswordRecovery: React.FC = () => {
  /**
   * That state keeps the information about the current registration process.
   * Basically it's empty until the registration process will be succesful.
   */
  const [response, setResponse] = useState<PasswordRecoveryState>({
    sent: false,
    email: null,
  });

  return (
    <div className="password-recovery-container">
      <Helmet>
        <body className="password-recovery" />
        <title>MSM - login</title>
        <meta name="description" content="password recovery page" />
      </Helmet>
      <Image src={mainLogo} />

      {!response.sent && (
        <div className="password-recovery-form-container">
          <PasswordRecoveryForm setResponse={setResponse} />
        </div>
      )}
      {response.sent && (
        <div className="password-recovery-completed-container">
          <Image src={registeredIcon} />
          <span className="title-text">
            <FormattedMessage
              id="passwordRecovery.succesful.title"
              defaultMessage="La richiesta di recupero della password è avvenuta correttamente!"
            />
          </span>
          <span className="subtitle-text">
            <FormattedMessage
              id="passwordRecovery.succesful.subtitle"
              defaultMessage="Controlla tua la casella mail {email}."
              values={{
                email: <b>{response.email}</b>,
              }}
            />
          </span>
          <Segment.Inline>
            <Button
              className="home-button"
              type="button"
              fluid
              content={
                <Link to={FE_ROUTES.LOGIN}>
                  <FormattedMessage
                    id="toHome"
                    defaultMessage="Torna alla Home"
                  />
                </Link>
              }
            />
          </Segment.Inline>
        </div>
      )}
    </div>
  );
};

export default PasswordRecovery;
