import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CompleteMetalResumeTableColumns,
  CreditsLimitTableProps,
} from './types';
import SemanticTableGrid from 'semantic-table-grid';
import moment from 'moment';
import _ from 'lodash';

export const CreditsLimitTable: React.FC<CreditsLimitTableProps> = ({
  creditsLimit,
  currencyCode,
}) => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');

  const columns: Array<CompleteMetalResumeTableColumns> = [
    {
      key: 'documentType',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="creditsLimitTable.header.documentType"
            defaultMessage="Tipo documento"
          />
        </div>
      ),
      formatter: ({ data }) => data.documentType,
    },
    {
      key: 'documentNumber',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="creditsLimitTable.header.documentCode"
            defaultMessage="Nr. documento"
          />
        </div>
      ),
      formatter: ({ data }) => data.documentNo,
    },
    {
      key: 'documentDate',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="creditsLimitTable.header.documentDate"
            defaultMessage="Data documento"
          />
        </div>
      ),
      formatter: ({ data }) =>
        moment(data.documentDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      key: 'payment',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="creditsLimitTable.header.payment"
            defaultMessage="Metodo e termini pagamento"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <>
          {data.paymentMethod}
          <br />
          {data.paymentTerms}
        </>
      ),
    },
    {
      key: 'dueDate',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="creditsLimitTable.header.dueDate"
            defaultMessage="Data scadenza"
          />
        </div>
      ),
      formatter: ({ data }) =>
        moment(moment(data.dueDate, 'YYYY-MM-DD')).isBefore(moment()) ? (
          <span className="expired-creditsLimit">
            {moment(data.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          </span>
        ) : (
          moment(data.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        ),
    },
    {
      key: 'amount',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="creditsLimitTable.header.amount"
            defaultMessage="Importo"
          />
          {' (' + currencyCode + ')'}
        </div>
      ),
      formatter: ({ data }) =>
        moment(moment(data.dueDate, 'YYYY-MM-DD')).isBefore(moment()) ? (
          <span className="expired-creditsLimit">
            {intlFormatNumber.format(_.toNumber(data.amount))}
          </span>
        ) : (
          intlFormatNumber.format(_.toNumber(data.amount))
        ),
    },
    {
      key: 'expiredAmount',
      sortable: true,
      name: (
        <div className="metal-resume-table-header">
          <FormattedMessage
            id="creditsLimitTable.header.remainingAmount"
            defaultMessage="Importo residuo"
          />
          {' (' + currencyCode + ')'}
        </div>
      ),
      formatter: ({ data }) => (
        <>{intlFormatNumber.format(_.toNumber(data.remainingAmount))}</>
      ),
    },
  ];

  return (
    <div className="semantic-table-grid-standard">
      <SemanticTableGrid
        columns={columns}
        elements={creditsLimit}
        emptyResults={intl.formatMessage({
          id: 'creditsLimitTable.table.empty',
          defaultMessage: 'Nessun Elemento disponibile',
        })}
      />
    </div>
  );
};

export default CreditsLimitTable;
