import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { getCustomerContracts } from 'services/customer/getCustomerDocuments';

import SvgIcon from 'components/SvgIcon';
import { COLORS, FE_ROUTES, ICONS, ROLES } from 'utils/global/globalConstants';
import { CustomerContract } from './types';
import {
  ContractLineParams,
  CustomerDocumentsSupportedSort,
  CustomerDocumentsTableColumns,
} from '../CustomerDocumentsTab/types';
import CustomerDocumentsTab from '../CustomerDocumentsTab';
import _ from 'lodash';
import { CUSTOMER_DOCUMENT_METHODS } from 'utils/global/customerCostants';
import {
  getCustomerDocument,
  getCustomerDocumentFromEdok,
} from 'services/document/getCustomerDocuments';
import { AppContext } from 'pages/App';
import { useNavigate } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { isThatRole } from 'utils/function/acl';

const CustomerContracts: React.FC = () => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const navigate = useNavigate();
  const appState = useContext(AppContext).state;
  const selectedContact = appState.selectedContact;
  const mySelf = appState.mySelf;

  const [customerContractsCollection, setCustomerContractsCollection] =
    useState<Array<CustomerContract>>([]);
  const [
    showedCustomerContractsCollection,
    setShowedCustomerContractsCollection,
  ] = useState<Array<CustomerContract>>([]);

  const [areCustomerContractLoading, setAreCustomerContractLoading] =
    useState(false);
  const [activeSort, setActiveSort] =
    useState<CustomerDocumentsSupportedSort>('delivery-date-desc');

  useEffect(() => {
    // Some customer can have the contracts tab blocked
    // Eventually redirect it to the main page
    if (
      !mySelf._permissions.customerContractsEnabled &&
      !isThatRole(ROLES.CUSTOMER_AGENT, appState)
    ) {
      navigate(FE_ROUTES.DASHBOARD);
    }

    // Two different calls for customer or customer's agent profile
    if (isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      callContractsForAgent();
    } else {
      getCustomerContracts(
        setCompleteContractsCollection,
        setAreCustomerContractLoading,
      );
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callContractsForAgent;
  }, [selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callContractsForAgent = () => {
    if (!_.isEmpty(selectedContact)) {
      getCustomerContracts(
        setCompleteContractsCollection,
        setAreCustomerContractLoading,
        selectedContact.contactCode,
      );
    }
  };

  const setCompleteContractsCollection = (
    completeContractCollection: Array<CustomerContract>,
  ) => {
    setCustomerContractsCollection(completeContractCollection);
    const filteredByYearCollection = completeContractCollection.filter(
      contract =>
        moment(contract.contractDate, 'YYYY-MM-DD').isSame(moment(), 'year'),
    );
    setShowedCustomerContractsCollection(filteredByYearCollection);
  };

  const onFilter = (
    documentNumber: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    let contractsToShow = customerContractsCollection;

    if (!_.isEmpty(documentNumber)) {
      contractsToShow = contractsToShow.filter(
        contract =>
          _.isString(contract.no) &&
          contract.no.toLowerCase().includes(documentNumber.toLowerCase()),
      );
    }

    if (!_.isEmpty(dateFrom) && moment(dateFrom, 'DD-MM-YYYY').isValid) {
      contractsToShow = contractsToShow.filter(contract =>
        moment(dateFrom, 'DD-MM-YYYY').isSameOrBefore(contract.contractDate),
      );
    }

    if (!_.isEmpty(dateTo) && moment(dateTo, 'DD-MM-YYYY').isValid) {
      contractsToShow = contractsToShow.filter(contract =>
        moment(dateTo, 'DD-MM-YYYY').isSameOrAfter(contract.contractDate),
      );
    }

    setShowedCustomerContractsCollection(contractsToShow);
  };

  const onChangeSort = (sortType: CustomerDocumentsSupportedSort) => {
    if (sortType === activeSort) {
      return;
    }

    setShowedCustomerContractsCollection(
      onSortContractCollection(sortType, showedCustomerContractsCollection),
    );
    setActiveSort(sortType);
  };

  const onSortContractCollection = (
    sortType: CustomerDocumentsSupportedSort,
    contractCollection: Array<CustomerContract>,
  ): Array<CustomerContract> => {
    let toSortContracts = contractCollection;

    switch (sortType) {
      case 'order-number-desc': {
        toSortContracts = toSortContracts.sort(
          (firstContract, secondContract) => {
            if (firstContract.no > secondContract.no) {
              return -1;
            }

            return 1;
          },
        );
        break;
      }
      case 'delivery-date-asc': {
        toSortContracts = toSortContracts.sort(
          (firstContract, secondContract) =>
            moment(firstContract.contractDate, 'YYYY-MM-DD').diff(
              moment(secondContract.contractDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'delivery-date-desc': {
        toSortContracts = toSortContracts.sort(
          (firstContract, secondContract) =>
            moment(secondContract.contractDate, 'YYYY-MM-DD').diff(
              moment(firstContract.contractDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        toSortContracts = toSortContracts.sort(
          (firstContract, secondContract) => {
            if (firstContract.no > secondContract.no) {
              return 1;
            }
            return -1;
          },
        );
      }
    }

    return toSortContracts;
  };

  const getFilteredByYearsCollection = (
    selectedYear: string,
  ): Array<CustomerContract> => {
    if (moment(selectedYear, 'YYYY', true).isValid()) {
      return customerContractsCollection.filter(contract =>
        moment(contract.contractDate, 'YYYY-MM-DD').isSame(
          selectedYear,
          'year',
        ),
      );
    }
    return customerContractsCollection;
  };

  const onChangeSelectedYear = (selectedYear: string) => {
    setShowedCustomerContractsCollection(
      getFilteredByYearsCollection(selectedYear),
    );
    onChangeSort('delivery-date-desc');
  };

  const columns: Array<CustomerDocumentsTableColumns> = [
    {
      key: 'contractNumber',
      sortable: true,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'order-number-asc'
                ? 'order-number-desc'
                : 'order-number-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.contracts.table.header.contractNumber"
            defaultMessage="N. contratto"
          />
          {activeSort === 'order-number-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'order-number-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-documentNumber">{data.no}</div>
      ),
    },
    {
      key: 'contractDate',
      sortable: false,
      name: (
        <div
          className="customer-documents-container-header sortable-header"
          onClick={() =>
            onChangeSort(
              activeSort === 'delivery-date-asc'
                ? 'delivery-date-desc'
                : 'delivery-date-asc',
            )
          }
        >
          <FormattedMessage
            id="customerDocuments.contracts.table.header.contractDate"
            defaultMessage="Data contratto"
          />
          {activeSort === 'delivery-date-asc' && (
            <SvgIcon
              className="sort-icon"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
          {activeSort === 'delivery-date-desc' && (
            <SvgIcon
              className="sort-icon-rotate"
              icon={ICONS.ARROW_DOWN}
              color={COLORS.WHITE}
            />
          )}
        </div>
      ),
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-date">
          {moment(data.contractDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>
      ),
    },
    {
      key: 'price',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.contracts.table.header.price"
            defaultMessage="Prezzo €/T"
          />
        </div>
      ),
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-standard">
          {`${intlFormatNumber.format(
            _.toNumber(data.unitPrice) * 1000,
          )},00 €/t`}
        </div>
      ),
    },
    {
      key: 'totalQuantity',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.contracts.table.header.totalQuantity"
            defaultMessage="Quantità totale"
          />
        </div>
      ),
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-standard">
          {intlFormatNumber.format(_.toNumber(data.quantity))}{' '}
          {data.unitOfMeasure}
        </div>
      ),
    },
    {
      key: 'remainingQuantity',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.contracts.table.header.remainingQuantity"
            defaultMessage="Quantità residua"
          />
        </div>
      ),
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-standard">
          {intlFormatNumber.format(_.toNumber(data.remainingQuantity))}{' '}
          {data.unitOfMeasure}
        </div>
      ),
    },
    {
      key: 'open',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.contracts.table.header.closed"
            defaultMessage="Chiuso"
          />
        </div>
      ),
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-standard">
          {data.closed ? (
            <SvgIcon icon={ICONS.CHECK} color={COLORS.SUCCESS} />
          ) : (
            <SvgIcon icon={ICONS.CROSS} color={COLORS.SECONDARY} />
          )}
        </div>
      ),
    },
    {
      key: 'actions',
      sortable: false,
      width: 10,
      name: '',
      formatter: ({ data }: ContractLineParams) => (
        <div className="customer-documents-table-actions">
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.contracts.table.documents.tooltip.download.details"
                  defaultMessage="Scarica dettaglio contratto"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button margin-right"
                onClick={() =>
                  getCustomerDocument(
                    data.no,
                    CUSTOMER_DOCUMENT_METHODS.SALES_CONTRACTS_DETAILS,
                    intl,
                    selectedContact?.name,
                  )
                }
              >
                <SvgIcon icon={ICONS.CHECKLIST} height={20} width={20} />
              </span>
            }
          />
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                <FormattedMessage
                  id="customerDocuments.contracts.table.documents.tooltip.download"
                  defaultMessage="Scarica documento"
                />
              </span>
            }
            basic
            hoverable
            trigger={
              <span
                className="actionMenu-button"
                onClick={() =>
                  getCustomerDocumentFromEdok(
                    data.edokVolumeId,
                    data.edokDocumentId,
                    intl,
                    selectedContact?.name,
                  )
                }
              >
                <SvgIcon icon={ICONS.DOCUMENT} height={20} width={20} />
              </span>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <CustomerDocumentsTab
      activeSort={activeSort}
      areDocumentsLoading={areCustomerContractLoading}
      completeDocumentsCollection={showedCustomerContractsCollection}
      onFilter={onFilter}
      tableColumns={columns}
      filterInputPlaceholder={intl.formatMessage({
        id: 'customerDocuments.contracts.filterBar.input.placheolder',
        defaultMessage: 'N. contratto',
      })}
      key="customer-contracts"
      onChangeSelectedYear={onChangeSelectedYear}
    />
  );
};

export default CustomerContracts;
