import { AxiosResponse } from 'axios';
import {
  DocumentViewerAction,
  DocumentViewerConstants,
} from 'pages/DocumentViewer/reducer/action';
import { DocumentFileStates } from 'pages/DocumentViewer/types';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { GetDocoumentDataResponse } from './types';
import { FetchDocumentActionTypes } from 'utils/global/documentConstants';
import { IntlShape } from 'react-intl';

export const getDocuments = (documentId: string, intl: IntlShape): void => {
  // Show a notification with loading when a file is downloading
  const notifyStatus = toast.loading(
    intl.formatMessage({
      id: 'service.getDocuments.download.running',
      defaultMessage: 'Recupero del file in corso...',
    }),
    {
      closeOnClick: true,
    },
  );

  standardClient({
    url: API.DOCUMENT + '/' + documentId,
    method: 'GET',
    headers: { Accept: 'application/vnd.msm\\media' },
    responseType: 'arraybuffer',
  })
    .then((response: AxiosResponse) => {
      // Get the name from content-dispositon
      // Ex. string => content-dispositon: attachment;filename=sample.pdf
      const contentDisposition = response.headers['content-disposition'];
      const fileNameString = contentDisposition.split('filename=')[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameString || 'file.pdf');
      document.body.appendChild(link);
      link.click();

      // Show success message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.getDocuments.download.done',
          defaultMessage: 'File scaricato correttamente',
        }),
        type: 'success',
      });
    })
    .catch(() =>
      // Show error message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.getDocuments.download.error',
          defaultMessage: 'Impossibile scaricare il file richiesto',
        }),
        type: 'error',
      }),
    );
};

export const getDocumentData = (
  dispatch: React.Dispatch<DocumentViewerAction>,
  documentId: string,
  attachmentId: string,
  contactId: string,
): void => {
  // Set the data loader
  dispatch({
    type: DocumentViewerConstants.SET_DOCUMENT_DATA_LOADING,
    payload: { areDataLoading: true },
  });

  // GET to BE's attachment module
  standardClient({
    url: API.CONTACT_DOCUMENT + '/' + documentId,
    method: 'GET',
    params: {
      attachmentId: attachmentId,
      contactId: contactId,
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then((response: AxiosResponse<GetDocoumentDataResponse>) => {
      // Add the document data to component state
      dispatch({
        type: DocumentViewerConstants.SET_DOCUMENT_DATA,
        payload: { documentData: response.data },
      });
    })
    .catch((error: AxiosResponse) => {
      // If the enitity doesn't exists, notify an error and ridirect
      // to homepage
      if (error?.status === 404) {
        toast.error('Il documento richiesto non è stato trovato');
      } else {
        toast.error('Impossibile recuperare il documento');
      }
    })
    .finally(() => {
      // Remove the loader
      dispatch({
        type: DocumentViewerConstants.SET_DOCUMENT_DATA_LOADING,
        payload: { areDataLoading: false },
      });
    });
};

export const getDocumentFile = (
  setDocumentFile: (file: File) => void,
  setDocumentFileState: (documentFileState: DocumentFileStates) => void,
  documentId: string,
  attachmentId?: string,
): void => {
  setDocumentFileState('loading');
  standardClient({
    url: API.CONTACT_DOCUMENT + '/' + documentId,
    method: 'GET',
    headers: { Accept: 'application/vnd.msm\\media' },
    responseType: 'arraybuffer',
    params: {
      actionType: FetchDocumentActionTypes.GET_MEDIA,
      attachmentId: attachmentId,
    },
    paramsSerializer: params => QueryString.stringify(params),
  })
    .then((response: AxiosResponse) => {
      const contentDisposition = response.headers['content-disposition'];
      const fileNameString = contentDisposition.split('filename=')[1];
      const file = new File([response.data], fileNameString, {
        type: response.headers['content-type'],
      });

      setDocumentFile(file);
    })
    .catch((error: AxiosResponse) => {
      // If the entity doesn't exists doesn't show anything
      // (the error is already showed by the service above).
      // Otherwise notify an error
      if (error?.status !== 404) {
        toast.error('Impossibile mostrare il documento');
      }

      setDocumentFileState('error');
    });
};

export const getPreviewDocument = (
  documentId: string,
  setIsLoading: (isLoading: boolean) => void,
  setDocumentFile: (file: File) => void,
): void => {
  setIsLoading(true);

  standardClient({
    url: API.DOCUMENT + '/' + documentId,
    method: 'GET',
    headers: { Accept: 'application/vnd.msm\\media' },
    responseType: 'arraybuffer',
  })
    .then((response: AxiosResponse) => {
      const contentDisposition = response.headers['content-disposition'];
      const fileNameString = contentDisposition.split('filename=')[1];
      const file = new File([response.data], fileNameString, {
        type: response.headers['content-type'],
      });

      setDocumentFile(file);
    })
    .catch(error => {
      // If the entity doesn't exists doesn't show anything
      // (the error is already showed by the service above).
      // Otherwise notify an error
      if (error?.status !== 404) {
        toast.error('Impossibile mostrare il documento');
      }
    })
    .finally(() => setIsLoading(false));
};
