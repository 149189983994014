import React, { useState } from 'react';
import {
  Form,
  Input,
  ResetButton,
  SubmitButton,
} from 'formik-semantic-ui-react';
import { Formik } from 'formik';
import _ from 'lodash';
import CalendarPicker from 'components/CalendarPicker';
import moment from 'moment';
import * as Yup from 'yup';
import { DATE_REGEX, ICONS } from 'utils/global/globalConstants';
import SvgIcon from 'components/SvgIcon';
import { useIntl } from 'react-intl';
import { CustomerDocumentsFilterBarProps } from './types';
import CustomerDocumentsYearsFilter from '../CustomerDocumentsYearsFilter';

export const CustomerDocumentsFilterBar: React.FC<
  CustomerDocumentsFilterBarProps
> = ({
  changeSelectedYear,
  filterPlaceholder = 'Inserisci un valore',
  fromDatePlaceholder = 'Da',
  onFilter,
  onRest,
  selectedYear,
  toDatePlaceholder = 'A',
}) => {
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState(false);
  const [isSecondCalendarPickerOpen, setIsSecondCalendarPickerOpen] =
    useState(false);
  const intl = useIntl();
  const dateError = intl.formatMessage({
    id: 'orders.filters.bar.error.date',
    defaultMessage: 'Inserire una data valida nel formato GG-MM-YYYY',
  });

  return (
    <Formik
      initialValues={{
        orderNumber: '',
        filteredDateFrom: '',
        filteredDateTo: '',
      }}
      onSubmit={values =>
        onFilter(
          values.orderNumber,
          values.filteredDateFrom,
          values.filteredDateTo,
        )
      }
      validationSchema={Yup.object().shape({
        filteredDateFrom: Yup.string()
          .matches(DATE_REGEX, dateError)
          .nullable(),
        filteredDateTo: Yup.string().matches(DATE_REGEX, dateError).nullable(),
      })}
    >
      {({ setFieldValue, values, errors }) => (
        <>
          <div className="orders-filters-bar">
            <Form>
              <Input
                className="orders-number-input"
                placeholder={filterPlaceholder}
                name="orderNumber"
              />
              <Input
                name="filteredDateFrom"
                className="orders-date-input"
                placeholder={fromDatePlaceholder}
                icon={
                  <span
                    className="calendar-modal-button"
                    onClick={() => setIsCalendarPickerOpen(true)}
                  >
                    <SvgIcon
                      icon={ICONS.CALENDAR_FLAT}
                      width={22}
                      height={22}
                    />
                  </span>
                }
              />
              <Input
                name="filteredDateTo"
                className="orders-date-input"
                placeholder={toDatePlaceholder}
                icon={
                  <span
                    className="calendar-modal-button"
                    onClick={() => setIsSecondCalendarPickerOpen(true)}
                  >
                    <SvgIcon
                      icon={ICONS.CALENDAR_FLAT}
                      width={22}
                      height={22}
                    />
                  </span>
                }
              />
              <SubmitButton
                className="orders-filters-search"
                content={intl.formatMessage({
                  id: 'customerDocuments.filterBar.button.search',
                  defaultMessage: 'Cerca',
                })}
                loading={false}
              />
              <ResetButton
                className="underlineButton orders-filters-reset"
                content={intl.formatMessage({
                  id: 'customerDocuments.filterBar.button.reset',
                  defaultMessage: 'Rimuovi i filtri attivi',
                })}
                onClick={() => onRest()}
              />
              {(!_.isEmpty(errors.filteredDateFrom) ||
                !_.isEmpty(errors.filteredDateTo)) && (
                <span className="default-error-message">
                  Inserire una data valida nel formato GG-MM-YYYY
                </span>
              )}

              <CalendarPicker
                isOpen={isCalendarPickerOpen}
                onClose={() => setIsCalendarPickerOpen(false)}
                onChange={(date: Date) => {
                  const formattedDate = moment(date)
                    .format('DD-MM-YYYY')
                    .toString();
                  setFieldValue('filteredDateFrom', formattedDate);
                }}
                value={
                  moment(values.filteredDateFrom, 'DD-MM-YYYY').isValid()
                    ? moment(values.filteredDateFrom, 'DD-MM-YYYY')
                        .format('MM-DD-YYYY')
                        .toString()
                    : null
                }
              />
              <CalendarPicker
                isOpen={isSecondCalendarPickerOpen}
                onClose={() => setIsSecondCalendarPickerOpen(false)}
                onChange={(date: Date) => {
                  const formattedDate = moment(date)
                    .format('DD-MM-YYYY')
                    .toString();
                  setFieldValue('filteredDateTo', formattedDate);
                }}
                value={
                  moment(values.filteredDateTo, 'DD-MM-YYYY').isValid()
                    ? moment(values.filteredDateTo, 'DD-MM-YYYY')
                        .format('MM-DD-YYYY')
                        .toString()
                    : null
                }
              />
            </Form>
          </div>
          <CustomerDocumentsYearsFilter
            changeSelectedYear={changeSelectedYear}
            selectedYear={selectedYear}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    </Formik>
  );
};

export default CustomerDocumentsFilterBar;
