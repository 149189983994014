import _ from 'lodash';

// ENV name variables
export const DEVELOPMENT_ENV = 'development';
export const TEST_ENV = 'test';
export const STAGING_ENV = 'staging';
export const PRODUCTION_ENV = 'production';

/**
 * EXTERNAL CONFIG
 * This configuration can be overwritten using the config.json files.
 */
export let API = {
  ADMIN_CONTACT_LOGIN: '/admin-contact-login',
  AGENT_LOGIN: '/agent-login',
  AGENT_PURCHASER_LIST: '/get-purchaser-list',
  ATTACHMENT: '/attachment',
  BASE_URL: null,
  BOOKING_AVAILABILITY: '/booking-availability',
  CER_LIST: '/cer-list',
  CHANGE_PREFERRED_PROFILE: '/change-preferred-profile',
  CONTACT_DOCUMENT: '/contact-document',
  CONTACT_ORIGIN: '/contact-origins',
  CONTACT_ORIGINS_LIST: '/contact-origins-list',
  CUSTOMER_DASHBOARD: '/customer-dashboard',
  CUSTOMER_DATA: '/customer-data-service',
  CUSTOMER_DATA_COLLECTION: '/customer-data-collection',
  CUSTOMER_DOCUMENT: '/customer-documents',
  CUSTOMER_METAL_RESUME: '/customer-metal-resume',
  CUSTOMER_METAL_RESUME_AVAILABILITY: '/customer-metal-account',
  DOCUMENT: '/attachment',
  DOCUMENTS_LIST_TYPE: '/contact-documents-list',
  ENVIRONMENTAL_CLASSES_LIST: '/environmental-classes-list',
  FIRST_AUTH_TOKEN: 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=',
  GET_ADMIN_DASHBOARD: '/administration-dashboard',
  GET_CUSTOMER_ITEMS: '/get-customer-items',
  MYSELF: '/myself',
  NATIONALITY: '/country',
  OAUTH_GOOGLE_POPUP: '/hybridauth/login/Google',
  OAUTH_GRANT_PASSWORD: '/oauth',
  OAUTH_GRAPH_POPUP: '/hybridauth/login/MicrosoftGraph',
  OAUTH_HYBRID: '/hybridauth',
  PASSWORD_RECOVERY: '/password-recovery',
  PERMISSIONS: '/permission',
  REGISTER: '/subscription-request',
  REGISTER_TOKEN: 'Basic RnJvbnRlbmRHdWVzdDpLUmdDfXF5PXc2bl57U0dQXiFUVA==',
  RESERVATION: '/reservation',
  RESERVATION_CALENDAR: '/reservation-calendar',
  RESOURCES: '/resource',
  ROLES: '/role',
  SIGNUP_CONTACT_REQUEST: '/signup-contact-request',
  SIGNUP_DOCUMENTS_REQUEST: '/signup-documents',
  SIGNUP_TOKEN: 'Basic RnJvbnRlbmRHdWVzdDpLUmdDfXF5PXc2bl57U0dQXiFUVA==',
  SUPPLIER: '/contact',
  SUPPLIER_DASHBOARD: '/contact-dashboard',
  SUPPLIER_DOCUMENT: 'contact-document',
  SUPPLIER_ORDERS: '/supplier-orders',
  USERS: '/user',
  USER_MANAGER: '/user-manager',
};

let loaded = false;

/**
 * This function expose a setter in order to config the application in the very early phase.
 * (after change it the application in auto-restarted)
 */
export const setConfig: (cfg: Record<string, unknown>) => void = config => {
  API = _.merge(API, config);
  loaded = true;
};

export const isLoaded: () => boolean = () => loaded;
