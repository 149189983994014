import { CertificateElement } from './types';

//Icons
import aia from 'assets/images/certificates/AIA.jpg';
import iqnet from 'assets/images/certificates/IQNet.jpg';
import iatf16949 from 'assets/images/certificates/SQS-IATF-16949.jpg';
import iso9001 from 'assets/images/certificates/SQS-ISO-9001.jpg';
import reach from 'assets/images/certificates/REACH.jpg';
import rohs from 'assets/images/certificates/ROHS.jpg';
import scip from 'assets/images/certificates/SCIP.jpg';
import sqs45001 from 'assets/images/certificates/SQS-ISO-45001.jpg';

export const certificateList: Array<CertificateElement> = [
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2023/11/ATTO-DIRIGENZIALE-2948-23-MSM.pdf',
    iconSrc: aia,
    id: 1,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2021/05/RoHS_IT_2021.pdf',
    iconSrc: rohs,
    id: 2,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2021/01/cert-scip.pdf',
    iconSrc: scip,
    id: 3,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2024/02/Reach-dichiarazione-01.2024.pdf',
    iconSrc: reach,
    id: 4,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2022/11/msm_111721_iq_36181.pdf',
    iconSrc: iqnet,
    id: 5,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2022/11/msm_111721_it_36181.pdf',
    iconSrc: sqs45001,
    id: 6,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2021/10/111721_it_IATF-16949_21.pdf',
    iconSrc: iatf16949,
    id: 7,
  },
  {
    downloadLink:
      'https://www.metallurgicasanmarco.it/wp-content/uploads/2021/10/111721_it_9001_21.pdf',
    iconSrc: iso9001,
    id: 8,
  },
];
