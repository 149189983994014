import DocViewer from 'components/DocumentViewer/DocViewer';
import PageHeader from 'components/PageHeader';
import React, { createContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {
  getDocumentData,
  getDocumentFile,
} from 'services/document/getDocuments';
import {
  DocumentViewerAction,
  DocumentViewerConstants,
} from './reducer/action';
import {
  documentViewerReducer,
  initialDocumentViewerState,
} from './reducer/reducer';
import { DocumentFileStates, DocumentViewerState } from './types';

export const DocumentViewerContext = createContext<{
  state: DocumentViewerState;
  dispatch: React.Dispatch<DocumentViewerAction>;
}>({
  state: initialDocumentViewerState,
  dispatch: () => null,
});

const DocumentViewer: React.FC = () => {
  // Required document Id from URL
  const { documentId, attachmentId, contactId } = useParams();

  // Initialize reducer
  const [state, dispatch] = useReducer(
    documentViewerReducer,
    initialDocumentViewerState,
  );

  // Called only on component mount
  useEffect(() => {
    // Require the document with ID in URL, if it doesn't exists or
    // you don't have permission redirect to homepage
    getDocumentData(dispatch, documentId, attachmentId, contactId);

    // Require the document file for show it directly in the frame
    // of the page.
    getDocumentFile(
      setDocumentFile,
      setDocumentFileState,
      documentId,
      attachmentId,
    );
  }, []);

  const setDocumentFile = (file: File) => {
    // Add the document data to component state
    dispatch({
      type: DocumentViewerConstants.SET_DOCUMENT_FILE,
      payload: { documentFile: file },
    });
  };

  const setDocumentFileState = (documentFileState: DocumentFileStates) => {
    // Set the data loader
    dispatch({
      type: DocumentViewerConstants.SET_DOCUMENT_FILE_STATE,
      payload: { documentFileState: documentFileState },
    });
  };

  return (
    <DocumentViewerContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>MSM - Documento</title>
      </Helmet>
      <PageHeader
        title={`${
          state.documentData?.document?.documentDesc
            ? state.documentData.document.documentDesc
            : ''
        }`}
      />
      <DocViewer />
    </DocumentViewerContext.Provider>
  );
};

export default DocumentViewer;
