import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';

// Internals and constants
import { clearAuthObject } from 'utils/function/localStorage';
import { API } from 'utils/global/backendRoutes';
import { CUSTOM_EVENT } from 'utils/global/globalConstants';
import { INVALID_USER_DATA } from 'utils/global/standardError';

/**
 * Define a specific client only for the requests about login
 * and access_token managment as the request for refresh the token.
 * The authentication header is set to default with the client token.
 */
const authClient = axios.create();

// Set default client token to the request
authClient.interceptors.request.use(
  (configuration: AxiosRequestConfig) => {
    let authObject = API.FIRST_AUTH_TOKEN;
    if (configuration.headers) {
      authObject = configuration.headers.Authorization || API.FIRST_AUTH_TOKEN;
    }

    return _.merge(configuration, {
      baseURL: API.BASE_URL,
      method: 'POST',
      headers: { Authorization: authObject },
    });
  },
  error => Promise.reject(error),
);

authClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const errorStatus = error.response.status;
    const errorText = error.response.statusText;

    // 400 - Bad request - invald_grant
    // Impossible to login the user, revoke the session
    if (errorStatus === 400 && errorText === INVALID_USER_DATA) {
      // If the user is not Authenticated, set Logout and clear the locale Storage
      clearAuthObject();
      const event = new CustomEvent(CUSTOM_EVENT.LOGOUT);
      window.dispatchEvent(event);
    }

    // Reject the promise and handle the error in the catch
    return Promise.reject(error.response);
  },
);

export default authClient;
