import { AxiosResponse } from 'axios';
import authClient from 'services/client/authRequestClient';
import { toast } from 'react-toastify';

// Internals and constants
import { API } from 'utils/global/backendRoutes';
import { SignupAction, SignupConstants } from 'pages/Signup/reducer/action';

// Types
import { Nullable } from 'utils/types';
import { SignupContactRequestResponse } from './types';
import { SignupDataFormValues } from 'pages/Signup/types';
import { StandardResponseError } from 'services/client/types';
import { IntlShape } from 'react-intl';

/**
 * New Xontact reuqest, also a record on NAV will be created
 *
 * @param dispatch Callback to components
 * @param data Request body data
 * @param signupType Type of singup - customer or supplier
 * @param acceptLanguage Default language selected in the app
 * @param token Recapatcha token generated for the request
 */
export const signupContactRequest = (
  dispatch: React.Dispatch<SignupAction>,
  data: SignupDataFormValues,
  signupType: 'customer' | 'supplier',
  acceptLanguage: Nullable<string> = null,
  token: Nullable<string> = null,
  intl: IntlShape,
): void => {
  dispatch({
    type: SignupConstants.SET_IS_LOADING,
    payload: { isLoading: true },
  });

  // Clear eventual error of previous register attempt
  dispatch({
    type: SignupConstants.CLEAR_ERROR_OBJECT,
  });

  let headers: { Authorization: string; 'Accept-Language'?: string } = {
    Authorization: API.SIGNUP_TOKEN,
  };
  if (acceptLanguage) {
    headers = { ...headers, 'Accept-Language': acceptLanguage };
  }

  authClient
    .request({
      headers,
      url: API.SIGNUP_CONTACT_REQUEST,
      method: 'POST',
      data: {
        ...data,
        signupType: signupType,
        recaptcha_response: token,
      },
    })
    .then((response: AxiosResponse<SignupContactRequestResponse>) => {
      dispatch({
        type: SignupConstants.SET_REQUESTED_DOCUMENT,
        payload: { requestedDocument: response.data.requiredDocuments },
      });
      dispatch({
        type: SignupConstants.CHANGE_SIGNUP_PHASE,
        payload: { signupPhase: 'documentUploading' },
      });
      dispatch({
        type: SignupConstants.SET_CONTACT_ID,
        payload: { contactId: response.data.contactId },
      });
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      // Vat number already exists
      if (error.data.status === 409) {
        toast.error(
          intl.formatMessage({
            id: 'services.signup.signupContactRequest.vatNumber',
            defaultMessage:
              'Impossibile completare la registrazione, si prega di contattare direttamente Metallurgica San Marco',
          }),
        );
      } else {
        toast.error(
          intl.formatMessage({
            id: 'services.signup.signupContactRequest.general',
            defaultMessage: 'Impossibile completare la registrazione',
          }),
        );
      }
      if (error.data && error.data.validation_messages) {
        dispatch({
          type: SignupConstants.SET_ERROR_OBJECT,
          payload: {
            errorObject: error.data.validation_messages,
          },
        });
      }
    })
    .finally(() =>
      dispatch({
        type: SignupConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      }),
    );
};

export const signupDocumentsRequest = (
  dispatch: React.Dispatch<SignupAction>,
  data: FormData,
  token?: string,
): void => {
  // Set loading
  dispatch({
    type: SignupConstants.SET_IS_LOADING,
    payload: { isLoading: true },
  });

  // Clear eventual error of previous register attempt
  dispatch({
    type: SignupConstants.CLEAR_ERROR_OBJECT,
  });

  // If Recaptcha token exist set it in the data
  if (token) {
    data.append('recaptcha_response', token);
  }

  authClient
    .request({
      headers: { Authorization: API.SIGNUP_TOKEN },
      url: API.SIGNUP_DOCUMENTS_REQUEST,
      method: 'POST',
      data: data,
    })
    .then(() => {
      dispatch({
        type: SignupConstants.CHANGE_SIGNUP_PHASE,
        payload: { signupPhase: 'completed' },
      });
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      toast.error('Impossibile completare la registrazione');
      if (error.data && error.data.validation_messages) {
        dispatch({
          type: SignupConstants.SET_ERROR_OBJECT,
          payload: {
            errorObject: error.data.validation_messages,
          },
        });
      }
    })
    .finally(() =>
      dispatch({
        type: SignupConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      }),
    );
};
