import React, { useContext, useEffect } from 'react';
import { ReservationListContext } from 'pages/ReservationList';
import { fetchReservationList } from 'services/reservation/fetchReservation';
import { ReservationStateConstants } from 'utils/global/reservationConstants';
import AccordionList from '../AccordionList';

const InWorkingList: React.FC = () => {
  const { dispatch } = useContext(ReservationListContext);

  useEffect(() => {
    fetchReservationList(
      [
        ReservationStateConstants.NEW,
        ReservationStateConstants.BASE_INFORMATION,
        ReservationStateConstants.DELIVERY_INFORMATION,
        ReservationStateConstants.DOCUMENT_UPLOADING,
      ],
      dispatch,
    );
  }, []);

  return <AccordionList />;
};

export default InWorkingList;
