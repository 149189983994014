import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { DropdownItemProps } from 'semantic-ui-react';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { AgentLoginResponse, GetDocumentsTypeListResponse } from './types';
import { LOCAL_STORAGE } from 'utils/global/globalConstants';
import { NavigateFunction } from 'react-router-dom';

export const getAgentPurchaserList = (
  setPurchaserList: (documentsList: Array<DropdownItemProps>) => void,
  setIsLoading: (isLoading: boolean) => void,
): void => {
  setIsLoading(true);
  standardClient({
    url: API.AGENT_PURCHASER_LIST,
    method: 'GET',
  })
    .then((response: AxiosResponse<GetDocumentsTypeListResponse>) => {
      setPurchaserList(response.data.purchaserList);
    })
    .catch(() => {
      toast.error("Impossibile caricare la lsita dei contatti dell'agente");
    })
    .finally(() => setIsLoading(false));
};

export const agentLoginService = (
  contactCode: string,
  navigate: NavigateFunction,
): void => {
  standardClient({
    url: API.AGENT_LOGIN,
    method: 'POST',
    data: {
      contactCode,
    },
  })
    .then((response: AxiosResponse<AgentLoginResponse>) => {
      localStorage.setItem(
        LOCAL_STORAGE.ACCESS_TOKEN,
        response.data.accessToken,
      );
      navigate(0);
    })
    .catch(() => {
      toast.error('Impossibile eseguire la login');
    });
};
