import React, { createContext, useReducer } from 'react';
import {
  contactOriginsReducer,
  initialContactOriginsState,
} from './reducer/reducer';
import { ContactOriginsListState } from './types';
import { ContactOriginsListAction } from './reducer/actions';
import ContactOriginsForContact from 'components/ContactOriginsList/ContactOriginsForContact';

export const ContactOriginsListContext = createContext<{
  state: ContactOriginsListState;
  dispatch: React.Dispatch<ContactOriginsListAction>;
}>({
  state: initialContactOriginsState,
  dispatch: () => null,
});

const ContactOriginsList: React.FC = () => {
  const [state, dispatch] = useReducer(
    contactOriginsReducer,
    initialContactOriginsState,
  );

  return (
    <ContactOriginsListContext.Provider value={{ state, dispatch }}>
      <ContactOriginsForContact />
    </ContactOriginsListContext.Provider>
  );
};

export default ContactOriginsList;
