import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import Styled from 'styled-components';

// Internals and constants
import {
  FE_ROUTES,
  HEIGHT_TOPBAR,
  ICONS,
  MAIN_CONTENT_BACKGROUND,
  ROLES,
  WIDTH_SIDEBAR,
} from 'utils/global/globalConstants';

// Layout
import Topbar from 'layout/Topbar';
import SideBar from 'layout/SideBar';

// Types
import { MenuItem } from 'layout/SideBar/types';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';
import { MySelf } from 'services/auth/types';
import _ from 'lodash';

export const adminSidebarMenu: Array<MenuItem> = [
  {
    id: 'menu',
    menuTitle: <FormattedMessage id="menu" defaultMessage="Menu" />,
    menuElements: [
      {
        elementTitle: (
          <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
        ),
        icon: ICONS.DASHBOARD,
        id: 'dashboard',
        linkAddress: FE_ROUTES.DASHBOARD,
      },
      {
        elementTitle: (
          <FormattedMessage id="reservations" defaultMessage="Prenotazioni" />
        ),
        icon: ICONS.CALENDAR,
        id: 'reservations',
        linkAddress: FE_ROUTES.RESERVATION_LIST + '/toApprove',
      },
      {
        elementTitle: (
          <FormattedMessage id="calendar" defaultMessage="Calendario" />
        ),
        icon: ICONS.CALENDAR_FLAT,
        id: 'calendar',
        linkAddress: FE_ROUTES.CALENDAR,
      },
    ],
  },
  {
    id: 'administration',
    menuTitle: (
      <FormattedMessage id="administration" defaultMessage="Amministrazione" />
    ),
    menuElements: [
      /*       {
        elementTitle: (
          <FormattedMessage
            id="newUserRequests"
            defaultMessage="Richieste nuovi utenti"
          />
        ),
        icon: ICONS.USER,
        id: 'newUserRequests',
        linkAddress: FE_ROUTES.NEW_USER_REQUESTS,
      }, */
      {
        elementTitle: (
          <FormattedMessage
            id="standardLayout.menu.admin.adminLogin"
            defaultMessage="Impersonifica utente"
          />
        ),
        icon: ICONS.GEAR,
        id: 'adminContactLogin',
        linkAddress: FE_ROUTES.ADMIN_CONTACT_LOGIN,
      },
    ],
  },
];

export const noviceSupplierSidebarMenu: Array<MenuItem> = [
  {
    id: 'menu',
    menuTitle: <FormattedMessage id="menu" defaultMessage="Menu" />,
    menuElements: [
      {
        elementTitle: (
          <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
        ),
        icon: ICONS.DASHBOARD,
        id: 'dashboard',
        linkAddress: FE_ROUTES.DASHBOARD,
      },
      {
        elementTitle: (
          <FormattedMessage
            id="documents-novice-contact"
            defaultMessage="Documenti"
          />
        ),
        icon: ICONS.DOCUMENT,
        id: 'documents',
        linkAddress: FE_ROUTES.DOCUMENTS,
      },
    ],
  },
];

export const supplierSidebarMenu: Array<MenuItem> = [
  {
    id: 'menu',
    menuTitle: <FormattedMessage id="menu" defaultMessage="Menu" />,
    menuElements: [
      {
        elementTitle: (
          <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
        ),
        icon: ICONS.DASHBOARD,
        id: 'dashboard',
        linkAddress: FE_ROUTES.DASHBOARD,
      },
      {
        elementTitle: (
          <FormattedMessage id="reservations" defaultMessage="Prenotazioni" />
        ),
        icon: ICONS.CALENDAR,
        id: 'reservations',
        linkAddress: FE_ROUTES.RESERVATION_LIST + '/toApprove',
      },
      {
        elementTitle: <FormattedMessage id="orders" defaultMessage="Ordini" />,
        icon: ICONS.CHECKLIST,
        id: 'orders',
        linkAddress: FE_ROUTES.ORDERS,
      },
      {
        elementTitle: (
          <FormattedMessage id="documents-contact" defaultMessage="Documenti" />
        ),
        icon: ICONS.DOCUMENT,
        id: 'documents',
        linkAddress: FE_ROUTES.DOCUMENTS,
      },
      {
        elementTitle: (
          <FormattedMessage id="origins" defaultMessage="Provenienze" />
        ),
        icon: ICONS.TRUCK_FRONTAL,
        id: 'documents',
        linkAddress: FE_ROUTES.ORIGINS_CONTACT,
      },
    ],
  },
];

export const customerSidebarMenu = (myself: MySelf): Array<MenuItem> => [
  {
    id: 'menu',
    menuTitle: <FormattedMessage id="menu" defaultMessage="Menu" />,
    menuElements: _.compact([
      {
        elementTitle: (
          <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
        ),
        icon: ICONS.DASHBOARD,
        id: 'dashboard',
        linkAddress: FE_ROUTES.DASHBOARD,
      },
      {
        elementTitle: (
          <FormattedMessage
            id="documents-customer"
            defaultMessage="Documenti"
          />
        ),
        icon: ICONS.DOCUMENT,
        id: 'documents',
        linkAddress: FE_ROUTES.CUSTOMER_DOCUMENTS + '/orders',
      },
      myself._permissions.customerMetalAccountEnabled
        ? {
            elementTitle: (
              <FormattedMessage
                id="customerPersonalDocuments"
                defaultMessage="Documenti validazione"
              />
            ),
            icon: ICONS.CHECKLIST,
            id: 'customerPersonalDocument',
            linkAddress: FE_ROUTES.DOCUMENTS,
          }
        : null,
      myself._permissions.customerMetalAccountEnabled
        ? {
            elementTitle: (
              <FormattedMessage
                id="metalResume"
                defaultMessage="Estratto conto metallo"
              />
            ),
            icon: ICONS.WEIGHT,
            id: 'metalResume',
            linkAddress: FE_ROUTES.CUSTOMER_METAL_RESUME,
          }
        : null,
      myself._permissions.customerMetalAccountEnabled
        ? {
            elementTitle: (
              <FormattedMessage
                id="customer-metal-availability"
                defaultMessage="Copertura metallo"
              />
            ),
            icon: ICONS.PENCIL,
            id: 'documents',
            linkAddress: FE_ROUTES.CUSTOMER_METAL_AVAILABILITY,
          }
        : null,
      myself._permissions.customerBookingEnabled
        ? {
            elementTitle: (
              <FormattedMessage
                id="sidebar.menu.costumer.reservations"
                defaultMessage="Prenotazioni"
              />
            ),
            icon: ICONS.CALENDAR,
            id: 'reservations',
            linkAddress: FE_ROUTES.CUSTOMER_RESERVATION_LIST + '/toApprove',
          }
        : null,
      myself._permissions.customerMetalAccountEnabled
        ? {
            elementTitle: (
              <FormattedMessage id="origins" defaultMessage="Provenienze" />
            ),
            icon: ICONS.TRUCK_FRONTAL,
            id: 'documents',
            linkAddress: FE_ROUTES.ORIGINS_CONTACT,
          }
        : null,
    ]),
  },
];

export const agentSidebarMenu: Array<MenuItem> = [
  {
    id: 'menu',
    menuTitle: <FormattedMessage id="menu" defaultMessage="Menu" />,
    menuElements: [
      {
        elementTitle: (
          <FormattedMessage id="selectUser" defaultMessage="Selezione utente" />
        ),
        icon: ICONS.USER,
        id: 'selectUser',
        linkAddress: FE_ROUTES.DASHBOARD,
      },
    ],
  },
];

export const customerAgentSidebar: Array<MenuItem> = [
  {
    id: 'menu',
    menuTitle: <FormattedMessage id="menu" defaultMessage="Menu" />,
    menuElements: [
      {
        elementTitle: (
          <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
        ),
        icon: ICONS.DASHBOARD,
        id: 'dashboard',
        linkAddress: FE_ROUTES.DASHBOARD,
      },
      {
        elementTitle: (
          <FormattedMessage
            id="customer-agent-documents"
            defaultMessage="Documenti"
          />
        ),
        icon: ICONS.CHECKLIST,
        id: 'agentCustomerDocuments',
        linkAddress: FE_ROUTES.AGENT_CUSTOMER_DOCUMENTS + '/orders',
      },
      {
        elementTitle: (
          <FormattedMessage
            id="customer-agent-metalResume"
            defaultMessage="Estratto conto metallo"
          />
        ),
        icon: ICONS.WEIGHT,
        id: 'agentCosutmerMetalResume',
        linkAddress: FE_ROUTES.AGENT_CUSTOMER_METAL_RESUME,
      },
      {
        elementTitle: (
          <FormattedMessage
            id="customer-agent-metal-availability"
            defaultMessage="Copertura metallo"
          />
        ),
        icon: ICONS.PENCIL,
        id: 'agentCostumerMetalAvailability',
        linkAddress: FE_ROUTES.AGENT_CUSTOMER_METAL_AVAILABILITY,
      },
      {
        elementTitle: (
          <FormattedMessage
            id="customer-agent-credit-limits"
            defaultMessage="Situazione contabile"
          />
        ),
        icon: ICONS.FILE,
        id: 'agentCostumerCreditsLimit',
        linkAddress: FE_ROUTES.AGENT_CUSTOMER_CREDITS_LIMIT,
      },
    ],
  },
];

const MainContent = Styled.div`
    padding-top: ${HEIGHT_TOPBAR};
    padding-left: ${WIDTH_SIDEBAR};
    background: ${MAIN_CONTENT_BACKGROUND};
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

const StandardLayout: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const getMenuItems = () => {
    if (!state.mySelf) {
      return [];
    }

    if (isThatRole(ROLES.ADMIN)) {
      return adminSidebarMenu;
    }

    if (isThatRole(ROLES.NOVICE_CONTACT)) {
      return noviceSupplierSidebarMenu;
    }

    if (isThatRole(ROLES.SUPPLIER)) {
      return supplierSidebarMenu;
    }

    if (isThatRole(ROLES.AGENT)) {
      return agentSidebarMenu;
    }

    if (isThatRole(ROLES.CUSTOMER)) {
      return customerSidebarMenu(state.mySelf);
    }

    if (isThatRole(ROLES.CUSTOMER_AGENT)) {
      return customerAgentSidebar;
    }

    return [];
  };

  return (
    <>
      <SideBar
        appState={state}
        dispatchAppState={dispatch}
        menuItems={getMenuItems()}
      />
      <Topbar />
      <MainContent>
        <Outlet />
      </MainContent>
    </>
  );
};

export default StandardLayout;
