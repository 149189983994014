import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SemanticTableGrid from 'semantic-table-grid';
import _ from 'lodash';

import {
  MetalAvailabilityContractsTableProps,
  MetalAvailabilityContractsColumn,
} from './types';
import moment from 'moment';

const MetalAvailabilityContractsTable: React.FC<
  MetalAvailabilityContractsTableProps
> = ({ contracts }) => {
  const intl = useIntl();
  const intlFormatNumber = new Intl.NumberFormat('it-IT');

  const columns: Array<MetalAvailabilityContractsColumn> = [
    {
      key: 'contractDate',
      sortable: true,
      name: (
        <FormattedMessage
          id="metalAvailabilityContracts.table.header.contractDate"
          defaultMessage="Data contratto"
        />
      ),
      formatter: ({ data }) =>
        moment(data.contractDate, 'YYYY-MM-DD').format('DD MMM YYYY'),
    },
    {
      key: 'contractNo',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityContracts.table.header.contractNo"
          defaultMessage="N. Contratto"
        />
      ),
      formatter: ({ data }) => data.contractNo,
    },
    {
      key: 'contractQty',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityContracts.table.header.contractQty"
          defaultMessage="Quantità iniziale"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.quantity)) +
        ' ' +
        data.unitOfMeasure,
    },

    {
      key: 'price',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityContracts.table.header.price"
          defaultMessage="Prezzo"
        />
      ),
      formatter: ({ data }) =>
        new Intl.NumberFormat('it-IT', {
          style: 'currency',
          currency: 'EUR',
        }).format(_.toNumber(data.unitPrice)) +
        '/' +
        data.unitOfMeasure,
    },
    {
      key: 'remainingAlloyQty',
      sortable: false,
      name: (
        <FormattedMessage
          id="metalAvailabilityContracts.table.header.remainingAlloyQty"
          defaultMessage="Quantità residua"
        />
      ),
      formatter: ({ data }) =>
        intlFormatNumber.format(_.toNumber(data.remainingQuantity)) +
        ' ' +
        data.unitOfMeasure,
    },
  ];

  return (
    <>
      <div className="metalAvailability-section-title">
        <FormattedMessage
          id="metalAvailabilityContracts.title"
          defaultMessage="Contratti"
        />
      </div>
      <div className="semantic-table-grid-standard">
        <SemanticTableGrid
          className="metal-availability-contracts"
          columns={columns}
          elements={contracts}
          emptyResults={intl.formatMessage({
            id: 'metalAvailabilityContracts.table.empty',
            defaultMessage: 'Nessun Elemento disponibile',
          })}
        />
      </div>
    </>
  );
};

export default MetalAvailabilityContractsTable;
