/**
 * Load an external source of Javascript code, as the script for
 * load the Reacaptch code used in the signup phase
 *
 * @param id
 * @param url
 * @param callback
 */
export const loadScriptByURL = (
  id: string,
  url: string,
  callback: null | (() => unknown),
): void => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = () => {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};
