import { CustomerReservationListState } from '../types';
import {
  CustomerReservationListAction,
  CustomerReservationListConstants,
} from './action';

export const initialReservationListState: CustomerReservationListState = {
  activaPage: 1,
  areReservationsLoading: false,
  reservationCollection: [],
  totalPages: 1,
  allowedState: [],
};

export const customerReservationListReducer = (
  state: CustomerReservationListState,
  action: CustomerReservationListAction,
): CustomerReservationListState => {
  const { type } = action;

  switch (type) {
    case CustomerReservationListConstants.SET_ARE_RESERVATIONS_LOADING: {
      return {
        ...state,
        areReservationsLoading: action.payload.areReservationLoading,
      };
    }
    case CustomerReservationListConstants.SET_CURRENT_PAGE: {
      return {
        ...state,
        activaPage: action.payload.currentPage,
      };
    }
    case CustomerReservationListConstants.SET_RESERVATION_COLLECTION:
      return {
        ...state,
        reservationCollection: action.payload.reservationCollection || [],
      };
    case CustomerReservationListConstants.SET_TOTAL_PAGES: {
      return {
        ...state,
        totalPages: action.payload.totalePages,
      };
    }
    case CustomerReservationListConstants.SET_ALLOWED_STATE: {
      return {
        ...state,
        allowedState: action.payload.allowedState,
      };
    }
    default:
      return state;
  }
};
