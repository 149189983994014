import { API } from 'utils/global/backendRoutes';
import { AxiosResponse } from 'axios';
import standardClient from 'services/client/standardRequestClient';
import { AdminDashboardData } from './types';
import { toast } from 'react-toastify';

export const getDashboardAdmin = (
  setAreDashboardDataLoading: (value: boolean) => void,
  setDashboardData: (dashboardData: AdminDashboardData) => void,
): void => {
  setAreDashboardDataLoading(true);

  standardClient
    .request({
      url: API.GET_ADMIN_DASHBOARD,
      method: 'GET',
    })
    .then((response: AxiosResponse<AdminDashboardData>) => {
      setDashboardData(response.data);
    })
    .catch(() => toast.error('Impossibile caricare i dati della dashboard'))
    .finally(() => setAreDashboardDataLoading(false));
};
