import axios from 'axios';
import _ from 'lodash';

// Internals and constants
import { API } from 'utils/global/backendRoutes';
import {
  failedRequestsQueue,
  isTokenRefreshing,
  refreshAccessToken,
} from './refreshAccessToken';
import { LOCAL_STORAGE } from 'utils/global/globalConstants';

// Types
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Define a standard client for all the requests to backend services.
 * The authentication header is set with the token saved in the localStorage.
 */
const standardClient = axios.create();

/**
 * Intercept the request and set in the header the BeareToken.
 * Also set as default POST method and the baseURL, this values can be
 * overwritten by defining them in the request.
 */
standardClient.interceptors.request.use((configuration: AxiosRequestConfig) =>
  _.merge(configuration, {
    baseURL: API.BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE.ACCESS_TOKEN,
      )}`,
    },
  }),
);

/**
 * Standard client's response interceptor, if the status is 200 return the response
 * otherwise trying to handle the error.
 */
standardClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // Check if the error object is an AxiosError and also if the response data
    // is definend otherwise return an unhandled error.
    if (axios.isAxiosError(error) && error.response) {
      // 401 Unauthorized - the access token could be expired
      if (error.response.status === 401) {
        return new Promise((resolve, reject) => {
          // Add a promise in the failed requests queue
          failedRequestsQueue.push({ resolve, reject });

          // If i'm not already trying to refresh the token send a request with the refreshToken.
          if (!isTokenRefreshing) {
            refreshAccessToken();
          }
        })
          .then(() => standardClient(error.config)) // Token has been refreshed correctly, redo the API call
          .catch(error => Promise.reject(error.response)); // Error on token refresh, return the error
      }
    }

    /**
     * @todo Verify that infinte loop are avoided
     * @todo Add expired password managment if required
     */

    // Not an AxiosError or unhandled error case, just return the error response
    return Promise.reject(error.response);
  },
);

export default standardClient;
