import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const elipsis = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 17}
    height={height || 4}
    viewBox="0 0 17 4"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="2.36999" cy="2" rx="1.98425" ry="2" />
    <ellipse cx="8.32288" cy="2" rx="1.98425" ry="2" />
    <ellipse cx="14.2758" cy="2" rx="1.98425" ry="2" />
  </svg>
);

export default elipsis;
