import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const recycle = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.124712 7.90953L1.62706 9.08338L0.213065 11.7921C-0.493916 13.0412 0.720634 14.2579 1.49448 14.6815C2.25628 15.0984 3.43867 15.148 4.54334 15.133L6.53173 11.8824L8.03409 12.6951L5.42706 7.86444L0.124712 7.90953ZM0.345614 14.5404L3.57122 20.4997C4.2193 21.3273 5.44181 21.5229 6.70848 21.4929H10.1108V15.8496L3.65961 15.8045C2.65808 15.8646 1.34718 15.6539 0.345614 14.5404ZM20.6396 3.55808L18.8846 4.30299L17.2009 1.7531C16.4513 0.529221 14.7982 1.00157 14.0526 1.47306C13.3187 1.93722 12.702 2.94734 12.1797 3.92079L14.0586 7.23577L12.6195 8.15577L18.1084 8.21757L20.6396 3.55808ZM14.733 0.536672L7.9571 0.46875C6.91903 0.634279 6.15522 1.60863 5.56746 2.73114L3.91797 5.70691L8.85369 8.44283L12.0208 2.82232C12.4537 1.9171 13.2735 0.87275 14.733 0.536672ZM15.0278 23.5312L15.2271 21.6351L18.2735 21.3988C19.7074 21.3364 20.0957 19.6617 20.0448 18.7809C19.9947 17.914 19.4101 16.8849 18.812 15.9561L15.0017 15.9924L14.8947 14.2877L12.1796 19.0584L15.0278 23.5312ZM20.5339 19.83L23.8784 13.9367C24.2369 12.9485 23.755 11.808 23.0582 10.7498L21.2552 7.8644L16.4694 10.8549L19.8498 16.3498C20.4316 17.1672 20.9476 18.3905 20.5339 19.83Z" />
  </svg>
);

export default recycle;
