import { Reservation } from '../types';

export enum ReservationConstants {
  ADD_ORDER_LINE = 'Reservation/ADD_ORDER_LINE',
  REMOVE_ORDER_LINE = 'Reservation/REMOVE_ORDER_LINE',
  SET_ARE_DATA_LOADING = 'Reservation/SET_ARE_DATA_LOADING',
  SET_RESERVATION = 'Reservation/SET_RESERVATION',
  SET_RESERVATION_LOADING = 'Reservation/SET_RESERVATION_LOADING',
  SET_PREV_RESERVATION_LOADING = 'Reservation/SET_PREV_RESERVATION_LOADING',
}

export type ReservationAction =
  | {
      type: ReservationConstants.ADD_ORDER_LINE;
      payload: { orderLine: string; articleType: string };
    }
  | {
      type: ReservationConstants.REMOVE_ORDER_LINE;
      payload: { orderLine: string; articleType: string };
    }
  | {
      type: ReservationConstants.SET_ARE_DATA_LOADING;
      payload: { areDataLoading: boolean };
    }
  | {
      type: ReservationConstants.SET_RESERVATION;
      payload: { reservation: Reservation };
    }
  | {
      type: ReservationConstants.SET_RESERVATION_LOADING;
      payload: { isReservationLoading: boolean };
    }
  | {
      type: ReservationConstants.SET_PREV_RESERVATION_LOADING;
      payload: { isPrevReservationLoading: boolean };
    };
