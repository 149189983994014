import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-semantic-ui-react';
import { Grid, Image } from 'semantic-ui-react';

// Components
import FileInputField from 'components/FileInputField';

// Images
import mainLogo from 'assets/images/main-logo.svg';

// Types
import { SignupContext } from 'pages/Signup';
import { COLORS, CONFIG, ICONS } from 'utils/global/globalConstants';

import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import SvgIcon from 'components/SvgIcon';
import { signupDocumentsRequest } from 'services/signup/signup';

const SignupDocumentForm: React.FC = () => {
  const { state, dispatch } = useContext(SignupContext);

  const getValidationErrorMessage = (dataField: string) => {
    if (!state.errorObject || !state.errorObject['documents']) {
      return;
    }

    const diplayedErrors = [];
    if (state.errorObject['documents'][dataField]) {
      const errors = state.errorObject['documents'][dataField];
      diplayedErrors.push(errors);
    }

    return (
      <div
        className="documents-error-message"
        key={diplayedErrors + '-' + dataField}
      >
        {diplayedErrors}
      </div>
    );
  };

  const ininitialValues = useMemo(() => {
    const obj = {};

    state.requestedDocument.forEach(doc => {
      obj[doc.documentType] = null;
    });

    return obj;
  }, [state.requestedDocument]);

  /** Handle the submit of the documents (second api request) */
  const handleDocumentsSubmit = (documents: unknown) => {
    // Create new form data and append the contact code value
    const documentsFormData = new FormData();
    documentsFormData.append('contactCode', state.contactId);

    const keys = Object.keys(documents);
    keys.forEach((key: string) => {
      if (!_.isNull(documents[key])) {
        documentsFormData.append(`documents[${key}]`, documents[key]);
      } else {
        documentsFormData.append(`documents[${key}]`, '');
      }
    });

    if (CONFIG.RECAPTCHA.active) {
      window['grecaptcha'].ready(() => {
        window['grecaptcha']
          .execute(CONFIG.RECAPTCHA.key, { action: 'subscription/request' })
          .then((token: string) => {
            signupDocumentsRequest(dispatch, documentsFormData, token);
          });
      });
    } else {
      signupDocumentsRequest(dispatch, documentsFormData);
    }
  };

  return (
    <div className="signup-documentform">
      <Image src={mainLogo} className="signup-documentform-logo" />
      <div className="signup-documentform-title">
        <FormattedMessage
          id="signup.documentform.title"
          defaultMessage={'Elenco <tag>documenti richiesti</tag>'}
          values={{
            tag: (text: string) => <span className="bold-text">{text}</span>,
          }}
        />
      </div>
      <Formik
        initialValues={ininitialValues}
        onSubmit={values => handleDocumentsSubmit(values)}
      >
        {({ setFieldValue }) => (
          <Form className="signup-documentform-form">
            <Grid className="register-form" textAlign="center">
              {state.requestedDocument.map(document => (
                <>
                  <Grid.Row key={document.documentType}>
                    <SvgIcon
                      icon={ICONS.DOCUMENT}
                      color={COLORS.SECONDARY}
                      width={40}
                      height={40}
                    />
                    <FileInputField
                      key={document.documentType}
                      field={document.documentType}
                      label={document.documentDescription}
                      setFieldValue={setFieldValue}
                    />
                  </Grid.Row>
                  {getValidationErrorMessage(document.documentType)}
                </>
              ))}

              <Grid.Row>
                <SubmitButton
                  className="register-button"
                  content={state.isLoading ? '' : 'Registrati'}
                  loading={state.isLoading}
                />
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupDocumentForm;
