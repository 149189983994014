import { DocumentData } from '../types';

export enum ContactDocumentsConstants {
  SET_ARE_DOCUMENTS_LOADING = 'ContactDocuments/SET_ARE_DOCUMENTS_LOADING',
  SET_DOCUMENTS_COLLECTION = 'ContactDocuments/DOCUMENTS_COLLECTION',
  SET_OPEN_MODAL = 'ContactDocuments/SET_OPEN_MODAL',
}

export type ContactDocumentsAction =
  | {
      type: ContactDocumentsConstants.SET_ARE_DOCUMENTS_LOADING;
      payload: { areDocumentsLoading: boolean };
    }
  | {
      type: ContactDocumentsConstants.SET_DOCUMENTS_COLLECTION;
      payload: { documentsCollection: Array<DocumentData> };
    }
  | {
      type: ContactDocumentsConstants.SET_OPEN_MODAL;
      payload: { isModalOpen: boolean };
    };
