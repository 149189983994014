import { AppAction, appActionConstants } from './actions';
import { clearAuthObject } from 'utils/function/localStorage';
import { MySelf } from 'services/auth/types';
import { SelectedContact } from 'layout/SideBar/types';

export type AppState = {
  isAuthenticated: boolean | undefined;
  isUserLoading: boolean;
  language: 'it' | 'en';
  mySelf: MySelf | null;
  selectedContact: SelectedContact | null;
};

export const initialAppState: AppState = {
  isAuthenticated: undefined,
  isUserLoading: false,
  language: 'it',
  mySelf: null,
  selectedContact: null,
};

export function appReducer(state: AppState, action: AppAction): AppState {
  const { type } = action;

  switch (type) {
    case appActionConstants.REVOKE_SESSION: {
      clearAuthObject();
      return { ...state, isAuthenticated: false, mySelf: null, language: 'it' };
    }
    case appActionConstants.SET_LANGUAGE:
      return { ...state, language: action.payload.language };
    case appActionConstants.SET_MYSELF:
      return { ...state, mySelf: action.payload.mySelf };
    case appActionConstants.SET_SESSION:
      return { ...state, isAuthenticated: true };
    case appActionConstants.SET_USER_LOADING:
      return { ...state, isUserLoading: action.payload.isLoading };
    case appActionConstants.SET_SELECTED_CONTACT:
      return { ...state, selectedContact: action.payload.contact };
    default:
      return state;
  }
}
