import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';
import { MetalResumeConstants } from 'pages/MetalResume/reducer/action';
import { MetalResumeAction } from 'pages/MetalResume/reducer/action';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  GetMetalResumeAccountEntriesResponse,
  GetMetalResumeResponse,
} from './type';
import { IntlShape } from 'react-intl';

export const getMetalResume = (
  dispatch: React.Dispatch<MetalResumeAction>,
  customerCode?: string,
): void => {
  dispatch({
    type: MetalResumeConstants.SET_METAL_RESUMES_LOADING,
    payload: { areMetalResumeLoading: true },
  });

  standardClient({
    url: API.CUSTOMER_METAL_RESUME,
    method: 'POST',
    data: {
      method: 'list',
      params: {
        customerCode,
      },
    },
  })
    .then((response: AxiosResponse<GetMetalResumeResponse>) => {
      return dispatch({
        type: MetalResumeConstants.SET_COMPLETE_METAL_RESUMES_COLLECTION,
        payload: { metalResumeCollection: response.data.metalResumes },
      });
    })
    .catch(() => {
      toast.error('Impossibile recuperare gli estratto conto metalli');
    })
    .finally(() => {
      dispatch({
        type: MetalResumeConstants.SET_METAL_RESUMES_LOADING,
        payload: { areMetalResumeLoading: false },
      });
    });
};

export const getMetalResumeDocument = (
  documentNo: string,
  intl: IntlShape,
): void => {
  // Show a notification with loading when a file is downloading
  const notifyStatus = toast.loading(
    intl.formatMessage({
      id: 'service.getMetalResumeDocument.download.running',
      defaultMessage: 'Recupero del file in corso...',
    }),
    {
      closeOnClick: true,
    },
  );

  standardClient({
    url: API.CUSTOMER_METAL_RESUME,
    method: 'POST',
    headers: { Accept: 'application/json, text/plain, */*' },
    responseType: 'arraybuffer',
    data: {
      method: 'document',
      params: {
        documentNo: documentNo,
      },
    },
  })
    .then((response: AxiosResponse) => {
      // Get the name from content-dispositon
      // Ex. string => content-dispositon: attachment;filename=sample.pdf
      const contentDisposition = response.headers['content-disposition'];
      const fileNameString = contentDisposition.split('filename=')[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileNameString || 'file.pdf');
      document.body.appendChild(link);
      link.click();

      // Show success message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.getMetalResumeDocument.download.done',
          defaultMessage: 'File scaricato correttamente',
        }),
        type: 'success',
      });
    })
    .catch(() =>
      // Show error message with auto dismiss after 3 second
      toast.update(notifyStatus, {
        autoClose: 3000,
        isLoading: false,
        render: intl.formatMessage({
          id: 'service.getMetalResumeDocument.download.error',
          defaultMessage: 'Impossibile scaricare il file richiesto',
        }),
        type: 'error',
      }),
    );
};

export const getMetalResumeAccountEntries = (
  dispatch: React.Dispatch<MetalResumeAction>,
  customerCode?: string,
): void => {
  dispatch({
    type: MetalResumeConstants.SET_METAL_RESUMES_ACCOUNT_LOADING,
    payload: { areMetalResumesAccountLoading: true },
  });

  standardClient({
    url: API.CUSTOMER_METAL_RESUME,
    method: 'POST',
    data: {
      method: 'account-entries',
      params: {
        customerCode,
      },
    },
  })
    .then((response: AxiosResponse<GetMetalResumeAccountEntriesResponse>) => {
      dispatch({
        type: MetalResumeConstants.SET_COMPLETE_METAL_RESUMES_ACCOUNT,
        payload: {
          metalResume: response.data,
        },
      });
    })
    .catch(() => {
      toast.error('Impossibile recuperare gli estratto conto metalli');
    })
    .finally(() => {
      dispatch({
        type: MetalResumeConstants.SET_METAL_RESUMES_ACCOUNT_LOADING,
        payload: { areMetalResumesAccountLoading: false },
      });
    });
};
