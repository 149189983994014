import { AppAction } from 'pages/App/reducer/actions';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import { loadMyself } from 'services/auth/loadMyself';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const updateUserData = (
  userId: string,
  language: string,
  email: string,
  email2: string,
  email3: string,
  setLanguage: (language: string) => void,
  dispatch: React.Dispatch<AppAction>,
  intl: IntlShape,
): void => {
  standardClient({
    url: `${API.USERS}/${userId}`,
    method: 'PATCH',
    data: {
      defaultLanguage: language,
      email: email,
      email2: email2,
      email3: email3,
    },
  })
    .then(() => {
      if (language === 'it') {
        toast.success('Informazioni aggiornate con successo');
      } else {
        toast.success('User information updated');
      }

      loadMyself(dispatch, setLanguage);
    })
    .catch(error => {
      if (
        error.data &&
        (error.data?.validation_messages?.email?.emailAddressInvalidFormat ||
          error.data?.validation_messages?.email2?.emailAddressInvalidFormat ||
          error.data?.validation_messages?.email3?.emailAddressInvalidFormat)
      ) {
        toast.error(
          intl.formatMessage({
            id: 'service.updateUserData.error.invalidMailFormat',
            defaultMessage:
              'La mail inserita non è nel formato valido: local-part@hostname',
          }),
        );
      } else {
        toast.error(
          intl.formatMessage({
            id: 'service.updateUserData.error.generic',
            defaultMessage: 'Impossibile aggiornare il profilo',
          }),
        );
      }
    });
};
