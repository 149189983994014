import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Dimmer, Image, Loader } from 'semantic-ui-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'moment/locale/it';

// Icons
import mainLogo from 'assets/images/main-logo.svg';

// Internals and constants
import { AppAction, appActionConstants } from './reducer/actions';
import { appReducer, AppState, initialAppState } from './reducer/reducer';
import {
  CUSTOM_EVENT,
  FE_ROUTES,
  LOCAL_STORAGE,
} from 'utils/global/globalConstants';
import { loadMyself } from 'services/auth/loadMyself';

// Routes
import standardLayoutRoutes from './routes/standardLayoutRoutes';
import accessLayoutRoutes from './routes/accessLayoutRoutes';
import footerLayoutRoutes from './routes/footerLayoutRoutes';

// Styles
import './style.less';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-less/semantic.less';
import { LangProviderContext } from 'components/LangProvider';

export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
}>({
  state: initialAppState,
  dispatch: () => null,
});

const App: React.FC = () => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  const { setLanguage } = useContext(LangProviderContext);

  useEffect(() => {
    // Custom event attachment for handle API errors
    window.addEventListener(CUSTOM_EVENT.LOGOUT, () => {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    });

    /**
     * Check if an accessToken is saved in the locale storage:
     * - If it's not, set isAuthenticated to false (REVOKE_SESSION)
     *    -> If the required page is only for authenticated user will also redirect to login page
     * - If it is, show a loading page until the myself API call has been done.
     *    -> This is necessary to check if the data in the local storage are already valid or not
     */
    if (!localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN)) {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    } else {
      loadMyself(dispatch, setLanguage);
    }

    // Remove events listener on component unmount
    return () => {
      window.removeEventListener(CUSTOM_EVENT.LOGOUT, () =>
        dispatch({ type: appActionConstants.REVOKE_SESSION }),
      );
    };
  }, []);

  // If state is undefined (check if token are expired) a loader will be showed
  if (state.isAuthenticated === undefined) {
    return (
      <Dimmer page active className="loading-page">
        <div className="loading-content">
          <Image src={mainLogo} />
          <Loader size="huge">
            <div className="main-text">Avvio applicativo in corso</div>
            <div className="subtitle-text">Si prega di attendere...</div>
          </Loader>
        </div>
      </Dimmer>
    );
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Routes>
        {standardLayoutRoutes()}
        {accessLayoutRoutes()}
        {footerLayoutRoutes()}
        <Route path="*" element={<Navigate to={FE_ROUTES.MAIN_ROUTE} />} />
      </Routes>
      <ToastContainer />
    </AppContext.Provider>
  );
};

export default App;
