import { AxiosResponse } from 'axios';
import { AppAction, appActionConstants } from 'pages/App/reducer/actions';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { MySelf } from './types';
import _ from 'lodash';

export const loadMyself = (
  dispatch: React.Dispatch<AppAction>,
  setLanguage: (language: string) => void,
  isOnLogin = false,
): void => {
  standardClient
    .request({
      baseURL: API.BASE_URL,
      url: API.MYSELF,
      method: 'GET',
    })
    .then((response: AxiosResponse<MySelf>) => {
      if (_.isEmpty(response.data.roles)) {
        dispatch({ type: appActionConstants.REVOKE_SESSION });
        toast.error("Nessun profilo attivo, contattare l'assistenza");
      }

      dispatch({
        type: appActionConstants.SET_MYSELF,
        payload: { mySelf: response.data },
      });

      dispatch({ type: appActionConstants.SET_SESSION });

      setLanguage(response.data.defaultLanguage);
    })
    .catch(() => {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
      if (isOnLogin) {
        toast.error(
          "Impossibile completare la login. Se l'errore persiste contattare l'assistenza",
        );
      }
    });
};
