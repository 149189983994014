import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const information = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 14}
    height={height || 16}
    viewBox="0 0 14 16"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.00034 5.71094C6.60291 5.71094 6.32031 5.87875 6.32031 6.12603V9.49082C6.32031 9.70276 6.60294 9.91473 7.00034 9.91473C7.38009 9.91473 7.68919 9.70276 7.68919 9.49082V6.12603C7.68919 5.87875 7.38009 5.71094 7.00034 5.71094Z" />
    <path d="M7.00007 3.63672C6.59382 3.63672 6.27588 3.92816 6.27588 4.26375C6.27588 4.59935 6.59382 4.89963 7.00007 4.89963C7.3975 4.89963 7.71544 4.59935 7.71544 4.26375C7.71544 3.92819 7.39747 3.63672 7.00007 3.63672Z" />
    <path d="M7 0C3.19994 0 0.125 3.07337 0.125 6.87103C0.125 9.972 2.16666 12.5468 4.91928 13.4213L6.48278 15.7259C6.59909 15.8973 6.79281 16 7 16C7.20719 16 7.40091 15.8973 7.51722 15.7259L9.08072 13.4213C11.8346 12.5464 13.875 9.97066 13.875 6.87103C13.875 3.07097 10.7976 0 7 0ZM8.52016 12.2843C8.37781 12.3242 8.25422 12.4131 8.17125 12.5354L7 14.2618L5.82878 12.5354C5.74578 12.4131 5.62219 12.3242 5.47984 12.2844C3.13678 11.6292 1.375 9.47441 1.375 6.87103C1.375 3.77156 3.89838 1.25 7 1.25C10.1016 1.25 12.625 3.77156 12.625 6.87103C12.625 9.477 10.8606 11.6299 8.52016 12.2843Z" />
  </svg>
);

export default information;
