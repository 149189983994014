import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const dashboard = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 6.28516H0.572385C0.257741 6.28516 0.00334728 6.53955 0 6.85754V15.4266C0 15.7446 0.257741 15.999 0.572385 15.999H4C4.31799 15.999 4.57239 15.7412 4.57239 15.4266V6.85754C4.57239 6.53955 4.31464 6.28516 4 6.28516Z" />
    <path d="M9.71386 0.00195312H6.28625C5.97161 0.00195312 5.71387 0.256346 5.71387 0.570991V15.4262C5.71387 15.7442 5.97161 15.9986 6.28625 15.9986H9.71386C10.0319 15.9986 10.2862 15.7409 10.2862 15.4262V0.574338C10.2862 0.256346 10.0285 0.00195312 9.71386 0.00195312Z" />
    <path d="M15.4277 4.57031H12.0001C11.6821 4.57031 11.4277 4.82471 11.4277 5.1427V15.4255C11.4277 15.7435 11.6855 15.9979 12.0001 15.9979H15.4277C15.7457 15.9979 16.0001 15.7402 16.0001 15.4255V5.1427C16.0001 4.82471 15.7424 4.57031 15.4277 4.57031Z" />
  </svg>
);

export default dashboard;
