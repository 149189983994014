import { CircularProgress } from '@material-ui/core';
import { MetalResumeContext } from 'pages/MetalResume';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CompleteMetalResumeTable from '../CompleteMetalResumeTable';
import { MetalResume } from 'pages/MetalResume/types';
import moment from 'moment';

export const MetalResumeList: React.FC = () => {
  const { state } = useContext(MetalResumeContext);

  const intl = useIntl();

  const getMetalResumeLines = (
    metalResumes: Array<MetalResume>,
    yearlyStatement: boolean,
  ) => {
    const metalResumeLineArray: Array<MetalResume> = metalResumes;
    const filteredArray = metalResumeLineArray.filter(
      line => line.isYearlyStatement === yearlyStatement,
    );
    let metalResumeLines: Array<MetalResume> = [];

    switch (state.activeSortMetal) {
      case 'metal-number-desc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) => {
            if (firstMetalResume.no === secondMetalResume.no) {
              if (firstMetalResume.no > secondMetalResume.no) {
                return 1;
              }
              return -1;
            }

            if (firstMetalResume.no > secondMetalResume.no) {
              return -1;
            } else {
              return 1;
            }
          },
        );
        break;
      }
      case 'start-date-asc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(firstMetalResume.startDate, 'YYYY-MM-DD').diff(
              moment(secondMetalResume.startDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'start-date-desc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(secondMetalResume.startDate, 'YYYY-MM-DD').diff(
              moment(firstMetalResume.startDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'end-date-asc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(firstMetalResume.endDate, 'YYYY-MM-DD').diff(
              moment(secondMetalResume.endDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'end-date-desc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(secondMetalResume.endDate, 'YYYY-MM-DD').diff(
              moment(firstMetalResume.endDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'statement-date-asc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(firstMetalResume.statementDate, 'YYYY-MM-DD').diff(
              moment(secondMetalResume.statementDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      case 'statement-date-desc': {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) =>
            moment(secondMetalResume.statementDate, 'YYYY-MM-DD').diff(
              moment(firstMetalResume.statementDate, 'YYYY-MM-DD'),
            ),
        );
        break;
      }
      default: {
        metalResumeLines = filteredArray.sort(
          (firstMetalResume, secondMetalResume) => {
            if (firstMetalResume.no === secondMetalResume.no) {
              if (firstMetalResume.no > secondMetalResume.no) {
                return 1;
              }
              return -1;
            }

            if (firstMetalResume.no > secondMetalResume.no) {
              return 1;
            } else {
              return -1;
            }
          },
        );
      }
    }
    return metalResumeLines;
  };

  return (
    <>
      {state.areMetalResumeLoading ? (
        <>
          <CircularProgress color="inherit" />
          <span className="metal-resume-loading">
            <FormattedMessage
              id="accordion.list.metal.resume.loading"
              defaultMessage="Estratto conto in caricamento"
            />
          </span>
        </>
      ) : (
        <>
          <div className="metal-resume-title second-title">
            {intl.formatMessage({
              id: 'customer.metal.resume.title.section.current',
              defaultMessage: 'Estratti conto anno corrente',
            })}
          </div>
          <CompleteMetalResumeTable
            metalResumes={getMetalResumeLines(
              state.filteredMetalResumeCollection,
              false,
            )}
          />
          <div className="metal-resume-title second-title">
            {intl.formatMessage({
              id: 'customer.metal.resume.title.section.yearly',
              defaultMessage: 'Archivio estratti conto',
            })}
          </div>
          <CompleteMetalResumeTable
            metalResumes={getMetalResumeLines(
              state.filteredMetalResumeCollection,
              true,
            )}
          />
        </>
      )}
    </>
  );
};

export default MetalResumeList;
