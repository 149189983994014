import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import SemanticTableGrid from 'semantic-table-grid';
import _ from 'lodash';

// Components
import CustomerDocumentsFilterBar from '../CustomerDocumentsFilterBar';
import Paginator from 'components/Paginator';

// Costants | Utils
import { CUSTOMER_DOCUMENTS_PER_PAGE } from 'utils/global/customerCostants';

// Types
import {
  CustomerDocumentsSupportedTypes,
  CustomerDocumentsTabProps,
} from './types';
import { PaginationProps } from 'semantic-ui-react';
import moment from 'moment';

const CustomerDocumentsTab: React.FC<CustomerDocumentsTabProps> = ({
  activeSort,
  areDocumentsLoading,
  completeDocumentsCollection,
  filterInputPlaceholder,
  onChangeSelectedYear,
  onFilter,
  tableColumns,
}) => {
  const intl = useIntl();

  const [currentPage, setCurrentPage] = useState<number | string>(0);
  const [totalPageNumber, setTotalPageNumber] = useState<number | string>(0);
  const [showedDocuments, setShowedDocuments] = useState<
    Array<CustomerDocumentsSupportedTypes>
  >([]);
  const [selectedYear, setSelectedYear] = useState<string>(
    _.toString(moment().year()),
  );

  /**
   * Everty time the complete documents collection form props change
   * go to the first page and calculate the new page number.
   * Usually this event is triggered onFIlter, onSort and onLoad of the documents
   */
  useEffect(() => {
    const pageNumber = Math.ceil(
      completeDocumentsCollection.length / CUSTOMER_DOCUMENTS_PER_PAGE,
    );
    const showedDocumentsPage = completeDocumentsCollection.slice(
      0,
      CUSTOMER_DOCUMENTS_PER_PAGE,
    );

    setCurrentPage(1);
    setTotalPageNumber(pageNumber);
    setShowedDocuments(showedDocumentsPage);
  }, [completeDocumentsCollection, activeSort]);

  const onChangeCurrentPage = (
    _event,
    { activePage }: PaginationProps,
  ): void => {
    const selectedPage = _.isString(activePage)
      ? parseInt(activePage)
      : activePage;

    // Define last item of the page that is SELECTED_PAGE * ITEMS_PER_PAGE
    // Ex. last item of page 3 with 5 elements x page is 15
    const lastItem = CUSTOMER_DOCUMENTS_PER_PAGE * selectedPage;
    const firstItem = lastItem - CUSTOMER_DOCUMENTS_PER_PAGE;

    const showedElements = completeDocumentsCollection.slice(
      firstItem,
      lastItem,
    );

    setCurrentPage(selectedPage);
    setShowedDocuments(showedElements);
  };

  const onChangeYearFilter = (selectedYearFromFilter: string) => {
    if (selectedYear !== selectedYearFromFilter) {
      onChangeSelectedYear(selectedYearFromFilter);
      setSelectedYear(selectedYearFromFilter);
    }
  };

  const resetAction = () => {
    const currentYear = _.toString(moment().year());
    onChangeSelectedYear(currentYear);
    setSelectedYear(currentYear);
  };

  const onFilterAction = (
    elementCode: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    setSelectedYear('all');
    onFilter(elementCode, dateFrom, dateTo);
  };

  return (
    <div className="customer-documents-container">
      <CustomerDocumentsFilterBar
        changeSelectedYear={onChangeYearFilter}
        filterPlaceholder={
          filterInputPlaceholder ||
          intl.formatMessage({
            id: 'customerDocumentsTab.filterBar.placeholder',
            defaultMessage: 'Codice documento',
          })
        }
        onFilter={onFilterAction}
        onRest={resetAction}
        selectedYear={selectedYear}
        fromDatePlaceholder={intl.formatMessage({
          id: 'customerDocumentsTab.filter.dateFrom',
          defaultMessage: 'Da',
        })}
        toDatePlaceholder={intl.formatMessage({
          id: 'customerDocumentsTab.filter.dateTo',
          defaultMessage: 'A',
        })}
      />
      <SemanticTableGrid
        className="customer-documents-table"
        columns={tableColumns}
        elements={showedDocuments}
        emptyResults={
          <div className="customer-documents-table-empty">
            {areDocumentsLoading ? (
              <>
                <CircularProgress color="inherit" />
                <FormattedMessage
                  id="customerDocumentsTab.table.loadingPlaceholder"
                  defaultMessage="Documenti in caricamento"
                />
              </>
            ) : (
              <FormattedMessage
                id="customerDocumentsTab.table.empty"
                defaultMessage="Nessun risultato"
              />
            )}
          </div>
        }
      />
      <div className="customer-documents-footer">
        <div className="customer-documents-footer-totalElements">
          {!areDocumentsLoading && (
            <FormattedMessage
              id="customerDocumentsTab.footer.elementCounter"
              defaultMessage="Totale documenti: {totalElements}"
              values={{
                totalElements: completeDocumentsCollection.length,
              }}
            />
          )}
        </div>
        <Paginator
          totalPages={totalPageNumber}
          activePage={currentPage}
          onPageChange={onChangeCurrentPage}
          shortText
        />
      </div>
    </div>
  );
};

export default CustomerDocumentsTab;
