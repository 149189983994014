import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import { Nullable } from 'utils/types';
import _ from 'lodash';
import { getCustomerCreditMemo } from 'services/customer/getCustomerDocumentDetails';
import { CircularProgress } from '@material-ui/core';
import { CustomerCreditNoteDetail, CustomerInvoiceModalProps } from './types';

const CustomerCreditsNoteModal: React.FC<CustomerInvoiceModalProps> = ({
  invoiceDocumentCode,
  onClose,
}) => {
  const intlCurrency = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });
  const [isCustomerOrderLoading, setIsCustomerOrderLoading] = useState(false);
  const [customerOrderDetails, setCustomerOrderDetails] =
    useState<Nullable<CustomerCreditNoteDetail>>(null);

  useEffect(() => {
    if (_.isEmpty(invoiceDocumentCode)) {
      setCustomerOrderDetails(null);
      return;
    }

    getCustomerCreditMemo(
      invoiceDocumentCode,
      setCustomerOrderDetails,
      setIsCustomerOrderLoading,
    );
  }, [invoiceDocumentCode]);

  return (
    <Modal
      open={!_.isEmpty(invoiceDocumentCode)}
      onClose={onClose}
      className="customer-documents-modal"
    >
      <Button className="crossIcon" onClick={onClose}>
        <SvgIcon icon={ICONS.CROSS} />
      </Button>
      {isCustomerOrderLoading && (
        <div className="customer-documents-modal-loading">
          <CircularProgress color="inherit" />
          <FormattedMessage
            id="customerDocuments.creditNotes.details.dataLoading"
            defaultMessage="Dettaglio nota di credito in caricamento"
          />
        </div>
      )}
      {!isCustomerOrderLoading && !_.isEmpty(customerOrderDetails) && (
        <>
          <Modal.Header className="upload-document-modal-header">
            <SvgIcon icon={ICONS.FILE} height={30} width={30} />
            <FormattedMessage
              id="customerDocuments.creditNote.details.header.title"
              defaultMessage="Documento Nota di Credito {orderNumber}"
              values={{
                orderNumber: customerOrderDetails.no,
              }}
            />
          </Modal.Header>
          <Modal.Content className="noPaddingTop upload-document-modal-content">
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.creditNotes.details.postingDate"
                  defaultMessage="Data: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {moment(customerOrderDetails.postingDate, 'YYYY-MM-DD').format(
                  'DD MMM YYYY',
                )}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.creditsNotes.details.taxable"
                  defaultMessage="Imponibile: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {intlCurrency.format(_.toNumber(customerOrderDetails.amount))}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.creditsNotes.details.price"
                  defaultMessage="Importo IVA: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {intlCurrency.format(
                  _.toNumber(customerOrderDetails.vatAmount),
                )}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.creditNotes.details.totalAmount"
                  defaultMessage="Importo totale: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {intlCurrency.format(
                  _.toNumber(customerOrderDetails.amountIncludingVat),
                )}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.creditNotes.details.paymentMethod"
                  defaultMessage="Metodo pagamento: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.paymentMethod}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.creditNotes.details.paymentTerms"
                  defaultMessage="Termini pagamento: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.paymentTerms}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.orders.details.salesInvoices"
                  defaultMessage="Fattura: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.salesInvoices}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.orders.details.dueDates"
                  defaultMessage="Date scadenza: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {_.isEmpty(customerOrderDetails.paymentLines)
                  ? '- - -'
                  : customerOrderDetails.paymentLines.map(
                      line =>
                        `${moment(line.dueDate, 'YYYY-MM-DD').format(
                          'DD MMM YYYY',
                        )}  `,
                    )}
              </span>
            </li>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};

export default CustomerCreditsNoteModal;
