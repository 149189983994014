export enum CustomerDocumentListTab {
  CONTRACT = 'contract',
  OFFER = 'offer',
  BILL = 'bill',
  INVOICE = 'invoice',
  ORIGIN = 'origin',
  ORDERS = 'orders',
  CREDITS_NOTES = 'creditsNotes',
  DDT = 'ddt',
  DDT_CT = 'ddt_ct',
}
