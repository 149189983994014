import { Input } from 'formik-semantic-ui-react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid } from 'semantic-ui-react';
import { ProductUpdatesProps } from './types';
import _ from 'lodash';

const ProductUpdates: React.FC<ProductUpdatesProps> = ({
  productUpdatesValues,
}) => {
  const intl = useIntl();

  const isSecondMailSet = !_.isEmpty(
    productUpdatesValues.secondEmailAddressMSMNewsletter,
  );

  const isThirdMailSet = !_.isEmpty(
    productUpdatesValues.thirdEmailAddressMSMNewsletter,
  );

  const [isAddMailButtonClicked, setIsAddMailButtonClicked] = useState<number>(
    isSecondMailSet ? 1 : isThirdMailSet ? 2 : 0,
  );

  return (
    <>
      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub20"
          defaultMessage="Aggiornamenti di prodotto/novità aziendali"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
      </span>
      <br />
      <FormattedMessage
        id="dataCollection.intro"
        defaultMessage="Se desiderate iscrivervi alla newsletter MSM, indicate qui di seguito almeno un indirizzo e-mail valido."
        description="Subtitle message in the phase 2 of the reservation flow, under the title"
      />

      <Grid.Row className="allRowInput" style={{ marginTop: '2rem' }}>
        <Input
          name="firstEmailAddressMSMNewsletter"
          label={
            <FormattedMessage
              id="firstEmailAddressMSMNewsletter"
              defaultMessage="Indirizzo / indirizzi e-mail per invio newsletter MSM"
            />
          }
          placeholder="Indirizzo / indirizzi e-mail per invio newsletter MSM"
        />
      </Grid.Row>

      {(isAddMailButtonClicked >= 1 || isSecondMailSet) && (
        <Grid.Row className="allRowInput" style={{ marginTop: '2rem' }}>
          <Input
            name="secondEmailAddressMSMNewsletter"
            label={
              <FormattedMessage
                id="secondEmailAddressMSMNewsletter"
                defaultMessage="Indirizzo / indirizzi e-mail per invio newsletter MSM"
              />
            }
            placeholder="Indirizzo / indirizzi e-mail per invio newsletter MSM"
          />
        </Grid.Row>
      )}

      {(isAddMailButtonClicked == 2 || isThirdMailSet) && (
        <Grid.Row className="allRowInput" style={{ marginTop: '2rem' }}>
          <Input
            name="thirdEmailAddressMSMNewsletter"
            label={
              <FormattedMessage
                id="thirdEmailAddressMSMNewsletter"
                defaultMessage="Indirizzo / indirizzi e-mail per invio newsletter MSM"
              />
            }
            placeholder="Indirizzo / indirizzi e-mail per invio newsletter MSM"
          />
        </Grid.Row>
      )}

      <Grid.Row>
        <Button
          className="insertButton"
          content={intl.formatMessage({
            id: 'productUpdates.button.newMail',
            defaultMessage: 'Aggiungi nuovo indirizzo mail',
          })}
          icon="plus"
          onClick={() => setIsAddMailButtonClicked(prev => prev + 1)}
          disabled={isAddMailButtonClicked == 2}
          type="button"
        />
      </Grid.Row>
    </>
  );
};

export default ProductUpdates;
