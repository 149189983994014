import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Select } from 'formik-semantic-ui-react';
import { DropdownItemProps, Grid } from 'semantic-ui-react';
import _ from 'lodash';
import { ErrorMessage, FormikProps } from 'formik';
import { ContactType, OriginDetails } from 'pages/ContactOriginDetail/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactOriginContext } from 'pages/ContactOriginDetail';
import DocumentsTable from 'components/DocumentsTable';
import { isThatRole } from 'utils/function/acl';
import { ICONS, ROLES } from 'utils/global/globalConstants';
import { getNationalitiesOptions } from 'services/signup/getNationalitiesOptions';
import SvgIcon from 'components/SvgIcon';
import FileInputField from 'components/FileInputField';

const ContactOriginForm: React.FC<{
  formikProps: FormikProps<OriginDetails>;
  readOnly?: boolean;
  originId: string;
}> = ({ formikProps, readOnly = false, originId }) => {
  const intl = useIntl();

  const { state } = useContext(ContactOriginContext);

  const [nationalityOptions, setNationalityOption] = useState<
    Array<DropdownItemProps>
  >([]);

  // Load the countries select options from DB
  useEffect(() => {
    getNationalitiesOptions(setNationalityOption, true);
  }, []);

  return (
    <Form
      className="contact-origin-details-form"
      id="contact-origin-form"
      loading={formikProps.isSubmitting}
    >
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column className="left-column">
            <Select
              label={intl.formatMessage({
                id: 'contactOriginForm.type',
                defaultMessage: 'Tipo *',
              })}
              name="type"
              options={[
                {
                  text: intl.formatMessage({
                    id: 'contactOriginForm.type.options.branch',
                    defaultMessage: 'Sede secondaria',
                  }),
                  value: ContactType.BRANCH,
                },
                {
                  text: intl.formatMessage({
                    id: 'contactOriginForm.type.options.other',
                    defaultMessage: 'Altra provenienza',
                  }),
                  value: ContactType.OTHER_ORIGIN,
                },
              ]}
              error={Boolean(formikProps.errors.isSedeSecondaria)}
              search
              disabled={readOnly}
              onChange={(_e, data) => {
                if (_.isString(data.value)) {
                  formikProps.setFieldValue('isSedeSecondaria', data.value);
                }
              }}
            />
            <ErrorMessage
              name="type"
              component="span"
              render={message => (
                <span className="default-error-message">{message}</span>
              )}
            />
          </Grid.Column>
          <Grid.Column className="right-column">
            <Input
              name="name"
              label={intl.formatMessage({
                id: 'contactOriginForm.businessName',
                defaultMessage: 'Ragione sociale *',
              })}
              readOnly={readOnly}
            />
            <ErrorMessage name="name" component="span" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column className="left-column">
            <Input
              name="address"
              label={intl.formatMessage({
                id: 'contactOriginForm.address',
                defaultMessage: 'Indirizzo',
              })}
              readOnly={readOnly}
            />
            <ErrorMessage name="address" component="span" />
          </Grid.Column>
          <Grid.Column className="right-column">
            <Input
              name="city"
              label={intl.formatMessage({
                id: 'contactOriginForm.city',
                defaultMessage: 'Città',
              })}
              readOnly={readOnly}
            />
            <ErrorMessage name="city" component="span" />
          </Grid.Column>
        </Grid.Row>
        {formikProps.values.isSedeSecondaria === ContactType.OTHER_ORIGIN && (
          <Grid.Row columns={2}>
            <Grid.Column className="left-column">
              <Input
                name="vatNumber"
                label={intl.formatMessage({
                  id: 'contactOriginForm.vatNumber',
                  defaultMessage: 'Partita IVA *',
                })}
                readOnly={readOnly}
              />
              <ErrorMessage name="vatNumber" component="span" />
            </Grid.Column>
            <Grid.Column className="right-column">
              <Input
                name="fiscalCode"
                label={intl.formatMessage({
                  id: 'contactOriginForm.fiscalCode',
                  defaultMessage: 'Codice fiscale',
                })}
                readOnly={readOnly}
              />
              <ErrorMessage name="fiscalCode" component="span" />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2}>
          <Grid.Column className="left-column">
            <Select
              label={intl.formatMessage({
                id: 'contactOriginForm.nationality',
                defaultMessage: 'Nazionalità *',
              })}
              name="countryRegionCode"
              loading={_.isEmpty(nationalityOptions)}
              options={nationalityOptions}
              error={Boolean(formikProps.errors.countryRegionCode)}
              search
              disabled={readOnly}
              icon={!readOnly ? null : <SvgIcon icon={ICONS.ARROW_DOWN} />}
            />
            <ErrorMessage
              name="countryRegionCode"
              component="span"
              render={message => (
                <span className="default-error-message">{message}</span>
              )}
            />
          </Grid.Column>
          {formikProps.values.isSedeSecondaria === ContactType.OTHER_ORIGIN ? (
            <Grid.Column className="right-column">
              <Select
                label={intl.formatMessage({
                  id: 'contactOriginForm.registeredOffice',
                  defaultMessage: 'Sede legale *',
                })}
                name="registeredOffice"
                loading={_.isEmpty(nationalityOptions)}
                options={[
                  {
                    text: intl.formatMessage({
                      id: 'contactOriginForm.registeredOffice.true',
                      defaultMessage: 'Si',
                    }),
                    value: true,
                  },
                  {
                    text: intl.formatMessage({
                      id: 'contactOriginForm.registeredOffice.false',
                      defaultMessage: 'No',
                    }),
                    value: false,
                  },
                ]}
                error={Boolean(formikProps.errors.countryRegionCode)}
                search
                disabled={readOnly}
                icon={!readOnly ? null : <SvgIcon icon={ICONS.ARROW_DOWN} />}
              />
            </Grid.Column>
          ) : (
            <Grid.Column className="right-column" />
          )}
        </Grid.Row>
        {originId === 'new' &&
          formikProps.values.isSedeSecondaria === ContactType.OTHER_ORIGIN && (
            <Grid.Row className="document-row">
              <FileInputField
                field="documentFile"
                setFieldValue={formikProps.setFieldValue}
                label={intl.formatMessage({
                  id: 'contactOriginForm.document.chamberOfCommerce',
                  defaultMessage: 'Visura camerale *',
                })}
              />
              <span className="new-origin-required-field">
                {intl.formatMessage({
                  id: 'contactOriginForm.document.chamberOfCommerce.required',
                  defaultMessage: 'Campo richiesto',
                })}
              </span>
              <ErrorMessage
                name="documentFile"
                component="span"
                render={message => (
                  <span className="default-error-message input-field-error-message">
                    {message}
                  </span>
                )}
              />
            </Grid.Row>
          )}

        {originId !== 'new' && (
          <>
            <div className="origin-document-title">
              <FormattedMessage
                id="contact.origin.form.title.documents"
                defaultMessage="Documenti"
              />
            </div>
            <DocumentsTable
              areDocumentsLoading={state.isLoadingDocuments}
              contactCode={state.contactOrigin?.contactCode || ''}
              disabledUpload={isThatRole(ROLES.ADMIN)}
              documentsCollection={state.documents}
              reloadDocuments={() => null}
              showUnique={true}
            />
          </>
        )}
      </Grid>
    </Form>
  );
};

export default ContactOriginForm;
