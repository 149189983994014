import React from 'react';
import { CustomerDocumentsYearsFilterProps } from './types';
import moment from 'moment';
import { YEARS_FILTER_QUANTITY } from 'utils/global/customerCostants';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

export const CustomerDocumentsYearsFilter: React.FC<
  CustomerDocumentsYearsFilterProps
> = ({ changeSelectedYear, selectedYear, setFieldValue }) => {
  /**
   * Every time the years changes clear also the filter fileds
   * @param selectedYear
   */
  const onChangeSelectedYear = (selectedYear: string) => {
    setFieldValue('orderNumber', '');
    setFieldValue('filteredDateFrom', '');
    setFieldValue('filteredDateTo', '');
    changeSelectedYear(selectedYear);
  };

  const generateYearsFilters = () => {
    const currentYear = moment().year();
    const listOfYears = Array.from(
      { length: YEARS_FILTER_QUANTITY },
      (_, i) => currentYear - i,
    );

    return listOfYears.map(year => (
      <span
        className={`years-filter-button ${
          selectedYear === _.toString(year) ? 'years-filter-button-active' : ''
        }`}
        key={year}
        onClick={() => onChangeSelectedYear(_.toString(year))}
      >
        {year}
      </span>
    ));
  };

  return (
    <div className="years-filterBar-container">
      {generateYearsFilters()}
      <span
        className={`years-filter-button ${
          selectedYear === 'all' ? 'years-filter-button-active' : ''
        }`}
        key="all"
        onClick={() => onChangeSelectedYear('all')}
      >
        <FormattedMessage
          id="customerDocuments.filter.buttons.year.all"
          defaultMessage="Tutti"
        />
      </span>
    </div>
  );
};

export default CustomerDocumentsYearsFilter;
