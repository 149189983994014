import React, { useContext, useEffect, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Form, Input, Select } from 'formik-semantic-ui-react';
import { FieldArray, Formik, FormikHelpers } from 'formik';

// Components
import SvgIcon from 'components/SvgIcon';
import DocumentPhase from '../DocumentPhase';
import { ReservationConstants } from 'pages/Reservation/reducer/action';
import { toApprovePhaseSubmit } from './onSubmit';
import NumberFormatterInput from 'components/NumberFormatterInput';
import { mapUnitOfMeasure } from '../BaseInformationPhase/mapUnitOfMeasure';

// Constants - Internals - Utils
import { COLORS, ICONS } from 'utils/global/globalConstants';
import {
  EnvironmentalClass,
  environmentalClassEngOptions,
  environmentalClassOptions,
} from 'utils/global/reservationConstants';
import { ReservationContext } from 'pages/Reservation';
import { fetchCerListOptions } from 'services/reservation/fetchCerList';

// Types
import { Reservation } from 'pages/Reservation/types';
import { fetchOriginsListOptions } from 'services/reservation/fetchOriginsList';
import moment from 'moment';
import { LangProviderContext } from 'components/LangProvider';
import QRCode from 'react-qr-code';

const ToApprovePhase: React.FC<{ readOnly?: boolean }> = ({
  readOnly = false,
}) => {
  const { reservationId } = useParams();
  const intl = useIntl();
  const currentLang = useContext(LangProviderContext).language;

  const { state, dispatch } = useContext(ReservationContext);
  const [cerCodeList, setCerCodeList] = useState<Array<DropdownItemProps>>([]);
  const [originsList, setOriginsList] = useState<Array<DropdownItemProps>>([]);

  // On component mount load the options for the select of CER code, this will be showed
  // only if the environmental class is "rifiuto"
  useEffect(() => {
    fetchCerListOptions(setCerCodeList);
    fetchOriginsListOptions(
      state.reservation._embedded.contact.contactCode,
      setOriginsList,
      () => null,
      intl,
    );
  }, []);

  const setIsLoading = (isLoading: boolean) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION_LOADING,
      payload: { isReservationLoading: isLoading },
    });
  };

  const setReservation = (reservation: Reservation) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION,
      payload: { reservation: reservation },
    });
  };

  return (
    <div className="to-approve">
      <div className="reservation-header">
        <span className="reservation-header-title">
          <FormattedMessage
            id="baseInformation.title"
            defaultMessage="Informazioni sulla spedizione"
            description="Title message in the phase 2 of the reservation flow"
          />
        </span>
      </div>
      <div className="to-approve-content">
        <Formik
          initialValues={state.reservation}
          enableReinitialize
          onSubmit={(
            values: Reservation,
            formikHelpers: FormikHelpers<Reservation>,
          ) => {
            toApprovePhaseSubmit(
              values,
              reservationId,
              setIsLoading,
              setReservation,
              formikHelpers,
            );
          }}
        >
          {({ values, setFieldValue }) => (
            <Form id={state.reservation?.state}>
              <div className="reservation-fields-row">
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.USER}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Select
                      name="originCode"
                      label={intl.formatMessage({
                        id: 'baseInformation.label.originBusinessName',
                        defaultMessage: 'Ragione sociale provenienza *',
                      })}
                      options={originsList}
                      disabled
                    />
                  </div>
                </div>
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.CLOCK}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Input
                      name="timeAndDateDelivery"
                      label={intl.formatMessage({
                        id: 'deliveryInformation.label.deliveryDate',
                        defaultMessage: 'Data di consegna *',
                      })}
                      value={`${moment(values.deliveryDate).format(
                        'DD/MM/YYYY',
                      )} ${
                        values.timeSlot === 'morning'
                          ? intl.formatMessage({
                              id: 'toApprovePhase.timeslot.morningslot',
                              defaultMessage: ' - Mattina',
                            })
                          : values.timeSlot === 'afternoon'
                          ? intl.formatMessage({
                              id: 'toApprovePhase.timeslot.afternoonslot',
                              defaultMessage: ' - Pomeriggio',
                            })
                          : ''
                      }`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              {values.reservationLines && values.reservationLines.length > 0 && (
                <FieldArray
                  name="reservationLines"
                  render={() =>
                    values.reservationLines.map((line, index) => (
                      <>
                        <div className="reservation-form-divider" />
                        <div>
                          <span className="reservation-lines-title">
                            <FormattedMessage
                              id="reservation.toApprove.lineDescription"
                              defaultMessage="Riga d'ordine {orderNumber} - {lineNumber}"
                              values={{
                                line: index + 1,
                                orderNumber: line.documentNumber,
                                lineNumber: line.lineNumber,
                              }}
                            />
                          </span>
                        </div>
                        <div className="reservation-fields-row">
                          <div className="fields-with-icon">
                            <SvgIcon
                              icon={ICONS.WEIGHT}
                              color={COLORS.SECONDARY}
                              height={32}
                              width={32}
                            />
                            <div className="fields-content">
                              <NumberFormatterInput
                                field={{
                                  name: `reservationLines.${index}.transportedQuantity`,
                                  value: _.toString(line.transportedQuantity),
                                }}
                                placeholder={intl.formatMessage({
                                  id: 'baseInformation.placeholder.transportedQuantity',
                                  defaultMessage: 'Inserire il valore',
                                })}
                                label={intl.formatMessage({
                                  id: 'baseInformation.label.transportedQuantity',
                                  defaultMessage:
                                    'Peso del materiale trasportato *',
                                })}
                                prefix={mapUnitOfMeasure(
                                  line.unitOfMeasure,
                                  intl,
                                )}
                                setFieldValue={setFieldValue}
                                readOnly
                              />
                              <span className="max-quantity">
                                <FormattedMessage
                                  id="baseInformation.maxQuantity"
                                  defaultMessage="La quantità massima prenotabile per questa riga d'ordine è di {orderNumber} {unitOfMeasure}"
                                  values={{
                                    orderNumber: line.outstandingQuantity,
                                    unitOfMeasure: line.unitOfMeasure,
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="reservation-description-field">
                            {line.orderDescription}
                          </div>
                        </div>
                        <div className="reservation-fields-row">
                          <div className="fields-with-icon">
                            <SvgIcon
                              icon={ICONS.RECYCLE}
                              color={COLORS.SECONDARY}
                              height={32}
                              width={32}
                            />
                            <div className="fields-content">
                              <Select
                                name={`reservationLines.${index}.environmentalClass`}
                                label={intl.formatMessage({
                                  id: 'toApprovePhase.label.environmentalClass',
                                  defaultMessage: 'Classe ambientale *',
                                })}
                                placeholder={intl.formatMessage({
                                  defaultMessage:
                                    'Selezionare la classe ambientale',
                                  description:
                                    'Placeholder message showed when none options is selected',
                                  id: 'baseInformation.placeholder.environmentalClass',
                                })}
                                options={
                                  currentLang === 'it-IT' ||
                                  currentLang === 'it'
                                    ? environmentalClassOptions
                                    : environmentalClassEngOptions
                                }
                                disabled
                                onChange={(_e, { value }) => {
                                  setFieldValue(
                                    `reservationLines.${index}.cerCode`,
                                    '',
                                  );
                                  setFieldValue(
                                    `reservationLines.${index}.environmentalClass`,
                                    value,
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {line.environmentalClass ===
                            EnvironmentalClass.RIFIUTO && (
                            <div className="fields-with-icon">
                              <SvgIcon
                                icon={ICONS.SHIELD}
                                color={COLORS.SECONDARY}
                                height={32}
                                width={32}
                              />
                              <div className="fields-content">
                                <Select
                                  name={`reservationLines.${index}.cerCode`}
                                  label={intl.formatMessage({
                                    id: 'toApprovePhase.label.cerCode',
                                    defaultMessage: 'Codice CER *',
                                  })}
                                  placeholder={intl.formatMessage({
                                    defaultMessage:
                                      'Selezionare il codice di certificazione',
                                    description:
                                      'Placeholder message showed when none options is selected',
                                    id: 'baseInformation.placeholder.cerCode',
                                  })}
                                  options={cerCodeList}
                                  loading={_.isEmpty(cerCodeList)}
                                  disabled
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))
                  }
                />
              )}
              <div className="reservation-form-divider" />
              <div>
                <span className="reservation-header-title">
                  <FormattedMessage
                    id="deliveryInformation.vehiclePlate"
                    defaultMessage="Inserisci la targa del veicolo"
                  />
                </span>
              </div>
              <div>
                <div className="reservation-fields-row reservation-border-top">
                  <div className="fields-with-icon">
                    <SvgIcon
                      icon={ICONS.TRUCK_FRONTAL}
                      color={COLORS.SECONDARY}
                      height={32}
                      width={32}
                    />
                    <div className="fields-content">
                      <Input
                        name="vehiclePlate"
                        placeholder={intl.formatMessage({
                          id: 'toApprovePhase.placeholder.vehiclePlate',
                          defaultMessage: 'Inserire targa veicolo',
                        })}
                        label={intl.formatMessage({
                          id: 'toApprovePhase.label.vehiclePlate',
                          defaultMessage: 'Targa veicolo',
                        })}
                        readOnly={readOnly}
                      />
                      <span className="delivery-fields-description">
                        <FormattedMessage
                          id="deliveryInformation.description.vehiclePlate"
                          defaultMessage="Indicare la targa del veicolo che effettuerà la consegna."
                        />
                      </span>
                    </div>
                  </div>
                  <div className="fields-with-icon">
                    <SvgIcon
                      icon={ICONS.USER}
                      color={COLORS.SECONDARY}
                      height={32}
                      width={32}
                    />
                    <div className="fields-content">
                      <Input
                        name="shippingAgent"
                        placeholder={intl.formatMessage({
                          id: 'toApprovePhase.placeholder.shippingAgent',
                          defaultMessage: 'Inserire nome spedizioniere',
                        })}
                        label={intl.formatMessage({
                          id: 'toApprovePhase.label.shippingAgent',
                          defaultMessage: 'Spedizioniere',
                        })}
                        readOnly={readOnly}
                      />
                      <span className="delivery-fields-description">
                        <FormattedMessage
                          id="deliveryInformation.description.shippingAgent"
                          defaultMessage="Indicare il nome dello spedizioniere."
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reservation-form-divider" />
              <div className="reservation-form-qrcode">
                <QRCode
                  value={`Codice prenotazione: ${state.reservation.bookingNumber}`}
                />
              </div>
              <div className="reservation-form-divider" />
              <DocumentPhase readOnly={readOnly} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ToApprovePhase;
