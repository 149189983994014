import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Button, Grid } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { PasswordRecoveryFormProps } from './types';
import { PasswordRecoveryRequest } from 'services/passwordRecovery/types';
import { requestRecovery } from 'services/passwordRecovery';
import { FE_ROUTES } from 'utils/global/globalConstants';

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({
  setResponse,
}) => {
  const intl = useIntl();

  const onSubmit = (
    values: PasswordRecoveryRequest,
    formikHelpers: FormikHelpers<PasswordRecoveryRequest>,
  ) => requestRecovery(values, formikHelpers, setResponse);

  return (
    <Formik
      initialValues={{
        username: '',
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        username: Yup.string().label('Username').required().nullable(),
      })}
    >
      <Form>
        <Grid className="password-recovery-form">
          <Grid.Row>
            <Input
              name="username"
              placeholder={intl.formatMessage({
                id: 'loginForm.placeholder.username',
                defaultMessage: 'Username o P.IVA',
              })}
              label={
                <FormattedMessage
                  id="username"
                  defaultMessage="Username / P.IVA"
                />
              }
            />
            <ErrorMessage name="username" component="span" />
          </Grid.Row>
          <Grid.Row>
            <SubmitButton
              content={
                <FormattedMessage
                  id="recoverPassword"
                  defaultMessage="Recupera password"
                />
              }
            />
          </Grid.Row>
          <Grid.Row>
            <Button
              className="home-button"
              type="button"
              fluid
              content={
                <Link to={FE_ROUTES.LOGIN}>
                  <FormattedMessage
                    id="toHome"
                    defaultMessage="Torna alla Home"
                  />
                </Link>
              }
            />
          </Grid.Row>
        </Grid>
      </Form>
    </Formik>
  );
};

export default PasswordRecoveryForm;
