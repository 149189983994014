import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// Constants - Internals
import { FE_ROUTES } from 'utils/global/globalConstants';
import { SignupConstants } from 'pages/Signup/reducer/action';
import { SignupContext } from 'pages/Signup';

// Images
import mainLogo from 'assets/images/main-logo.svg';

const SignupType: React.FC = () => {
  const { dispatch } = useContext(SignupContext);

  return (
    <div className="signup-type">
      <Image
        src={mainLogo}
        className="signup-type-logo"
        as={Link}
        to={FE_ROUTES.LOGIN}
      />
      <div className="signup-type-title">
        <FormattedMessage
          id="signupType.header.title"
          defaultMessage="Come desideri registrarti?"
        />
      </div>
      <div className="signup-type-buttons">
        <div
          className="type-button"
          onClick={() =>
            dispatch({
              type: SignupConstants.SET_REGISTRATION_TYPE,
              payload: { signupType: 'supplier' },
            })
          }
        >
          <span className="type-button-text">
            <FormattedMessage
              id="signupType.button.supplier"
              defaultMessage="Fornitore"
            />
          </span>
        </div>
        <div
          className="type-button"
          onClick={() =>
            dispatch({
              type: SignupConstants.SET_REGISTRATION_TYPE,
              payload: { signupType: 'customer' },
            })
          }
        >
          <span className="type-button-text">
            <FormattedMessage
              id="signupType.button.client"
              defaultMessage="Cliente"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignupType;
