import { Input } from 'formik-semantic-ui-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Icon, Popup } from 'semantic-ui-react';

const Contacts: React.FC = () => {
  return (
    <>
      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub2"
          defaultMessage="Contatti"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
        <span>
          <Popup
            className="tooltip-password"
            content={
              <span className="tooltip-password-content">
                Indicare <b>un solo indirizzo mail</b> per ogni tipologia di
                documento
              </span>
            }
            basic
            hoverable
            trigger={
              <Icon className="tooltip-password-icon" name="question circle" />
            }
          />
        </span>
      </span>
      <Grid.Row className="allRowInput">
        <Input
          name="purchasingDepartmentHead"
          label={
            <FormattedMessage
              id="purchasingDepartmentHead"
              defaultMessage="Responsabile ufficio acquisti:"
            />
          }
          placeholder="Responsabile ufficio acquisti"
        />
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="purchasingDepartmentPhoneNumber"
            label={
              <FormattedMessage
                id="purchasingDepartmentPhoneNumber"
                defaultMessage="Numero di telefono del contatto"
              />
            }
            placeholder="Numero di telefono del contatto"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="purchasingDepartmentEmailAddress"
            label={
              <FormattedMessage
                id="purchasingDepartmentEmailAddress"
                defaultMessage="Indirizzo e-mail del contatto"
              />
            }
            placeholder="Indirizzo e-mail del contatto"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="sendOffersEmail"
            label={
              <FormattedMessage
                id="sendOffersEmail"
                defaultMessage="Indirizzo e-mail per invio offerte"
              />
            }
            placeholder="Indirizzo e-mail per invio offerte"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="sendLeagueContracts"
            label={
              <FormattedMessage
                id="sendLeagueContracts"
                defaultMessage="Indirizzo e-mail per invio contratti lega"
              />
            }
            placeholder="Indirizzo e-mail per invio contratti lega"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="sendOrderConfirmations"
            label={
              <FormattedMessage
                id="sendOrderConfirmations"
                defaultMessage="Indirizzo e-mail per invio conferme d’ordine"
              />
            }
            placeholder="Indirizzo e-mail per invio conferme d’ordine"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="sendTransportDocuments"
            label={
              <FormattedMessage
                id="sendTransportDocuments"
                defaultMessage="Indirizzo e-mail per invio documenti di trasporto"
              />
            }
            placeholder="Indirizzo e-mail per invio documenti di trasporto"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="sendCertifiedGoods"
            label={
              <FormattedMessage
                id="sendCertifiedGoods"
                defaultMessage="Indirizzo e-mail per invio certificati merce"
              />
            }
            placeholder="Indirizzo e-mail per invio certificati merce"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="sendMSMNewsletter"
            label={
              <span>
                <FormattedMessage
                  id="sendMSMNewsletter"
                  defaultMessage="Indirizzo e-mail per invio newsletter MSM"
                />
                <span>
                  <Popup
                    className="tooltip-password"
                    content={
                      <span className="tooltip-password-content">
                        Ricevere una mail per la conferma dell’iscrizione alla
                        newsletter
                      </span>
                    }
                    basic
                    hoverable
                    trigger={
                      <Icon
                        className="tooltip-password-icon"
                        name="question circle"
                      />
                    }
                  />
                </span>
              </span>
            }
            placeholder="Indirizzo e-mail per invio newsletter MSM"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="allRowInput" style={{ marginTop: '2rem' }}>
        <Input
          name="supplierAdministrationHead"
          label={
            <FormattedMessage
              id="supplierAdministrationHead"
              defaultMessage="Responsabile ufficio amministrazione fornitori"
            />
          }
          placeholder="Responsabile ufficio amministrazione fornitori"
        />
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="supplierAdministrationPhoneNumber"
            label={
              <FormattedMessage
                id="supplierAdministrationPhoneNumber"
                defaultMessage="Numero di telefono del contatto"
              />
            }
            placeholder="Numero di telefono del contatto"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="supplierAdministrationEmailAddress"
            label={
              <FormattedMessage
                id="supplierAdministrationEmailAddress"
                defaultMessage="Indirizzo e-mail del contatto"
              />
            }
            placeholder="Indirizzo e-mail del contatto"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column className="noPaddingLeft">
          <Input
            name="SDICode"
            label={
              <FormattedMessage
                id="data.collection.SDICode"
                defaultMessage="CODICE SDI per fatturazione elettronica"
              />
            }
            placeholder="CODICE SDI per fatturazione elettronica"
          />
        </Grid.Column>

        <Grid.Column className="noPaddingRight">
          <Input
            name="PECMail"
            label={
              <FormattedMessage
                id="data.collection.mail.pec"
                defaultMessage="PEC mail"
              />
            }
            placeholder="PEC mail"
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
export default Contacts;
