import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Image } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from 'pages/App';

// Images
import loginLogo from 'assets/images/main-logo.svg';

// Form
import LoginForm from 'components/Forms/LoginForm';
import { LoginFormValues } from 'components/Forms/LoginForm/types';
import { userLogin } from 'services/auth/userLogin';
import { LangProviderContext } from 'components/LangProvider';

const Login: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const { setLanguage } = useContext(LangProviderContext);
  const navigate = useNavigate();

  const onSubmit = (values: LoginFormValues) => {
    userLogin(dispatch, values, 'password', navigate, setLanguage);
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>MSM - Login</title>
        <meta name="description" content="login page" />
      </Helmet>
      <Image src={loginLogo} />
      <div className="login-container-form">
        <LoginForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default Login;
