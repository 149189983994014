import React from 'react';
import { Button, Image } from 'semantic-ui-react';

// Icons
import finishedIcon from 'assets/images/signup-completed.svg';
import { useNavigate } from 'react-router-dom';
import { FE_ROUTES } from 'utils/global/globalConstants';
import { FormattedMessage, useIntl } from 'react-intl';

const FinishedPhase: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className="finished-phase">
      <Image src={finishedIcon} />
      <span className="title-text">
        <FormattedMessage
          id="reservation.finishedPhase.title"
          defaultMessage="Richiesta di prenotazione inserita con successo"
        />
      </span>
      <span className="subtitle-text">
        <FormattedMessage
          id="reservation.finishedPhase.firstSubtitile"
          defaultMessage="La prenotazione ora è in fase di validazione."
        />
        <br />
        <FormattedMessage
          id="reservation.finishedPhase.secondSubtitle"
          defaultMessage="Riceverai una mail di conferma entro 24h lavorative."
        />
      </span>
      <Button
        className="insertButton"
        content={intl.formatMessage({
          id: 'booking.finished.phase.newBooking',
          defaultMessage: 'Nuova prenotazione',
        })}
        onClick={() => navigate(FE_ROUTES.RESERVATION_DETAILS + '/new')}
      />
      <Button
        className="underlineButton"
        content={intl.formatMessage({
          id: 'booking.finished.phase.seeBookings',
          defaultMessage: 'Vedi prenotazioni',
        })}
        onClick={() => navigate(FE_ROUTES.RESERVATION_LIST + '/toApprove')}
      />
    </div>
  );
};

export default FinishedPhase;
