import { MetalAvailabilityState } from '../types';
import { MetalAvailabilityAction, MetalAvailabilityConstants } from './action';

export const initialMetalAvailabilityState: MetalAvailabilityState = {
  isMetalAvailabilityLoading: false,
  metalAvailability: {
    totalQuantity: '',
    totalAlloyQuantity: '',
    pendingItems: [],
    contracts: [],
    items: [],
  },
};

export const MetalAvailabilityReducer = (
  state: MetalAvailabilityState,
  action: MetalAvailabilityAction,
): MetalAvailabilityState => {
  const { type } = action;

  switch (type) {
    case MetalAvailabilityConstants.SET_METAL_AVAILABILITY_SUMMARY_DATA:
      return {
        ...state,
        metalAvailability: action.payload.metalAvailability,
      };
    case MetalAvailabilityConstants.SET_METAL_AVAILABILITY_LOADING:
      return {
        ...state,
        isMetalAvailabilityLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
};
