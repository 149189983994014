import { AxiosResponse } from 'axios';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { FetchBookingAvailabilityResponse } from './types';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

export const fetchBookingAvailability = (
  setAvailableDates: (availableDates: Array<Date>) => void,
  setAvailableTimeslot: (availableTImeSlot: Array<string>) => void,
  areAvailableDateLoaded: (areLoaded: boolean) => void,
  reservationCode: string | null,
): void => {
  standardClient({
    url: API.BOOKING_AVAILABILITY,
    method: 'POST',
    data: {
      params: {
        reservationCode: reservationCode,
      },
    },
  })
    .then((response: AxiosResponse<FetchBookingAvailabilityResponse>) => {
      // This function create an object with key equals to date
      const grouppedDays = _.groupBy(
        response.data.reservation_calendar,
        'date',
      );

      const blockedDays: Array<Date> = [];
      const blockedTimeSlot: Array<string> = [];

      /**
       * Block only the dates where is blocked the morning and also the afternoon
       *
       * Add in the blocked time slot the reference to the not available time slot for
       * a specific day (that will be key)
       *  ex. [2022-11-09: 'morning', 2022-10-28: 'morning', 2022-10-15: 'afternoon']
       */
      Object.keys(grouppedDays).forEach(key => {
        if (grouppedDays[key].length >= 2) {
          blockedDays.push(new Date(key));
        } else {
          const formattedDate = moment(key).format('YYYY-MM-DD').toString();
          blockedTimeSlot[formattedDate] = grouppedDays[key][0].timeSlot;
        }
      });

      setAvailableDates(blockedDays);
      setAvailableTimeslot(blockedTimeSlot);
      areAvailableDateLoaded(true);
    })
    .catch(() => {
      toast.error('Impossibile caricare le date disponibili');
    });
};
