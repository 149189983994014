import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const clockFilled = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 20}
    height={height || 20}
    viewBox="0 0 20 20"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.48 4.47 0 9.99 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 9.99 20C4.47 20 0 15.52 0 10ZM9 5H10.5V10.25L15 12.92L14.25 14.15L9 11V5Z"
    />
  </svg>
);

export default clockFilled;
