import { DocumentData } from 'pages/ContactDocuments/types';
import { Contact } from 'services/auth/types';

export enum UserDetailsConstants {
  SET_DETAILS_LOADING = 'UserDetails/SET_DETAILS_LOADING',
  SET_DOCUMENTS_LOADING = 'UserDetails/SET_DOCUMENTS_LOADING',
  SET_USER_DETAILS = 'UserDetails/SET_USER_DETAILS',
  SET_USER_DOCUMENTS = 'UserDetails/SET_USER_DOCUMENTS',
}

export type UserDetailsAction =
  | {
      type: UserDetailsConstants.SET_DETAILS_LOADING;
      payload: { areDetailsLoading: boolean };
    }
  | {
      type: UserDetailsConstants.SET_DOCUMENTS_LOADING;
      payload: { areDocumentsLoading: boolean };
    }
  | {
      type: UserDetailsConstants.SET_USER_DETAILS;
      payload: { userDetails: Contact };
    }
  | {
      type: UserDetailsConstants.SET_USER_DOCUMENTS;
      payload: { documentsCollection: Array<DocumentData> };
    };
