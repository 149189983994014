import { ContactOrigin } from 'services/contactOrigin/types';

export enum ContactOriginsListConstants {
  RESET_ALL_FILTERS = 'ContactOrigins/RESET_ALL_FILTERS',
  SET_COMPLETE_ORIGINS_COLLECTION = 'ContactOrigins/SET_COMPLETE_ORIGINS_COLLECTION',
  SET_FILTERED_ORIGINS_COLLECTION = 'ContactOrigins/SET_FILTERED_ORIGINS_COLLECTION',
  SET_IS_LOADING = 'ContactOrigins/SET_IS_LOADING',
}

export type ContactOriginsListAction =
  | {
      type: ContactOriginsListConstants.RESET_ALL_FILTERS;
    }
  | {
      type: ContactOriginsListConstants.SET_COMPLETE_ORIGINS_COLLECTION;
      payload: { completeOriginsCollection: Array<ContactOrigin> };
    }
  | {
      type: ContactOriginsListConstants.SET_FILTERED_ORIGINS_COLLECTION;
      payload: { filteredOriginsCollection: Array<ContactOrigin> };
    }
  | {
      type: ContactOriginsListConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    };
