import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { PasswordRecoveryState } from 'pages/PasswordRecovery/types';
import { toast } from 'react-toastify';

// Client
import standardClient from 'services/client/standardRequestClient';
import { StandardResponseError } from 'services/client/types';

// Constants
import { API } from 'utils/global/backendRoutes';

// Types
import { PasswordRecoveryRequest, PasswordRecoveryResponse } from './types';

export const requestRecovery = (
  values: PasswordRecoveryRequest,
  formikHelpers: FormikHelpers<PasswordRecoveryRequest>,
  callback: (response: PasswordRecoveryState) => void,
): void => {
  standardClient({
    url: API.PASSWORD_RECOVERY,
    method: 'POST',
    data: {
      method: 'request_recovery',
      params: {
        ...values,
      },
    },
  })
    .then((response: AxiosResponse<PasswordRecoveryResponse>) => {
      if (!response.data.result) {
        toast.error(
          `Errore durante l'invio dell'email a ${response.data.email}`,
        );
        return;
      }

      // The response is okay, so back to the response page.
      callback({
        email: response.data.email,
        sent: true,
      });
    })
    .catch((error: AxiosResponse<StandardResponseError | undefined>) => {
      if (error.status === 500 && error.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error('Errore durante il recupero della password');
      }
    })
    .finally(() => formikHelpers.setSubmitting(false));
};
