import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const calendarFlat = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.4 0H1.6C0.72 0 0 0.720533 0 1.6V14.4C0 15.2789 0.72 16 1.6 16H14.4C15.28 16 16 15.2789 16 14.4V1.6C16 0.720533 15.28 0 14.4 0ZM4.31787 14.0811H1.86667V11.9008H4.31787V14.0811ZM4.31787 11.1696H1.86667V8.9904H4.31787V11.1696ZM4.13333 2.42507C3.7584 2.42507 3.4544 2.1216 3.4544 1.74613C3.4544 1.37067 3.7584 1.06667 4.13333 1.06667C4.50827 1.06667 4.81227 1.37013 4.81227 1.7456C4.81227 2.12107 4.50827 2.42507 4.13333 2.42507ZM7.5888 14.0811H5.13813V11.9008H7.5888V14.0811ZM7.5888 11.1696H5.13813V8.9904H7.5888V11.1696ZM7.5888 8.26027H5.13813V6.08107H7.5888V8.26027ZM7.32107 1.7456C7.32107 1.37013 7.62507 1.06667 8 1.06667C8.37493 1.06667 8.67893 1.37013 8.67893 1.7456C8.67893 2.12107 8.37493 2.42453 8 2.42453C7.62507 2.42453 7.32107 2.1216 7.32107 1.7456ZM10.8619 11.1696H8.41067V8.9904H10.8624V11.1696H10.8619ZM10.8619 8.26027H8.41067V6.08107H10.8624V8.26027H10.8619ZM11.1877 1.7456C11.1877 1.37013 11.4917 1.06667 11.8667 1.06667C12.2416 1.06667 12.5456 1.37013 12.5456 1.7456C12.5456 2.12107 12.2416 2.42453 11.8667 2.42453C11.4917 2.42453 11.1877 2.1216 11.1877 1.7456ZM14.1333 11.1696H11.6821V8.9904H14.1333V11.1696ZM14.1333 8.26027H11.6821V6.08107H14.1333V8.26027Z" />
  </svg>
);

export default calendarFlat;
