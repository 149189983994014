import React, { useContext, useEffect } from 'react';
import { ReservationListContext } from 'pages/ReservationList';
import { fetchReservationList } from 'services/reservation/fetchReservation';
import { ReservationStateConstants } from 'utils/global/reservationConstants';
import AccordionList from '../AccordionList';

const ApprovedList: React.FC = () => {
  const { dispatch } = useContext(ReservationListContext);

  useEffect(() => {
    fetchReservationList([ReservationStateConstants.APPROVED], dispatch);
  }, []);

  return <AccordionList />;
};

export default ApprovedList;
