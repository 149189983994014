import React from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { useIntl } from 'react-intl';

export const ReservationFiltersBar: React.FC<{
  onReset: () => void;
  onSearch: (bookingNumber: string) => void;
}> = ({ onReset, onSearch }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{ bookingNumber: '' }}
      onSubmit={values => onSearch(values.bookingNumber)}
    >
      <Form>
        <div className="reservation-filters">
          <Input
            className="reservation-number-input"
            placeholder={intl.formatMessage({
              id: 'reservationFilterBar.bookingNumber.placeholder',
              defaultMessage: 'Numero prenotazione',
            })}
            name="bookingNumber"
          />
          <Button
            className="reservation-filters-search"
            content={intl.formatMessage({
              id: 'reservationFiltersBar.button.search',
              defaultMessage: 'Cerca',
            })}
            type="submit"
          />
          <Button
            className="underlineButton reservation-filters-reset"
            content={intl.formatMessage({
              id: 'reservationFiltersBar.button.reset',
              defaultMessage: 'Rimuovi i filtri attivi',
            })}
            onClick={onReset}
            type="button"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default ReservationFiltersBar;
