import { DataCollectionDetails } from '../types';

export enum DataCollectionConstants {
  SET_IS_LOADGING = 'DataCollection/SET_IS_LOADGING',
  SET_DATA_COLLECTION = 'DataCollection/SET_CONTACT_ORIGIN',
  SET_IS_SUBMITTING = 'DataCollection/SET_IS_SUBMITTING',
}

export type DataCollectionAction =
  | {
      type: DataCollectionConstants.SET_IS_LOADGING;
      payload: { isLoading: boolean };
    }
  | {
      type: DataCollectionConstants.SET_DATA_COLLECTION;
      payload: { dataCollection: DataCollectionDetails };
    }
  | {
      type: DataCollectionConstants.SET_IS_SUBMITTING;
      payload: { isSubmitting: boolean };
    };
