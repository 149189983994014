import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

// Components
import PageHeader from 'components/PageHeader';

// Constants - Internals - Utils
import { AppContext } from 'pages/App';
import {
  CreditsLimitReducer,
  initialCreditsLimitState,
} from './reducer/reducer';
import { CreditsLimitAction, CreditsLimitConstants } from './reducer/action';

// Types
import { CreditsLimit, CreditsLimitState } from './types';
import NoContactSelected from 'components/NoContactSelected';
import _ from 'lodash';
import { getCustomerCreditsLimit } from 'services/customer/getCustomerDocumentDetails';
import CreditsLimitTable from 'components/CreditsLimitTable';

export const CreditsLimitContext = createContext<{
  state: CreditsLimitState;
  dispatch: React.Dispatch<CreditsLimitAction>;
}>({
  state: initialCreditsLimitState,
  dispatch: () => null,
});

const CustomerCreditsLimit: React.FC = () => {
  const intl = useIntl();
  const appState = useContext(AppContext).state;
  const intlFormatNumber = new Intl.NumberFormat('it-IT');

  const [state, dispatch] = useReducer(
    CreditsLimitReducer,
    initialCreditsLimitState,
  );

  useEffect(() => {
    callCreditsLimit();
  }, []);

  useEffect(() => {
    callCreditsLimit();
  }, [appState.selectedContact]);

  const setIsLoading = (isCreditsLineLoading: boolean) => {
    dispatch({
      type: CreditsLimitConstants.SET_CREDITS_LIMIT_LOADING,
      payload: { isLoading: isCreditsLineLoading },
    });
  };

  const setData = (creditsLimitData: CreditsLimit) => {
    dispatch({
      type: CreditsLimitConstants.SET_CREDITS_LIMIT_DATA,
      payload: { creditsLimit: creditsLimitData },
    });
  };

  // Check if the agent has selected the customer otherwise don't do the api call
  const callCreditsLimit = () => {
    if (!_.isEmpty(appState.selectedContact)) {
      getCustomerCreditsLimit(
        setIsLoading,
        setData,
        appState.selectedContact.contactCode,
        intl,
      );
    }
  };

  return (
    <CreditsLimitContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'customerCreditsLimit.helmet.title',
            defaultMessage: 'MSM - Situazione contabile',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'customerCreditsLimit.header.title',
          defaultMessage: 'Situazione contabile',
        })}
      />
      {_.isEmpty(appState.selectedContact) ? (
        <NoContactSelected />
      ) : (
        <div className="credits-limit-container">
          {state.isCreditsLimitLoading ? (
            <div className="customerCreditsLimit-loading">
              <CircularProgress color="inherit" />
              <span>
                <FormattedMessage
                  id="customerCreditsLimit.loading"
                  defaultMessage="Situazione contabile in caricamento"
                />
              </span>
            </div>
          ) : (
            <>
              <div className="credits-limit-main-data">
                <div className="credits-limit-card">
                  <div className="card-title">
                    <FormattedMessage
                      id="customerCreditsLimit.creditLimitAmount"
                      defaultMessage="Limite di credito"
                    />
                  </div>
                  <div className="card-data">
                    {intlFormatNumber.format(
                      _.toNumber(state.creditsLimitData.creditLimitAmount),
                    )}
                    {' EUR'}
                  </div>
                </div>
                <div className="credits-limit-card">
                  <div className="card-title">
                    <FormattedMessage
                      id="customerCreditsLimit.totalAmount"
                      defaultMessage="Totale importo"
                    />
                  </div>
                  <div className="card-data">
                    {intlFormatNumber.format(
                      _.toNumber(state.creditsLimitData.totalAmount),
                    )}
                    {' ' + state.creditsLimitData.currencyCode}
                  </div>
                </div>
                <div className="credits-limit-card">
                  <div className="card-title">
                    <FormattedMessage
                      id="customerCreditsLimit.totalExpiredAmount"
                      defaultMessage="Totale scaduto"
                    />
                  </div>
                  <div className="card-data">
                    {intlFormatNumber.format(
                      _.toNumber(state.creditsLimitData.totalExpiredAmount),
                    )}
                    {' ' + state.creditsLimitData.currencyCode}
                  </div>
                </div>
              </div>
              <CreditsLimitTable
                creditsLimit={state.creditsLimitData.lines}
                currencyCode={state.creditsLimitData.currencyCode}
              />
            </>
          )}
        </div>
      )}
    </CreditsLimitContext.Provider>
  );
};

export default CustomerCreditsLimit;
