import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Button } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import ArticlePhase from 'components/Reservation/ArticlePhase';
import BaseInformationPhase from 'components/Reservation/BaseInformationPhase';
import DeliveryInformationPhase from 'components/Reservation/DeliveryInformationPhase';
import DocumentPhase from 'components/Reservation/DocumentPhase';
import FinishedPhase from 'components/Reservation/FinishedPhase';
import ToApprovePhase from 'components/Reservation/ToApprovePhase';
import PageHeader from 'components/PageHeader';
import StepMenu from 'components/Reservation/StepMenu';

// Constants - Internals - Utils
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import { initialReservationState, reservationReducer } from './reducer/reducer';
import { mapStateToStepMenuProps } from 'components/Reservation/StepMenu/mapStateToStepMenuProps';
import { ReservationAction, ReservationConstants } from './reducer/action';
import { ReservationStateConstants } from 'utils/global/reservationConstants';

// Services
import { fetchReservation } from 'services/reservation/fetchReservation';
import {
  nextStateReservation,
  postNewReservation,
  prevStateReservation,
} from 'services/reservation/postReservation';

// Types
import { Reservation as ReservationType, ReservationState } from './types';
import { isThatRole } from 'utils/function/acl';
import { AppContext } from 'pages/App';
import _ from 'lodash';

export const ReservationContext = createContext<{
  state: ReservationState;
  dispatch: React.Dispatch<ReservationAction>;
}>({
  state: initialReservationState,
  dispatch: () => null,
});

const Reservation: React.FC = () => {
  const { reservationId, mode, from } = useParams();
  const contextState = useContext(AppContext).state;

  const navigate = useNavigate();
  const intl = useIntl();

  const [state, dispatch] = useReducer(
    reservationReducer,
    initialReservationState,
  );

  /**
   * Need also the useEffect that look for the reservatonId changes in
   * the URL beacause on reload page when we create a new reservation
   * (from ArticlePhase to BaseInformationPhase) the useEffect of component
   * initialization isn't reloaded
   */
  useEffect(() => {
    if (reservationId !== ReservationStateConstants.NEW) {
      fetchReservation(dispatch, reservationId, navigate);
    }
  }, [reservationId]);

  /**
   * Need also the useEffect that look for the reservatonId changes in
   * the URL beacause on reload page when we create a new reservation
   * (from ArticlePhase to BaseInformationPhase) the useEffect of component
   * initialization isn't reloaded
   */
  useEffect(() => {
    if (mode === 'edit' && isThatRole(ROLES.ADMIN, contextState)) {
      navigate(`/reservation/${reservationId}/view`);
    }
  }, [mode]);

  /** Render the reservation main content based on the current state */
  const renderReservationContent = () => {
    const readOnly = mode === 'edit' ? false : true;

    if (reservationId === ReservationStateConstants.NEW) {
      return <ArticlePhase />;
    }

    if (
      state.reservation?.state === ReservationStateConstants.BASE_INFORMATION
    ) {
      return <BaseInformationPhase readOnly={readOnly} />;
    }

    if (
      state.reservation?.state ===
      ReservationStateConstants.DELIVERY_INFORMATION
    ) {
      return <DeliveryInformationPhase readOnly={readOnly} />;
    }

    if (
      state.reservation?.state === ReservationStateConstants.DOCUMENT_UPLOADING
    ) {
      return <DocumentPhase readOnly={readOnly} />;
    }

    if (state.reservation?.state === ReservationStateConstants.FINISHED) {
      return <FinishedPhase />;
    }

    if (
      state.reservation?.state === ReservationStateConstants.TO_APPROVE ||
      state.reservation?.state === ReservationStateConstants.APPROVED
    ) {
      return <ToApprovePhase readOnly={readOnly} />;
    }

    if (state.reservation?.state === ReservationStateConstants.CLOSED) {
      return <ToApprovePhase readOnly={true} />;
    }

    if (state.reservation?.state === ReservationStateConstants.UNAPPROVED) {
      return <ToApprovePhase readOnly={true} />;
    }
  };

  const isUserAdmin = () => {
    if (_.includes(contextState.mySelf?.roles, ROLES.ADMIN)) {
      return true;
    }

    return false;
  };

  /**
   * Custom calls for the reservation's NEW state, there isn't a form so the post function
   * must be called explicitly.
   * The others submit function are linked to the button with form param
   */
  const reservationSubmit = () => {
    if (
      reservationId === ReservationStateConstants.NEW &&
      !_.isEmpty(state.selectedOrdersLine)
    ) {
      postNewReservation(
        state.selectedOrdersLine,
        setIsLoading,
        reloadPageWithId,
      );
    } else if (
      state.reservation &&
      state.reservation.state === ReservationStateConstants.DOCUMENT_UPLOADING
    ) {
      nextStateReservation(
        {
          state: ReservationStateConstants.TO_APPROVE,
          vehiclePlate: state.reservation.vehiclePlate,
        },
        reservationId,
        setIsLoading,
        setReservation,
      );
    }
  };

  const setReservation = (reservation: ReservationType) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION,
      payload: { reservation: reservation },
    });
  };

  const setIsLoading = (isLoading: boolean) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION_LOADING,
      payload: { isReservationLoading: isLoading },
    });
  };

  const setIsPrevLoading = (isLoading: boolean) => {
    dispatch({
      type: ReservationConstants.SET_PREV_RESERVATION_LOADING,
      payload: { isPrevReservationLoading: isLoading },
    });
  };

  /**
   * Reload the page with the reservation id passed as param
   * @param reservationId
   */
  const reloadPageWithId = (reservationId: string) => {
    navigate(FE_ROUTES.RESERVATION_DETAILS + '/' + reservationId + '/edit');
  };

  /**
   * Call the function that maps the props for the step menu based on
   * the current state and return them
   */
  const getCurrentStateStepMenuProps = () => {
    // If the ID in the url is 'new' pass as value 'new' otherwise take
    // it from reservation entity in the state
    return mapStateToStepMenuProps(
      reservationId === 'new' ? 'new' : state.reservation?.state,
    );
  };

  /**
   * Based on the current state return a menu collection - this is necessary because
   * when a reservation is completed the options on the left are different
   * @returns Array<StepMenuElement> Array of options for menu
   */
  const getReservationStepMenu = () => {
    if (
      state.reservation?.state === ReservationStateConstants.TO_APPROVE ||
      state.reservation?.state === ReservationStateConstants.APPROVED ||
      state.reservation?.state === ReservationStateConstants.CLOSED
    ) {
      return [
        {
          description: intl.formatMessage({
            id: 'reservation.reservationCompleteStepMenu.info',
            defaultMessage: 'Informazioni sulla spedizione',
          }),
          key: 1,
          number: 1,
        },
        {
          description: intl.formatMessage({
            id: 'reservation.reservationCompleteStepMenu.documentation',
            defaultMessage: 'Documentazione aggiuntiva',
          }),
          key: 2,
          number: 2,
        },
        {
          description: intl.formatMessage({
            id: 'reservation.reservationCompleteStepMenu.vehiclePlate',
            defaultMessage: 'Targa veicolo',
          }),
          key: 3,
          number: 3,
        },
      ];
    }

    return [
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.article',
          defaultMessage: 'Articolo',
        }),
        key: 1,
        number: 1,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.information',
          defaultMessage: 'Informazioni sulla spedizione',
        }),
        key: 2,
        number: 2,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.deliveryDate',
          defaultMessage: 'Data consegna',
        }),
        key: 3,
        number: 3,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.additionalDocumentation',
          defaultMessage: 'Documentazione aggiuntiva',
        }),
        key: 4,
        number: 4,
      },
    ];
  };

  return (
    <ReservationContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'reservation.title',
            defaultMessage: 'MSM - Prenotazione',
            description: 'The title in the browser tab',
          })}
        </title>
      </Helmet>
      {reservationId === 'new' ? (
        <PageHeader
          title={intl.formatMessage({
            id: 'reservation.header.newReservation',
            defaultMessage: 'Nuova richiesta di prenotazione',
          })}
        />
      ) : (
        <PageHeader
          title={intl.formatMessage(
            {
              id: 'reservation.header.savedReservation',
              defaultMessage: 'Prenotazione {reservationNumber}',
            },
            { reservationNumber: state.reservation?.bookingNumber },
          )}
        />
      )}

      <div className="reservation-container">
        <div className="reservation-content">
          <div className="reservation-content-sidebar">
            <StepMenu
              blockedPhase={getCurrentStateStepMenuProps().blockedPhase}
              currentPhase={getCurrentStateStepMenuProps().currentPhase}
              editablePhase={getCurrentStateStepMenuProps().editablePhase}
              stepMenuDefinition={getReservationStepMenu()}
            />
          </div>
          <div className="reservation-content-form">
            {renderReservationContent()}
          </div>
        </div>
        <div className="reservation-footer">
          <Button
            className="underlineButton withMarginRight"
            content={
              mode === 'view' ||
              state.reservation?.state === ReservationStateConstants.FINISHED ||
              state.reservation?.state === ReservationStateConstants.CLOSED ||
              state.reservation?.state === ReservationStateConstants.APPROVED
                ? intl.formatMessage({
                    id: 'reservation.button.toList',
                    defaultMessage: 'Torna alla lista',
                    description:
                      'Button label for the back button in reservation flow',
                  })
                : intl.formatMessage({
                    id: 'reservation.button.back',
                    defaultMessage: 'Precedente',
                    description:
                      'Button label for the back button in reservation flow',
                  })
            }
            disabled={state.isPrevStateLoading || state.isReservationLoading}
            loading={state.isPrevStateLoading}
            onClick={() => {
              // I am adming go back pge
              if (isUserAdmin()) {
                // If previous URL is calendar set deliveryDate
                if (from) {
                  navigate(
                    FE_ROUTES.CALENDAR + '/' + state.reservation.deliveryDate,
                  );
                } else {
                  navigate(-1);
                }
              } else if (
                reservationId === ReservationStateConstants.NEW ||
                state.reservation?.state ===
                  ReservationStateConstants.FINISHED ||
                mode === 'view'
              ) {
                navigate(FE_ROUTES.RESERVATION_LIST + '/working');
              } else if (
                state.reservation?.state === ReservationStateConstants.CLOSED
              ) {
                navigate(FE_ROUTES.RESERVATION_LIST + '/closed');
              } else if (
                state.reservation?.state === ReservationStateConstants.APPROVED
              ) {
                navigate(FE_ROUTES.RESERVATION_LIST + '/approved');
              } else if (
                state.reservation?.state ===
                ReservationStateConstants.DELIVERY_INFORMATION
              ) {
                prevStateReservation(
                  {
                    state:
                      ReservationStateConstants.DELIVERY_INFORMATION + '_back',
                  },
                  reservationId,
                  setIsPrevLoading,
                  setReservation,
                );
              } else if (
                state.reservation?.state ===
                ReservationStateConstants.DOCUMENT_UPLOADING
              ) {
                prevStateReservation(
                  {
                    state:
                      ReservationStateConstants.DOCUMENT_UPLOADING + '_back',
                  },
                  reservationId,
                  setIsPrevLoading,
                  setReservation,
                );
              } else if (
                state.reservation?.state ===
                ReservationStateConstants.BASE_INFORMATION
              ) {
                navigate(FE_ROUTES.RESERVATION_DETAILS + '/new');
                /** @TODO for base information state must delete the reservation */
              }
            }}
          />
          {state.reservation?.state != ReservationStateConstants.FINISHED &&
            (mode === 'edit' ||
              reservationId === ReservationStateConstants.NEW) && (
              <Button
                className="mainButton insertButton"
                content={
                  [
                    ReservationStateConstants.TO_APPROVE,
                    ReservationStateConstants.APPROVED,
                  ].includes(state.reservation?.state)
                    ? intl.formatMessage({
                        id: 'reservation.button.save',
                        defaultMessage: 'Salva modifiche',
                        description: 'Button label for the save data',
                      })
                    : intl.formatMessage({
                        id: 'reservation.button.next',
                        defaultMessage: 'Continua',
                        description:
                          'Button label for the next button in reservation flow',
                      })
                }
                loading={state.isReservationLoading}
                disabled={
                  (!state.selectedOrdersLine && !state.reservation) ||
                  state.isPrevStateLoading ||
                  state.isReservationLoading
                }
                // Call the submit function of the form with this ID
                form={state.reservation?.state || null}
                onClick={() => reservationSubmit()}
                type="submit"
              />
            )}
        </div>
      </div>
    </ReservationContext.Provider>
  );
};

export default Reservation;
