import { Input } from 'formik-semantic-ui-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Icon, Popup } from 'semantic-ui-react';

const GeneralData: React.FC = () => {
  return (
    <>
      <span className="subtitle">
        <FormattedMessage
          id="dataCollection.sub1"
          defaultMessage="Dati generali"
          description="Subtitle message in the phase 2 of the reservation flow, under the title"
        />
      </span>
      <Grid.Row className="allRowInput">
        <Input
          name="legalResidence"
          label={
            <span>
              <FormattedMessage
                id="legalResidence"
                defaultMessage="Sede legale"
              />
              <span>
                <Popup
                  className="tooltip-password"
                  content={
                    <span className="tooltip-password-content">
                      Indirizzo completo o timbro azienda
                    </span>
                  }
                  basic
                  hoverable
                  trigger={
                    <Icon
                      className="tooltip-password-icon"
                      name="question circle"
                    />
                  }
                />
              </span>
            </span>
          }
          placeholder="Sede legale"
        />
      </Grid.Row>
      <Grid.Row className="allRowInput">
        <Input
          name="operatingOffice"
          label={
            <span>
              <FormattedMessage
                id="operatingOffice"
                defaultMessage="Eventuali sedi operative"
              />
              <span>
                <Popup
                  className="tooltip-password"
                  content={
                    <span className="tooltip-password-content">
                      Se diverse dalla sede legale
                    </span>
                  }
                  basic
                  hoverable
                  trigger={
                    <Icon
                      className="tooltip-password-icon"
                      name="question circle"
                    />
                  }
                />
              </span>
            </span>
          }
          placeholder="Eventuali sedi operative"
        />
      </Grid.Row>
    </>
  );
};

export default GeneralData;
