import SvgIcon from 'components/SvgIcon';
import _ from 'lodash';
import React from 'react';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { StepMenuProps } from './types';

const StepMenu: React.FC<StepMenuProps> = ({
  blockedPhase = [],
  currentPhase,
  editablePhase = [],
  stepMenuDefinition,
}) => {
  const renderMenuIcon = (menuNumber: number) => {
    // If this phase is blocked return a padlock icon
    if (_.includes(blockedPhase, menuNumber)) {
      return <SvgIcon icon={ICONS.PADLOCK} />;
    }

    // If this phase can be edit return pencil icon
    if (_.includes(editablePhase, menuNumber)) {
      return <SvgIcon icon={ICONS.PENCIL} />;
    }

    // If this phase has already been done return a positive check
    if (currentPhase > menuNumber) {
      return <SvgIcon color={COLORS.SUCCESS} icon={ICONS.CHECK} />;
    }

    // None of the above rules, return the menu number
    return menuNumber;
  };

  return (
    <div className="step-menu-container">
      {stepMenuDefinition.map(menuElement => (
        <div
          className={`step-menu-element ${
            menuElement.number === currentPhase ? 'step-active' : ''
          }`}
          key={menuElement.key}
        >
          <span className="step-menu-element-text">
            {menuElement.description}
          </span>
          <span className="step-menu-element-icon">
            {renderMenuIcon(menuElement.number)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StepMenu;
