import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import {
  CustomerQuoteDetails,
  CustomerQuoteLineTableColunns,
  CustomerQuoteModalProps,
} from './types';
import SvgIcon from 'components/SvgIcon';
import { ICONS } from 'utils/global/globalConstants';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import SemanticTableGrid from 'semantic-table-grid';

import { Nullable } from 'utils/types';
import _ from 'lodash';
import { getCustomerQuoteDetails } from 'services/customer/getCustomerDocumentDetails';
import { CircularProgress } from '@material-ui/core';

const CustomerQuoteModal: React.FC<CustomerQuoteModalProps> = ({
  quoteDocumentCode,
  onClose,
}) => {
  const intlCurrency = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });
  const intlFormatNumber = new Intl.NumberFormat('it-IT');
  const [isCustomerOrderLoading, setIsCustomerOrderLoading] = useState(false);
  const [customerOrderDetails, setCustomerOrderDetails] =
    useState<Nullable<CustomerQuoteDetails>>(null);

  useEffect(() => {
    if (_.isEmpty(quoteDocumentCode)) {
      setCustomerOrderDetails(null);
      return;
    }

    getCustomerQuoteDetails(
      quoteDocumentCode,
      setCustomerOrderDetails,
      setIsCustomerOrderLoading,
    );
  }, [quoteDocumentCode]);

  const columns: Array<CustomerQuoteLineTableColunns> = [
    {
      key: 'description',
      sortable: true,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.quote.details.lines.description"
            defaultMessage="Descrizione articolo"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-documentNumber">
          {data.description}
        </div>
      ),
    },
    {
      key: 'quantity',
      sortable: true,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.quote.details.lines.quantity"
            defaultMessage="Quantità"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {intlFormatNumber.format(_.toNumber(data.quantity))}{' '}
          {data.unitOfMeasureCode}
        </div>
      ),
    },
    {
      key: 'rowType',
      sortable: true,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.quote.details.lines.rowType"
            defaultMessage="Tipo ordine"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">{data.rowType}</div>
      ),
    },
    {
      key: 'lineAmount',
      sortable: true,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.quote.details.lines.lineAmount"
            defaultMessage="Importo riga"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-standard">
          {intlCurrency.format(_.toNumber(data.lineAmount))}
        </div>
      ),
    },
    {
      key: 'deliveryDate',
      sortable: false,
      name: (
        <div className="customer-documents-container-header">
          <FormattedMessage
            id="customerDocuments.quote.details.lines.plannedDeliveryDate"
            defaultMessage="Consegna prevista"
          />
        </div>
      ),
      formatter: ({ data }) => (
        <div className="customer-documents-table-date">
          {moment(data.promisedDeliveryDate, 'YYYY-MM-DD').format('ww/YYYY')}
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={!_.isEmpty(quoteDocumentCode)}
      onClose={onClose}
      className="customer-documents-modal"
    >
      <Button className="crossIcon" onClick={onClose}>
        <SvgIcon icon={ICONS.CROSS} />
      </Button>
      {isCustomerOrderLoading && (
        <div className="customer-documents-modal-loading">
          <CircularProgress color="inherit" />
          <FormattedMessage
            id="customerDocuments.quote.details.dataLoading"
            defaultMessage="Dettaglio offerta in caricamento"
          />
        </div>
      )}
      {!isCustomerOrderLoading && !_.isEmpty(customerOrderDetails) && (
        <>
          <Modal.Header className="upload-document-modal-header">
            <SvgIcon icon={ICONS.FILE} height={30} width={30} />
            <FormattedMessage
              id="customerDocuments.quote.details.header.title"
              defaultMessage="Documento offerta {orderNumber}"
              values={{
                orderNumber: customerOrderDetails.no,
              }}
            />
          </Modal.Header>
          <Modal.Content className="noPaddingTop upload-document-modal-content">
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.externalCode"
                  defaultMessage="Codice esterno: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.yourReference}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.date"
                  defaultMessage="Data offerta: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {moment(customerOrderDetails.orderDate, 'YYYY-MM-DD').format(
                  'DD MMM YYYY',
                )}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.paymentMethod"
                  defaultMessage="Metodo pagamento: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.paymentMethod}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.paymentTerms"
                  defaultMessage="Termini pagamento: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.paymentTerms}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quotes.details.priceNoVat"
                  defaultMessage="Importo IVA esclusa: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {intlCurrency.format(_.toNumber(customerOrderDetails.amount))}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.shipToCustomer"
                  defaultMessage="Spedire a: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.shipToName}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.shipAddress"
                  defaultMessage="Indirizzo spedizione: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.shipToAddress +
                  ' (' +
                  customerOrderDetails.shipToCity +
                  ')'}
              </span>
            </li>
            <li>
              <span className="customer-documents-field-title">
                <FormattedMessage
                  id="customerDocuments.quote.details.shipmentMethod"
                  defaultMessage="Resa: "
                />
              </span>
              <span className="customer-documents-field-data">
                {' '}
                {customerOrderDetails.shipmentMethod}
              </span>
            </li>
            <SemanticTableGrid
              className="customer-documents-table"
              columns={columns}
              elements={customerOrderDetails.lines}
              emptyResults={
                <FormattedMessage
                  id="customerDocuments.quote.details.lines.emptyTable"
                  defaultMessage="Nessun linea"
                />
              }
            />
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};

export default CustomerQuoteModal;
