import React, { createContext, useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import _ from 'lodash';

import { LangProviderProps, MessageIds } from './types';
import defaultMessages from '../../assets/lang/it-IT.json';
import { DEFAULT_LOCALE } from 'utils/global/globalConstants';
import { Dimmer, Header, Icon } from 'semantic-ui-react';
import moment from 'moment';

export const supportedLanguages = ['it-IT', 'en'];

/**
 * Check if the current language passed as param is currently supported
 * @param language Required langue for check
 */
export const isLanguageSupported = (language: string): boolean => {
  return _.includes(supportedLanguages, language);
};

/**
 * Create lang provider for access to change language in every point of the application.
 * const { language, setLanguage } = useContext(LangProviderContext); => for import context
 */
export const LangProviderContext = createContext<{
  language: string;
  setLanguage: (language: string) => void;
}>({
  language: isLanguageSupported(navigator.language)
    ? navigator.language
    : 'it-IT',
  setLanguage: language => (language = language),
});

// @TODO => add the selected language also in the local storage for avoid loses selected lang on refresh

/**
 * The LangProvider is meant to inject the application messages based on the current language.
 * In the first versione, the language comes from the browser settings, but it will be replaced
 * from the one saved in the logged user profile.
 *
 * There are several issues to deal on the scalability of the {lang}.json file.
 * Here some ideas:
 * - Add a loader (the website may not be ready until all the assets are loaded)
 * - Render the App component without translation (it takes the default language, that could be loaded statically)
 * @param param0
 * @returns
 */
const LangProvider: React.FC<LangProviderProps> = ({ children }) => {
  /**
   * Get language from navigator (defualt browser's language)
   * Check if the language is currently supported, if it's not set as default en
   */
  const [language, setLanguage] = useState(
    isLanguageSupported(navigator.language) ? navigator.language : 'it-IT',
  );
  const [messages, setMessages] = useState<
    | Record<MessageIds, string>
    | Record<MessageIds, MessageFormatElement[]>
    | null
  >(null);

  // Called on component mount
  useEffect(() => {
    loadLocale();
  }, []);

  /*
   * Load asynchronously the current language messages from the compiled file.
   * @TODO: It could be NOT production-ready since loading external assets in production could lead to some issues.
   */
  const loadLocale = async () => {
    const messageByLanguage = require.context(
      '../../assets/lang/',
      false,
      /\.json$/,
    );

    let messages = defaultMessages;

    if (language !== DEFAULT_LOCALE) {
      try {
        messages = messageByLanguage(`./${language}.json`);
      } catch (err) {}
    }

    setMessages(messages);
    moment.locale(language);
  };

  // When the language state changes and the language is supported load the new messages
  useEffect(() => {
    loadLocale();
  }, [language]);

  // If messages has not been loaded return a error page
  if (!messages) {
    return (
      <Dimmer active page>
        <Header as="h2" icon inverted>
          <Icon name="alarm" />
          Unable to load language library
          <Header.Subheader>
            <p>We are sorry, try to reload the page.</p>
            <p>If the error persists contact the support</p>
          </Header.Subheader>
        </Header>
      </Dimmer>
    );
  }

  return (
    <LangProviderContext.Provider value={{ language, setLanguage }}>
      <IntlProvider
        locale={language}
        messages={messages}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </LangProviderContext.Provider>
  );
};

export default LangProvider;
