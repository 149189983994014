import {
  MetalResume,
  MetalResumeAccountEntries,
  MetalResumeAccountSupportedSort,
  MetalResumeSupportedSort,
} from '../types';

export enum MetalResumeConstants {
  CHANGE_ACTIVE_SORT_METAL = 'MetalResume/CHANGE_ACTIVE_SORT_METAL',
  CHANGE_ACTIVE_SORT_METAL_ACCOUNT = 'MetalResume/CHANGE_ACTIVE_SORT_METAL_ACCOUNT',
  COLLAPSE_METAL_ACTIVITY = 'MetalResume/COLLAPSE_METAL_ACTIVITY',
  RESET_ALL_FILTERS = 'MetalResume/RESET_ALL_FILTERS',
  SET_COMPLETE_METAL_RESUMES_COLLECTION = 'MetalResume/SET_COMPLETE_METAL_RESUMES_COLLECTION',
  SET_FILTERED_METAL_RESUMES_COLLECTION = 'MetalResume/SET_FILTERED_METAL_RESUMES_COLLECTION',
  SET_METAL_RESUMES_LOADING = 'MetalResume/SET_METAL_RESUMES_LOADING',
  SET_COMPLETE_METAL_RESUMES_ACCOUNT = 'MetalResume/SET_COMPLETE_METAL_RESUMES_ACCOUNT',
  SET_METAL_RESUMES_ACCOUNT_LOADING = 'MetalResume/SET_METAL_RESUMES_ACCOUNT_LOADING',
}

export type MetalResumeAction =
  | {
      type: MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL;
      payload: { activeSortMetal: MetalResumeSupportedSort };
    }
  | {
      type: MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL_ACCOUNT;
      payload: { activeSortAccount: MetalResumeAccountSupportedSort };
    }
  | {
      type: MetalResumeConstants.COLLAPSE_METAL_ACTIVITY;
    }
  | {
      type: MetalResumeConstants.RESET_ALL_FILTERS;
    }
  | {
      type: MetalResumeConstants.SET_COMPLETE_METAL_RESUMES_COLLECTION;
      payload: { metalResumeCollection: Array<MetalResume> };
    }
  | {
      type: MetalResumeConstants.SET_FILTERED_METAL_RESUMES_COLLECTION;
      payload: { filteredMetalResumeCollection: Array<MetalResume> };
    }
  | {
      type: MetalResumeConstants.SET_METAL_RESUMES_LOADING;
      payload: { areMetalResumeLoading: boolean };
    }
  | {
      type: MetalResumeConstants.SET_METAL_RESUMES_ACCOUNT_LOADING;
      payload: { areMetalResumesAccountLoading: boolean };
    }
  | {
      type: MetalResumeConstants.SET_COMPLETE_METAL_RESUMES_ACCOUNT;
      payload: { metalResume: MetalResumeAccountEntries };
    };
