import React from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

// Constants and utils
import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';
import {
  UserDetailsAction,
  UserDetailsConstants,
} from 'pages/UserDetails/reducer/action';
import { NavigateFunction } from 'react-router-dom';
import { Contact } from 'services/auth/types';

export const fetchUserData = (
  dispatch: React.Dispatch<UserDetailsAction>,
  userId: string,
  navigate: NavigateFunction,
): void => {
  dispatch({
    type: UserDetailsConstants.SET_DETAILS_LOADING,
    payload: { areDetailsLoading: true },
  });

  standardClient({
    url: API.SUPPLIER + '/' + userId,
    method: 'GET',
  })
    .then((response: AxiosResponse<Contact>) => {
      dispatch({
        type: UserDetailsConstants.SET_USER_DETAILS,
        payload: { userDetails: response.data },
      });
    })
    .catch((error: AxiosResponse) => {
      if (error.status === 404) {
        navigate('/');
      }
      toast.error('Errore nel recuperare i dati');
    })
    .finally(() => {
      dispatch({
        type: UserDetailsConstants.SET_DETAILS_LOADING,
        payload: { areDetailsLoading: false },
      });
    });
};
