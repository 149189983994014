import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { UserOrdersContext } from 'pages/UserOrders';
import { Order, OrderLine } from 'pages/UserOrders/types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import CompleteOrderTable from '../CompleteOrderTable';
import OrdersFiltersBar from '../OrdersFiltersBar';

export const OrdersList: React.FC = () => {
  const { state } = useContext(UserOrdersContext);

  const getOrdersLine = (orders: Array<Order>) => {
    const orderLineArray: Array<OrderLine> = [];
    let orderedLines: Array<OrderLine> = [];

    orders.map(order => order.lines.map(line => orderLineArray.push(line)));

    switch (state.activeSort) {
      case 'order-number-desc': {
        orderedLines = orderLineArray.sort((firstOrder, secondOrder) => {
          if (firstOrder.orderNumber === secondOrder.orderNumber) {
            if (firstOrder.lineNo > secondOrder.lineNo) {
              return 1;
            }
            return -1;
          }

          if (firstOrder.orderNumber > secondOrder.orderNumber) {
            return -1;
          } else {
            return 1;
          }
        });
        break;
      }
      case 'delivery-date-asc': {
        orderedLines = orderLineArray.sort((firstOrder, secondOrder) =>
          moment(firstOrder.plannedReceiptDate, 'YYYY-MM-DD').diff(
            moment(secondOrder.plannedReceiptDate, 'YYYY-MM-DD'),
          ),
        );
        break;
      }
      case 'delivery-date-desc': {
        orderedLines = orderLineArray.sort((firstOrder, secondOrder) =>
          moment(secondOrder.plannedReceiptDate, 'YYYY-MM-DD').diff(
            moment(firstOrder.plannedReceiptDate, 'YYYY-MM-DD'),
          ),
        );
        break;
      }
      default: {
        orderedLines = orderLineArray.sort((firstOrder, secondOrder) => {
          if (firstOrder.orderNumber === secondOrder.orderNumber) {
            if (firstOrder.lineNo > secondOrder.lineNo) {
              return 1;
            }
            return -1;
          }

          if (firstOrder.orderNumber > secondOrder.orderNumber) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
    return orderedLines;
  };

  const renderOrderBody = () => {
    // If data has not been already loaded show a loader
    if (state.areOrdersLoading) {
      return (
        <>
          <CircularProgress color="inherit" />
          <span className="order-accordion-content-loading">
            <FormattedMessage
              id="accordion.list.orders.loading"
              defaultMessage="Ordini aperti in caricamento"
            />
          </span>
        </>
      );
    }

    // If there are no open orders from API show only a message
    if (_.isEmpty(state.filteredOrdersCollection)) {
      return (
        <span className="order-accordion-content-loading">
          <FormattedMessage
            id="accordion.list.userOrders.noOrders"
            defaultMessage="Non sono presenti ordini"
          />
        </span>
      );
    }

    return (
      <CompleteOrderTable
        orders={getOrdersLine(state.filteredOrdersCollection)}
      />
    );
  };

  return (
    <div className="orders-list-container">
      <OrdersFiltersBar />
      {renderOrderBody()}
    </div>
  );
};

export default OrdersList;
