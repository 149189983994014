import SvgIcon from 'components/SvgIcon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ICONS } from 'utils/global/globalConstants';

const NoContactSelected: React.FC = () => {
  return (
    <div className="no-contact-selected-container">
      <div className="no-contact-selected-title">
        <SvgIcon icon={ICONS.WARNING_CIRCLE} height={36} width={36} />
        <FormattedMessage
          id="no-contact-selected-title"
          defaultMessage="Selezionare un contatto"
        />
      </div>
      <div className="no-contact-selected-subtitle">
        <span>
          <FormattedMessage
            id="no-contact-selected-subtitle-1"
            defaultMessage="Non è stato selezionato il contatto di cui si vogliono visualizzare i dati"
          />
        </span>
        <span>
          <FormattedMessage
            id="no-contact-selected-subtitle-2"
            defaultMessage="Si prega di selezionarlo dal menù laterale"
          />
        </span>
      </div>
    </div>
  );
};

export default NoContactSelected;
