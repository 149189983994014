import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const gear = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 22}
    height={height || 22}
    viewBox="0 0 22 22"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.4824 8.49506L19.7915 8.12747C19.6444 7.67731 19.4623 7.23822 19.2473 6.81525L20.1834 5.35918C20.3506 5.09901 20.3138 4.75745 20.0953 4.53891L17.4611 1.90472C17.2426 1.68619 16.901 1.64943 16.6408 1.8166L15.1848 2.75269C14.7618 2.53767 14.3227 2.35556 13.8725 2.20853L13.5049 0.517639C13.4393 0.215515 13.1718 0 12.8626 0H9.13741C8.82823 0 8.56068 0.215515 8.49506 0.517639L8.12747 2.20853C7.67731 2.35556 7.23822 2.53767 6.81525 2.75269L5.35918 1.8166C5.09901 1.64943 4.75745 1.68619 4.53891 1.90472L1.90472 4.53891C1.68619 4.75745 1.64943 5.09901 1.8166 5.35918L2.75269 6.81525C2.53767 7.23822 2.35556 7.67731 2.20853 8.12747L0.517639 8.49506C0.215515 8.56085 0 8.82823 0 9.13741V12.8626C0 13.1718 0.215515 13.4391 0.517639 13.5049L2.20853 13.8725C2.35556 14.3227 2.53767 14.7618 2.75269 15.1848L1.8166 16.6408C1.64943 16.901 1.68619 17.2426 1.90472 17.4611L4.53891 20.0953C4.75745 20.3138 5.09901 20.3506 5.35918 20.1834L6.81525 19.2473C7.23822 19.4623 7.67731 19.6444 8.12747 19.7915L8.49506 21.4824C8.56068 21.7845 8.82823 22 9.13741 22H12.8626C13.1718 22 13.4393 21.7845 13.5049 21.4824L13.8725 19.7915C14.3227 19.6444 14.7618 19.4623 15.1848 19.2473L16.6408 20.1834C16.901 20.3506 17.2426 20.314 17.4611 20.0953L20.0953 17.4611C20.3138 17.2426 20.3506 16.901 20.1834 16.6408L19.2473 15.1848C19.4623 14.7618 19.6444 14.3227 19.7915 13.8725L21.4824 13.5049C21.7845 13.4391 22 13.1718 22 12.8626V9.13741C22 8.82823 21.7845 8.56085 21.4824 8.49506ZM14.9442 11C14.9442 13.1748 13.1748 14.9442 11 14.9442C8.82521 14.9442 7.05577 13.1748 7.05577 11C7.05577 8.82521 8.82521 7.05577 11 7.05577C13.1748 7.05577 14.9442 8.82521 14.9442 11Z" />
  </svg>
);

export default gear;
