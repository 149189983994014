import { DropdownItemProps } from 'semantic-ui-react';
import { CustomerItem } from '../types';

export enum CustomerArticlePhaseConstants {
  SET_ARE_ORIGINS_LOADING = 'CustomerArticlePhase/SET_ARE_ORIGINS_LOADING',
  SET_IS_COLLECTION_LOADING = 'CustomerArticlePhase/SET_IS_COLLECTION_LOADING',
  SET_ITEMS_COLLECTION = 'CustomerArticlePhase/SET_ITEMS_COLLECTION',
  SET_ORIGINS_LIST = 'CustomerArticlePhase/SET_ORIGINS_LIST',
  SET_SELECTED_ORIGIN = 'CustomerArticlePhase/SET_SELECTED_ORIGIN',
}

export type CustomerArticlePhaseAction =
  | {
      type: CustomerArticlePhaseConstants.SET_ARE_ORIGINS_LOADING;
      payload: { areOriginsLoading: boolean };
    }
  | {
      type: CustomerArticlePhaseConstants.SET_IS_COLLECTION_LOADING;
      payload: { isCollectionLoading: boolean };
    }
  | {
      type: CustomerArticlePhaseConstants.SET_ITEMS_COLLECTION;
      payload: { itemsCollection: Array<CustomerItem> };
    }
  | {
      type: CustomerArticlePhaseConstants.SET_ORIGINS_LIST;
      payload: { orginsList: Array<DropdownItemProps> };
    }
  | {
      type: CustomerArticlePhaseConstants.SET_SELECTED_ORIGIN;
      payload: { selectedOriginCode: string };
    };
