import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const arrowRight = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 9}
    height={height || 15}
    viewBox="0 0 9 15"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16429 14.2499C1.77376 14.6404 1.1406 14.6404 0.750075 14.2499V14.2499C0.359551 13.8594 0.359552 13.2262 0.750076 12.8357L5.83586 7.74992L0.750075 2.66414C0.359551 2.27361 0.359551 1.64045 0.750075 1.24992V1.24992C1.1406 0.8594 1.77376 0.859401 2.16429 1.24992L7.95718 7.04282C8.34771 7.43334 8.34771 8.06651 7.95718 8.45703L2.16429 14.2499Z"
    />
  </svg>
);

export default arrowRight;
