import React from 'react';
import { COLORS } from 'utils/global/globalConstants';

export const clock = (
  className: string | undefined,
  color: COLORS | undefined,
  height: number | undefined,
  width: number | undefined,
): JSX.Element => (
  <svg
    className={className}
    width={width || 25}
    height={height || 24}
    viewBox="0 0 25 24"
    fill={color || COLORS.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.9304 2C7.41043 2 2.94043 6.48 2.94043 12C2.94043 17.52 7.41043 22 12.9304 22C18.4604 22 22.9404 17.52 22.9404 12C22.9404 6.48 18.4604 2 12.9304 2ZM12.9404 20C8.52043 20 4.94043 16.42 4.94043 12C4.94043 7.58 8.52043 4 12.9404 4C17.3604 4 20.9404 7.58 20.9404 12C20.9404 16.42 17.3604 20 12.9404 20Z" />
    <path d="M13.4404 7H11.9404V13L17.1904 16.15L17.9404 14.92L13.4404 12.25V7Z" />
  </svg>
);

export default clock;
