import { API } from 'utils/global/backendRoutes';
import { AxiosResponse } from 'axios';
import standardClient from 'services/client/standardRequestClient';
import { SupplierDashboardData } from 'components/Dashboards/Supplier/types';

export const getSupplierDashboard = (
  setAreDashboardDataLoading: (value: boolean) => void,
  setDashboardData: (dashboardData: SupplierDashboardData) => void,
): void => {
  setAreDashboardDataLoading(true);

  standardClient
    .request({
      url: API.SUPPLIER_DASHBOARD,
      method: 'GET',
    })
    .then((response: AxiosResponse<SupplierDashboardData>) => {
      setDashboardData(response.data);
    })
    .finally(() => setAreDashboardDataLoading(false));
};
