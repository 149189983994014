import React from 'react';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// Constants - Internals
import { FE_ROUTES } from 'utils/global/globalConstants';

// Images
import signupCompletedLogo from 'assets/images/signup-completed.svg';
import mainLogo from 'assets/images/main-logo.svg';
import { FormattedMessage } from 'react-intl';

const SignupCompleted: React.FC = () => {
  return (
    <div className="signup-completed">
      <Image
        src={mainLogo}
        className="signup-completed-msm-logo"
        as={Link}
        to={FE_ROUTES.LOGIN}
      />
      <Image
        src={signupCompletedLogo}
        className="signup-completed-check-logo"
      />
      <div className="signup-completed-title">
        <FormattedMessage
          id="signupCompleted.tilte"
          defaultMessage="La richiesta di registrazione è avvenuta con successo"
        />
      </div>
      <div className="signup-completed-subtitle">
        <FormattedMessage
          id="signupCompleted.subtitle"
          defaultMessage="La registrazione ora è in fase di validazione. {newLine} Riceverai una mail di conferma entro 24h lavorative."
          values={{ newLine: <br /> }}
        />
      </div>
    </div>
  );
};

export default SignupCompleted;
