import { CreditsLimit } from '../types';

export enum CreditsLimitConstants {
  SET_CREDITS_LIMIT_DATA = 'CreditsLimit/SET_METAL_AVAILABILITY_SUMMARY_DATA',
  SET_CREDITS_LIMIT_LOADING = 'CreditsLimit/SET_METAL_AVAILABILITY_LOADING',
}

export type CreditsLimitAction =
  | {
      type: CreditsLimitConstants.SET_CREDITS_LIMIT_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: CreditsLimitConstants.SET_CREDITS_LIMIT_DATA;
      payload: {
        creditsLimit: CreditsLimit;
      };
    };
