import { MetalAvailability } from '../types';

export enum MetalAvailabilityConstants {
  SET_METAL_AVAILABILITY_SUMMARY_DATA = 'MetalAvailability/SET_METAL_AVAILABILITY_SUMMARY_DATA',
  SET_METAL_AVAILABILITY_LOADING = 'MetalAvailability/SET_METAL_AVAILABILITY_LOADING',
}

export type MetalAvailabilityAction =
  | {
      type: MetalAvailabilityConstants.SET_METAL_AVAILABILITY_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: MetalAvailabilityConstants.SET_METAL_AVAILABILITY_SUMMARY_DATA;
      payload: {
        metalAvailability: MetalAvailability;
      };
    };
