import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { DropdownItemProps } from 'semantic-ui-react';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { NationalityResponse } from './types';

export const getNationalitiesOptions = (
  setNationalityOptions: React.Dispatch<
    React.SetStateAction<DropdownItemProps[]>
  >,
  regionCodeAsValue = false,
): void => {
  standardClient({
    url: API.NATIONALITY,
    method: 'GET',
  })
    .then((response: AxiosResponse<NationalityResponse>) => {
      let nationalityOptions = [];
      if (regionCodeAsValue) {
        nationalityOptions = response.data._embedded.country.map(country => ({
          text: country.name,
          value: country.code,
        }));
      } else {
        nationalityOptions = response.data._embedded.country.map(country => ({
          text: country.name,
          value: country.id,
        }));
      }

      const orderedNationalityOptions = _.orderBy(nationalityOptions, 'text');

      setNationalityOptions(orderedNationalityOptions);
    })
    .catch(() => {
      toast.error('Impossibile caricare le nazionalità');
    });
};
