import React, { useState, useEffect } from 'react';
import { Button, DropdownItemProps, Modal } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

import { NotMandatoryDocumentModalProps } from './types';
import SvgIcon from 'components/SvgIcon';
import { DATE_REGEX, ICONS } from 'utils/global/globalConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Form,
  Input,
  ResetButton,
  Select,
  SubmitButton,
} from 'formik-semantic-ui-react';
import moment from 'moment';
import CalendarPicker from 'components/CalendarPicker';
import FileInputField from 'components/FileInputField';
import { Nullable } from 'utils/types';
import { getDocumentsTypeList } from 'services/document/getDocumentsTypeList';
import _ from 'lodash';

const NotMandatoryDocumentModal: React.FC<NotMandatoryDocumentModalProps> = ({
  isOpen,
  onClose,
  uploadDocumentFile,
}) => {
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] =
    useState<Nullable<string>>(null);
  const [contactDocumentsList, setContactDocumentsList] = useState<
    Array<DropdownItemProps>
  >([]);
  const intl = useIntl();

  useEffect(() => {
    getDocumentsTypeList(setContactDocumentsList);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="tiny"
      className="upload-document-modal"
    >
      <Button className="crossIcon" onClick={onClose}>
        <SvgIcon icon={ICONS.CROSS} />
      </Button>
      <Modal.Header className="upload-document-modal-header">
        <SvgIcon icon={ICONS.FILE} height={30} width={30} />
        <FormattedMessage
          id="uploadDocumentModal.header.title"
          defaultMessage="Carica documento"
        />
      </Modal.Header>
      <Modal.Content className="noPaddingTop">
        <FormattedMessage
          id="uploadDocumentModal.notMandatory.header.subtitle"
          defaultMessage="Carica il documento, seleziona la data di emissione, di scadenza e il tipo di documento"
        />
      </Modal.Content>
      <Formik
        initialValues={{
          documentType: '',
          documentFile: null,
          expirationDate: null,
          releaseDate: null,
        }}
        validationSchema={Yup.object().shape({
          documentType: Yup.string().required(
            intl.formatMessage({
              id: 'notMandatoryDocumentModal.error.documentType.required',
              defaultMessage: 'Seleziona il tipo di documento',
            }),
          ),
          documentFile: Yup.mixed().required(
            intl.formatMessage({
              id: 'notMandatoryDocumentModal.error.document.required',
              defaultMessage: 'Caricare un file',
            }),
          ),
          expirationDate: Yup.string()
            .matches(
              DATE_REGEX,
              intl.formatMessage({
                id: 'notMandatoryDocumentModal.error.expirationDate.valid',
                defaultMessage:
                  'Inserire una data valida nel formato GG-MM-YYYY',
              }),
            )
            .nullable()
            .required(
              intl.formatMessage({
                id: 'notMandatoryDocumentModal.error.expirationDate.required',
                defaultMessage: 'Seleziona la data di scadenza del documento',
              }),
            )
            .test(
              'max_date',
              intl.formatMessage({
                id: 'notMandatoryDocumentModal.error.expirationDate.maxDate',
                defaultMessage: 'La data deve essere succcessiva ad oggi',
              }),
              value => {
                return moment().isBefore(moment(value, 'DD-MM-YYYY'));
              },
            ),
          releaseDate: Yup.string()
            .matches(
              DATE_REGEX,
              intl.formatMessage({
                id: 'notMandatoryDocumentModal.error.releaseDate.valid',
                defaultMessage:
                  'Inserire una data valida nel formato GG-MM-YYYY',
              }),
            )
            .nullable()
            .required(
              intl.formatMessage({
                id: 'notMandatoryDocumentModal.error.releaseDate.required',
                defaultMessage: 'Seleziona la data di emissione del documento',
              }),
            )
            .test(
              'min_date',
              intl.formatMessage({
                id: 'notMandatoryDocumentModal.error.releaseDate.minDate',
                defaultMessage: 'La data deve essere precedente ad oggi',
              }),
              value => {
                return moment().isAfter(moment(value, 'DD-MM-YYYY'));
              },
            ),
        })}
        onSubmit={values =>
          uploadDocumentFile(
            values.documentFile,
            moment(values.releaseDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            moment(values.expirationDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            values.documentType,
          )
        }
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="upload-document-modal-form">
              <div className="input-container">
                <Select
                  name="documentType"
                  placeholder={intl.formatMessage({
                    id: 'notMandotryDocumentModal.documentType.placeholder',
                    defaultMessage: 'Seleziona tipo documento',
                  })}
                  label={intl.formatMessage({
                    id: 'notMandotryDocumentModal.documentType.label',
                    defaultMessage: 'Tipo documento *',
                  })}
                  options={contactDocumentsList}
                  loading={_.isEmpty(contactDocumentsList)}
                />
                <ErrorMessage
                  name="documentType"
                  render={message => (
                    <span className="default-error-message">{message}</span>
                  )}
                />
              </div>
              <div className="input-container">
                <Input
                  name="releaseDate"
                  className="date-input"
                  placeholder="GG-MM-YYYY"
                  label={intl.formatMessage({
                    id: 'notMandotryDocumentModal.releaseDate.label',
                    defaultMessage: 'Data di emissione *',
                  })}
                  icon={
                    <span
                      className="calendar-modal-button"
                      onClick={() => setIsCalendarPickerOpen('releaseDate')}
                    >
                      <SvgIcon
                        icon={ICONS.CALENDAR_FLAT}
                        width={22}
                        height={22}
                      />
                    </span>
                  }
                />
                <ErrorMessage
                  name="releaseDate"
                  render={message => (
                    <span className="default-error-message">{message}</span>
                  )}
                />
              </div>
              <div className="input-container">
                <Input
                  name="expirationDate"
                  className="date-input"
                  placeholder="GG-MM-YYYY"
                  label={intl.formatMessage({
                    id: 'notMandotryDocumentModal.expirationDate.label',
                    defaultMessage: 'Data di scadenza *',
                  })}
                  icon={
                    <span
                      className="calendar-modal-button"
                      onClick={() => setIsCalendarPickerOpen('expirationDate')}
                    >
                      <SvgIcon
                        icon={ICONS.CALENDAR_FLAT}
                        width={22}
                        height={22}
                      />
                    </span>
                  }
                />
                <ErrorMessage
                  name="expirationDate"
                  render={message => (
                    <span className="default-error-message">{message}</span>
                  )}
                />
              </div>
              <div className="input-container">
                <FileInputField
                  field="documentFile"
                  label={intl.formatMessage({
                    id: 'notMandotryDocumentModal.documentUpload.label',
                    defaultMessage: 'Carica documento *',
                  })}
                  setFieldValue={setFieldValue}
                />
                <ErrorMessage
                  name="documentFile"
                  render={message => (
                    <span className="default-error-message file-error">
                      <br />
                      {message}
                    </span>
                  )}
                />
              </div>
            </div>
            <div className="upload-document-modal-buttons">
              <ResetButton
                className="underlineButton withMarginRight"
                content={intl.formatMessage({
                  id: 'uploadDocumentModal.actions.undoButton',
                  defaultMessage: 'Annulla',
                })}
                onClick={onClose}
              />
              <SubmitButton
                content={intl.formatMessage({
                  id: 'uploadDocumentModal.actions.confirmButton',
                  defaultMessage: 'Carica documento',
                })}
              />
            </div>

            <CalendarPicker
              key={isCalendarPickerOpen}
              minDate={
                isCalendarPickerOpen === 'expirationDate' ? moment() : null
              }
              maxDate={isCalendarPickerOpen === 'releaseDate' ? moment() : null}
              isOpen={!!isCalendarPickerOpen}
              onClose={() => setIsCalendarPickerOpen(null)}
              onChange={(date: Date) => {
                const formattedDate = moment(date)
                  .format('DD-MM-YYYY')
                  .toString();
                if (isCalendarPickerOpen === 'expirationDate') {
                  setFieldValue('expirationDate', formattedDate);
                } else {
                  setFieldValue('releaseDate', formattedDate);
                }
              }}
              value={
                moment(values[isCalendarPickerOpen], 'DD-MM-YYYY').isValid()
                  ? moment(values[isCalendarPickerOpen], 'DD-MM-YYYY')
                      .format('MM-DD-YYYY')
                      .toString()
                  : null
              }
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NotMandatoryDocumentModal;
