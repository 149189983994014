import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

// Components
import MetalAvailabilityItemsTable from 'components/CustomerMetalAvailability/MetalAvailabilityItemsTable';
import MetalAvailabilityPendingItemsTable from 'components/CustomerMetalAvailability/MetalAvailabilityPendingItemsTable';
import MetalAvailabilityContractsTable from 'components/CustomerMetalAvailability/MetalAvailabilityContractsTable';
import PageHeader from 'components/PageHeader';

// Constants - Internals - Utils
import { AppContext } from 'pages/App';
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import { getMetalAvailability } from 'services/customer/getCustomerMetalAvailability';
import {
  initialMetalAvailabilityState,
  MetalAvailabilityReducer,
} from './reducer/reducer';
import {
  MetalAvailabilityAction,
  MetalAvailabilityConstants,
} from './reducer/action';

// Types
import {
  MetalAvailability as MetalAvailabilityType,
  MetalAvailabilityState,
} from './types';
import { isThatRole } from 'utils/function/acl';
import NoContactSelected from 'components/NoContactSelected';
import _ from 'lodash';

export const MetalAvailabilityContext = createContext<{
  state: MetalAvailabilityState;
  dispatch: React.Dispatch<MetalAvailabilityAction>;
}>({
  state: initialMetalAvailabilityState,
  dispatch: () => null,
});

const MetalAvailability: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const appState = useContext(AppContext).state;
  const mySelf = appState.mySelf;

  const [state, dispatch] = useReducer(
    MetalAvailabilityReducer,
    initialMetalAvailabilityState,
  );

  useEffect(() => {
    // Some customer don't the access to metal account data
    // Eventually redirect it to the main page
    if (
      !mySelf._permissions.customerMetalAccountEnabled &&
      !isThatRole(ROLES.CUSTOMER_AGENT, appState)
    ) {
      navigate(FE_ROUTES.DASHBOARD);
    }

    // Two different calls for customer or customer's agent profile
    if (isThatRole(ROLES.CUSTOMER_AGENT, appState)) {
      callMetalAvailabilityForAgent();
    } else {
      getMetalAvailability(setIsLoading, setData, intl);
    }
  }, []);

  // Check when customerCode change for reload the metal availability data
  useEffect(() => {
    callMetalAvailabilityForAgent();
  }, [appState.selectedContact]);

  // Check if the agent has selected the customer otherwise don't do the api call
  const callMetalAvailabilityForAgent = () => {
    if (!_.isEmpty(appState.selectedContact)) {
      getMetalAvailability(
        setIsLoading,
        setData,
        intl,
        appState.selectedContact.contactCode,
      );
    }
  };

  const setIsLoading = (isMetalAvailabilityLoading: boolean) => {
    dispatch({
      type: MetalAvailabilityConstants.SET_METAL_AVAILABILITY_LOADING,
      payload: { isLoading: isMetalAvailabilityLoading },
    });
  };

  const setData = (metalAvailabilityData: MetalAvailabilityType) => {
    dispatch({
      type: MetalAvailabilityConstants.SET_METAL_AVAILABILITY_SUMMARY_DATA,
      payload: { metalAvailability: metalAvailabilityData },
    });
  };

  return (
    <MetalAvailabilityContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'customerMetalAvailability.helmet.title',
            defaultMessage: 'MSM - Copertura metallo',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'customerMetalAvailability.header.title',
          defaultMessage: 'Copertura metallo',
        })}
      />
      {isThatRole(ROLES.CUSTOMER_AGENT, appState) &&
      _.isEmpty(appState.selectedContact) ? (
        <NoContactSelected />
      ) : (
        <div className="metalAvailability-container">
          {state.isMetalAvailabilityLoading ? (
            <div className="metalAvailability-loading">
              <CircularProgress color="inherit" />
              <span>
                <FormattedMessage
                  id="customerMetalAvailability.loading"
                  defaultMessage="Copertura in caricamento"
                />
              </span>
            </div>
          ) : (
            <>
              <MetalAvailabilityItemsTable
                metalAvailabilityItems={state.metalAvailability.items}
                totalAlloyQuantity={state.metalAvailability.totalAlloyQuantity}
              />
              <MetalAvailabilityPendingItemsTable
                pendingItems={state.metalAvailability.pendingItems}
              />
              {mySelf._permissions.customerContractsEnabled && (
                <MetalAvailabilityContractsTable
                  contracts={state.metalAvailability.contracts}
                />
              )}
            </>
          )}
        </div>
      )}
    </MetalAvailabilityContext.Provider>
  );
};

export default MetalAvailability;
