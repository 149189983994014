export const CUSTOMER_DOCUMENTS_PER_PAGE = 20;

export enum CUSTOMER_DOCUMENT_METHODS {
  SALES_INVOICE = 'sales-invoice-documents',
  SALES_ORDER = 'sales-order-documents',
  SALES_QUOTE = 'sales-quote-documents',
  SALES_CREDITS_NOTES = 'sales-credit-memo-documents',
  SALES_GENERAL_SHIPMENT = 'sales-general-shipment-documents',
  SALES_CONTRACTS = 'sales-contract-documents',
  SALES_CONTRACTS_DETAILS = 'sales-contract-details-document',
  SALES_SHIPMENT_DOCUMENTS = 'sales-shipment-documents',
  SALES_SHIPMENT_PACKING_LIST = 'sales-shipment-packing-list',
  SALES_SHIPMENT_CERTIFICATE = 'sales-shipment-certificate',
  GENERAL_SHIPMENT_PACKING_LIST = 'general-shipment-packing-list',
  GENERAL_SHIPMENT_CERTIFICATE = 'general-shipment-certificate',
  CUSTOMER_TERMS_DOCUMENT = 'customer-terms-documents',
}

export enum CUSTOMER_SHIPMENT_TYPE {
  SALES_SHIPMENT_TYPE = 'sales',
  GENRAL_SHIPMENT_TYPE = 'general',
}

export const YEARS_FILTER_QUANTITY = 4;
