import { LangProviderContext } from 'components/LangProvider';
import PageHeader from 'components/PageHeader';
import TooltipEmail from 'components/TooltipEmail';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-semantic-ui-react';
import { AppContext } from 'pages/App';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { updateUserData } from 'services/user/updateUserData';
import { isThatRole } from 'utils/function/acl';
import { ROLES } from 'utils/global/globalConstants';

const Myself: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { setLanguage } = useContext(LangProviderContext);

  const languageToFormat = ['it-IT'];

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'myself.header.title',
            defaultMessage: 'MSM - Profilo personale',
          })}
        </title>
      </Helmet>
      <PageHeader
        title={intl.formatMessage({
          id: 'myself.title',
          defaultMessage: 'Profilo personale',
        })}
      />
      <div className="myself-container">
        <Formik
          initialValues={{
            name: state.mySelf._registry.contact?.name,
            vatNumber: state.mySelf._registry.contact?.vatNumber,
            phoneNumber: state.mySelf._registry.contact?.phoneNumber,
            fiscalCode: state.mySelf._registry.contact?.fiscalCode,
            emailAddress: state.mySelf.email,
            emailAddress2: state.mySelf.email2 || '',
            emailAddress3: state.mySelf.email3 || '',
            language: state.mySelf.defaultLanguage,
          }}
          onSubmit={values => {
            let language = values.language;

            if (languageToFormat.includes(language)) {
              language = language.substring(0, values.language.indexOf('-'));
            }

            // Update the langugae in the LangProvider
            updateUserData(
              state.mySelf.id,
              language,
              values.emailAddress,
              values.emailAddress2,
              values.emailAddress3,
              setLanguage,
              dispatch,
              intl,
            );
          }}
          enableReinitialize
        >
          <Form className="contact-origin-details-form" id="myself-form">
            <Grid>
              <span className="myself-contact-description">
                {isThatRole([ROLES.CUSTOMER]) &&
                  intl.formatMessage({
                    id: 'myself.tooltip.customer.description.firstLine',
                    defaultMessage:
                      'Alle email riportate verranno indirizzate tutte le comunicazioni e notifiche del portale (ad esempio recupero e aggiornamento password, eventuali documenti in scadenza, ...).',
                  })}
              </span>
              {isThatRole([ROLES.CONTACT, ROLES.NOVICE_CONTACT]) && (
                <>
                  <Grid.Row columns={2}>
                    <Grid.Column className="left-column">
                      <Input
                        name="name"
                        label={intl.formatMessage({
                          id: 'myself.form.name',
                          defaultMessage: 'Ragione sociale',
                        })}
                        readOnly
                      />
                    </Grid.Column>
                    <Grid.Column className="right-column">
                      <Input
                        name="vatNumber"
                        label={intl.formatMessage({
                          id: 'myself.form.vatNumber',
                          defaultMessage: 'Partita IVA',
                        })}
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column className="left-column">
                      <Input
                        name="fiscalCode"
                        label={intl.formatMessage({
                          id: 'myself.form.fiscalCode',
                          defaultMessage: 'Codice fiscale',
                        })}
                        readOnly
                      />
                    </Grid.Column>
                    <Grid.Column className="right-column">
                      <Input
                        name="phoneNumber"
                        label={intl.formatMessage({
                          id: 'myself.form.phoneNumber',
                          defaultMessage: 'Numero di telefono',
                        })}
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              <Grid.Row columns={2}>
                <Grid.Column className="left-column">
                  <Input
                    name="emailAddress"
                    label={
                      <span>
                        {intl.formatMessage({
                          id: 'myself.form.email',
                          defaultMessage: 'Email per notifiche',
                        })}
                        <span>
                          <TooltipEmail />
                        </span>
                      </span>
                    }
                    readOnly
                  />
                </Grid.Column>
                <Grid.Column className="right-column">
                  <Select
                    name="language"
                    label={intl.formatMessage({
                      id: 'myself.form.language',
                      defaultMessage: 'Lingua',
                    })}
                    options={[
                      { text: 'English', value: 'en' },
                      { text: 'Italiano', value: 'it' },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column className="left-column">
                  <Input
                    name="emailAddress2"
                    label={
                      <span>
                        {intl.formatMessage({
                          id: 'myself.form.email2',
                          defaultMessage: 'Email per notifiche 2',
                        })}
                      </span>
                    }
                  />
                </Grid.Column>
                <Grid.Column className="right-column">
                  <Input
                    name="emailAddress3"
                    label={
                      <span>
                        {intl.formatMessage({
                          id: 'myself.form.email3',
                          defaultMessage: 'Email per notifiche 3',
                        })}
                      </span>
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Formik>
        <div className="contact-origin-details-footer">
          <div className="footer-content">
            <Button
              className="underlineButton"
              content={intl.formatMessage({
                id: 'myself.button.toList',
                defaultMessage: 'Torna alla home',
              })}
              onClick={() => navigate('/')}
            />
            <Button
              className="insertButton"
              content={intl.formatMessage({
                id: 'myself.button.save',
                defaultMessage: 'Salva',
              })}
              form="myself-form"
              type="submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Myself;
