import { AppContext } from 'pages/App';
import React, { useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownItemProps, Select } from 'semantic-ui-react';
import { fetchOriginsListOptions } from 'services/reservation/fetchOriginsList';
import {
  customerArticlePhaseReducer,
  initialCutomerArticlePhaseState,
} from './reducer/reducer';
import { CustomerArticlePhaseConstants } from './reducer/action';
import _ from 'lodash';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { getCustomerItemsCollection } from 'services/reservation/getCustomerItemsCollection';
import { CustomerItem, CustomerItemTableColumns } from './types';
import SemanticTableGrid from 'semantic-table-grid';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { CustomerReservationContext } from 'pages/CustomerReservation';
import { CustomerReservationConstants } from 'pages/CustomerReservation/reducer/action';

const CustomerArticlePhase: React.FC = () => {
  const intl = useIntl();
  const myself = useContext(AppContext).state.mySelf;
  const reservationDispatch = useContext(CustomerReservationContext).dispatch;
  const reservationState = useContext(CustomerReservationContext).state;
  const [state, dispatch] = useReducer(
    customerArticlePhaseReducer,
    initialCutomerArticlePhaseState,
  );

  useEffect(() => {
    // Fetch the origins list for the current contact
    // This is required to get the article list with the right filters
    fetchOriginsListOptions(
      myself._registry.contact?.contactCode,
      setOriginsList,
      setAreOriginsLoading,
      intl,
    );
  }, []);

  useEffect(() => {
    // Look for change of the selected origin
    // Whent it changes get the articles collection of that origin
    if (_.isEmpty(state.selectedOrigin)) {
      dispatch({
        type: CustomerArticlePhaseConstants.SET_ITEMS_COLLECTION,
        payload: { itemsCollection: [] },
      });
    } else {
      getCustomerItemsCollection(
        setIsCollectionLoading,
        setItemsCollection,
        state.selectedOrigin,
        intl,
      );
    }
  }, [state.selectedOrigin]);

  const setAreOriginsLoading = (areOriginsLoading: boolean) => {
    dispatch({
      type: CustomerArticlePhaseConstants.SET_ARE_ORIGINS_LOADING,
      payload: { areOriginsLoading },
    });
  };

  const setOriginsList = (originsCollection: Array<DropdownItemProps>) => {
    dispatch({
      type: CustomerArticlePhaseConstants.SET_ORIGINS_LIST,
      payload: { orginsList: originsCollection },
    });
  };

  const setIsCollectionLoading = (isLoading: boolean) => {
    dispatch({
      type: CustomerArticlePhaseConstants.SET_IS_COLLECTION_LOADING,
      payload: { isCollectionLoading: isLoading },
    });
  };

  const setItemsCollection = (itemsCollection: Array<CustomerItem>) => {
    dispatch({
      type: CustomerArticlePhaseConstants.SET_ITEMS_COLLECTION,
      payload: { itemsCollection: itemsCollection },
    });
  };

  const itemTableColumns: Array<CustomerItemTableColumns> = [
    {
      key: 'customerItemIcon',
      name: <></>,
      formatter: () => (
        <SvgIcon
          icon={ICONS.WEIGHT}
          color={COLORS.SECONDARY}
          height={30}
          width={30}
        />
      ),
      width: '7%',
    },
    {
      key: 'customerItemCode',
      name: (
        <FormattedMessage
          id="customer.articlePhase.table.header.code"
          defaultMessage="Codice"
        />
      ),
      formatter: ({ data }) => data.itemCode,
    },
    {
      key: 'customerItemDescription',
      name: (
        <FormattedMessage
          id="customer.articlePhase.table.header.description"
          defaultMessage="Descrizione"
        />
      ),
      formatter: ({ data }) => data.itemDescription,
    },
    {
      key: 'orderAction',
      name: '',
      formatter: ({ data }) => (
        <Checkbox
          color="primary"
          onChange={(_e, checked) =>
            checked
              ? reservationDispatch({
                  type: CustomerReservationConstants.ADD_ITEM,
                  payload: { itemCode: data.itemCode },
                })
              : reservationDispatch({
                  type: CustomerReservationConstants.REMOVE_ITEM,
                  payload: { itemCode: data.itemCode },
                })
          }
          checked={_.includes(reservationState.selectedItems, data.itemCode)}
        />
      ),
    },
  ];

  return (
    <div className="customer-article-phase-container">
      <div className="customer-reservation-header">
        <span className="customer-reservation-header-title">
          <FormattedMessage
            id="customer.articlePhase.header.title.origin"
            defaultMessage="Provenienza"
          />
        </span>
        <span className="customer-reservation-header-subtitle">
          <FormattedMessage
            id="customer.articlePhase.header.subtitle.origin"
            defaultMessage="Selezionare prima una provenienza per visualizzare gli articoli disponibili"
          />
        </span>
      </div>
      <div className="customer-orgins-select-container">
        <Select
          icon={
            state.areOriginsLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <SvgIcon icon={ICONS.ARROW_DOWN} />
            )
          }
          noResultsMessage={intl.formatMessage({
            id: 'customer.articlePhase.orginSelect.empty',
            defaultMessage: 'Nessuna provenienza trovata',
          })}
          onChange={(_e, data) => {
            dispatch({
              type: CustomerArticlePhaseConstants.SET_SELECTED_ORIGIN,
              payload: {
                selectedOriginCode: _.isString(data.value) ? data.value : '',
              },
            });
            reservationDispatch({
              type: CustomerReservationConstants.CLEAR_ITEMS,
            });
          }}
          options={state.originsList}
          placeholder={intl.formatMessage({
            id: 'customer.articlePhase.orginSelect.placeholder',
            defaultMessage: 'Selezione una proveneinza',
          })}
          search
          disabled={state.areOriginsLoading}
        />
      </div>
      <div className="customer-reservation-header">
        <span className="customer-reservation-header-title">
          <FormattedMessage
            id="customer.articlePhase.header.title.item"
            defaultMessage="Articolo"
          />
        </span>
        <span className="customer-reservation-header-subtitle">
          <FormattedMessage
            id="customer.articlePhase.header.subtitle.item"
            defaultMessage="Selezionare uno o piuù articoli tra quelli disponibili"
          />
        </span>
      </div>
      <div className="semantic-table-grid-standard">
        <SemanticTableGrid
          className="select-order-table"
          columns={itemTableColumns}
          elements={state.itemsCollection}
          emptyResults={
            <div className="customer-articlePhase-table-empty">
              {state.isCollectionLoading ? (
                <>
                  <CircularProgress color="inherit" />
                  <FormattedMessage
                    id="customer.articlePhase.table.loading"
                    defaultMessage="Articoli in caricamento"
                  />
                </>
              ) : _.isEmpty(state.selectedOrigin) ? (
                <FormattedMessage
                  id="customer.articlePhase.table.empty.noOrigin"
                  defaultMessage="Selezionare un'origine per visualizzare gli articoli disponibili"
                />
              ) : (
                <FormattedMessage
                  id="customer.articlePhase.table.empty.noArticle"
                  defaultMessage="Nessun articolo disponibile"
                />
              )}
            </div>
          }
        />
      </div>
      <div className="customer-articlePhase-table-footer">
        {reservationState.selectedItems.length === 1 && (
          <FormattedMessage
            id="customer.articlePhase.footer.selectedItem"
            defaultMessage="1 articolo selezionato"
          />
        )}
        {reservationState.selectedItems.length > 1 && (
          <FormattedMessage
            id="acustomer.articlePhase.footer.selectedItems"
            defaultMessage="{itemsNumber} articoli selezionati"
            values={{
              itemsNumber: reservationState.selectedItems.length,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerArticlePhase;
