import React, { useContext } from 'react';
import { Formik } from 'formik';
import {
  Form,
  Input,
  ResetButton,
  SubmitButton,
} from 'formik-semantic-ui-react';
import { ContactOriginsListContext } from 'pages/ContactOriginsList';
import { ContactOriginsListConstants } from 'pages/ContactOriginsList/reducer/actions';
import _ from 'lodash';
import { useIntl } from 'react-intl';

export const OriginsFiltersBar: React.FC = () => {
  const intl = useIntl();
  const { state, dispatch } = useContext(ContactOriginsListContext);

  const onFilter = (businessName: string, vatNumber: string) => {
    let originsListToShow = state.completeOriginsCollection;

    if (!_.isEmpty(businessName)) {
      originsListToShow = originsListToShow.filter(
        origin =>
          _.isString(origin.name) &&
          origin.name.toLowerCase().includes(businessName.toLowerCase()),
      );
    }

    if (!_.isEmpty(vatNumber)) {
      originsListToShow = originsListToShow.filter(
        origin =>
          _.isString(origin.vatNumber) &&
          origin.vatNumber.toLowerCase().includes(vatNumber.toLowerCase()),
      );
    }

    dispatch({
      type: ContactOriginsListConstants.SET_FILTERED_ORIGINS_COLLECTION,
      payload: { filteredOriginsCollection: originsListToShow },
    });
  };

  return (
    <div className="origins-filters-bar">
      <Formik
        initialValues={{
          businessName: '',
          vatNumber: '',
        }}
        onSubmit={values => onFilter(values.businessName, values.vatNumber)}
        onReset={() =>
          dispatch({
            type: ContactOriginsListConstants.RESET_ALL_FILTERS,
          })
        }
      >
        <Form>
          <Input
            className="origin-name-input"
            placeholder={intl.formatMessage({
              id: 'originsFilterBar.placeholder.businessName',
              defaultMessage: 'Azienda',
            })}
            name="businessName"
          />
          <Input
            className="origin-vatNumber-input"
            placeholder={intl.formatMessage({
              id: 'originsFilterBar.placeholder.vatNumber',
              defaultMessage: 'Partita IVA',
            })}
            name="vatNumber"
          />
          <SubmitButton
            className="origin-filters-search"
            content={intl.formatMessage({
              id: 'contactOriginList.filter.button.search',
              defaultMessage: 'Cerca',
            })}
            loading={false}
          />
          <ResetButton
            className="underlineButton origin-filters-reset"
            content={intl.formatMessage({
              id: 'contactOriginList.filter.button.reset',
              defaultMessage: 'Rimuovi i filtri attivi',
            })}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default OriginsFiltersBar;
