import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FE_ROUTES } from 'utils/global/globalConstants';
import { CustomerReservationStateConstants } from 'utils/global/reservationConstants';

const TypeSelection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="typeSelection-container">
      <div className="typeSelection-title">
        <FormattedMessage
          id="customerReservation.typeSelection.title"
          defaultMessage="Selezionare il tipo di prenotazione"
        />
      </div>
      <div className="typeSelection-subtitle">
        <FormattedMessage
          id="customerReservation.typeSelection.subtitle"
          defaultMessage="Indicare la tipologia di prenotazione che si intende effettuare"
        />
      </div>
      <div
        className="typeSelection-buttonsContainer"
        onClick={() =>
          navigate(
            FE_ROUTES.CUSTOMER_RESERVATION +
              '/' +
              CustomerReservationStateConstants.DELIVERY,
          )
        }
      >
        <div className="typeSelection-button">
          <FormattedMessage
            id="customerReservation.typeSelection.button.delivery"
            defaultMessage="Consegna"
          />
        </div>
        <div
          className="typeSelection-button"
          onClick={() =>
            navigate(
              FE_ROUTES.CUSTOMER_RESERVATION +
                '/' +
                CustomerReservationStateConstants.PICKUP,
            )
          }
        >
          <FormattedMessage
            id="customerReservation.typeSelection.button.pickup"
            defaultMessage="Ritiro"
          />
        </div>
      </div>
    </div>
  );
};

export default TypeSelection;
