import { MetalResumeState } from '../types';
import { MetalResumeAction, MetalResumeConstants } from './action';

export const initialMetalResumeState: MetalResumeState = {
  activeSortMetal: 'end-date-desc',
  areFiltersActive: false,
  areMetalResumeLoading: false,
  completeMetalResumeCollection: [],
  filteredMetalResumeCollection: [],
  isMetalActivityCollapsed: true,
  metalAccountResumeList: [],
  metalAccountData: {
    initialQtyAlloy: '',
    initialQty: '',
    yourReference: '',
    finalQtyAlloy: '',
  },
  areMetalResumesAccountLoading: false,
  activeSortAccount: 'postingDate-desc',
};

export const metalResumeReducer = (
  state: MetalResumeState,
  action: MetalResumeAction,
): MetalResumeState => {
  const { type } = action;

  switch (type) {
    case MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL:
      return { ...state, activeSortMetal: action.payload.activeSortMetal };
    case MetalResumeConstants.CHANGE_ACTIVE_SORT_METAL_ACCOUNT:
      return { ...state, activeSortAccount: action.payload.activeSortAccount };
    case MetalResumeConstants.COLLAPSE_METAL_ACTIVITY:
      return {
        ...state,
        isMetalActivityCollapsed: !state.isMetalActivityCollapsed,
      };
    case MetalResumeConstants.RESET_ALL_FILTERS:
      return {
        ...state,
        filteredMetalResumeCollection: state.completeMetalResumeCollection,
      };
    case MetalResumeConstants.SET_COMPLETE_METAL_RESUMES_COLLECTION:
      return {
        ...state,
        completeMetalResumeCollection: action.payload.metalResumeCollection,
        filteredMetalResumeCollection: action.payload.metalResumeCollection,
      };
    case MetalResumeConstants.SET_FILTERED_METAL_RESUMES_COLLECTION:
      return {
        ...state,
        filteredMetalResumeCollection:
          action.payload.filteredMetalResumeCollection,
      };
    case MetalResumeConstants.SET_METAL_RESUMES_LOADING:
      return {
        ...state,
        areMetalResumeLoading: action.payload.areMetalResumeLoading,
      };
    case MetalResumeConstants.SET_COMPLETE_METAL_RESUMES_ACCOUNT: {
      const metalData = {
        initialQtyAlloy: action.payload.metalResume.initialQtyAlloy,
        initialQty: action.payload.metalResume.initialQty,
        yourReference: action.payload.metalResume.yourReference,
        finalQtyAlloy: action.payload.metalResume.finalQtyAlloy,
      };
      return {
        ...state,
        metalAccountResumeList: action.payload.metalResume.lines,
        metalAccountData: metalData,
      };
    }
    case MetalResumeConstants.SET_METAL_RESUMES_ACCOUNT_LOADING:
      return {
        ...state,
        areMetalResumesAccountLoading:
          action.payload.areMetalResumesAccountLoading,
      };
    default:
      return state;
  }
};
