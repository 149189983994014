import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Button } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import CustomerArticlePhase from 'components/CustomerReservation/CustomerArticlePhase';
import PageHeader from 'components/PageHeader';
import StepMenu from 'components/Reservation/StepMenu';

// Constants - Internals - Utils
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import { initialReservationState, reservationReducer } from './reducer/reducer';
import {
  mapCustomerReservationStateToStepMenu,
  mapStateToStepMenuProps,
} from 'components/Reservation/StepMenu/mapStateToStepMenuProps';
import {
  CustomerReservationConstants,
  CustomerReservationAction,
} from './reducer/action';
import { CustomerReservationStateConstants } from 'utils/global/reservationConstants';

// Services

// Types
import { CustomerReservationState } from './types';
import { isThatRole } from 'utils/function/acl';
import { AppContext } from 'pages/App';
import _ from 'lodash';
import TypeSelection from 'components/CustomerReservation/TypeSelection';

export const CustomerReservationContext = createContext<{
  state: CustomerReservationState;
  dispatch: React.Dispatch<CustomerReservationAction>;
}>({
  state: initialReservationState,
  dispatch: () => null,
});

const CustomerReservation: React.FC = () => {
  const { reservationId, mode } = useParams();
  const contextState = useContext(AppContext).state;

  const navigate = useNavigate();
  const intl = useIntl();

  const [state, dispatch] = useReducer(
    reservationReducer,
    initialReservationState,
  );

  /**
   * Need also the useEffect that look for the reservatonId changes in
   * the URL beacause on reload page when we create a new reservation
   * (from ArticlePhase to BaseInformationPhase) the useEffect of component
   * initialization isn't reloaded
   */
  useEffect(() => {
    /*     if (reservationId !== ReservationStateConstants.NEW) {
      fetchReservation(dispatch, reservationId, navigate);
    } */
  }, [reservationId]);

  useEffect(() => {
    if (mode === 'edit' && isThatRole(ROLES.ADMIN, contextState)) {
      navigate(`/reservation/${reservationId}/view`);
    }
  }, [mode]);

  const isNextButtonVisible = (): boolean => {
    if (
      reservationId === CustomerReservationStateConstants.PICKUP ||
      reservationId === CustomerReservationStateConstants.DELIVERY
    ) {
      return true;
    }

    return false;
  };

  /** Render the reservation main content based on the current state */
  const renderReservationContent = () => {
    //const readOnly = mode === 'edit' ? false : true;

    if (reservationId === CustomerReservationStateConstants.TYPE_SELECTION) {
      return <TypeSelection />;
    }
    if (
      reservationId === CustomerReservationStateConstants.PICKUP ||
      reservationId === CustomerReservationStateConstants.DELIVERY
    ) {
      return <CustomerArticlePhase />;
    }
  };

  /**
   * Custom calls for the reservation's NEW state, there isn't a form so the post function
   * must be called explicitly.
   * The others submit function are linked to the button with form param
   */
  const onCustomerReservationSubmit = () => {
    /* else if (
      state.reservation &&
      state.reservation.state ===
        CustomerReservationStateConstants.DOCUMENT_UPLOADING
    ) {
      nextStateReservation(
        {
          state: CustomerReservationStateConstants.TO_APPROVE,
          vehiclePlate: state.reservation.vehiclePlate,
        },
        reservationId,
        setIsLoading,
        setReservation,
      );
    } */
  };

  /**
   * Call the function that maps the props for the step menu based on
   * the current state and return them
   */
  const getCurrentStateStepMenuProps = () => {
    if (isNewReservation()) {
      return mapCustomerReservationStateToStepMenu(reservationId);
    }
    if (reservationId === CustomerReservationStateConstants.TYPE_SELECTION) {
      return mapStateToStepMenuProps('type_selection');
    }
    return mapStateToStepMenuProps(
      reservationId === 'new' ? 'new' : state.reservation?.state,
    );
  };

  const onUndoButtonClick = () => {
    if (
      reservationId === CustomerReservationStateConstants.PICKUP ||
      reservationId === CustomerReservationStateConstants.DELIVERY
    ) {
      dispatch({
        type: CustomerReservationConstants.CLEAR_ITEMS,
      });
      navigate(
        FE_ROUTES.CUSTOMER_RESERVATION +
          '/' +
          CustomerReservationStateConstants.TYPE_SELECTION,
      );
    }
  };

  const isNewReservation = (): boolean => {
    return [
      CustomerReservationStateConstants.TYPE_SELECTION,
      CustomerReservationStateConstants.DELIVERY,
      CustomerReservationStateConstants.PICKUP,
      'new',
    ].includes(reservationId);
  };

  /**
   * Based on the current state return a menu collection - this is necessary because
   * when a reservation is completed the options on the left are different
   * @returns Array<StepMenuElement> Array of options for menu
   */
  const getReservationStepMenu = () => {
    if (
      state.reservation?.state ===
        CustomerReservationStateConstants.TO_APPROVE ||
      state.reservation?.state === CustomerReservationStateConstants.APPROVED ||
      state.reservation?.state === CustomerReservationStateConstants.CLOSED
    ) {
      return [
        {
          description: intl.formatMessage({
            id: 'reservation.reservationCompleteStepMenu.info',
            defaultMessage: 'Informazioni sulla spedizione',
          }),
          key: 1,
          number: 1,
        },
        {
          description: intl.formatMessage({
            id: 'reservation.reservationCompleteStepMenu.documentation',
            defaultMessage: 'Documentazione aggiuntiva',
          }),
          key: 2,
          number: 2,
        },
        {
          description: intl.formatMessage({
            id: 'reservation.reservationCompleteStepMenu.vehiclePlate',
            defaultMessage: 'Targa veicolo',
          }),
          key: 3,
          number: 3,
        },
      ];
    }

    return [
      {
        description: intl.formatMessage({
          id: 'customerReservation.reservationStepMenu.typeSelection',
          defaultMessage: 'Tipologia prenotazione',
        }),
        key: 1,
        number: 1,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.article',
          defaultMessage: 'Articolo',
        }),
        key: 2,
        number: 2,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.information',
          defaultMessage: 'Informazioni sulla spedizione',
        }),
        key: 3,
        number: 3,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.deliveryDate',
          defaultMessage: 'Data consegna',
        }),
        key: 4,
        number: 4,
      },
      {
        description: intl.formatMessage({
          id: 'reservation.reservationStepMenu.additionalDocumentation',
          defaultMessage: 'Documentazione aggiuntiva',
        }),
        key: 5,
        number: 5,
      },
    ];
  };

  return (
    <CustomerReservationContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'customer.reservation.title',
            defaultMessage: 'MSM - Prenotazione',
          })}
        </title>
      </Helmet>
      {isNewReservation() ? (
        <PageHeader
          title={intl.formatMessage({
            id: 'customer.reservation.header.newReservation',
            defaultMessage: 'Nuova richiesta di prenotazione',
          })}
        />
      ) : (
        <PageHeader
          title={intl.formatMessage(
            {
              id: 'customer.reservation.header.savedReservation',
              defaultMessage: '',
              //defaultMessage: 'Prenotazione {reservationNumber}',
            },
            //{ reservationNumber: state.reservation?.bookingNumber },
          )}
        />
      )}

      <div className="customer-reservation-container">
        <div className="reservation-content">
          <div className="reservation-content-sidebar">
            <StepMenu
              blockedPhase={getCurrentStateStepMenuProps().blockedPhase}
              currentPhase={getCurrentStateStepMenuProps().currentPhase}
              editablePhase={getCurrentStateStepMenuProps().editablePhase}
              stepMenuDefinition={getReservationStepMenu()}
            />
          </div>
          <div className="reservation-content-form">
            {renderReservationContent()}
          </div>
        </div>
        <div className="reservation-footer">
          <Button
            className="underlineButton withMarginRight"
            content={
              mode === 'view' ||
              state.reservation?.state ===
                CustomerReservationStateConstants.FINISHED
                ? intl.formatMessage({
                    id: 'customer.reservation.button.toList',
                    defaultMessage: 'Torna alla lista',
                  })
                : intl.formatMessage({
                    id: 'customer.reservation.button.back',
                    defaultMessage: 'Precedente',
                    description:
                      'Button label for the back button in reservation flow',
                  })
            }
            disabled={state.isPrevStateLoading || state.isReservationLoading}
            loading={state.isPrevStateLoading}
            onClick={() => onUndoButtonClick()}
            /*             onClick={() => {
              // I am adming go back pge
              if (checkRole()) {
                navigate(-1);
              } else if (
                reservationId === CustomerReservationStateConstants.NEW ||
                state.reservation?.state ===
                  CustomerReservationStateConstants.FINISHED ||
                mode === 'view'
              ) {
                navigate(FE_ROUTES.RESERVATION_LIST + '/working');
              } else if (
                state.reservation?.state ===
                CustomerReservationStateConstants.DELIVERY_INFORMATION
              ) {
                prevStateReservation(
                  {
                    state:
                      CustomerReservationStateConstants.DELIVERY_INFORMATION + '_back',
                  },
                  reservationId,
                  setIsPrevLoading,
                  setReservation,
                );
              } else if (
                state.reservation?.state ===
                ReservationStateConstants.DOCUMENT_UPLOADING
              ) {
                prevStateReservation(
                  {
                    state:
                      ReservationStateConstants.DOCUMENT_UPLOADING + '_back',
                  },
                  reservationId,
                  setIsPrevLoading,
                  setReservation,
                );
              } else if (
                state.reservation?.state ===
                ReservationStateConstants.BASE_INFORMATION
              ) {
                navigate(FE_ROUTES.RESERVATION_DETAILS + '/new');
                /** @TODO for base information state must delete the reservation 
              }
            }} */
          />
          {isNextButtonVisible() && (
            <Button
              className="mainButton insertButton"
              content={
                [
                  CustomerReservationStateConstants.TO_APPROVE,
                  CustomerReservationStateConstants.APPROVED,
                ].includes(state.reservation?.state)
                  ? intl.formatMessage({
                      id: 'customer.reservation.button.save',
                      defaultMessage: 'Salva modifiche',
                      description: 'Button label for the save data',
                    })
                  : intl.formatMessage({
                      id: 'customer.reservation.button.next',
                      defaultMessage: 'Continua',
                    })
              }
              loading={state.isReservationLoading}
              disabled={
                (_.isEmpty(state.selectedItems) &&
                  (reservationId === CustomerReservationStateConstants.PICKUP ||
                    reservationId ===
                      CustomerReservationStateConstants.DELIVERY)) ||
                state.isPrevStateLoading ||
                state.isReservationLoading
              }
              // Call the submit function of the form with this ID
              form={state.reservation?.state || null}
              onClick={() => onCustomerReservationSubmit()}
              type="submit"
            />
          )}
        </div>
      </div>
    </CustomerReservationContext.Provider>
  );
};

export default CustomerReservation;
