import {
  CustomerReservationStateConstants,
  ReservationStateConstants,
} from 'utils/global/reservationConstants';
import { MappedStepMenuProps } from './types';

/**
 * Function for map the props needed from StepMenu for each reservation state
 * @param currentState string of the current state
 * @returns mapped props for the current state
 */
export const mapStateToStepMenuProps = (
  currentState: string,
): MappedStepMenuProps => {
  switch (currentState) {
    case ReservationStateConstants.NEW:
      return { blockedPhase: [], currentPhase: 1, editablePhase: [] };
    case ReservationStateConstants.BASE_INFORMATION:
      return { blockedPhase: [], currentPhase: 2, editablePhase: [] };
    case ReservationStateConstants.DELIVERY_INFORMATION:
      return { blockedPhase: [], currentPhase: 3, editablePhase: [] };
    case ReservationStateConstants.DOCUMENT_UPLOADING:
      return { blockedPhase: [], currentPhase: 4, editablePhase: [] };
    case ReservationStateConstants.FINISHED:
      return { blockedPhase: [], currentPhase: 4, editablePhase: [] };
    case ReservationStateConstants.TO_APPROVE:
      return { blockedPhase: [1], currentPhase: 0, editablePhase: [2, 3] };
    case ReservationStateConstants.APPROVED:
      return { blockedPhase: [1], currentPhase: 0, editablePhase: [2, 3] };
    case ReservationStateConstants.CLOSED:
      return { blockedPhase: [1, 2, 3], currentPhase: 0, editablePhase: [] };
    default:
      return { blockedPhase: [], currentPhase: 0, editablePhase: [] };
  }
};

export const mapCustomerReservationStateToStepMenu = (
  currentState: string,
): MappedStepMenuProps => {
  switch (currentState) {
    case CustomerReservationStateConstants.TYPE_SELECTION:
      return { blockedPhase: [], currentPhase: 1, editablePhase: [] };
    case CustomerReservationStateConstants.DELIVERY:
    case CustomerReservationStateConstants.PICKUP:
      return { blockedPhase: [], currentPhase: 2, editablePhase: [] };
    default:
      return { blockedPhase: [], currentPhase: 0, editablePhase: [] };
  }
};
