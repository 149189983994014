import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import ContactOriginForm from 'components/ContactOrigin/Form';
import { AppContext } from 'pages/App';
import { isThatRole } from 'utils/function/acl';
import { FE_ROUTES, ROLES } from 'utils/global/globalConstants';
import { ContactOriginAction, ContactOriginConstants } from './reducer/action';
import {
  contactOriginReducer,
  initialContactOriginState,
} from './reducer/reducer';
import { ContactOriginState, ContactType, OriginDetails } from './types';
import { postNewContact } from 'services/contactOrigin/postContactOrigin';
import { fetchContactAsOriginById } from 'services/contactOrigin/fetchContactAsOriginById';
import { getContactDocuments } from 'services/contactDocuments/getContactDocuments';
import { DocumentData } from 'pages/ContactDocuments/types';

export const ContactOriginContext = createContext<{
  state: ContactOriginState;
  dispatch: React.Dispatch<ContactOriginAction>;
}>({
  state: initialContactOriginState,
  dispatch: () => null,
});

const ContactOriginDetail: React.FC = () => {
  const intl = useIntl();

  const { originId, mode } = useParams();
  const navigate = useNavigate();

  const { state: appState } = useContext(AppContext);

  const [state, dispatch] = useReducer(
    contactOriginReducer,
    initialContactOriginState,
  );

  /**
   * Need also the useEffect that look for the originId changes in
   * the URL beacause on reload page when we create a new contact as origin
   */
  useEffect(() => {
    if (originId !== 'new') {
      fetchContactAsOriginById(originId, setIsLoading, intl, setContactOrigin);
      getContactDocuments(
        setIsLoadingDocuments,
        setDocuments,
        null,
        null,
        originId,
      );
    }
  }, [originId]);

  useEffect(() => {
    if (mode === 'edit' && isThatRole(ROLES.ADMIN, appState)) {
      navigate(`/${FE_ROUTES.ORIGINS_CONTACT}/${originId}/view`);
    }
  }, [mode]);

  // Update isLoading state variable
  const setIsLoading = (isLoading: boolean) => {
    dispatch({
      type: ContactOriginConstants.SET_IS_LOADGING,
      payload: { isLoading },
    });
  };

  // Update contactOrigin state variable
  const setContactOrigin = (contactOrigin: OriginDetails) => {
    dispatch({
      type: ContactOriginConstants.SET_CONTACT_ORIGIN,
      payload: { contactOrigin },
    });
  };

  // Update documents state variable
  const setDocuments = (documents: Array<DocumentData>) => {
    dispatch({
      type: ContactOriginConstants.SET_DOCUMENTS,
      payload: { documents: documents },
    });
  };

  // Update isLoadingDocuments state variable
  const setIsLoadingDocuments = (isLoadingDocuments: boolean) => {
    dispatch({
      type: ContactOriginConstants.SET_IS_LOADGING_DOCUMENTS,
      payload: { isLoadingDocuments },
    });
  };

  /** Submit form */
  const onSumbitForm = (
    values: OriginDetails,
    formikHelpers: FormikHelpers<OriginDetails>,
  ) => {
    if (originId === 'new') {
      postNewContact(values, setIsLoading, navigate, formikHelpers, intl);
    }
  };

  const initialValues = useMemo<OriginDetails>(() => {
    if (state.contactOrigin) {
      return {
        address: state.contactOrigin.address || '',
        city: state.contactOrigin.city || '',
        contactCode: state.contactOrigin.contactCode || '',
        isSedeSecondaria:
          state.contactOrigin.isSedeSecondaria || ContactType.OTHER_ORIGIN,
        name: state.contactOrigin.name || '',
        vatNumber: state.contactOrigin.vatNumber || '',
        fiscalCode: state.contactOrigin.fiscalCode || '',
        countryRegionCode: state.contactOrigin.countryRegionCode || 'IT',
        type: state.contactOrigin.isSedeSecondaria || '',
        documentFile: null,
        registeredOffice: state.contactOrigin.registeredOffice || false,
      };
    }

    return {
      address: '',
      city: '',
      contactCode: '',
      name: '',
      vatNumber: '',
      isSedeSecondaria: ContactType.OTHER_ORIGIN,
      fiscalCode: '',
      countryRegionCode: 'IT',
      type: ContactType.OTHER_ORIGIN,
      documentFile: null,
      registeredOffice: false,
    };
  }, [state.contactOrigin]);

  // Define if form is readOnyl mode
  const readOnly = mode === 'view' ? true : false;

  return (
    <ContactOriginContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <body />
        <title>
          {intl.formatMessage({
            id: 'origin.title',
            defaultMessage: 'MSM - Gestione provenienza',
            description: 'The title in the browser tab',
          })}
        </title>
      </Helmet>
      <div className="contact-origin-header">
        <span className="contact-origin-complete-name">
          {state.contactOrigin
            ? state.contactOrigin.name
            : intl.formatMessage({
                id: 'origin.completeName',
                defaultMessage: 'Nuova provenienza',
                description: 'Complete name of contact origin',
              })}
        </span>
      </div>
      <div className="contact-origin-details-body">
        <div className="contact-origin-details-main-content">
          <span className="form-title">
            {intl.formatMessage({
              id: 'contactOrginDetail.form.title',
              defaultMessage: 'Dati provenienza',
              description: 'The title in the browser tab',
            })}
          </span>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSumbitForm}
            validationSchema={Yup.object().shape({
              type: Yup.string().required().nullable(),
              name: Yup.string()
                .required(
                  intl.formatMessage({
                    id: 'contactOriginDetails.error.name.required',
                    defaultMessage: 'La ragione sociale è richiesta',
                  }),
                )
                .nullable(),
              countryRegionCode: Yup.string()
                .required(
                  intl.formatMessage({
                    id: 'contactOriginDetails.error.countryRegionCode.required',
                    defaultMessage: 'La nazionalità è richiesta',
                  }),
                )
                .nullable(),
              vatNumber: Yup.string().when('type', {
                is: (value: string) =>
                  value && value === ContactType.OTHER_ORIGIN,
                then: Yup.string()
                  .nullable()
                  .required(
                    intl.formatMessage({
                      id: 'contactOriginDetails.error.vatNumber.required',
                      defaultMessage: 'La partita IVA è richiesta',
                    }),
                  )
                  .min(
                    8,
                    intl.formatMessage({
                      id: 'contactOriginDetails.error.vatNumber.min',
                      defaultMessage: 'La partita IVA è troppo corta',
                    }),
                  )
                  .max(
                    20,
                    intl.formatMessage({
                      id: 'contactOriginDetails.error.vatNumber.max',
                      defaultMessage: 'La partita IVA è troppo lunga',
                    }),
                  ),
              }),
              documentFile: Yup.mixed().when('type', {
                is: (value: string) =>
                  value && value === ContactType.OTHER_ORIGIN,
                then: Yup.mixed().required(
                  intl.formatMessage({
                    id: 'updloadDocumentModal.error.document.required',
                    defaultMessage: 'Caricare un file',
                  }),
                ),
              }),
            })}
          >
            {props => (
              <ContactOriginForm
                formikProps={props}
                readOnly={readOnly}
                originId={originId}
              />
            )}
          </Formik>
        </div>
        <div className="contact-origin-details-footer">
          <div className="footer-content">
            <Button
              className="underlineButton"
              content={intl.formatMessage({
                id: 'contactOrginDetail.button.toList',
                defaultMessage: 'Torna alle provenienze',
                description:
                  'Button label for the back button in reservation flow',
              })}
              onClick={() => navigate(-1)}
            />
            {originId === 'new' && (
              <Button
                className="insertButton"
                content={intl.formatMessage({
                  id: 'contactOrginDetail.button.save',
                  defaultMessage: 'Salva',
                  description:
                    'Button label for the back button in reservation flow',
                })}
                form="contact-origin-form"
                type="submit"
                loading={state.isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </ContactOriginContext.Provider>
  );
};

export default ContactOriginDetail;
