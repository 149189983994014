import { AxiosResponse } from 'axios';
import { API } from 'utils/global/backendRoutes';
import { GetMetalAvailability } from './type';
import { IntlShape } from 'react-intl';
import { MetalAvailability } from 'pages/CustomerMetalAvailability/types';
import standardClient from 'services/client/standardRequestClient';
import { toast } from 'react-toastify';

export const getMetalAvailability = (
  setIsLoading: (isLoading: boolean) => void,
  setMetalAvailabilitySummary: (availability: MetalAvailability) => void,
  intl: IntlShape,
  customerCode?: string,
): void => {
  setIsLoading(true);

  standardClient({
    url: API.CUSTOMER_METAL_RESUME_AVAILABILITY,
    method: 'POST',
    data: {
      method: 'complete-data',
      params: {
        customerCode,
      },
    },
  })
    .then((response: AxiosResponse<GetMetalAvailability>) => {
      setMetalAvailabilitySummary(response.data);
    })
    .catch(() => {
      toast.error(
        intl.formatMessage({
          id: 'service.getCustomerMetalAvailability.error',
          defaultMessage:
            'Impossibile ottenere le informazioni sulla copertura metalli',
        }),
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
};
