import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

// Constants - Internals - Services - Utils
import { API } from 'utils/global/backendRoutes';
import standardClient from 'services/client/standardRequestClient';
import { ReservationStateConstants } from 'utils/global/reservationConstants';

// Types
import { AxiosResponse } from 'axios';
import { Reservation } from 'pages/Reservation/types';
import { StandardResponseError } from 'services/client/types';
import { FormikHelpers } from 'formik';
import { getFormikErrors } from 'utils/function/getFormikErrors';

/**
 * Send post request for create a new reservation for the order with the key passed as params.
 * If the reservation has been created successfully reload the page with the id returned form
 * the backend otherwise notify the error.
 *
 * @param orderKey string with the NAV order key0
 * @param setIsLoading function for manage loading variable
 * @param navigateTo function for redirect
 */
export const postNewReservation = (
  orderLines: Array<string>,
  setIsLoading: (isLoading: boolean) => void,
  navigateTo: (reservationId: string) => void,
): void => {
  setIsLoading(true);

  standardClient({
    url: API.RESERVATION,
    method: 'POST',
    data: {
      state: ReservationStateConstants.BASE_INFORMATION,
      orderLines: orderLines,
    },
  })
    .then((response: AxiosResponse<Reservation>) => {
      // Reservation created successfully, reload the page with the id
      navigateTo(response.data.id);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (error.data?.detail) {
        toast.error(error.data.detail);
      } else {
        const intl = useIntl();
        toast.error(
          intl.formatMessage({
            id: 'postNewReservation.error.create',
            defaultMessage: 'Impossibile creare una nuova prenotazione',
            description: 'Default error message showed in notification popup',
          }),
        );
      }
    })
    .finally(() => setIsLoading(false));
};

/**
 * When a reservation as been already created for update it you must use this method, based on the current state
 * the reservation data changes and also the validator on BE
 *
 * @param reservationData @TODO FIX unknown type
 * @param reservationId id of reservation
 * @param setReservation callback for update reservation in the state after positive BE response
 */
export const nextStateReservation = (
  reservationData: unknown,
  reservationId: string,
  setIsLoading: (isLoading: boolean) => void,
  setReservation: (reservation: Reservation) => void,
  formikHelpers?: FormikHelpers<Reservation>,
): void => {
  setIsLoading(true);

  standardClient({
    url: `${API.RESERVATION}/${reservationId}`,
    method: 'PUT',
    data: reservationData,
  })
    .then((response: AxiosResponse<Reservation>) => {
      if (response.data?.state === ReservationStateConstants.TO_APPROVE) {
        const reservation = {
          ...response.data,
          state: ReservationStateConstants.FINISHED,
        };
        setReservation(reservation);
      } else {
        setReservation(response.data);
      }
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (formikHelpers) {
        // Set the errors in the Formik bag.
        getFormikErrors(error, formikHelpers);
      }

      if (error.data?.detail) {
        toast.error(error.data.detail);
      } else {
        const intl = useIntl();
        toast.error(
          intl.formatMessage({
            id: 'postNewReservation.error.complete',
            defaultMessage: "Impossibile completare l'operazione",
            description: 'Default error message showed in notification popup',
          }),
        );
      }
    })
    .finally(() => setIsLoading(false));
};

export const prevStateReservation = (
  reservationData: unknown,
  reservationId: string,
  setIsLoading: (isLoading: boolean) => void,
  setReservation: (reservation: Reservation) => void,
): void => {
  setIsLoading(true);

  standardClient({
    url: `${API.RESERVATION}/${reservationId}`,
    method: 'PUT',
    data: reservationData,
  })
    .then((response: AxiosResponse<Reservation>) => {
      setReservation(response.data);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (error.data?.detail) {
        toast.error(error.data.detail);
      } else {
        const intl = useIntl();
        toast.error(
          intl.formatMessage({
            id: 'postNewReservation.error.complete',
            defaultMessage: "Impossibile completare l'operazione",
            description: 'Default error message showed in notification popup',
          }),
        );
      }
    })
    .finally(() => setIsLoading(false));
};

export const updateReservation = (
  reservationData: unknown,
  reservationId: string,
  setIsLoading: (isLoading: boolean) => void,
  setReservation: (reservation: Reservation) => void,
  formikHelpers?: FormikHelpers<Reservation>,
): void => {
  setIsLoading(true);

  standardClient({
    url: `${API.RESERVATION}/${reservationId}`,
    method: 'PUT',
    data: reservationData,
  })
    .then((response: AxiosResponse<Reservation>) => {
      setReservation(response.data);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (formikHelpers) {
        // Set the errors in the Formik bag.
        getFormikErrors(error, formikHelpers);
      }

      if (error.data?.detail) {
        toast.error(error.data.detail);
      } else {
        const intl = useIntl();
        toast.error(
          intl.formatMessage({
            id: 'postNewReservation.error.complete',
            defaultMessage: "Impossibile completare l'operazione",
            description: 'Default error message showed in notification popup',
          }),
        );
      }
    })
    .finally(() => setIsLoading(false));
};
