import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

export const updateLanguage = (
  userId: string,
  language: string,
  setLanguage: (language: string) => void,
  onClose: () => void,
): void => {
  standardClient({
    url: `${API.USERS}/${userId}`,
    method: 'PATCH',
    data: {
      defaultLanguage: language,
    },
  })
    .then(() => {
      // Close modal
      onClose();

      // Show success message
      toast.success('Lingua aggiornata con successo');

      // Update language on state
      setLanguage(language);
    })
    .catch(() => toast.error('Impossibile aggiornare la lingua'));
};

export const updateDefaultLanguage = (
  userId: string,
  language: string,
  intl: IntlShape,
): void => {
  standardClient({
    url: `${API.USERS}/${userId}`,
    method: 'PATCH',
    data: {
      defaultLanguage: language,
    },
  })
    .then(() => {
      // Show success message
      if (language === 'it') {
        toast.success('Lingua aggiornata correttamente');
      } else {
        toast.success('Default language updated');
      }
    })
    .catch(() =>
      toast.error(
        intl.formatMessage({
          id: 'service.user.updateDefaultLanguage.errror',
          defaultMessage: 'Impossibile aggiornare la lingua',
        }),
      ),
    );
};
