import React from 'react';
import { Button, ButtonGroup, Modal } from 'semantic-ui-react';
import { ConfirmModalProps } from './types';

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  cancelButtonText,
  confirmButtonText,
  isOpen,
  onClose,
  onConfirm,
  subtitleText,
  titleText,
  type = 'update',
}) => {
  const buttonText = confirmButtonText
    ? confirmButtonText
    : type === 'delete'
    ? 'Cancella'
    : type === 'insert'
    ? 'Salva'
    : 'Aggiorna';

  /** Button classname for the style */
  const confirmButtonClass = type + 'Button';

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>{titleText}</Modal.Header>
      {subtitleText && (
        <Modal.Content className="noPaddingTop">{subtitleText}</Modal.Content>
      )}
      <Modal.Actions>
        <ButtonGroup>
          <Button
            className="underlineButton withMarginRight"
            content={cancelButtonText ? cancelButtonText : 'Annulla'}
            onClick={onClose}
          />
          <Button
            className={`mainButton ${confirmButtonClass}`}
            content={buttonText}
            onClick={onConfirm}
          />
        </ButtonGroup>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
