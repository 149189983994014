import moment from 'moment';
import { Reservation } from 'pages/Reservation/types';
import { ReservationTimeSlot } from 'utils/global/reservationConstants';
import { CalendarState } from '../types/state';
import { CalendarAction, CalendarConstants } from './action';

export const initialCalendarState: CalendarState = {
  afternoonReservations: [],
  isCalendarOpen: false,
  isCollectionLoading: false,
  morningReservations: [],
  selectedDate: new Date(),
};

export const calendarReducer = (
  state: CalendarState,
  action: CalendarAction,
): CalendarState => {
  const { type } = action;

  switch (type) {
    case CalendarConstants.NEXT_DATE:
      return {
        ...state,
        selectedDate: moment(state.selectedDate).add(1, 'days').toDate(),
      };
    case CalendarConstants.PREVIOUS_DATE:
      return {
        ...state,
        selectedDate: moment(state.selectedDate).subtract(1, 'days').toDate(),
      };
    case CalendarConstants.SET_DATE:
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
      };
    case CalendarConstants.SET_IS_LOADING:
      return {
        ...state,
        isCollectionLoading: action.payload.isCollectionLoading,
      };
    case CalendarConstants.SET_OPEN_CALENDAR:
      return {
        ...state,
        isCalendarOpen: action.payload.isCalendarOpen,
      };
    case CalendarConstants.SET_RESERVATION_COLLECTION: {
      const afternoonReservations: Array<Reservation> = [];
      const morningReservations: Array<Reservation> = [];

      action.payload.reservationCollection.map(reservation => {
        if (reservation.timeSlot === ReservationTimeSlot.MORNING_SLOT) {
          morningReservations.push(reservation);
        } else {
          afternoonReservations.push(reservation);
        }
      });
      return {
        ...state,
        afternoonReservations: afternoonReservations,
        morningReservations: morningReservations,
      };
    }
  }
};
