import { Reservation } from 'pages/Reservation/types';

export enum ReservationListConstants {
  SET_ARE_RESERVATIONS_LOADING = 'ReservationList/SET_RESERVATION_LOADING',
  SET_CURRENT_PAGE = 'ReservationList/SET_CURRENT_PAGE',
  SET_RESERVATION_COLLECTION = 'ReservationList/SET_RESERVATION_COLLECTION',
  SET_TOTAL_PAGES = 'ReservationList/SET_TOTAL_PAGES',
  SET_ALLOWED_STATE = 'ReservationList/SET_ALLOWED_STATE',
}

export type ReservationListAction =
  | {
      type: ReservationListConstants.SET_ARE_RESERVATIONS_LOADING;
      payload: { areReservationLoading: boolean };
    }
  | {
      type: ReservationListConstants.SET_CURRENT_PAGE;
      payload: { currentPage: string | number };
    }
  | {
      type: ReservationListConstants.SET_RESERVATION_COLLECTION;
      payload: { reservationCollection: Array<Reservation> };
    }
  | {
      type: ReservationListConstants.SET_TOTAL_PAGES;
      payload: { totalePages: string | number };
    }
  | {
      type: ReservationListConstants.SET_ALLOWED_STATE;
      payload: { allowedState: Array<string> };
    };
